import { useMutation, useQueryClient } from '@tanstack/react-query';
import { httpOnboardCommerce } from 'app/api/commerce';
import { httpGetPlaceDetailsByID } from 'app/api/estimate';
import { CenterLogoHeader } from 'app/components/Header';
import { defaultProfileEntry, Profile, useGetProfile } from 'app/hooks/user';
import { getCurrentCountry } from 'app/utilities/country';
import { queryKeys } from 'app/utilities/query';
import { COMMERCE } from 'app/utilities/roles';
import { privateRoutes } from 'app/utilities/routes';
import { Session } from 'app/utilities/Session';
import { useFormik } from 'formik';
import { capitalize } from 'lodash';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import {
  ElrButton,
  ElrInput,
  ElrLeftArrowButton,
  ElrLocationSelect,
  ElrMainSelect,
  ElrPhoneInput,
} from 'ui-components';
import * as Yup from 'yup';

interface IOptions {
  value: any;
  label: string;
  id: number;
}

export interface BusinessInformationInterface {
  businessName: string;
  websiteUrl: string;
  businessEmail: string;
  businessPhone: string;
  country: string;
  fullAddress: {
    id: string;
    label: string;
    value: string;
    latitude: number;
    longitude: number;
    city: string;
    state: string;
    country: string;
    postalCode: string;
  };
  rcNumber: string;
}

const BusinessProfileSchema = Yup.object().shape({
  businessName: Yup.string()
    .matches(/^[a-zA-Z0-9\s]*$/, 'Only letters and numbers are allowed')
    .required('Required'),
  websiteUrl: Yup.string().optional(),
  businessEmail: Yup.string().required('Required'),
  fullAddress: Yup.object().shape({
    id: Yup.string().required('Required'),
    label: Yup.string().required('Required'),
    value: Yup.string().required('Required'),
  }),
  country: Yup.string().required('Required'),
  rcNumber: Yup.string().required('Required'),
  // certificateOfIncorporation: Yup.object().test(
  //   'hasFile',
  //   'Required',
  //   (value) => Object.keys(value || {}).length > 0
  // ),
});

const BusinessInformationChildComponent: React.FC = () => {
  const history = useNavigate();
  const queryClient = useQueryClient();
  const { id: userId } = useGetProfile();
  const [businessPhone, setBusinessPhone] = useState<{
    isValid: boolean;
    mobile: string;
    formattedNumber: string;
  }>({ isValid: false, mobile: '', formattedNumber: '' });
  const [countryOptions, setCountryOptions] = useState<IOptions>({
    id: 0,
    label: capitalize(getCurrentCountry()),
    value: getCurrentCountry(),
  });

  const tenantData = Session.tenantData();

  const onSelectChange = (selectedOption: any) => {
    const { value = '', label = '' } = selectedOption || {};

    if (Object.keys(tenantData).includes(value)) {
      setCountryOptions({ value, id: 0, label });
      setFieldValue('country', value);
    }
  };

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (payload: any) => httpOnboardCommerce(payload, userId),

    onSuccess: (data) => {
      queryClient.setQueryData(queryKeys.user, (oldData: any | Profile) =>
        oldData
          ? {
              ...oldData,
              currentAccessRole: COMMERCE,
              accountInformation: data,
            }
          : defaultProfileEntry
      );
    },
  });

  const { handleSubmit, values, handleChange, errors, setFieldValue } =
    useFormik<BusinessInformationInterface>({
      initialValues: {
        businessName: '',
        websiteUrl: '',
        businessEmail: '',
        businessPhone: '',
        rcNumber: '',
        fullAddress: {
          id: '',
          label: '',
          value: '',
          latitude: 0,
          longitude: 0,
          city: '',
          state: '',
          country: '',
          postalCode: '',
        },
        country: capitalize(getCurrentCountry()),
      },
      validationSchema: BusinessProfileSchema,
      onSubmit: (value: BusinessInformationInterface) =>
        onUpdateBusinessInfo(value),
    });

  // const onUploaded = (payload: FileStructureResponse, tag: string): void => {
  //   setFieldValue(tag, payload);
  // };

  const onLocationChange = async (payload: {
    id: string;
    label: string;
    value: string;
  }) => {
    if (!payload) return setFieldValue('fullAddress', null);
    const response = await httpGetPlaceDetailsByID(payload.id);
    return setFieldValue('fullAddress', {
      id: payload.id,
      label: payload.label,
      value: payload.label,
      latitude: response.geolocation.lat,
      longitude: response.geolocation.lng,
      city: response.place.city,
      state: response.place.state,
      country: response.place.country,
      postalCode: response.place.postalCode,
    });
  };

  const onPhoneInputChange = (payload: {
    isValid: boolean;
    mobile: string;
    formattedNumber: string;
  }) => {
    setBusinessPhone((prev) => ({ ...prev, ...payload }));
  };

  // eslint-disable-next-line consistent-return
  const onUpdateBusinessInfo = async (value: BusinessInformationInterface) => {
    try {
      const payload = {
        businessName: value.businessName,
        websiteUrl: value.websiteUrl,
        country: value.country,
        email: value.businessEmail,
        ...(businessPhone.formattedNumber && {
          phone: businessPhone.formattedNumber,
        }),
        // certificateOfIncorporation: value.certificateOfIncorporation,
        rcNumber: value.rcNumber,
        mainAddress: {
          country: value.fullAddress.country,
          state: value.fullAddress.state,
          fullAddress: value.fullAddress.label,
          placeId: value.fullAddress.id,
          latitude: String(value.fullAddress.latitude),
          longitude: String(value.fullAddress.longitude),
          city: value.fullAddress.city,
          postalCode: value.fullAddress.postalCode,
        },
        type: 'BVN',
      };
      await mutateAsync(payload);
      return history(privateRoutes.dashboard(COMMERCE));
    } catch (error) {
      /* noop */
    }
  };

  const goToLoginPortal = () => history(privateRoutes.selectPortal);

  return (
    <div className="flex flex-col w-11/12 mb-12 bg-elr-white-400 rounded-2xl md:pb-4 pb-9 md:w-430 px-7 md:px-10">
      <div className="text-xl text-center mb-7 mt-14">Business info</div>
      <form onSubmit={handleSubmit}>
        <div className="mb-10 md:mb-11">
          {errors?.businessName && (
            <span className="text-xs text-elr-error">
              {errors?.businessName}
            </span>
          )}
          <ElrInput
            className="mb-3 border-none rounded-none bg-elr-gray"
            placeholder="Business name *"
            type="name"
            name="businessName"
            value={values.businessName}
            onChange={handleChange}
          />
          {errors?.websiteUrl && (
            <span className="text-xs text-elr-error">{errors?.websiteUrl}</span>
          )}
          <ElrInput
            className="mb-3 border-none rounded-none bg-elr-gray"
            placeholder="Website URL"
            name="websiteUrl"
            value={values.websiteUrl}
            onChange={handleChange}
          />
          {errors?.businessEmail && (
            <span className="text-xs text-elr-error">
              {errors?.businessEmail}
            </span>
          )}
          <ElrInput
            className="mb-3 border-none rounded-none bg-elr-gray"
            placeholder="Business email *"
            type="email"
            name="businessEmail"
            value={values.businessEmail}
            onChange={handleChange}
          />
          {
            <span className="text-xs text-elr-error">
              {!businessPhone.isValid && businessPhone.mobile
                ? 'Invalid phone number'
                : null}
            </span>
          }
          {errors?.businessPhone && (
            <span className="text-xs text-elr-error">
              {errors?.businessPhone}
            </span>
          )}
          <ElrPhoneInput
            containerClass="w-full mb-3 flex justify-between"
            value={businessPhone.mobile}
            onChange={onPhoneInputChange}
            placeholder="Business phone *"
          />
          {errors?.fullAddress && (
            <span className="text-xs text-elr-error">Required</span>
          )}
          <ElrLocationSelect
            className="bg-white"
            currentValue={values.fullAddress?.label}
            placeholder="Enter address here"
            onChange={(value) => onLocationChange(value as any)}
          />
          {errors?.country && (
            <span className="text-xs text-elr-error">{errors?.country}</span>
          )}
          <ElrMainSelect
            placeholder="Country"
            onChange={onSelectChange}
            currentValue={countryOptions.label}
            value={countryOptions}
            data={Object.keys(tenantData)}
          />
          {/* <ElrUploadInput
            type="file"
            tag="certificateOfIncorporation"
            accept=".jpg,.jpeg,.png,.pdf,.docx,.doc"
            name="certificateOfIncorporation"
            value={values.certificateOfIncorporation}
            onUploaded={onUploaded}
            placeholder="Certificate of Incorporation*"
          /> */}

          <ElrInput
            className="mb-3 border-none rounded-none bg-elr-gray"
            placeholder="RC Number"
            type="text"
            name="rcNumber"
            value={values.rcNumber}
            onChange={handleChange}
          />
          {errors?.rcNumber && (
            <span className="text-xs text-elr-error">{errors?.rcNumber}</span>
          )}
        </div>
        <p className="text-xs text-elr-gray-700">
          This information will be used to verify your business and ensure
          compliance with our terms of service. We conduct periodic reviews to
          verify business authenticity. Please make sure to upload valid
          information to prevent your account from being locked.
        </p>
        <div className="flex justify-between mb-3 mt-3">
          <div
            onClick={goToLoginPortal}
            className="rounded-full h-9 w-9 mr-4 pl-2.5"
          >
            <ElrLeftArrowButton />
          </div>
          <ElrButton
            text="Complete"
            submit
            disabled={!businessPhone.isValid || isPending}
            loading={isPending}
            spinnerColor="White"
            className="w-40 h-12 text-lg text-white bg-elr-black"
          />
        </div>
      </form>
    </div>
  );
};

const BusinessInformation: React.FC = () => (
  <div>
    <div className="flex flex-col items-center pb-12 bg-elr-gray-500 h-1000">
      <CenterLogoHeader />
      <BusinessInformationChildComponent />
    </div>
  </div>
);

export default BusinessInformation;
