import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { currentYear } from 'app/utilities/helpers';
import { publicRoutes } from 'app/utilities/routes';
import facebookIcon from 'assets/images/facebookIcon.svg';
import instagramIcon from 'assets/images/instagramIcon.svg';
import logo from 'assets/images/logo_light.svg';
import twitterIcon from 'assets/images/twitterIcon.svg';
import React, { ChangeEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import { ElrInput } from 'ui-components';
import { FooterInput } from './styles';

interface FooterProps {
  hideTopFooter?: boolean;
}

export const Footer: React.FC<FooterProps> = () => {
  const [subscribe, setSubscribe] = useState('');
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSubscribe(e.target.value);
  };
  const footerSections = [
    {
      title: 'Services',
      links: [
        { text: 'Logistics', to: publicRoutes.logisticsPage },
        { text: 'E-commerce', to: publicRoutes.productPage },
        { text: 'Experience', to: publicRoutes.experiencePage },
        { text: 'Payment', to: publicRoutes.paymentPage },
      ],
    },
    {
      title: 'Explore',
      links: [
        { text: 'Business', to: publicRoutes.businessPage },
        { text: 'Riders', to: publicRoutes.riderPage },
        { text: 'Estimate', to: publicRoutes.estimate },
        { text: 'Track Parcel', to: publicRoutes.track },
        { text: 'About Us', to: publicRoutes.aboutUs },
      ],
    },
    {
      title: 'Legal',
      links: [
        { text: 'Privacy Policy', to: publicRoutes.privacyPolicy },
        { text: 'Terms and Conditions', to: publicRoutes.termsAndCondition },
        { text: 'Rider T&C', to: publicRoutes.riderTermsAndCondition },
        { text: 'Delivery policy', to: publicRoutes.deliveryPolicy },
        { text: 'Cookie policy', to: publicRoutes.cookiePolicy },
      ],
    },
  ];

  const contactInfo = {
    socialLinks: [
      {
        href: 'https://instagram.com/errandlrhq',
        icon: instagramIcon,
        alt: 'Instagram Icon',
      },
      {
        href: 'https://twitter.com/errandlr/',
        icon: twitterIcon,
        alt: 'Twitter Icon',
      },
      {
        href: 'https://facebook.com/errandlr',
        icon: facebookIcon,
        alt: 'Facebook Icon',
      },
    ],
  };

  return (
    <div className="bg-elr-black">
      <div className="max-w-6.5xl mx-auto">
        <div className="footer_container mx-5 md:mx-0 text-elr-white-400 py-12 md:py-24  md:flex flex-col flex-wrap md:flex-row md:justify-between">
          <div className="mb-8 md:w-64 w-full">
            <div className="flex items-center">
              <img src={logo} alt="" className="h-10" />
              <span className="text-3xl md:text-2xl ml-3 md:ml-2">
                Errandlr
              </span>
            </div>
            <p className="text-14 text-elr-gray-400 w-10/12 pt-4 mb-6">
              Shop Anywhere, Ship Anywhere, Pay Anywhere.
            </p>
            <span className="text-14 text-elr-gray flex flex-col gap-2  w-full">
              <p>support@errandlr.com</p>
              <p>+234 916 504 3902</p>
              <p>13 Wumego Crescent, Lekki Phase 1, Lagos, Nigeria.</p>
            </span>
          </div>
          {footerSections.map((section) => (
            <div key={section.title} className="mb-8 md:mb-11">
              <h3 className="text-base font-medium mb-2 md:mb-4 md:text-lg">
                {section.title}
              </h3>
              {section.links.map((link) =>
                link.to.startsWith('http') ? (
                  <a
                    key={link.text}
                    href={link.to}
                    className="text-14 leading-10 text-elr-gray-400"
                  >
                    {link.text}
                  </a>
                ) : (
                  <Link key={link.text} to={link.to}>
                    <p className="text-14 leading-10 text-elr-gray-400">
                      {link.text}
                    </p>
                  </Link>
                )
              )}
            </div>
          ))}
          <div className="mb-11">
            <h3 className="text-base font-medium mb-4 md:text-lg">
              Connect with us &nbsp;
            </h3>
            <p className="text-sm leading-10 flex mb-10">
              {contactInfo.socialLinks.map((link) => (
                <a
                  key={link.href}
                  href={link.href}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={link.icon} alt={link.alt} className="mr-6" />
                </a>
              ))}
            </p>
            <FooterInput className="w-10/12 flex md:w-258 flex-col gap-2">
              <p className="text-14 text-elr-gray">Subscribe</p>
              <div className="w-10/12 flex md:w-258 relative">
                <ElrInput
                  placeholder="Enter Email Address"
                  className="bg-elr-purple rounded "
                  value={subscribe}
                  color="#FFFFFF"
                  onChange={handleChange}
                  onFocus={(e: ChangeEvent<HTMLInputElement>) => {
                    e.target.placeholder = '';
                  }}
                  onBlur={(e: ChangeEvent<HTMLInputElement>) => {
                    e.target.placeholder = 'Enter Email Address';
                  }}
                />
                <div className=" bg-elr-black absolute right-2 py-1 px-1.5 rounded-md top-1.5">
                  <ArrowForwardIcon className="text-elr-yellow" />
                </div>
              </div>
            </FooterInput>
          </div>
          <div className="flex items-center flex-row w-full md:justify-center justify-start gap-10 md:items-center h-20">
            <p className="text-base text-elr-gray-neutral-300">
              <span className="text-elr-yellow"> © </span>
              {currentYear} Errandlr. All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
