import { TopNav } from 'app/components/menu/Navbar';
import { useEffect } from 'react';
import { ElrPageTitle } from '../../../ui-components';
import { Footer } from '../landing/components/Footer';
import DeliveryPolicyContents from './deliveryPolicyContents';
import Header from './Header';
import PoliciesAppBanner from '../landing/components/PoliciesAppBanner';

const DeliveryPolicy = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  });

  return (
    <div className="bg-white">
      <ElrPageTitle title="Errandlr - Delivery Policy" />
      <div>
        <TopNav isDefault />
        <div className="mx-5 max-w-6.5xl md:mx-auto">
          <Header />
          <DeliveryPolicyContents />
          <PoliciesAppBanner />
        </div>

        <Footer hideTopFooter />
      </div>
    </div>
  );
};

export default DeliveryPolicy;
