import MenuIcon from '@material-ui/icons/Menu';
import { useLogout } from 'app/hooks/auth';
import {
  currentTimeLogo,
  mobileMenuIconStyle,
} from 'app/modules/landing/components/ClassUtils';
import { privateRoutes, publicRoutes } from 'app/utilities/routes';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Session } from 'app/utilities/Session';
import { ElrButton } from 'ui-components';
import { HamburgerMenu } from './HamburgerMenu';

interface Props {
  children: React.ReactNode;
  className?: string;
}

export const NavBar: React.FC<Props> = ({ children, className = '' }) => (
  <div className={`${className}`}>{children}</div>
);

export const RenderAuthMenuListLinks: React.FC<{
  containerClassName: string;
}> = ({ containerClassName }) => {
  const [sendLogout] = useLogout();

  return (
    <>
      <Link to={privateRoutes.selectPortal} className={containerClassName}>
        Select Portal
      </Link>
      <div onClick={sendLogout} className={containerClassName}>
        Logout
      </div>
    </>
  );
};

interface TopNavProp {
  isDefault?: boolean;
}

export const TopNav: React.FC<TopNavProp> = ({ isDefault = true }) => {
  const navLinks = [
    { path: publicRoutes.logisticsPage, label: 'Logistics' },
    { path: publicRoutes.businessPage, label: 'Business' },
    { path: publicRoutes.experiencePage, label: 'Experience' },
  ];

  const [showMenu, setShowMenu] = useState<boolean>(false);
  const isAuthenticated = Boolean(Session.currentUser());
  const [sendLogout] = useLogout();
  const toggleMobileMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div className="max-w-6.5xl pt-10 mx-auto">
      <NavBar className="flex items-center justify-between px-12 py-6 mx-5 rounded-full md:mx-0 bg-elr-gray">
        <Link to="/">
          <img src={currentTimeLogo(isDefault)} alt="site logo" />
        </Link>

        <div className="flex items-center lg:hidden">
          <span
            className={mobileMenuIconStyle(isDefault)}
            onClick={toggleMobileMenu}
          >
            <MenuIcon fontSize="large" />
          </span>
        </div>

        {showMenu && (
          <HamburgerMenu onClose={toggleMobileMenu} navLinks={navLinks} />
        )}

        <ul className="hidden lg:flex gap-11">
          {navLinks.map((link) => (
            <Link
              key={link.path}
              to={link.path}
              className="block p-1 cursor-pointer text-opacity-40"
            >
              {link.label}
            </Link>
          ))}
        </ul>

        <div className="hidden gap-3 lg:flex">
          {isAuthenticated ? (
            <>
              <Link to={privateRoutes.selectPortal}>
                <ElrButton
                  className="flex items-center px-8 py-3 bg-white rounded-full cursor-pointer"
                  text="Select Portal"
                />
              </Link>
              <ElrButton
                onClick={() => sendLogout()}
                className="flex items-center align-middle justify-center text-center min-w-8 py-3 text-white rounded-full cursor-pointer bg-elr-black"
                text="Logout"
              />
            </>
          ) : (
            <>
              <Link to={publicRoutes.login}>
                <ElrButton
                  className="flex items-center px-8 py-3 align-middle justify-center text-center bg-white rounded-full cursor-pointer"
                  text="Login"
                />
              </Link>
              <Link to={publicRoutes.registration}>
                <ElrButton
                  className="flex items-center px-4 align-middle justify-center text-center py-3 text-white rounded-full cursor-pointer bg-elr-black"
                  text="Create Account"
                />
              </Link>
            </>
          )}
        </div>
      </NavBar>
    </div>
  );
};
