import { useQuery } from '@tanstack/react-query';
import {
  httpGetNewEstimate,
  httpGetNewProfileRequests,
  httpRecreateDelivery,
} from 'app/api/requests';
import CancelDeliveryModal from 'app/components/CancelDeliveryModal';
import GenerateInvoice from 'app/components/GenerateInvoice';
import {
  AssignedDriver,
  NotAssignedDriver,
} from 'app/dispatcher/modules/components/requestItem/AgentSection';
import {
  useFetchNewRequests,
  useRefetchUpcomingRequests,
} from 'app/hooks/requests/dashboard';
import { useCancelRequest } from 'app/hooks/requests/platform';
import { useGetProfile } from 'app/hooks/user';
import {
  NEW_REQUEST_QUERY_KEY,
  currencyFormatter,
  isRequestCollected,
  isRequestCompleted,
} from 'app/utilities/helpers';
import { publicRoutes } from 'app/utilities/routes';
import {
  CancelRequestTypes,
  Request,
  RequestTypes,
} from 'app/utilities/types/shared';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ElrModal, ElrPillButton } from 'ui-components';

export const CancelRequest: React.FC<{
  request: Request;
}> = ({ request }) => {
  const [showPreconfirmBtn, setPreconfirmCancel] = useState<boolean>(false);
  const [cancelReason, setCancelReason] = useState('');

  const { loading, setShowCancelModal, showCancelModal, cancelRequest } =
    useCancelRequest();
  const refetch = useRefetchUpcomingRequests();
  const { refetch: refetchNewRequests } = useFetchNewRequests();

  const onCancelRequest = async () => {
    try {
      await cancelRequest({
        trackingId: request.trackingId,
        cancelReason,
      });
      refetch();
      refetchNewRequests();
    } catch (err) {
      /* noop */
    }
  };

  const cancelText: string =
    request.status === RequestTypes.open
      ? CancelRequestTypes.full_refund
      : CancelRequestTypes.partial_refund;

  const _renderPreconfirmCancellation = () => (
    <>
      <CancelDeliveryModal
        onCancelDelivery={onCancelRequest}
        text={cancelText}
        onClose={() => setShowCancelModal(false)}
        isOpen={showCancelModal}
        loading={loading}
        reason={cancelReason}
        setReason={setCancelReason}
      />
      <span>
        <p className="inline mr-3 text-elr-black opacity-60">Are you sure?</p>
        <ElrPillButton
          onClick={() => setShowCancelModal(true)}
          size="lg"
          text="Yes, Cancel"
          className="w-full py-1 bg-elr-black text-elr-white md:w-24 md:text-sm"
        />
      </span>
    </>
  );

  const _renderInitialCancelBtn = () => (
    <ElrPillButton
      size="lg"
      text="Cancel"
      onClick={() => setPreconfirmCancel(true)}
      className="w-full py-1 bg-elr-gray-400 bg-opacity-30 text-elr-black md:w-24 md:text-sm"
    />
  );

  return (
    <div className="flex flex-col-reverse justify-between md:flex-row opacity-80">
      <div>
        {request.status && isRequestCollected(request.status) && (
          <ElrPillButton
            size="lg"
            text="Parcel has been picked up"
            className="w-full px-2 py-1 mt-2 cursor-not-allowed bg-elr-purple bg-opacity-10 md:bg-elr-gray-400 md:bg-opacity-30 text-elr-black md:text-sm md:ml-4 md:mt-0 opacity-60"
          />
        )}
      </div>
      <div>
        {showPreconfirmBtn
          ? _renderPreconfirmCancellation()
          : _renderInitialCancelBtn()}
      </div>
    </div>
  );
};

const RenderPreconfirmCancellation = ({ request }: { request: Request }) => {
  const [loading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const {
    id: userId,
    accountInformation: { id: roleId },
    currentAccessRole,
  } = useGetProfile();
  const [estimate, setEstimate] = useState(0);

  const { refetch } = useQuery({
    queryKey: [NEW_REQUEST_QUERY_KEY],
    queryFn: () =>
      httpGetNewProfileRequests({
        nextPage: 1,
        userId,
        roleId: currentAccessRole,
      }),
  });

  const onClose = () => {
    setOpen(false);
  };

  const getNewEstimate = async () => {
    setLoading(true);
    try {
      const response = await httpGetNewEstimate({
        userId,
        trackingId: request.trackingId,
      });
      setLoading(false);
      if (response.estimate) {
        setEstimate(response.estimate);
        setOpen(!isOpen);
      }
    } catch (e) {
      setLoading(false);
    }
  };

  const recreateDelivery = async () => {
    setLoading(true);
    try {
      const response = await httpRecreateDelivery({
        userId,
        trackingId: request.trackingId,
        roleId,
      });
      setLoading(false);
      setEstimate(response.estimate);
      setOpen(!isOpen);
      refetch();
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <>
      <ElrModal height="h-64" isOpen={isOpen} onClose={onClose}>
        <div className="my-5">
          <p className="mx-3 text-lg text-center">Confirm Estimate</p>

          <div className="flex items-center justify-center mt-10">
            <p className="mr-5 text-sm text-elr-black text-opacity-60">
              New order estimate:
            </p>
            <p>{currencyFormatter(estimate)}</p>
          </div>
          <div className="flex items-center justify-center mt-10">
            <ElrPillButton
              text="Place Order"
              size="sm"
              className="bg-elr-black text-sm py-2.5 text-elr-white px-10"
              loading={loading}
              onClick={recreateDelivery}
              spinnerColor="White"
            />
          </div>
        </div>
      </ElrModal>

      <div className="flex flex-col justify-between mb-2 md:flex-row gap-y-4 md:gap-y-0 md:items-center">
        <ElrPillButton
          size="lg"
          text="Redo delivery"
          className="px-5 py-1 ml-4 bg-elr-green text-elr-white md:text-sm"
          onClick={getNewEstimate}
          loading={loading}
          spinnerColor="White"
        />
        <div className="flex items-center justify-end md:flex-row">
          <p className="inline mr-3 text-xs ">
            Any issues?&nbsp;
            <span className="text-elr-purple opacity-60">
              <Link to={publicRoutes.situation}>
                Raise a query within 1 day
              </Link>
            </span>
          </p>

          <ElrPillButton
            size="lg"
            text="Completed"
            className="w-24 px-2 py-1 ml-4 mr-5 cursor-not-allowed bg-elr-gray-400 bg-opacity-30 text-elr-black md:text-sm opacity-60"
          />
          <GenerateInvoice data={request} />
        </div>
      </div>
    </>
  );
};

type RequestUnAssignedProps = {
  request: Request;
};

export const SenderRequestUnAssigned: React.FC<RequestUnAssignedProps> = ({
  request,
}) => (
  <>
    <NotAssignedDriver />
    <CancelRequest request={request} />
  </>
);

type RequestAssignedProps = {
  request: Request;
};

export const SenderRequestAssigned: React.FC<RequestAssignedProps> = ({
  request,
}) => (
  <>
    <AssignedDriver agent={request.designatedAgent} />
    {isRequestCompleted(request.status) ? (
      <RenderPreconfirmCancellation request={request} />
    ) : (
      <CancelRequest request={request} />
    )}
  </>
);
