import DanceMixImg from '../../../assets/images/dance-mix.png';
import MusicDanceImg from '../../../assets/images/music-and-dance.png';
import TropicalSummerImg from '../../../assets/images/tropical-summer.png';

export type ExperienceData = {
  uuid: number;
  experienceName: string;
  experienceDate: string;
  experienceTime: string;
  experienceLocation: string;
  bannerUrl: string;
};

export const exploreExperiences: ExperienceData[] = [
  {
    uuid: 1,
    experienceName: 'Tropical Summer Party',
    experienceDate: 'August 20, 2024',
    experienceTime: '10:00 AM',
    experienceLocation: 'Lagos',
    bannerUrl: TropicalSummerImg,
  },
  {
    uuid: 2,
    experienceName: 'Dance Mix Party',
    experienceDate: 'September 12, 2024',
    experienceTime: '8:00 PM',
    experienceLocation: 'Abuja',
    bannerUrl: DanceMixImg,
  },
  {
    uuid: 3,
    experienceName: 'Music and Dance Festival',
    experienceDate: 'October 5, 2024',
    experienceTime: '6:00 PM',
    experienceLocation: 'Ibadan',
    bannerUrl: MusicDanceImg,
  },
  {
    uuid: 4,
    experienceName: 'Rock Festival',
    experienceDate: 'November 3, 2024',
    experienceTime: '5:00 PM',
    experienceLocation: 'Enugu',
    bannerUrl: TropicalSummerImg,
  },
];
