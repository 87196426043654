import React from 'react';
import { TopNav } from 'app/components/menu/Navbar';
import { ElrButton } from 'ui-components';
import WhiteBubbles from 'assets/images/bubbles-white.svg';

interface HeroHeaderProps {
  title: React.ReactNode;
  buttonText: string;
  backgroundClass: string;
  headerClass?: string;
  bubblesOpacity?: string;
  imageSrc: string;
  altText: string;
  buttonAction?: () => void;
}

const HeroHeader: React.FC<HeroHeaderProps> = ({
  title,
  buttonText,
  backgroundClass,
  headerClass = 'md:w-9/12 w-8/12',
  bubblesOpacity = '',
  imageSrc,
  buttonAction,
  altText,
}) => (
  <div className="w-full relative">
    <TopNav isDefault />
    <div className="mx-5 md:mx-0">
      <div
        className={`max-w-6.5xl mx-auto flex flex-col md:gap-0 gap-14 md:flex-row items-center md:py-32 px-6 md:px-16 ${backgroundClass} mt-20 rounded-2xl relative overflow-hidden`}
      >
        <div className="absolute inset-0">
          <img
            src={WhiteBubbles}
            alt="bubbles"
            className={`object-left-top absolute inset-0 md:w-full max-w-4xl w-auto h-full ${bubblesOpacity}`}
          />
        </div>
        <div className="flex flex-col justify-center gap-10 md:gap-12 md:w-8/12 z-10 text-left pt-16 md:pt-0">
          <h1
            className={`font-bold text-40 md:text-6xl leading-tight md:leading-tight ${headerClass}`}
          >
            {title}
          </h1>
          <ElrButton
            text={buttonText}
            className="bg-elr-black text-white rounded-full md:w-fit w-full px-8 py-3 mx-auto md:mx-0"
            onClick={buttonAction}
          />
        </div>
        <div className="relative md:absolute md:bottom-0 md:right-0 z-10 mt-8 md:mt-0">
          <img
            src={imageSrc}
            alt={altText}
            className="md:w-11/12 md:h-7/12 w-10/12 mx-auto md:mx-0"
          />
        </div>
      </div>
    </div>
  </div>
);

export default HeroHeader;
