import { publicRoutes } from 'app/utilities/routes';
import apiScreenMockup from 'assets/images/Errandlr-api-screen.png';
import DeliverFast from 'assets/images/Errandlr-track-screen.svg';
import logisticsBike from 'assets/images/logistics-bike.svg';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ElrPageTitle } from 'ui-components';
import ExploreMore from '../components/ExploreMore';
import { FeatureBlock } from '../components/FeatureBlock';
import FeatureSection from '../components/FeatureSection';
import HeroHeader from '../components/HeroHeader';
import PopularQuestions from '../components/PopularQuestions';
import { QrCodeModal } from '../components/QrCodeModal';
import { Footer } from '../landing/components';
import DeliverMore from './components/DeliverMore';

const APP_DOWNLOAD_LINK = 'https://onelink.to/ph8jez';

const LogiscticsPage = () => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    setIsMobile(/Mobi|Android|iPhone/i.test(navigator.userAgent));
  });
  const handleExploreClick = () => {
    if (isMobile) {
      window.location.href = APP_DOWNLOAD_LINK;
    } else {
      setShowModal(true);
    }
  };
  const questions = [
    {
      id: 1,
      header: 'What are the delivery rates and charges?',
      content:
        'Our rates are influenced by factors such as distance, package weight, and service level. In addition, for non API users, riders can bid a rate that differs from our recommended price – either lower or higher – to reflect market conditions and deliver optimal service. This dynamic approach ensures fairness and competitiveness across our platform.',
    },
    {
      id: 2,
      header: 'How do I track my parcel?',
      content:
        'Track your parcel in real-time with our intuitive system. Once your order is scheduled, you can monitor its progress every step of the way, ensuring you know exactly when to expect delivery.',
    },
    {
      id: 3,
      header: 'Do you offer express delivery?',
      content:
        'We do not offer express delivery. Our focus is on reliable same-day and next-day services.',
    },
    {
      id: 4,
      header: 'How do I navigate the platform?',
      content:
        'Download our mobile app to enjoy seamless access to our delivery services. Businesses looking to leverage our API should register on our website for a tailored experience.',
    },
  ];

  return (
    <div className="bg-white">
      <ElrPageTitle title="Errandlr - Logistics" />
      <HeroHeader
        title={
          <>
            Handle <span className="text-elr-purple">Logistics</span> the
            Smartest Way
          </>
        }
        buttonText="Estimate Delivery"
        backgroundClass="bg-elr-purple-50"
        headerClass="md:w-11/12 w-10/12"
        bubblesOpacity="opacity-80"
        imageSrc={logisticsBike}
        altText="Logistics bike"
        buttonAction={handleExploreClick}
      />
      <QrCodeModal isOpen={showModal} onClose={() => setShowModal(false)} />
      <DeliverMore />
      <FeatureSection
        title="Request Custom Logistics with Errandlr APIs"
        description="Access well-documented APIs to integrate seamless checkout into your
            commerce service. Access well-documented APIs to integrate seamless
            checkout into your commerce service."
        buttonText="API Quickstart"
        buttonAction={() => navigate(publicRoutes.apiPage)}
        imageSrc={apiScreenMockup}
        imageAlt="Errandlr API Screen"
        imageClassName="md:max-w-lg"
        gradients={{
          mainBackground:
            'bg-gradient-to-b box-decoration-clone from-elr-black via-elr-black to-elr-black',
          leftBackground:
            'bg-gradient-to-b box-decoration-clone from-elr-black via-elr-black to-elr-black',
          rightBackground: 'bg-elr-gray',
        }}
        gradientReverseLayout
      />
      <FeatureBlock
        imageSrc={DeliverFast}
        imageAlt="fast-delivery"
        headingText="Monitor your Delivery Every Step of the Way"
        layoutClassName="md:flex-row py-20 md:py-40"
        descriptionText="Track your deliveries in real-time and enjoy complete visibility and control over the movement of your goods."
        buttonText="Track Parcel"
        buttonLink={publicRoutes.track}
      />
      <PopularQuestions questions={questions} />
      <ExploreMore hiddenCards={['Deliver']} />
      <Footer />
    </div>
  );
};
export default LogiscticsPage;
