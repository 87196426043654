import SupportHeader from 'app/utilities/HttpSupportHeader';
import { Session, accessToken, refreshToken } from 'app/utilities/Session';
import { axiosDefault as axios } from 'app/utilities/axios';
import { COMMERCE, DISPATCHER, SENDER } from 'app/utilities/roles';
import { User } from 'app/utilities/types/userTypes';
import { toast } from 'react-toastify';
import { AccountResponse } from './types';

export interface LoginRequest {
  email: string;
  password: string;
}
export interface CreateAccount extends LoginRequest {
  firstname: string;
  lastname: string;
  role: typeof SENDER | typeof DISPATCHER | typeof COMMERCE;
  phone: string;
  country: string;
}

const setSessions = (
  accessTokenPayload: string,
  refreshTokenPayload: string
): void => {
  Session.setValue(accessToken, accessTokenPayload);
  Session.setValue(refreshToken, refreshTokenPayload);
};

// make http request here
export const httpLogin = async (data: LoginRequest): Promise<User> => {
  try {
    const {
      data: { tokens, user },
    } = await axios.post<LoginRequest, AccountResponse>('/auth/login', data);

    setSessions(tokens.access.token, tokens.refresh.token);
    return user;
  } catch (error: any) {
    toast.error('Email or Password incorrect');
    throw new Error(error);
  }
};

export const httpCreateAccount = async (data: CreateAccount): Promise<User> => {
  try {
    const {
      data: { tokens, user },
    } = await axios.post<CreateAccount, AccountResponse>(
      '/auth/register',
      data
    );
    setSessions(tokens.access.token, tokens.refresh.token);
    return user;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpLogout = async (userId: string) => {
  try {
    await axios.get(`/auth/${userId}/logout`, {
      ...SupportHeader(),
    });
    return Session.logout();
  } catch (error: any) {
    /** noop */
    Session.logout();
    return error;
  }
};

export const httpVerifyReCaptcha = async (token: string) => {
  try {
    const { data } = await axios.post('/auth/recaptcha-verify', { token });
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};
