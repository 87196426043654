import {
  fulfilmentReturnStatus,
  sortingReturnStatus,
} from 'app/components/ClassUtils';
import SupportHeader from 'app/utilities/HttpSupportHeader';
import { axiosDefault as axios } from 'app/utilities/axios';
import { getCurrentCountry } from 'app/utilities/country';
import { Request, RequestType } from 'app/utilities/types/shared';
import { pickBy } from 'lodash';
import { toast } from 'react-toastify';
import {
  ITenantData,
  PackageTypeI,
  RequestsDataInterface,
  SendRequest,
  VerifyParams,
  VerifyResponse,
} from './d';
import { AcceptRequestResponse, InitializeRequestResponse } from './types';

/**
 *@description Get new requests for dispatchers
 */
interface Params {
  userId: string;
  nextPage: number;
  type?: RequestType;
}

export const httpGetNewRequests = async ({
  userId,
  nextPage,
  type,
}: Params): Promise<RequestsDataInterface> => {
  try {
    const response = await axios.get(`/request/${userId}/get-requests`, {
      params: { page: nextPage, type },
      ...SupportHeader(),
    });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpGetDispatchRequest = async (
  trackingId: string
): Promise<Request | null> => {
  try {
    const response = await axios.get('/request', {
      params: {
        trackingId,
      },
    });
    return response.data;
  } catch (error: any) {
    return null;
  }
};

export const httpVerifyRequestTransaction = async ({
  reqRef,
}: VerifyParams): Promise<VerifyResponse> => {
  try {
    const response = await axios.get(`/request/verify?reqRef=${reqRef}`);
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const httpAcceptRequest = async ({
  userId,
  requestId,
  agentId,
}: {
  userId: string;
  requestId: string;
  agentId: string;
}) => {
  try {
    const response = await axios.post<
      {
        dispatcherId: string;
        requestId: string;
        agentId: string;
      },
      AcceptRequestResponse
    >(
      `/request/${userId}/accept-request`,
      {
        requestId,
        agentId,
      },
      SupportHeader()
    );
    toast.success('Request accepted 🥳.', { position: 'top-right' });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message, { position: 'top-right' });

    // change this part with caution, check where this function is being called
    // @todo implemnet better error parsing
    throw new Error(error.response?.data?.message);
  }
};

export const httpOutsourceRequest = async ({
  userId,
  requestId,
}: {
  userId: string;
  requestId: string;
}) => {
  try {
    const response = await axios.put(
      `/request/${userId}/outsource/${requestId}`,
      {},
      SupportHeader()
    );
    toast.success('Outsourced Successfully.', { position: 'top-right' });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message, { position: 'top-right' });
    throw new Error(error.response?.data?.message);
  }
};

export interface cancelRequestResponse {
  status: string;
  trackingId: string;
  requestClosedAt: string;
}

export const httpCancelRequest = async (
  trackingId: string,
  roleId: string,
  userId: string,
  cancellationReason?: string
): Promise<cancelRequestResponse> => {
  try {
    const response = await axios.post<
      { trackingId: string; roleId: string },
      { data: cancelRequestResponse }
    >(
      `/public/${userId}/cancel-request`,
      {
        trackingId,
        roleId,
        cancellationReason,
      },
      SupportHeader()
    );
    toast.success('Request has been cancelled successfully');
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpMarkDeliveryAsCompleted = async ({
  userId,
  trackingId,
}: {
  userId: string;
  trackingId: string;
}) => {
  try {
    const response = await axios.put(
      `/request/${userId}/complete-request`,
      {
        trackingId,
      },
      SupportHeader()
    );
    toast.success('Delivery marked as completed', { position: 'top-right' });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message, { position: 'top-right' });
    throw new Error(error.response?.data?.message);
  }
};

export const httpUpdatePickedupStatus = async ({
  userId,
  trackingId,
}: {
  userId: string;
  trackingId: string;
}) => {
  try {
    const response = await axios.put(
      `/request/${userId}/set-collected-status?trackingId=${trackingId}`,
      {},
      SupportHeader()
    );
    toast.success('Delivery marked as collected', { position: 'top-right' });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message, { position: 'top-right' });
    throw new Error(error.response?.data?.message);
  }
};

export const httpInitializePrivateRequest = async ({
  userId,
  roleId,
  role,
  payload,
}: {
  userId: string;
  roleId: string;
  role: string;
  payload: SendRequest;
}) => {
  const requestUrl = `/request/${userId}/request?roleId=${roleId}&role=${role}`;

  try {
    const response = await axios.post<SendRequest, InitializeRequestResponse>(
      requestUrl,
      payload,
      SupportHeader()
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpInitializeBusinessRequest = async ({
  roleId,
  payload,
}: {
  roleId: string;
  payload: SendRequest & { businessPays: boolean };
}) => {
  const requestUrl = `/public/request?roleId=${roleId}`;

  try {
    const response = await axios.post<SendRequest, InitializeRequestResponse>(
      requestUrl,
      payload,
      SupportHeader()
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpGetConfirmationRequest = async (
  trackingId: string
): Promise<Request> => {
  try {
    const response = await axios.get(`/request?trackingId=${trackingId}`);
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpGenerateTrackingID = async ({
  userId,
  batch,
}: {
  userId: string;
  batch: boolean;
}): Promise<string> => {
  try {
    const response = await axios.post(
      `/request/${userId}/generate-trackingID`,
      {
        batch,
      },
      SupportHeader()
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpReopenRequest = async (
  userId: string,
  trackingId: string,
  reason: string
) => {
  try {
    const response = await axios.post(
      `/request/${userId}/reopen`,
      {
        trackingId,
        reason,
      },
      SupportHeader()
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

interface RequestParams {
  userId: string;
  roleId: string;
  nextPage: number;
}

export const httpGetNewProfileRequests = async ({
  userId,
  roleId,
  nextPage,
}: RequestParams): Promise<RequestsDataInterface> => {
  try {
    const response = await axios.get(`/${roleId}/${userId}/get-requests`, {
      params: { page: nextPage },
      ...SupportHeader(),
    });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpGetCommerceRequests = async ({
  userId,
  roleId,
  nextPage,
}: RequestParams): Promise<RequestsDataInterface> => {
  try {
    const response = await axios.get(
      `/request/${userId}/get-commerce-requests?commerceId=${roleId}`,
      {
        params: { page: nextPage },
        ...SupportHeader(),
      }
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpGetNewEstimate = async ({
  userId,
  trackingId,
}: {
  userId: string;
  trackingId: string;
}): Promise<{ estimate: number }> => {
  try {
    const response = await axios.get(
      `/request/${userId}/recreate-delivery?trackingId=${trackingId}`,
      {
        ...SupportHeader(),
      }
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpRecreateDelivery = async ({
  userId,
  trackingId,
  roleId,
}: {
  userId: string;
  trackingId: string;
  roleId: string;
}): Promise<{ estimate: number }> => {
  try {
    const response = await axios.post(
      `/request/${userId}/recreate-delivery?trackingId=${trackingId}&roleId=${roleId}`,
      {},
      SupportHeader()
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpFetchTenants = async (): Promise<ITenantData> => {
  try {
    const response = await axios.get(`/util/tenant`, {
      ...SupportHeader(),
    });

    const activeStates = pickBy(
      response.data[getCurrentCountry()].states,
      (value) => value.active
    );
    response.data[getCurrentCountry()].states = activeStates;
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpMarkRibAsPickedUp = async ({
  userId,
  pickupTrackingId,
  pickupAddressIdentifier,
}: {
  userId: string;
  pickupTrackingId: string;
  pickupAddressIdentifier: string;
}) => {
  try {
    const response = await axios.put(
      `request/${userId}/set-pickup-collected`,
      { pickupTrackingId, pickupAddressIdentifier },
      SupportHeader()
    );
    toast.success('Delivery marked as collected', { position: 'top-right' });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message, { position: 'top-right' });
    throw new Error(error.response?.data?.message);
  }
};

export const httpMarkRibAsDelivered = async ({
  userId,
  ribTrackingId,
}: {
  userId: string;
  ribTrackingId: string;
}) => {
  try {
    const response = await axios.put(
      `request/${userId}/set-fulfilment-delivered`,
      { ribTrackingId },
      SupportHeader()
    );
    toast.success('Delivery marked as delivered', { position: 'top-right' });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message, { position: 'top-right' });
    throw new Error(error.response?.data?.message);
  }
};

export const httpRemovePickup = async ({
  userId,
  pickupTrackingId,
  pickupAddressIdentifier,
}: {
  userId: string;
  pickupTrackingId: string;
  pickupAddressIdentifier: string;
}) => {
  try {
    const response = await axios.delete(
      `request/${userId}/remove-batch-pickup`,
      {
        data: { pickupTrackingId, pickupAddressIdentifier },
        ...SupportHeader(),
      }
    );
    toast.success('Pick up has been removed', { position: 'top-right' });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message, { position: 'top-right' });
    throw new Error(error.response?.data?.message);
  }
};

export const httpReturnToFulfilment = async ({
  userId,
  ribTrackingId,
  fulfilmentPartner,
  status,
}: {
  userId: string;
  ribTrackingId: string;
  fulfilmentPartner: string;
  status: string;
}) => {
  try {
    const response = await axios.delete(
      `request/${userId}/set-fulfilment-delivered`,
      {
        data: { ribTrackingId, fulfilmentPartner, status },
        ...SupportHeader(),
      }
    );
    toast.success(fulfilmentReturnStatus(status), { position: 'top-right' });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message, { position: 'top-right' });
    throw new Error(error.response?.data?.message);
  }
};

export const httpReturnToOther = async ({
  userId,
  batchId,
  ribTrackingId,
  selectedSortingStationPartner,
  status,
}: {
  userId: string;
  batchId: string;
  ribTrackingId: string;
  selectedSortingStationPartner: string;
  status: string;
}) => {
  try {
    const response = await axios.patch(
      `request/${userId}/set-fulfilment-delivered`,
      {
        batchId,
        ribTrackingId,
        selectedSortingStationPartner,
        status,
      },
      SupportHeader()
    );
    toast.success(sortingReturnStatus(status), { position: 'top-right' });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message, { position: 'top-right' });
    throw new Error(error.response?.data?.message);
  }
};

export const httpTipFromWallet = async (params: {
  trackingId: string;
  batch: boolean;
  tipAmount: number;
}): Promise<{}> => {
  try {
    const response = await axios.post(
      `request/tip`,
      { ...params },
      SupportHeader()
    );
    toast.success(response?.data?.message);
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpFetchPackageTypes: () => Promise<PackageTypeI> = async () => {
  try {
    const response = await axios.get('/util/package-types', {
      ...SupportHeader(),
    });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpGetDeliveryBids = async (userId: string) => {
  const response = await axios.get(`/commerce/${userId}/bids`, {
    ...SupportHeader(),
  });
  return response.data;
};
