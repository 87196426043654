import paymentSuccessLogo from 'assets/images/payment-success-logo.svg';
import React from 'react';

import hamburger from 'assets/images/hamburger.svg';
import logo from 'assets/images/logo.svg';
import { Link } from 'react-router-dom';

type PaymentSuccessProps = {
  children: React.ReactNode; // 👈️ type children
};

const PaymentSuccess: React.FC<PaymentSuccessProps> = ({ children }) => (
  <div className="w-full h-screen bg-elr-gray">
    <div className="flex justify-between px-8 pt-10 lg:px-16">
      <Link to="/">
        <img src={logo} alt="elr-logo" />
      </Link>
      <img src={hamburger} alt="hamburger" className="sm:hidden" />
    </div>
    <div className="flex items-center justify-center w-full h-5/6">
      {children}
    </div>
  </div>
);

const ExperiencePurchaseSuccess: React.FC = () => (
  <PaymentSuccess>
    <div className="flex flex-col items-center justify-center w-full gap-10 mx-8 bg-white border rounded sm:w-406 md:w-560 sm:min-w-2/6 h-96 md:h-120 border-elr-green border-opacity-60">
      <div>
        <img
          src={paymentSuccessLogo}
          alt="payment success logo"
          className="w-16 sm:w-20"
        />
      </div>
      <div className="flex flex-col items-center justify-center">
        <p className="pb-3 text-xl font-medium md:text-2xl">
          Payment Successful
        </p>
        <p className="text-base font-medium text-elr-black text-opacity-60">
          Your have successfully purchased your tickets
        </p>
        <br />
        <div className="flex-col items-center justify-center hidden text-base text-elr-black text-opacity-60 sm:flex">
          <p>The tickets have been sent to the email address you provided</p>
        </div>
      </div>
    </div>
  </PaymentSuccess>
);

export default ExperiencePurchaseSuccess;
