import { useMutation } from '@tanstack/react-query';
import {
  httpGetIdentityVerificationRequests,
  httpVerifyCustomer,
} from 'app/api/operator';
import { useFetchInfiniteQuery } from 'app/components/dashboard/hooks';
import { IDENTITY_VERIFICATION_REQUESTS } from 'app/state/constants';
import { useGetProfile } from './user';

// Operator-specific hook for fetching identity verification requests
export const useFetchIdentityVerificationRequests = (
  page: number = 1,
  limit: number = 50
) => {
  const { id: userId } = useGetProfile();
  const requestTag = IDENTITY_VERIFICATION_REQUESTS;

  return useFetchInfiniteQuery({
    key: requestTag,
    fn: httpGetIdentityVerificationRequests,
    payload: { userId, page, limit },
    querySettings: {
      notifyOnChangeProps: ['data'],
    },
  });
};

export const useVerifyCustomer = () => {
  const { id: userId } = useGetProfile();

  return useMutation({
    mutationFn: ({ status, senderId }: { status: string; senderId: string }) =>
      httpVerifyCustomer({ userId, status, senderId }),
  });
};
