import { useEffect, useRef, useState } from 'react';
import MoreIcon from '../../../assets/images/more-2-fill.svg';

interface DropdownProps {
  options: {
    value: string;
    label: string;
    icon?: JSX.Element;
    labelClassName?: string;
  }[];
  onSelect: (value: string) => void;
  buttonIcon?: JSX.Element;
  dropdownPosition?: 'right' | 'left';
  dropdownOptionClassName?: string;
  className?: string;
  dropdownClassName?: string;
  showDropdownIcon?: boolean;
}

export const MoreDropdown: React.FC<DropdownProps> = ({
  options,
  onSelect,
  buttonIcon,
  dropdownPosition = 'right',
  className,
  dropdownClassName,
  dropdownOptionClassName,
  showDropdownIcon = true,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={`relative ${className}`} ref={dropdownRef}>
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center justify-between gap-2 align-middle cursor-pointer"
      >
        {buttonIcon && <span>{buttonIcon}</span>}
        {showDropdownIcon && (
          <img
            src={MoreIcon}
            alt=""
            className="w-6 h-6 filter invert opacity-60"
          />
        )}
      </div>
      {isOpen && (
        <div
          className={`dropdown-menu top-12 ${dropdownClassName} ${dropdownPosition === 'right' ? 'right-0' : 'left-0'}`}
        >
          {options.map((option) => (
            <div
              key={option.value}
              className={`flex items-center gap-3 dropdown-option ${dropdownOptionClassName}`}
              onClick={() => {
                onSelect(option.value);
                setIsOpen(false);
              }}
            >
              {option.icon && <span className="icon">{option.icon}</span>}
              <span className={`${option.labelClassName}`}>{option.label}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
