import { useQueryClient } from '@tanstack/react-query';
import { httpUploadFile } from 'app/api/utils';
import { BackIconWonWithBG, PencilIcon } from 'app/components/SvgAssets';
import { useCreateExperience } from 'app/hooks/requests/commerce';
import { useGetProfile } from 'app/hooks/user';
import { COMMERCE } from 'app/utilities/roles';
import { privateRoutes } from 'app/utilities/routes';
import {
  CreateExperienceProps,
  CreateExperienceResponse,
  ExperienceChargesType,
  Ticket,
} from 'app/utilities/types/shared';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ElrButton } from 'ui-components';

const ExperiencePreview = () => {
  const location = useLocation();
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const {
    experienceName,
    experienceLocation,
    experienceDescription,
    experienceDate,
    experienceTime,
    ticketTypes,
  } = location.state || {};
  const handleEditClick = () => {
    navigate(
      `/${COMMERCE}/${privateRoutes.ecommerce}/${privateRoutes.experiences}`,
      {
        state: { experience: location.state },
      }
    );
  };

  const { createExperience, loading } = useCreateExperience();
  const { id: userId, ...profile } = useGetProfile();
  const roleId = profile.accountInformation.id;

  const handleUploadBanner = async (file: File) => {
    try {
      const data = await queryClient.fetchQuery({
        queryKey: [file.name],
        queryFn: () => httpUploadFile(userId, file, 'image'),
        gcTime: 30000,
      });

      const response = {
        key: data.key,
        originalFilename: file.name,
        type: file.type,
        url: `${new URL(data.url).origin}${new URL(data.url).pathname}`,
      };
      return response;
    } catch (error: any) {
      // Handle error
      return null;
    }
  };

  const handleStartSelling = async () => {
    const experienceData = location.state?.experienceData ?? location.state;
    setIsLoading(true);
    if (experienceData?.bannerImage) {
      const bannerRes = await handleUploadBanner(experienceData.bannerImage);
      experienceData.media = [
        {
          type: 'image',
          url: bannerRes?.url || experienceData.bannerUrl,
        },
      ];
    }
    const payload: CreateExperienceProps = {
      name: experienceData.experienceName,
      description: experienceData.experienceDescription,
      location: experienceData.experienceLocation?.label || '',
      roleId,
      experienceDate: new Date(experienceData.experienceDate).toISOString(),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      media: experienceData.media || undefined,
      tickets: experienceData.ticketTypes.map((ticket: Ticket) => ({
        id: ticket.id,
        type: ticket.type.toLowerCase(),
        price: ticket.price,
        quantity: ticket.quantity,
        active: true,
        expiryDate: ticket.expiryDate,
        charge:
          ticket.whoPays === ExperienceChargesType.Business
            ? 'business'
            : 'customer',
        ticketCategory: ticket.ticketCategory || 'single',
        groupQuantity:
          ticket.ticketCategory === 'group' ? ticket.groupQuantity : undefined,
      })),
      category: experienceData.experienceCategory,
    };
    try {
      const response: CreateExperienceResponse =
        await createExperience(payload);
      const experienceUUId = response?.uuid;
      navigate(
        `/${COMMERCE}/${privateRoutes.ecommerce}/${experienceUUId}/${privateRoutes.experiencesDashboard}`
      );
    } catch (error) {
      // Handle error
    }
    setIsLoading(false);
  };

  const capitalize = (s: any) => s && s[0].toUpperCase() + s.slice(1);

  return (
    <div className="flex justify-center pt-16">
      <div className="flex flex-col justify-center w-full px-4 md:w-8/12 md:px-0">
        <section
          className="flex ml-6 cursor-pointer gap gap-x-2"
          onClick={() => navigate(-1)}
        >
          <BackIconWonWithBG />
          <p className="self-center">Back</p>
        </section>
        <div className="w-full pt-12 mb-32">
          <header className="mb-6 text-center">
            <h1 className="mb-2 text-2xl font-normal leading-7 text-elr-black">
              Experience Preview
            </h1>
            <p className="text-lg leading-5 text-elr-black-400">
              Please confirm your experience details
            </p>
          </header>
          <div className="w-full px-10 py-8 mx-auto mb-10 bg-elr-white-400">
            <div>
              <section className="flex-col mb-8 md:flex md:flex-row">
                <p className="w-1/2 pb-1 text-elr-black-300">Experience Name</p>
                <p>{experienceName}</p>
              </section>
              <section className="flex-col mb-8 md:flex md:flex-row">
                <p className="w-1/2 pb-1 text-elr-black-300">
                  Experience Description
                </p>
                <p className="md:w-1/2 w-9/10">{experienceDescription}</p>
              </section>
              <section className="flex-col mb-8 md:flex md:flex-row">
                <p className="w-1/2 pb-1 text-elr-black-300">
                  Experience Location
                </p>
                <p className="md:w-1/2 w-9/10">{experienceLocation?.label}</p>
              </section>
              <section className="flex-col mb-8 md:flex md:flex-row">
                <p className="w-1/2 pb-1 text-elr-black-300">Experience Date</p>
                <p className="w-1/2">
                  {new Date(experienceDate).toLocaleDateString()}
                </p>
              </section>
              <section className="flex-col mb-8 md:flex md:flex-row">
                <p className="w-1/2 pb-1 text-elr-black-300">Experience Time</p>
                <p className="w-1/2">{experienceTime}</p>
              </section>

              {ticketTypes?.map((ticket: Ticket, index: number) => (
                <section
                  key={ticket.id}
                  className="flex-col mb-8 md:flex md:flex-row"
                >
                  <p className="w-1/2 pb-1 text-elr-black-300">
                    Ticket {index + 1}
                  </p>
                  <div className="w-full md:w-1/2">
                    <p>{capitalize(ticket.type)}</p>
                    <p>NGN {ticket.price}</p>
                    <p>Quantity - {ticket.quantity}</p>
                    <p>
                      Deadline -{' '}
                      {ticket.expiryDate
                        ? new Date(ticket.expiryDate).toLocaleDateString()
                        : 'N/A'}
                    </p>
                    <p>
                      {ticket.ticketCategory === 'single'
                        ? 'One person'
                        : `Max people allowed - ${ticket.groupQuantity}`}
                    </p>
                    <p>
                      {ticket.whoPays === ExperienceChargesType.Business
                        ? 'Business pays'
                        : 'Customer pays'}{' '}
                      payment charges
                    </p>
                  </div>
                </section>
              ))}
            </div>
            <div className="flex w-full gap-2 mt-14">
              <ElrButton
                text={'Edit'}
                className="flex items-center w-full gap-2 align-middle rounded-sm bg-elr-gray text-elr-black"
                onClick={handleEditClick}
                imageSrc={<PencilIcon />}
                imagePosition="Right"
              />
              <ElrButton
                text="Start Selling"
                className="w-full rounded-sm bg-elr-black text-elr-white-400"
                onClick={handleStartSelling}
                spinnerColor="White"
                disabled={loading || isLoading}
                loading={loading || isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExperiencePreview;
