import { useNavigate } from 'react-router';
import { BackIconWonWithBG } from '../../SvgAssets';
import HeadlessHeader from '../components/HeadlessHeader';
import ProductFormFields from './ProductFormFields';

const UploadProducts = () => {
  const navigate = useNavigate();

  const initialMenuCategory = {
    cuisine: 'Default',
    shelf: 'Default',
    categoryName: '',
    categoryDescription: 'Default Nothing for Resturant',
    categoryImage: '',
    items: [
      {
        itemName: '',
        itemPrice: 0,
        itemDescription: '',
        itemImage: '',
        itemPopular: false,
        itemHandPicked: false,
        itemInStock: true,
        options: [] as any[],
      },
    ],
  };

  return (
    <>
      <HeadlessHeader title="Upload Products" />
      <div className="max-w-6xl md:mx-auto mx-5">
        <div className="py-16 w-full">
          <div className="flex flex-col">
            <section
              className="flex cursor-pointer gap gap-x-2"
              onClick={() => navigate(-1)}
            >
              <BackIconWonWithBG />
              <p className="self-center">Back</p>
            </section>
            <header className="my-6 text-left">
              <h1 className="mb-2 text-2xl font-normal leading-7 text-elr-black">
                Add Menu
              </h1>
              <p className="text-lg leading-5 text-elr-black-400">
                Upload your meals you sell
              </p>
            </header>
          </div>
          <div className="flex md:flex-row flex-col gap-3 w-full">
            <ProductFormFields
              initialMenuCategory={initialMenuCategory}
              outletType="restaurant"
            />
            <div className="px-10 py-8 bg-white w-full">
              <p className="text-lg text-center">App Preview</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadProducts;
