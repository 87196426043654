import { FeatureCard } from 'app/modules/components/FeatureCard';
import AlignIcon from '../../../assets/images/align-item.svg';
import GallaryIcon from '../../../assets/images/gallery-view-2.svg';
import QRCodeIcon from '../../../assets/images/qr-code-purple.svg';

const ManageExperiences = () => {
  const experienceNeeds = [
    {
      icon: QRCodeIcon,
      title: 'QR Code for Your Experience and Attendees',
      description:
        'A QR code for direct access to your experience page plus a unique QR code for each attendee.',
    },
    {
      icon: AlignIcon,
      title: 'Double Visibility for Your Experience',
      description:
        'Our platform gives your experience two access points: a dedicated web page and our mobile app.',
    },
    {
      icon: GallaryIcon,
      title: 'Efficient Management of Ticket Sales',
      description:
        'You get to manage your experience in real time and withdraw funds from your wallet seamlessly.',
    },
  ];
  return (
    <div className="pt-20 mx-5 md:mx-0 md:pt-40">
      <div className="flex flex-col max-w-6.5xl mx-auto md:flex-col">
        <p className="w-11/12 mx-auto text-4xl text-center md:w-full md:text-5xl">
          Access All You Need for Experiences
        </p>
        <p className="w-10/12 pt-6 mx-auto text-lg text-center md:text-2xl text-elr-black-300">
          Manage experiences seamlessly on our all-in-one platform
        </p>
        <div className="flex flex-wrap items-center justify-center gap-6 mt-10 md:mt-16 md:gap-8">
          {experienceNeeds.map((benefits) => (
            <div key={benefits.title} className="w-full md:w-[30%]">
              <FeatureCard
                icon={benefits.icon}
                title={benefits.title}
                titleClassName="md:w-11/12 w-9/12"
                description={benefits.description}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ManageExperiences;
