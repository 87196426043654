import { httpGetPlaceDetailsByID } from 'app/api/estimate';
import { useCreateServiceProfile } from 'app/hooks/requests/commerce';
import { useGetProfile } from 'app/hooks/user';
import { useUploadFileImage } from 'app/hooks/utils';
import { COMMERCE } from 'app/utilities/roles';
import { privateRoutes } from 'app/utilities/routes';
import { OutletType } from 'app/utilities/types/shared';
import { useFormik } from 'formik';
import { DateTime } from 'luxon';
import { FC, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ElrButton,
  ElrInput,
  ElrLocationSelect,
  ElrPhoneInput,
  ElrSwitch,
} from 'ui-components';
import { ElrDropdown } from 'ui-components/components/ElrButton';
import { ElrRadio } from 'ui-components/components/ElrRadio';
import { ElrUploadV2 } from 'ui-components/components/ElrUpload';
import TimePicker from 'ui-components/components/ErlTimePicker';
import * as Yup from 'yup';
import { BackIconWonWithBG } from '../SvgAssets';

const validationSchema = Yup.object({
  businessName: Yup.string().required('Business name is required'),
  businessLocation: Yup.object({
    latitude: Yup.number().required('Latitude is required'),
    longitude: Yup.number().required('Longitude is required'),
    label: Yup.string().required('Business location is required'),
  }).required('Business location is required'),
  businessPhone: Yup.string().required('Business phone number is required'),
  businessEmail: Yup.string()
    .email('Invalid email')
    .required('Business email is required'),
  businessOpeningTime: Yup.string().required(
    'Business opening time is required'
  ),
  businessClosingTime: Yup.string().required(
    'Business closing time is required'
  ),
  bannerImage: Yup.mixed().required('Banner image is required'),
  notificationPreference: Yup.boolean(),
});

const BusinessInfoForm: FC<{ outletType: string }> = ({ outletType }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [businessPhone, setBusinessPhone] = useState<{
    isValid: boolean;
    mobile: string;
    formattedNumber: string;
  }>({ isValid: false, mobile: '', formattedNumber: '' });
  const { handleCreateServiceProfile, isLoading } = useCreateServiceProfile();
  const {
    accountInformation: { id: roleId },
  } = useGetProfile();

  const { handleUploadImage } = useUploadFileImage();

  const onLocationChange = async (payload: {
    id: string;
    label: string;
    value: string;
  }) => {
    if (!payload) return setFieldValue('businessLocation', null);
    const response = await httpGetPlaceDetailsByID(payload.id);
    return setFieldValue('businessLocation', {
      id: payload.id,
      label: payload.label,
      value: payload.label,
      latitude: response.geolocation.lat,
      longitude: response.geolocation.lng,
      city: response.place.city,
      state: response.place.state,
      country: response.place.country,
      postalCode: response.place.postalCode,
    });
  };

  const onPhoneInputChange = (payload: {
    isValid: boolean;
    mobile: string;
    formattedNumber: string;
  }) => {
    setBusinessPhone({
      isValid: payload.isValid,
      mobile: payload.mobile,
      formattedNumber: payload.formattedNumber,
    });
    setFieldValue('businessPhone', payload.formattedNumber);
  };

  const formatDateTime = (time: string | null) => {
    if (!time) return '';

    const now = DateTime.now();
    return (
      now
        .set({
          ...DateTime.fromFormat(time, 'h:mm a').toObject(),
          second: 0,
          millisecond: 0,
        })
        .toISO() || ''
    );
  };

  const deliveryOptions = [
    { label: '1 - 2hrs', value: '1-2' },
    { label: '2 - 4hrs', value: '2-4' },
    { label: '4 - 6hrs', value: '4-6' },
  ];

  const deliveryTimeMap: Record<
    string,
    { deliveryTimeMin: number; deliveryTimeMax: number }
  > = {
    '1-2': { deliveryTimeMin: 60, deliveryTimeMax: 120 },
    '2-4': { deliveryTimeMin: 120, deliveryTimeMax: 240 },
    '4-6': { deliveryTimeMin: 240, deliveryTimeMax: 360 },
  };

  const {
    handleSubmit,
    setValues,
    values,
    handleChange,
    setFieldValue,
    isValid,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      businessName: '',
      businessLocation: {
        label: '',
        latitude: 0,
        longitude: 0,
        state: '',
        country: '',
      },
      businessPhone: '',
      businessEmail: '',
      businessOpeningTime: '',
      businessClosingTime: '',
      bannerImage: undefined,
      notificationPreference: false,
      deliveryEstimate: '',
      deliveryTimeMin: 0,
      deliveryTimeMax: 0,
      stateWide: false,
    },
    validationSchema,
    onSubmit: async () => {
      const { userUUId, ...userData } = location.state || {};
      if (values.bannerImage) {
        const bannerRes = await handleUploadImage(values.bannerImage);
        userData.bannerImage = bannerRes?.url || '';
      }

      const payload = {
        roleId,
        address: {
          fullAddress: values.businessLocation.label,
          latitude: String(values.businessLocation.latitude || 0),
          longitude: String(values.businessLocation.longitude || 0),
          state: values.businessLocation.state,
          country: values.businessLocation.country,
        },
        name: values.businessName,
        bannerImage: userData.bannerImage || '',
        phone: values.businessPhone,
        email: values.businessEmail,
        opens: formatDateTime(values.businessOpeningTime),
        closes: formatDateTime(values.businessClosingTime),
        outletType,
        deliveryTimeMin: values.deliveryTimeMin,
        deliveryTimeMax: values.deliveryTimeMax,
        stateWide: values.stateWide,
      };

      const outletTypeToRouteMap: Record<OutletType, string> = {
        market: privateRoutes.marketDashboard,
        restaurant: privateRoutes.restaurantDashboard,
        experience: '',
        essentials: '',
        product: '',
        logistics: '',
      };

      try {
        const response = await handleCreateServiceProfile(payload);
        const serviceProfileId = response?._id;
        const route = outletTypeToRouteMap[payload.outletType as OutletType];
        navigate(
          `/${COMMERCE}/${privateRoutes.ecommerce}/${payload.outletType}/${serviceProfileId}/${route}`
        );
      } catch (error) {
        // Handle error
      }
    },
  });

  const isDisabled =
    !isValid ||
    !values.businessOpeningTime ||
    !values.businessClosingTime ||
    isLoading;

  const handleDeliveryChange = (selectedValue: string) => {
    const selectedOption = deliveryOptions.find(
      (option) => option.value === selectedValue
    );
    if (!selectedOption) return;

    const { deliveryTimeMin, deliveryTimeMax } =
      deliveryTimeMap[selectedOption.value] || {};
    setValues({
      ...values,
      deliveryEstimate: selectedOption.value,
      deliveryTimeMin,
      deliveryTimeMax,
    });
  };

  return (
    <div className="flex justify-center">
      <div className="flex flex-col justify-center w-full">
        <section
          className="flex ml-6 cursor-pointer gap gap-x-2"
          onClick={() => navigate(-1)}
        >
          <BackIconWonWithBG />
          <p className="self-center">Back</p>
        </section>
        <div className="pt-12 mb-32">
          <header className="mb-6 text-center">
            <h1 className="mb-2 text-2xl font-normal leading-7 text-elr-black">
              Business Information
            </h1>
            <p className="text-lg leading-5 text-elr-black-400">
              Kindly fill in your business details
            </p>
          </header>
          <form
            className="w-full px-10 py-8 mx-auto mb-10 bg-elr-white-400"
            onSubmit={handleSubmit}
          >
            <div className="flex flex-col gap-3">
              <ElrInput
                onChange={handleChange}
                value={values.businessName}
                name="businessName"
                className="border-none rounded-none bg-elr-gray !h-12 outline-none"
                placeholder="Business Name"
              />
              <ElrPhoneInput
                onChange={onPhoneInputChange}
                value={businessPhone.mobile}
                inputClassName="w-full"
                containerClass="w-full flex"
                placeholder="Phone Number"
              />
              {
                <span className="text-xs text-elr-error">
                  {!businessPhone.isValid && businessPhone.mobile
                    ? 'Invalid phone number'
                    : null}
                </span>
              }
              <div className="h-12">
                <ElrLocationSelect
                  className="pr-2 !mb-3"
                  controlHeight={44}
                  currentValue={values.businessLocation?.label}
                  placeholder="Address"
                  onChange={(value) => onLocationChange(value as any)}
                />
              </div>
              <ElrInput
                onChange={handleChange}
                value={values.businessEmail}
                name="businessEmail"
                className="border-none rounded-none bg-elr-gray !h-12 outline-none"
                placeholder="Email Address"
              />
              <div className="flex flex-row items-center justify-between gap-4 align-middle md:flex-row">
                <div className="flex items-center justify-between w-full md:w-1/2 h-12 bg-elr-gray">
                  <TimePicker
                    className="w-full bg-elr-gray"
                    placeholder="Opening Time"
                    clearIcon={null}
                    value={values.businessOpeningTime}
                    onChange={(time) =>
                      setFieldValue(
                        'businessOpeningTime',
                        time ? time.format('h:mm A') : ''
                      )
                    }
                  />
                </div>
                <div className="flex items-center justify-between w-full md:w-1/2 h-12 bg-elr-gray">
                  <TimePicker
                    className="w-full bg-elr-gray"
                    placeholder="Closing Time"
                    clearIcon={null}
                    value={values.businessClosingTime}
                    onChange={(time) =>
                      setFieldValue(
                        'businessClosingTime',
                        time ? time.format('h:mm A') : ''
                      )
                    }
                  />
                </div>
              </div>
              <div className="flex items-center w-full h-12 bg-elr-gray">
                <ElrDropdown
                  options={deliveryOptions}
                  borderRadius="0"
                  placeholder="Select Delivery Options"
                  inputClassName="h-12 flex align-center justify-center"
                  currentValue={values.deliveryEstimate}
                  onChange={handleDeliveryChange}
                  className="h-full w-full"
                />
              </div>
              <div className="py-4 mb-2 border border-b-2 border-elr-gray-500 flex flex-row align-middle justify-between items-center gap-1.5">
                <h3 className="pl-1">Do you deliver all around your city?</h3>
                <div className="flex gap gap-x-2 text-elr-elr-black-300">
                  <ElrRadio
                    checked={values.stateWide}
                    onChange={() => setFieldValue('stateWide', true)}
                    radioClassName="accent-elr-black"
                    label="Yes"
                    labelClassName="text-elr-black-300 before:align-text-top gap-2"
                  />
                  <ElrRadio
                    checked={!values.stateWide}
                    onChange={() => setFieldValue('stateWide', false)}
                    radioClassName="accent-elr-black"
                    label="No"
                    labelClassName="text-elr-black-300 before:align-text-top gap-2"
                  />
                </div>
              </div>
              <div className="mb-5">
                <h3 className="mb-2">Business Image</h3>
                <ElrUploadV2
                  autoUpload={false}
                  accept=".jpeg,.jpg,.png"
                  tag={''}
                  defaultFile={values.bannerImage}
                  onUploaded={() => {}}
                  onTempUpload={(file) => setFieldValue('bannerImage', file)}
                />
              </div>
              <div className="flex justify-between">
                <p className="w-5/12">
                  Notifications about new orders should be sent to the provided
                  phone number/email address
                </p>
                <ElrSwitch
                  isToggled={values.notificationPreference}
                  toggle={() =>
                    setFieldValue(
                      'notificationPreference',
                      !values.notificationPreference
                    )
                  }
                  parentClassName="bg-elr-gray-200 border-none cursor-pointer"
                  circleClassName="bg-elr-white "
                  toggledCircleClassName="!bg-elr-white "
                  toggledParentClassName="!bg-elr-black cursor-pointer"
                />
              </div>
            </div>
            <div className="mx-auto text-center mt-14">
              <ElrButton
                text={isLoading ? 'Submitting...' : 'Continue'}
                spinnerColor="White"
                className="w-full rounded-sm bg-elr-black text-elr-white-400"
                onClick={() => handleSubmit()}
                disabled={isDisabled}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BusinessInfoForm;
