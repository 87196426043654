import { publicRoutes } from 'app/utilities/routes';
import { useNavigate } from 'react-router-dom';
import { ElrButton } from 'ui-components';

interface BoxProps {
  num: number | string;
  text: string;
  bgColor: string;
  textColor: string;
  roundedCorners: string;
}

const AboutUs = () => {
  const navigate = useNavigate();
  const handleGetStarted = () => {
    navigate(publicRoutes.aboutUs);
  };
  const StatBox: React.FC<BoxProps> = ({
    num,
    text,
    bgColor,
    textColor,
    roundedCorners,
  }) => (
    <div
      className={`md:w-320 w-full lg:w-214 h-155 lg:h-155 flex flex-col justify-center items-center gap-2 align-middle lg:p-6 md:p-6 p-8 ${bgColor} ${roundedCorners}`}
    >
      <p className="text-gray-600 mb-2 text-sm md:text-lg">{text}</p>
      <h2 className={`text-5xl md:text-6xl ${textColor}`}>{num}</h2>
    </div>
  );

  const statsData = [
    {
      num: 5,
      text: 'Core Services',
      bgColor: 'bg-elr-green-50A',
      textColor: 'text-elr-green',
      roundedCorners:
        'rounded-tl-2xl rounded-bl-none rounded-tr-none rounded-br-none',
    },
    {
      num: '5k+',
      text: 'Active Users',
      bgColor: 'bg-elr-purple-50A',
      textColor: 'text-elr-purple',
      roundedCorners:
        'rounded-tl-none rounded-bl-2xl rounded-tr-none rounded-br-none',
    },
    {
      num: 20,
      text: 'Team Members',
      bgColor: 'bg-elr-yellow-50A',
      textColor: 'text-elr-yellow',
      roundedCorners:
        'rounded-tl-none rounded-bl-none rounded-tr-2xl rounded-br-none',
    },
    {
      num: '2+',
      text: 'Countries',
      bgColor: 'bg-elr-gray',
      textColor: 'text-elr-black',
      roundedCorners:
        'rounded-tl-none rounded-bl-none rounded-tr-none rounded-br-2xl',
    },
  ];

  return (
    <div className="relative my-10 flex justify-center items-center">
      <div className="md:h-600 h-700 py-10 box-decoration-clone">
        <div className="absolute z-10 left-0 md:h-447 h-full w-full top-1/2 transform -translate-y-1/2 bg-gradient-to-b box-decoration-clone from-elr-green-150 via-elr-black to-elr-black" />
        <div className="hidden md:block absolute z-20 right-0 w-1/2 h-540 top-1/2 transform -translate-y-1/2 bg-gradient-to-b box-decoration-clone from-elr-green-150 via-elr-black to-elr-black rounded-tl-2xl rounded-bl-2xl" />
        <div className="hidden md:block absolute z-0 left-0 w-7/12 h-600 top-1/2 transform -translate-y-1/2 bg-elr-gray rounded-tr-2xl rounded-br-2xl" />
      </div>
      <div className="relative z-30 mx-auto max-w-6.5xl py-24 md:py-2">
        <div className="flex-col-reverse md:flex flex md:flex-row md:gap-8 gap-10 justify-center items-center mx-5 md:mx-5">
          <div className="flex flex-col">
            <h2 className="text-2xl md:text-40 leading-tight font-bold text-elr-white">
              About Us
            </h2>
            <p className="pt-6 pb-14 text-elr-white text-opacity-55 md:text-xl text-lg md:w-9/12 w-11/12">
              Errandlr’s purpose is to simplify everyday life for individuals
              and businesses by offering seamless payments, logistics, shopping,
              and more—all in one super app.
            </p>
            <ElrButton
              text="Read More"
              className="bg-elr-white text-elr-black w-fit"
              onClick={handleGetStarted}
            />
          </div>
          <div className="flex md:w-auto w-full">
            <div className="flex flex-col w-1/2 md:w-full">
              {statsData.slice(0, 2).map((stat) => (
                <StatBox key={stat.text} {...stat} />
              ))}
            </div>
            <div className="flex flex-col w-1/2 md:w-full">
              {statsData.slice(2).map((stat) => (
                <StatBox key={stat.text} {...stat} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
