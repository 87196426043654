import { useGetProfile } from 'app/hooks/user';
import { privateRoutes } from 'app/utilities/routes';
import { OutletType } from 'app/utilities/types/shared';
import { useNavigate } from 'react-router';
import {
  BasketIconWonWithBG,
  CalendarIconWonWithBG,
  CutleryIconWonWithBG,
  LeafIconWonWithBG,
  WearIconWonWithBG,
} from '../SvgAssets';

const EcommerceInitial = () => {
  const navigate = useNavigate();
  const {
    id: userId,
    accountInformation: {
      outletType = {
        market: false,
        logistics: false,
        essentials: false,
        product: false,
        experience: false,
        restaurant: false,
      },
    },
  } = useGetProfile();

  const outletTypeToRouteMap: Record<OutletType, string> = {
    market: outletType.market
      ? `${privateRoutes.market}/${userId}/${privateRoutes.marketDashboard}`
      : privateRoutes.marketOnboarding,
    logistics: '',
    essentials: '',
    product: '',
    experience: privateRoutes.experiencesList,
    restaurant: outletType.restaurant
      ? `${privateRoutes.restaurant}/${userId}/${privateRoutes.restaurantDashboard}`
      : privateRoutes.restaurantOnboarding,
  };

  const handleProceed = async (outletType_: OutletType) => {
    try {
      const route = outletTypeToRouteMap[outletType_];
      navigate(route);
    } catch (error) {
      // Handle error
    }
  };

  const onSelectOutletType = (outletType_: OutletType) => {
    handleProceed(outletType_);
  };

  return (
    <div className="flex justify-center">
      <div className="pt-20 mb-32">
        <header className="mb-6 text-center">
          <h1 className="mb-2 text-2xl font-normal leading-7 text-elr-black">
            What do you want to sell?
          </h1>
          <p className="text-lg leading-5 text-elr-black-400">
            Select the option that applies to you
          </p>
        </header>

        <div className="grid gap-4 grid-cols-column-two">
          <div
            className="px-6 py-5 cursor-pointer bg-elr-white-400"
            onClick={() => onSelectOutletType('market')}
          >
            <LeafIconWonWithBG />
            <div className="mb-6" />
            <p className="text-lg leading-5 text-elr-black">Market Products</p>
            <p className="text-base text-elr-black-300">
              e.g Foodstuffs items in bulk
            </p>
          </div>
          <div
            className="px-6 py-5 cursor-pointer bg-elr-white-400"
            onClick={() => onSelectOutletType('restaurant')}
          >
            <CutleryIconWonWithBG />
            <div className="mb-6" />
            <p className="text-lg leading-5 text-elr-black">Restaurant</p>
            <p className="text-base text-elr-black-300">
              e.g Restaurant Meals and Dishes
            </p>
          </div>
          <div
            className="px-6 py-5 cursor-pointer bg-elr-white-400"
            onClick={() => onSelectOutletType('essentials')}
          >
            <BasketIconWonWithBG />
            <div className="mb-6" />
            <p className="text-lg leading-5 text-elr-black">Essential Items</p>
            <p className="text-base text-elr-black-300">
              e.g Hair care products, Toiletries
            </p>
          </div>
          <div className="px-6 py-5 opacity-50 cursor-pointer bg-elr-white-400">
            <WearIconWonWithBG />
            <div className="mb-6" />
            <p className="text-lg leading-5 text-elr-black">Lifestyle</p>
            <p className="text-base text-elr-black-300">
              e.g Clothes, shoes, accessories
            </p>
          </div>
          <div
            className="px-6 py-5 cursor-pointer bg-elr-white-400"
            onClick={() => onSelectOutletType('experience')}
          >
            <CalendarIconWonWithBG />
            <div className="mb-6" />
            <p className="text-lg leading-5 text-elr-black">Experiences</p>
            <p className="text-base text-elr-black-300">
              e.g Tickets to a rave, conferences, hangout
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EcommerceInitial;
