import React from 'react';

interface SubSectionProps {
  title: string;
  content: React.ReactNode;
}

const SubSection: React.FC<SubSectionProps> = ({ title, content }) => (
  <div className="mx-auto mt-14 mb-5 md:px-0">
    <h2 className="text-3xl font-medium mb-5">{title}</h2>
    <div className="text-elr-black text-opacity-60 text-lg">{content}</div>
  </div>
);

export default SubSection;
