import ListIdentLine from 'assets/images/list-indefinite.svg';
import ContactBookLine from 'assets/images/contacts-book-upload-line.svg';
import Wallet3LineIcon from 'assets/images/wallet-3-line.svg';
import { FeatureCard } from 'app/modules/components/FeatureCard';

const MoreVisibility = () => {
  const visibilityBenefits = [
    {
      icon: ContactBookLine,
      title: 'User-Friendly Product Upload Method',
      description:
        'Effortlessly list your products with our intuitive and simplified upload flow.',
    },
    {
      icon: ListIdentLine,
      title: 'Seamless Management of Orders',
      description:
        'Track and manage your orders with our seamless and easy-to-use dashboard.',
    },
    {
      icon: Wallet3LineIcon,
      title: 'Easy Withdrawal Process',
      description:
        'Withdraw your money at any desired time, with just a few simple clicks.',
    },
  ];
  return (
    <div className="mx-5 md:mx-0 md:py-40 py-28">
      <div className="max-w-6.5xl mx-auto flex flex-col md:flex-col">
        <p className="text-4xl mx-auto md:w-full w-11/12 md:text-5xl text-center">
          More Visibility, More Customers, More Sales
        </p>
        <p className="w-9/12 md:text-2xl text-center text-lg  text-elr-black-300 mx-auto pt-6">
          Seamless e-commerce solution tailored for your business
        </p>
        <div className="md:mt-16 mt-10 flex flex-wrap items-center justify-center md:gap-8 gap-6">
          {visibilityBenefits.map((benefits) => (
            <div key={benefits.title} className="w-full md:w-[30%]">
              <FeatureCard
                icon={benefits.icon}
                titleClassName="w-11/12 md:w-full h-50"
                title={benefits.title}
                description={benefits.description}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MoreVisibility;
