import FocusIcon from 'assets/images/focus-3-line.svg';
import BoxLineIcon from 'assets/images/box-3-line.svg';
import SupportLineIcon from 'assets/images/customer-service-line.svg';
import { FeatureCard } from 'app/modules/components/FeatureCard';

const DeliverMore = () => {
  const deliveryBenefits = [
    {
      icon: BoxLineIcon,
      title: 'Local and International Deliveries',
      description:
        'Send and receive parcel both locally and internationally at your own convenience.',
    },
    {
      icon: FocusIcon,
      title: 'Monitor and Track Delivery Status',
      description:
        'Follow up your orders and easily track the delivery status of each order.',
    },
    {
      icon: SupportLineIcon,
      title: 'Dedicated Customer Support',
      description:
        'Committed team that ensures your delivery experience is seamless and stress-free.',
    },
  ];
  return (
    <div className="mx-5 md:mx-0 md:py-40 py-28">
      <div className="max-w-6.5xl mx-auto flex flex-col md:flex-col">
        <p className="text-4xl mx-auto md:w-full w-11/12 md:text-5xl text-center">
          Deliver More, Stress Less
        </p>
        <p className="w-9/12 md:text-2xl text-center text-lg  text-elr-black-300 mx-auto pt-6">
          Efficient and reliable logistics solutions tailored to your needs
        </p>
        <div className="md:mt-16 mt-10 flex flex-wrap items-center justify-center md:gap-8 gap-6">
          {deliveryBenefits.map((benefits) => (
            <div key={benefits.title} className="w-full md:w-[30%]">
              <FeatureCard
                icon={benefits.icon}
                title={benefits.title}
                description={benefits.description}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DeliverMore;
