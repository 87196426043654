import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ElrButton } from 'ui-components';

interface FeatureBlockProps {
  imageSrc?: string;
  imageAlt?: string;
  containerClassName?: string;
  layoutClassName?: string;
  headingText: string;
  headingClassName?: string;
  buttonImage?: string;
  buttonImageAltName?: string;
  buttonImageClassName?: string;
  descriptionText: string;
  descriptionClassName?: string;
  buttonText: string;
  buttonClassName?: string;
  buttonLink?: string;
  secondaryButtonText?: string;
  secondaryButtonClassName?: string;
  secondaryButtonImage?: string;
  secondaryButtonImageAltName?: string;
  secondaryButtonImageClassName?: string;
  secondaryButtonLink?: string;
  buttonAction?: () => void;
}

export const FeatureBlock: React.FC<FeatureBlockProps> = ({
  imageSrc,
  imageAlt,
  containerClassName = '',
  layoutClassName = 'md:flex-row',
  headingText,
  headingClassName = 'text-elr-black',
  descriptionText,
  descriptionClassName = 'text-elr-black text-opacity-55 w-11/12',
  buttonText,
  buttonClassName = 'bg-elr-black text-white',
  buttonLink,
  buttonImage,
  buttonImageAltName,
  buttonImageClassName = '',
  secondaryButtonText,
  secondaryButtonClassName = 'bg-elr-black text-white',
  secondaryButtonImage,
  secondaryButtonImageAltName,
  secondaryButtonImageClassName = '',
  secondaryButtonLink,
  buttonAction,
}) => {
  const navigate = useNavigate();

  const handleButtonClick = (link?: string, action?: () => void) => {
    if (action) {
      action();
    } else if (link) {
      if (link.startsWith('http')) {
        window.open(link, '_blank');
      } else {
        navigate(link);
      }
    }
  };

  return (
    <div className={`max-w-6.5xl mx-auto ${containerClassName}`}>
      <div
        className={`mx-5 md:mx-0 w-11/12 flex flex-col gap-16 justify-center items-center ${layoutClassName}`}
      >
        <img src={imageSrc} alt={imageAlt} />
        <div className="flex flex-col">
          <h2
            className={`text-4xl md:text-40 leading-tight font-bold ${headingClassName}`}
          >
            {headingText}
          </h2>
          <p
            className={`pt-6 pb-14 md:text-xl text-lg ${descriptionClassName}`}
          >
            {descriptionText}
          </p>
          <div className="flex md:flex-row flex-col w-full gap-4">
            <ElrButton
              text={buttonText}
              imageSrc={
                <img
                  src={buttonImage}
                  alt={buttonImageAltName}
                  className={buttonImageClassName}
                />
              }
              className={`w-fit ${buttonClassName}`}
              onClick={() => handleButtonClick(buttonLink, buttonAction)}
            />
            {secondaryButtonText && secondaryButtonLink && (
              <ElrButton
                text={secondaryButtonText}
                imageSrc={
                  <img
                    src={secondaryButtonImage}
                    alt={secondaryButtonImageAltName}
                    className={secondaryButtonImageClassName}
                  />
                }
                className={`w-fit ${secondaryButtonClassName}`}
                onClick={() =>
                  handleButtonClick(secondaryButtonLink, buttonAction)
                }
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
