import { publicRoutes } from 'app/utilities/routes';
import ecommerceMockup from 'assets/images/errandlr-ecommerce-mockup.png';
import exploreCityImage from 'assets/images/explore-city.svg';
import luxuryDining from 'assets/images/luxury-dinner.svg';
import experienceVector from 'assets/images/relax-and-experience.svg';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { ElrPageTitle } from 'ui-components';
import ExploreMore from '../components/ExploreMore';
import { FeatureBlock } from '../components/FeatureBlock';
import FeatureSection from '../components/FeatureSection';
import HeroHeader from '../components/HeroHeader';
import PopularQuestions from '../components/PopularQuestions';
import { QrCodeModal } from '../components/QrCodeModal';
import { Footer } from '../landing/components';
import ExperienceCarousel from './ExperienceCarousel';
import ManageExperiences from './ManageExperiences';

const APP_DOWNLOAD_LINK = 'https://onelink.to/ph8jez';

const ExperiencePage = () => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    setIsMobile(/Mobi|Android|iPhone/i.test(navigator.userAgent));
  });

  const questions = [
    {
      id: 1,
      header: 'Are charges attached to tickets?',
      content:
        'Our ticket prices reflect the base cost of your experience. Please note that additional payment gateway fees and service charges may apply at checkout to cover secure processing and platform maintenance.',
    },
    {
      id: 2,
      header: 'How quickly can I set-up my experience?',
      content:
        'Setting up your experience is fast and straightforward. Our user-friendly platform guides you step-by-step to get your event live in just minutes. Please note, you must register as a business to start hosting experiences.',
    },
    {
      id: 3,
      header:
        'Is there a way to verify people that registered for my experience?',
      content:
        'Every experience purchase generates a unique QR code, ensuring a secure and verifiable entry process. Since most users are registered, payment is collected upfront before any service is provided, maintaining a trusted and streamlined experience.',
    },
  ];

  const handleButtonClick = () => {
    if (isMobile) {
      window.location.href = APP_DOWNLOAD_LINK;
    } else {
      setShowModal(true);
    }
  };

  return (
    <div className="bg-white">
      <ElrPageTitle title="Errandlr - Experience" />
      <HeroHeader
        title={
          <>
            Unwind and <span className="text-elr-yellow">Discover</span>{' '}
            Experiences
          </>
        }
        buttonText="Explore Now"
        backgroundClass="bg-elr-yellow bg-opacity-10"
        imageSrc={experienceVector}
        altText="Relax and Experience"
        buttonAction={handleButtonClick}
      />
      <QrCodeModal isOpen={showModal} onClose={() => setShowModal(false)} />
      <FeatureBlock
        imageSrc={exploreCityImage}
        imageAlt="explore-the-city"
        headingText="Explore the City, Savor the Experience"
        layoutClassName="md:flex-row md:py-40 py-28 flex-col-reverse"
        descriptionText="Discover the best dining spots and experience venues in your destination. Reserve ahead and make your visit unforgettable."
        buttonText="Book Now"
        buttonAction={handleButtonClick}
      />
      <ExperienceCarousel />
      <FeatureSection
        title="Turn Your Ideas into Memorable Experiences"
        description="Create, manage, and share your experiences effortlessly with our user-friendly platform—designed to make experience process hassle-free."
        imageSrc={ecommerceMockup}
        imageAlt="Errandlr Ecommerce Screen"
        imageClassName="md:max-w-lg"
        gradients={{
          mainBackground:
            'bg-gradient-to-b box-decoration-clone from-elr-black via-elr-black to-elr-black',
          leftBackground:
            'bg-gradient-to-b box-decoration-clone from-elr-black via-elr-black to-elr-black',
          rightBackground: 'bg-elr-yellow bg-opacity-5',
        }}
        rightBorderRadius="rounded-tl-2xl rounded-bl-2xl"
        leftBorderRadius="rounded-tr-2xl rounded-br-2xl"
        buttonText="Create Experience"
        buttonAction={() => navigate(publicRoutes.apiPage)}
        reverseLayout
      />
      <ManageExperiences />

      <FeatureBlock
        imageSrc={luxuryDining}
        imageAlt="luxury-dinning"
        headingText="Reserve a Seat at Luxury Dining Places"
        layoutClassName="md:flex-row md:py-40 py-28 flex-col-reverse"
        descriptionText="Elevate your dining experience by securing a place at exclusive restaurants offering sophistication and style."
        descriptionClassName="md:w-10/12 w-full text-elr-black text-opacity-55"
        buttonText="Book Now"
        buttonAction={handleButtonClick}
      />
      <PopularQuestions questions={questions} headingColor="text-elr-yellow" />
      <ExploreMore hiddenCards={['Explore']} />
      <Footer />
    </div>
  );
};

export default ExperiencePage;
