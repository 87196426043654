import { MobileHeader } from 'app/components/Header';
import TopNavigationMenu from 'app/components/menu/TopNavigationMenu';
import { ElrPageTitle } from 'ui-components';

const HeadlessHeader: React.FC<{ title: string }> = ({ title }) => (
  <>
    <ElrPageTitle title={title} />
    <div className="bg-white">
      <div className="flex justify-between align-middle w-full md:mx-auto items-center">
        <div className="w-full md:w-11/12 md:mx-auto">
          <MobileHeader mdHide />
        </div>
        <TopNavigationMenu containerClass="md:mr-0" />
      </div>
    </div>
  </>
);

export default HeadlessHeader;
