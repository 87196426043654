/* eslint-disable import/no-unresolved */
import QuoteIcon from 'assets/images/quote-icon.svg';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';

interface Testimonial {
  id: number;
  name: string;
  socialHandle: string;
  message: string;
}

interface TestimonialCardProps {
  testimonial: Testimonial;
}

const TestimonialCard: React.FC<TestimonialCardProps> = ({ testimonial }) => {
  const { name, socialHandle, message } = testimonial;

  return (
    <div className="bg-elr-gray h-full p-16 rounded-lg text-left flex flex-col justify-between">
      <div className="w-60 flex-1 md:w-full">
        <img src={QuoteIcon} alt="Quote Icon" />
        <p className="text-elr-black-300 leading-snug pt-4 pb-8">{message}</p>
      </div>
      <div className="flex flex-col items-start mt-auto">
        <h3 className="text-lg font-bold">{name}</h3>
        <p className="text-sm text-elr-black-300">{socialHandle}</p>
      </div>
    </div>
  );
};

const testimonials: Testimonial[] = [
  {
    id: 1,
    name: 'TamedLux',
    socialHandle: '@tamedlux',
    message:
      'Errandlr transformed our business logistics. Their same-day deliveries and competitive rates have significantly improved our customer satisfaction and sales performance.',
  },
  {
    id: 2,
    name: 'Michael Edith',
    socialHandle: '@michaeledith',
    message:
      "As a small business owner, Errandlr's all-in-one platform saves me countless hours. From deliveries to payments, everything is streamlined and professional.",
  },
  {
    id: 3,
    name: 'Mama Dee',
    socialHandle: '@mamadee',
    message:
      'Shopping internationally used to be complicated until I found Errandlr. Now I can easily shop from different markets and manage my daily errands in one place.',
  },
];

const Testimonials: React.FC = () => (
  <div className="pb-20 mx-5 md:mx-0 md:py-28 py-20">
    <div className="text-center flex flex-col justify-center items-center">
      <h1 className="text-14 md:text-2xl leading-5 text-elr-purple">
        Our Users Say
      </h1>
      <div className="flex justify-center pt-4 px-6 pb-12 md:pt-6 md:pb-16">
        <h2 className="text-28 md:text-40 leading-tight font-bold w-full md:w-9/12">
          Trusted by Businesses and Individuals Alike
        </h2>
      </div>
    </div>

    <Swiper slidesPerView={'auto'} spaceBetween={20} className="md:hidden">
      {testimonials.map((testimonial) => (
        <SwiperSlide
          key={testimonial.id}
          style={{ display: 'flex', height: 'auto', width: 'auto' }}
        >
          <TestimonialCard testimonial={testimonial} />
        </SwiperSlide>
      ))}
    </Swiper>

    <div className="hidden md:flex gap-6 justify-center items-start max-w-6.5xl mx-auto">
      {testimonials.map((testimonial) => (
        <TestimonialCard key={testimonial.id} testimonial={testimonial} />
      ))}
    </div>
  </div>
);

export default Testimonials;
