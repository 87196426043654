import React from 'react';
import Bubbles from 'assets/images/bubbles.png';
import downloadAppVector from 'assets/images/download-app-vector.svg';
import signUpMockup from 'assets/images/sign-up-mockup.png';
import trackVector from 'assets/images/track-request-vector.svg';
import trackingRequest from 'assets/images/tracking-screen-mockup.png';
import shopVector from 'assets/images/select-services-vector.svg';
import shopAppMockup from 'assets/images/shop-app-mockup.png';
import selectServices from 'assets/images/shop-dashboard-mockup.png';
import appStore from 'assets/images/app-store-btn.svg';
import googlePlay from 'assets/images/google-play-btn.svg';

const HowItWorks: React.FC = () => (
  <div className="flex flex-col mx-auto max-w-6.5xl">
    <div className=" md:mb-72 relative mb-64p md:min-h-full min-h-[1300px]">
      <div className="bg-elr-black mx-5 md:mx-0 relative rounded-xl pb-40 px-8">
        <img
          src={Bubbles}
          alt=""
          className="absolute inset-0 w-full h-full object-cover"
        />
        <div className="py-14 text-center relative">
          <h1 className="text-14 md:text-2xl leading-5 text-elr-yellow">
            How It Works
          </h1>
          <div className="flex justify-center pt-4 px-6 pb-12 md:pt-6 md:pb-16">
            <h2 className="text-white text-28 md:text-40 leading-tight font-bold w-full md:w-8/12">
              Everything You Need, All in One App
            </h2>
          </div>
          <div className="flex justify-center">
            <div className="flex md:flex-row gap-6 flex-col absolute justify-between w-50">
              <div className="flex-col flex-nowrap relative align-middle items-center justify-center bg-elr-gray py-6 px-2 rounded-xl md:max-w-[305px] h-[415px] overflow-hidden">
                <p className="font-semibold mx-auto md:w-10/12 w-8/12 text-2xl">
                  Download App & Create Account
                </p>
                <img
                  src={downloadAppVector}
                  alt=""
                  className="absolute bottom-2 left-1/2 -translate-x-1/2"
                />
                <img
                  src={signUpMockup}
                  alt=""
                  className="absolute -bottom-5 w-64 left-1/2 -translate-x-1/2"
                />
              </div>

              <div className="md:hidden flex-col flex-nowrap relative align-middle items-center justify-center bg-elr-gray py-6 px-2 rounded-xl md:max-w-[305px] h-[415px] overflow-hidden">
                <p className="font-semibold mx-auto md:w-10/12 w-8/12 text-2xl">
                  Select & Use Preferred Service
                </p>
                <img
                  src={shopVector}
                  alt=""
                  className="absolute -bottom-5 left-1/2 -translate-x-1/2"
                />
                <img
                  src={shopAppMockup}
                  alt=""
                  className="absolute -bottom-40 w-64 left-1/2 -translate-x-1/2"
                />
              </div>

              <div className="hidden md:flex flex-col relative align-middle items-center justify-center bg-elr-gray py-6 px-2 rounded-xl md:max-w-[305px] h-[415px] overflow-hidden">
                <img
                  src={shopVector}
                  alt=""
                  className="absolute top-2 left-1/2 -translate-x-1/2"
                />
                <img
                  src={selectServices}
                  alt=""
                  className="absolute -top-5 w-64 left-1/2 -translate-x-1/2"
                />
                <p className="font-semibold mx-auto md:w-10/12 w-8/12 left-1/2 translate-y-44 text-2xl">
                  Select & Use Preferred Service
                </p>
              </div>

              <div className="flex-col relative align-middle items-center justify-center bg-elr-gray py-6 px-2 rounded-xl md:max-w-[305px] h-[415px] overflow-hidden">
                <p className="font-semibold mx-auto md:w-10/12 w-8/12 text-2xl">
                  Track & Follow up Your Request
                </p>
                <img
                  src={trackVector}
                  alt=""
                  className="absolute bottom-2 left-1/2 -translate-x-1/2"
                />
                <img
                  src={trackingRequest}
                  alt=""
                  className="absolute -bottom-5 w-64 left-1/2 -translate-x-1/2"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="px-5 md:mx-0 flex justify-center gap-6 md:h-12 w-full mt-8 mb-12 md:mb-0">
      <button
        onClick={() =>
          window.open(
            'https://apps.apple.com/app/errandlr/id6499235011',
            '_blank'
          )
        }
        type="button"
        className="cursor-pointer"
      >
        <img src={appStore} alt="Download on App Store" className="h-full" />
      </button>
      <button
        onClick={() =>
          window.open(
            'https://play.google.com/store/apps/details?id=com.errandlr.commerce&pcampaignid=web_share',
            '_blank'
          )
        }
        type="button"
        className="cursor-pointer"
      >
        <img src={googlePlay} alt="Get it on Google Play" className="h-full" />
      </button>
    </div>
  </div>
);

export default HowItWorks;
