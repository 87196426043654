import { publicRoutes } from 'app/utilities/routes';
import appleStoreBtn from 'assets/images/app-store-btn-white.svg';
import googlePlay from 'assets/images/google-play-btn-black.svg';
import NigerianAccountVector from 'assets/images/nigerian-account.svg';
import PayBillsVector from 'assets/images/pay-bills.svg';
import paymentCards from 'assets/images/payment-cards.svg';
import requestATMockup from 'assets/images/request-atm.svg';
import SendMoneyVector from 'assets/images/send-money.svg';
import { useEffect, useState } from 'react';
import { ElrPageTitle } from 'ui-components';
import ExploreMore from '../components/ExploreMore';
import { FeatureBlock } from '../components/FeatureBlock';
import FeatureSection from '../components/FeatureSection';
import HeroHeader from '../components/HeroHeader';
import PopularQuestions from '../components/PopularQuestions';
import { QrCodeModal } from '../components/QrCodeModal';
import { Footer } from '../landing/components';

const APP_DOWNLOAD_LINK = 'https://onelink.to/ph8jez';
const PaymentPage = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    setIsMobile(/Mobi|Android|iPhone/i.test(navigator.userAgent));
  });
  const handleExploreClick = () => {
    if (isMobile) {
      window.location.href = APP_DOWNLOAD_LINK;
    } else {
      setShowModal(true);
    }
  };

  const questions = [
    {
      id: 1,
      header: 'Are there any additional fees on my payments?',
      content:
        'Yes, there are certain fees on your payments. Fees are determined by the payment gateway and the amount of the transaction.',
    },
    {
      id: 2,
      header: 'Is Errandlr licensed to provide this service?',
      content:
        'Errandlr is licensed to provide this service in partnership with Safehaven MFB, which are approved by the Central Bank of Nigeria and have all necessary licenses to operate in Nigeria.',
    },
    {
      id: 3,
      header:
        'Can I accept a payment and then later transfer it to my bank account?',
      content:
        'Yes, you can receive payments at any time. However, to transfer your money to your bank account, you first need to verify your identity. This is only needed once. You can then transfer part or full balance to your bank account at any time.',
    },
    {
      id: 4,
      header: 'Can i pay through Apple Pay or Google Pay?',
      content:
        'Yes, you can pay through Apple Pay or Google Pay. However, you need to have an apple pay or google pay account activated on your device. International transfers are processed by Stripe.',
    },
    {
      id: 5,
      header: 'How is identity verified?',
      content:
        'There are two ways to verify your identity. You can either verify your BVN or upload a selfie with your identity document.',
    },
  ];
  return (
    <div className="bg-white">
      <ElrPageTitle title="Errandlr - Payment" />
      <HeroHeader
        title="Send, Receive and Pay Securely"
        buttonText="Explore Now"
        backgroundClass="bg-elr-gray"
        bubblesOpacity="opacity-90"
        imageSrc={paymentCards}
        altText="Payment Cards"
        buttonAction={handleExploreClick}
      />
      <QrCodeModal isOpen={showModal} onClose={() => setShowModal(false)} />
      <FeatureBlock
        imageSrc={PayBillsVector}
        imageAlt="pay-bills"
        headingText="Pay Bills and Recharge Airtime and Data"
        descriptionText="Settle your electricity bill in just a few taps, and also top up airtime and data for any network without leaving the comfort of your home."
        layoutClassName="md:flex-row md:py-40 py-20"
        descriptionClassName="w-10/12 text-elr-black text-opacity-55"
        buttonText="Pay Now"
        buttonLink={publicRoutes.registration}
      />
      <FeatureBlock
        imageSrc={SendMoneyVector}
        imageAlt="send-money"
        headingText="Send and Receive Money Safely"
        descriptionText="Enjoy the convenience of fast, reliable money transfers right from your mobile device, backed by trusted safety protocols."
        layoutClassName="md:flex-row-reverse md:pb-40 pt-0 pb-20"
        descriptionClassName="w-10/12 text-elr-black text-opacity-55"
        buttonText="Explore Now"
        buttonLink={publicRoutes.registration}
      />
      <FeatureSection
        title="Request ATM Card and Have it Delivered"
        description="Apply for an ATM card quickly and have it delivered to your doorstep. Enjoy hassle-free banking and seamless transactions anytime, anywhere."
        imageSrc={requestATMockup}
        imageAlt="Errandlr API Screen"
        imageClassName="md:max-w-sm"
        gradients={{
          mainBackground:
            'bg-gradient-to-b box-decoration-clone from-elr-black via-elr-black to-elr-black',
          leftBackground:
            'bg-gradient-to-b box-decoration-clone from-elr-black via-elr-black to-elr-black',
          rightBackground: 'bg-elr-gray',
        }}
        rightBorderRadius="rounded-tl-2xl rounded-bl-2xl"
        leftBorderRadius="rounded-tr-2xl rounded-br-2xl"
        buttons={[
          {
            text: 'Download on App Store',
            imageSrc: appleStoreBtn,
            action: () =>
              window.open(
                'https://apps.apple.com/app/errandlr/id6499235011',
                '_blank'
              ),
          },
          {
            text: 'Get it on Google Play',
            imageSrc: googlePlay,
            action: () =>
              window.open(
                'https://play.google.com/store/apps/details?id=com.errandlr.commerce&pcampaignid=web_share',
                '_blank'
              ),
          },
        ]}
        reverseLayout
      />
      <FeatureBlock
        imageSrc={NigerianAccountVector}
        imageAlt="nigerian-account"
        headingText="Open a Nigerian Account as a Foreigner"
        descriptionText="Open a Nigerian bank account from anywhere and convert your funds to Naira effortlessly with Errandlr's secure, user-friendly banking platform."
        layoutClassName="md:flex-row-reverse md:py-40 py-20"
        descriptionClassName="w-10/12 text-elr-black text-opacity-55"
        buttonText="Get Started"
        buttonLink={publicRoutes.registration}
      />
      <PopularQuestions questions={questions} headingColor="text-elr-black" />
      <ExploreMore hiddenCards={['Pay']} />
      <Footer />
    </div>
  );
};

export default PaymentPage;
