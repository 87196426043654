import {
  ExperienceDataInterface,
  ExperienceTicket,
  SoldTicketsResponse,
  TicketSale,
  TicketSalesDataInterface,
} from 'app/api/types';
import SlideIn from 'app/components/SlideIn/SlideIn';
import {
  CopyDoubleIcon,
  DownloadLineIcon,
  DrawerIcon,
  InformationIcon,
  MoneyIcon,
  PencilIcon,
  PlusIcon,
  QRcodeIcon,
} from 'app/components/SvgAssets';
import {
  useCloseExperience,
  useFetchExperience,
  useFetchTicketSales,
  useListSoldTickets,
  useToggleTicket,
} from 'app/hooks/experience';
import { useTicketSalesAggregate } from 'app/hooks/requests/commerce';
import { useGetProfile } from 'app/hooks/user';
import copyToClipboard from 'app/utilities/copyToClipboard';
import { currencyFormatter } from 'app/utilities/helpers';
import { COMMERCE } from 'app/utilities/roles';
import { privateRoutes, publicRoutes } from 'app/utilities/routes';
import { ExperienceChargesType, Ticket } from 'app/utilities/types/shared';
import { DateTime } from 'luxon';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ElrModal, ElrSwitch } from 'ui-components';
import {
  ElrButton,
  ElrButtonPreIcon,
  ElrDropdownButton,
} from 'ui-components/components/ElrButton';
import ErrorIcon from '../../../../assets/images/error-icon.svg';
import MoreIcon from '../../../../assets/images/more-2-fill.svg';
import ExperienceDropdown from './ExperienceDropdown';
import './index.css';

const ExperienceDashboard = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [experience, setExperience] = useState<ExperienceDataInterface | null>(
    null
  );
  const [ticketSales, setTicketSales] = useState<TicketSalesDataInterface[]>(
    []
  );
  const [, setIsDropdownVisible] = useState(false);
  const [, setIsQrDropdownVisible] = useState(false);

  const [soldTickets, setSoldTickets] = useState<SoldTicketsResponse[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { experienceUUId } = params;
  const {
    accountInformation: { id: roleId },
  } = useGetProfile();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { getExperience } = useFetchExperience(
    experienceUUId as string,
    setExperience
  );
  const { fetchTicketSales } = useFetchTicketSales();
  const { toggleTicket } = useToggleTicket();
  const { closeExperience } = useCloseExperience();
  const { listSoldTickets } = useListSoldTickets();
  const location = useLocation();
  const {
    experienceName = experience?.name,
    experienceLocation = experience?.location,
    experienceDescription = experience?.description,
    experienceDate = experience?.experienceDate,
    experienceTime = experienceDate
      ? new Date(experienceDate).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        })
      : '',
    createdDate = location.state?.createdDate || 'N/A',
    ticketTypes = experience?.tickets,
  } = location.state || {};

  const { totalTicketSales } = useTicketSalesAggregate({
    experienceId: experience?.id ?? '',
    ticketId: ticketTypes ? ticketTypes[0]?.id : null,
  });

  useEffect(() => {
    const handleClickOutside = (clickEvent: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(clickEvent.target as Node)
      ) {
        setIsDropdownVisible(false);
        setIsQrDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    getExperience();
  }, []);

  useEffect(() => {
    if (experience) {
      fetchTicketSales(experience.id, (sales) => {
        if (sales !== null) {
          setTicketSales(sales);
        }
      });
      listSoldTickets(
        { experienceId: experience.id, page: 1, limit: 100 },
        (fetchedSoldTickets) => {
          if (fetchedSoldTickets !== null) {
            setSoldTickets(fetchedSoldTickets);
          }
        }
      );
    }
  }, [experience?.id, fetchTicketSales, listSoldTickets]);

  if (!experience) return <div />;
  const experienceIsClosed = experience.status === 'closed';

  const experienceLink = `${window.location.origin}${publicRoutes.experience}/${experience.uuid}`;
  const calculateTotalSalesAmount = (
    businessticketSales: TicketSalesDataInterface[] = []
  ): number =>
    businessticketSales.reduce(
      (total, sale) => total + sale.totalAmountOfTicketSold,
      0
    );

  const calculateTotalTicketsSold = (
    businessticketSales: TicketSalesDataInterface[] = []
  ): number =>
    businessticketSales.reduce(
      (total, sale) => total + sale.totalQuantityofTicketSold,
      0
    );

  const handleCopy = () => {
    copyToClipboard(experienceLink);
    toast.success('Experience link copied to clipboard');
  };

  const formatExpiryDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getUTCFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleToggleTicket = async (
    ticketType: string,
    ticketStatus: boolean
  ) => {
    try {
      setIsLoading(true);
      await toggleTicket(experience.id, ticketType, ticketStatus);
      getExperience();
    } catch (error) {
      // handle error
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseExperience = async () => {
    try {
      setIsLoading(true);
      await closeExperience(experience.id, roleId);
      getExperience();
      toast.success('Experience closed successfully');
    } catch (error) {
      toast.error('Error closing experience');
    } finally {
      setIsLoading(false);
      setShowModal(false);
    }
  };

  const handleAddNewExperience = () => {
    navigate(
      `/${COMMERCE}/${privateRoutes.ecommerce}/${privateRoutes.experiences}`
    );
  };

  const qrOptions = [
    {
      value: 'Download Experience QR Code',
      label: 'Download Experience QR Code',
      icon: <DownloadLineIcon />,
    },
    {
      value: 'Scan QR Code Link',
      label: 'Scan QR Code Link',
      icon: <CopyDoubleIcon fill="#636366" />,
    },
  ];

  const moreOptions = [
    { value: 'Request Sponsorship', label: 'Request Sponsorship' },
    { value: 'Edit Experience Info', label: 'Edit Experience Info' },
  ];

  const handleSelectQrOption = (selectedValue: string) => {
    if (selectedValue === 'Download Experience QR Code') {
      let qrCodeData = experience.qrCode;
      if (!qrCodeData.startsWith('data:image/png;base64,')) {
        qrCodeData = `data:image/png;base64,${qrCodeData}`;
      }
      const base64Data = qrCodeData.split(',')[1];
      const byteString = atob(base64Data);
      const mimeString = qrCodeData.split(',')[0].split(':')[1].split(';')[0];
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const uint8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i += 1) {
        uint8Array[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([uint8Array], { type: mimeString });
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = `${experience.name}-qrcode.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(blobUrl);
    } else if (selectedValue === 'Scan QR Code Link') {
      window.open(publicRoutes.experienceQrScan, '_blank');
    }
  };

  const handleMoreOptionSelect = (selectedValue: string) => {
    setIsDropdownVisible(false);
    if (selectedValue === 'Request Sponsorship') {
      // To handle Request Sponsorship
    } else if (selectedValue === 'Edit Experience Info') {
      setIsModalOpen(true);
    }
  };

  const handleEditClick = () => {
    navigate(
      `/${COMMERCE}/${privateRoutes.ecommerce}/${experienceUUId}/${privateRoutes.experiencesEdit}`,
      { state: { experience } }
    );
  };

  const experienceDateObject = new Date(experienceDate);
  const formattedDate = isNaN(experienceDateObject.getTime())
    ? 'Invalid Date'
    : DateTime.fromJSDate(experienceDateObject).toFormat('dd-MM-yyyy');

  const createdDateObject = new Date(createdDate);
  const formattedCreatedDate = isNaN(createdDateObject.getTime())
    ? 'Invalid Date'
    : DateTime.fromJSDate(createdDateObject).toFormat('dd-MM-yyyy');

  const capitalize = (s: any) => s && s[0].toUpperCase() + s.slice(1);

  const renderEditExperienceInfo = (
    <SlideIn
      close={() => setIsModalOpen(false)}
      shown={isModalOpen}
      leftMarginOffset="md:ml-[60%]"
    >
      <p className="pb-6 text-center">Experience Information</p>
      <div>
        <ElrButton
          text={'Edit Experience'}
          className="flex items-center w-full gap-2 align-middle rounded-sm bg-elr-gray text-elr-black"
          onClick={handleEditClick}
          imageSrc={<PencilIcon />}
          imagePosition="Right"
        />
        <div className="w-full py-8 mx-auto mb-10 bg-elr-white-400">
          <div>
            <section className="flex-col mb-6 md:flex md:flex-row">
              <p className="w-1/2 pb-1 text-elr-black-300">Experience Name</p>
              <p>{experienceName}</p>
            </section>
            <section className="flex-col mb-6 md:flex md:flex-row">
              <p className="w-1/2 pb-1 text-elr-black-300">
                Experience Description
              </p>
              <p className="md:w-1/2 w-9/10">{experienceDescription}</p>
            </section>
            <section className="flex-col mb-6 md:flex md:flex-row">
              <p className="w-1/2 pb-1 text-elr-black-300">
                Experience Location
              </p>
              <p className="md:w-1/2 w-9/10">{experienceLocation}</p>
            </section>
            <section className="flex-col mb-6 md:flex md:flex-row">
              <p className="w-1/2 pb-1 text-elr-black-300">Experience Date</p>
              <p className="w-1/2">{formattedDate}</p>
            </section>
            <section className="flex-col mb-6 md:flex md:flex-row">
              <p className="w-1/2 pb-1 text-elr-black-300">Experience Time</p>
              <p className="w-1/2">{experienceTime.toLocaleUpperCase()}</p>
            </section>

            {ticketTypes?.map((ticket: Ticket, index: number) => (
              <section
                key={ticket.id}
                className="flex-col mb-8 md:flex md:flex-row"
              >
                <p className="w-1/2 pb-1 text-elr-black-300">
                  Ticket {index + 1}
                </p>
                <div className="w-full md:w-1/2">
                  <p>{capitalize(ticket.type)}</p>
                  <p>NGN {ticket.price}</p>
                  <p>Quantity - {ticket.quantity}</p>
                  <p>
                    Deadline -{' '}
                    {ticket.expiryDate
                      ? new Date(ticket.expiryDate).toLocaleDateString()
                      : 'N/A'}
                  </p>
                  <p>
                    {ticket.whoPays === ExperienceChargesType.Business
                      ? 'Business pays'
                      : 'Customer pays'}{' '}
                    payment charges
                  </p>
                </div>
              </section>
            ))}
            <section className="flex-col mb-6 md:flex md:flex-row">
              <p className="w-1/2 pb-1 text-elr-black-300">Created At</p>
              <p className="w-1/2">{formattedCreatedDate}</p>
            </section>
          </div>
        </div>
      </div>
    </SlideIn>
  );

  const getMatchingTicketSales = (
    ticket: ExperienceTicket,
    salesData: TicketSale[] | null
  ) =>
    salesData?.find(
      (sale) =>
        sale.type.trim().toLowerCase() === ticket.type.trim().toLowerCase()
    );

  return (
    <div className="px-40 pt-8 pb-8">
      {showModal && (
        <ExperienceTurnOffModal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          onClick={handleCloseExperience}
          loading={isLoading}
        />
      )}
      {isModalOpen && renderEditExperienceInfo}
      <section className="flex justify-between pb-8">
        <div>
          <h3>Experiences</h3>
          <p className="text-elr-black-300">
            View and follow up with your experiences here.
          </p>
        </div>
        <div className="flex items-center gap-4">
          <ElrButtonPreIcon
            text={'Add New Experience'}
            icon={<PlusIcon fill="#081120" />}
            onClick={handleAddNewExperience}
            className="px-5 py-3 rounded-sm bg-elr-white-400 text-elr-black"
          />
          <ExperienceDropdown
            buttonLabel="QR Code"
            buttonIcon={<QRcodeIcon width="30" />}
            options={qrOptions}
            onSelect={handleSelectQrOption}
            dropdownPosition="right"
            className="px-3 py-3 rounded-md bg-elr-white-400 text-elr-black"
          />
          <ExperienceDropdown
            buttonLabel={<img src={MoreIcon} alt="more-icon" />}
            options={moreOptions}
            onSelect={handleMoreOptionSelect}
            dropdownPosition="right"
            showDropdownIcon={false}
            className="px-3 py-2 rounded-md bg-elr-black"
          />
        </div>
      </section>
      <section className="flex justify-between px-8 py-4 mb-5 bg-elr-white-400">
        <div className="flex items-center">
          <p>{experience.name} &nbsp; - &nbsp;</p>
          <div className="px-3 py-2 bg-elr-gray">
            <p className="text-elr-purple">{experienceLink}</p>
          </div>
        </div>
        <div className="flex gap-x-4">
          {!experienceIsClosed ? (
            <ElrButton
              text={'Turn off Experience'}
              disabled={isLoading}
              loading={isLoading}
              onClick={() => setShowModal(true)}
              className="px-5 py-3 rounded-sm bg-elr-gray text-elr-error"
            />
          ) : (
            <ElrButton
              text={'Experience Closed'}
              disabled
              onClick={handleCloseExperience}
              className="px-5 py-3 rounded-sm bg-elr-gray text-success"
            />
          )}
          <ElrButtonPreIcon
            text={'Copy Experience Link'}
            icon={<CopyDoubleIcon />}
            onClick={handleCopy}
            className="px-5 py-3 rounded-sm bg-elr-black text-elr-white-400"
          />
        </div>
      </section>
      <section className="grid gap-4 mb-5 grid-cols-column-two">
        <div className="px-6 py-5 bg-elr-white-400">
          <div>
            <div className="float-end">
              <ElrButton
                text={'Withdraw'}
                disabled={isLoading}
                loading={isLoading}
                className="px-5 py-3 rounded-sm bg-elr-black text-elr-white-400"
              />
            </div>
            <MoneyIcon />
            <div className="mb-5" />
            <p className="mb-3 text-lg leading-5 text-elr-black-300">
              Total Sales
            </p>
            <p className="text-3xl text-elr-black">
              {currencyFormatter(calculateTotalSalesAmount(ticketSales))}
            </p>
          </div>
        </div>
        <div className="px-6 py-5 bg-elr-white-400">
          <div>
            <DrawerIcon />
            <div className="mb-5" />
            <p className="mb-3 text-lg leading-5 text-elr-black-300">
              Total Tickets Sold
            </p>
            <p className="text-3xl text-elr-black">
              {calculateTotalTicketsSold(ticketSales)}
            </p>
          </div>
        </div>
      </section>
      <section className="px-8 py-5 mb-5 bg-elr-white-400">
        <h3>Experience Tickets</h3>
        <div className="flex mt-4 gap-x-4">
          {experience.tickets.map((ticket) => {
            const matchingTicketSales = getMatchingTicketSales(
              ticket,
              totalTicketSales
            );
            return (
              <div key={ticket.id} className="flex px-5 py-4 bg-elr-gray">
                <div>
                  <p>{ticket.type.toLocaleUpperCase()}</p>
                  <p className="text-elr-black-300">N{ticket.price}</p>
                  <p className="text-elr-black-300">
                    Quantity Sold -{' '}
                    {matchingTicketSales?.quantityOfTicketsSold ?? 'N/A'}
                  </p>
                  <p className="text-elr-black-300">
                    Sales Deadline - {formatExpiryDate(ticket.expiryDate)}
                  </p>
                </div>
                <div className="self-center">
                  <ElrSwitch
                    isToggled={ticket.active}
                    toggle={() =>
                      handleToggleTicket(ticket.type, !ticket.active)
                    }
                    parentClassName="bg-elr-white-400"
                    circleClassName="bg-elr-black"
                    toggledCircleClassName="bg-elr-white-400"
                    toggledParentClassName="!bg-elr-black"
                  />
                </div>
              </div>
            );
          })}
        </div>
      </section>

      <section className="bg-elr-white-400">
        <div className="grid px-8 py-4 grid-cols-column-two">
          <h3 className="self-center">Ticket Sales Breakdown - First(100)</h3>
          <div className="flex justify-between gap-2">
            <ElrDropdownButton
              text="All Tickets"
              isToggled={false}
              className="bg-elr-gray rounded-sm !w-1/2"
              size="lg"
            />
            <ElrButtonPreIcon
              text={'View Experience Info'}
              icon={<InformationIcon />}
              className="w-1/2 px-5 py-3 rounded-sm bg-elr-gray text-elr-black"
            />
          </div>
        </div>
        <div>
          <table className="w-full table-auto">
            <thead>
              <tr className="border border-x-0 border-elr-gray">
                <th className="py-5">Attendee</th>
                <th>Ticket Type</th>
                <th>Quantity</th>
                <th>Amount</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {soldTickets.map((ticket) => (
                <tr
                  key={ticket.id}
                  className="text-center border border-x-0 border-elr-gray"
                >
                  <td className="py-5">
                    {ticket.firstname} {ticket.lastname}
                  </td>
                  <td>{ticket.type}</td>
                  <td>{ticket.quantity}</td>
                  <td>{ticket.totalAmount}</td>
                  <td>{new Date(ticket.createdAt).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
};

export default ExperienceDashboard;

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onClick: () => void;
  loading: boolean;
}

export const ExperienceTurnOffModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onClick,
  loading,
}) => (
  <ElrModal isOpen={isOpen} onClose={onClose}>
    <div className="flex flex-col items-center px-6 py-10 text-center">
      <div className="flex items-center justify-center mb-8">
        <img src={ErrorIcon} alt="error-icon" className="w-20 h-20" />
      </div>
      <p className="w-10/12 pb-4 text-2xl text-elr-black">
        You are about to deactivate this Experience
      </p>
      <p className="w-11/12 text-base text-center text-elr-black-400">
        This experience will be removed from the sales platform and the
        experience tickets will no longer be sold.
      </p>
      <p className="pt-4 text-base text-center text-elr-black-400">
        Do you still want to proceed?
      </p>
      <div className="flex gap-2.5 mt-10 w-full">
        <ElrButton
          text="Yes, Deactivate It"
          className="w-full h-10 px-4 py-2 rounded-md bg-elr-gray text-elr-black"
          spinnerColor="Black"
          onClick={onClick}
          loading={loading}
        />
        <ElrButton
          text="No, Go Back"
          className="w-full h-10 px-4 py-2 text-white rounded-md bg-elr-black"
          onClick={onClose}
        />
      </div>
    </div>
  </ElrModal>
);
