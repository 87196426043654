import InfoCards from 'app/modules/landing/components/InfoCards';
import PoliciesAppBanner from 'app/modules/landing/components/PoliciesAppBanner';

interface ExploreMoreProps {
  hiddenCards?: string[];
  title?: string;
}

const ExploreMore: React.FC<ExploreMoreProps> = ({
  hiddenCards = [],
  title = 'Explore More with Errandlr',
}) => (
  <div className="mx-5 md:mx-0 mt-24 md:mt-40">
    <div className="max-w-6xl mx-auto flex flex-col">
      <p className="text-28 text-elr-black text-center mx-auto md:text-40 w-9/12 md:w-full leading-tight font-bold">
        {title}
      </p>
      <InfoCards className="my-10 md:my-12" hiddenCards={hiddenCards} />
    </div>
    <div className="max-w-6.5xl mx-auto">
      <PoliciesAppBanner />
    </div>
  </div>
);

export default ExploreMore;
