import { publicRoutes } from 'app/utilities/routes';
import appleStoreBtn from 'assets/images/app-store-btn-white.svg';
import apiScreenMockup from 'assets/images/errandlr-shop-app-mockup.png';
import EssentialItemsVector from 'assets/images/essential-items.svg';
import googlePlay from 'assets/images/google-play-btn-black.svg';
import OrderFoodVector from 'assets/images/order-food.svg';
import productGroceries from 'assets/images/product-groceries.svg';
import QualityItemsVector from 'assets/images/quality-clothing.svg';
import ShopGroceriesVector from 'assets/images/shop-foodstuff.svg';
import { useEffect, useState } from 'react';
import { ElrPageTitle } from 'ui-components';
import ExploreMore from '../components/ExploreMore';
import { FeatureBlock } from '../components/FeatureBlock';
import FeatureSection from '../components/FeatureSection';
import HeroHeader from '../components/HeroHeader';
import PopularQuestions from '../components/PopularQuestions';
import { QrCodeModal } from '../components/QrCodeModal';
import { Footer } from '../landing/components';

const APP_DOWNLOAD_LINK = 'https://onelink.to/ph8jez';

const ProductPage = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    setIsMobile(/Mobi|Android|iPhone/i.test(navigator.userAgent));
  });
  const handleExploreClick = () => {
    if (isMobile) {
      window.location.href = APP_DOWNLOAD_LINK;
    } else {
      setShowModal(true);
    }
  };
  const questions = [
    {
      id: 1,
      header:
        'What happens if my order does not arrive or if there is a missing item?',
      content:
        'If your order doesn’t arrive or any item is missing, please contact our support team immediately with your order details. We will promptly investigate the issue and arrange for a resolution as needed to ensure your complete satisfaction.',
    },
    {
      id: 2,
      header: 'How long will my order take to be delivered?',
      content:
        'We ensure prompt delivery with same-day shipping for early orders and next-day delivery for orders placed late. Delivery times may vary slightly based on your location and order volume.',
    },
    {
      id: 3,
      header: 'How do I report an issue with my order?',
      content:
        'If you experience any issues with your order, please contact our support team using the in-app help center or email us at support@errandlr.com with your order details. We will investigate the matter and work quickly to resolve it to your satisfaction.',
    },
  ];
  return (
    <div className="bg-white">
      <ElrPageTitle title="Errandlr - Products" />
      <HeroHeader
        title={
          <>
            The <span className="text-elr-green">Products</span> You Need in One
            Place
          </>
        }
        buttonText="Explore Now"
        backgroundClass="bg-elr-green-50A"
        bubblesOpacity="opacity-80"
        imageSrc={productGroceries}
        altText="Product Groceries"
        buttonAction={handleExploreClick}
      />
      <QrCodeModal isOpen={showModal} onClose={() => setShowModal(false)} />
      <FeatureBlock
        imageSrc={OrderFoodVector}
        imageAlt="order-food-vector"
        headingText="Order Fresh and Delicious Meals"
        layoutClassName="md:flex-row md:py-40 py-20"
        descriptionText="Explore a wide range of cuisines and get fresh, hot meals delivered quickly from top-rated restaurants near you."
        buttonText="Order Now"
        buttonLink={publicRoutes.registration}
      />
      <FeatureBlock
        imageSrc={ShopGroceriesVector}
        imageAlt="shop-groceries"
        headingText="Shop Groceries and Foodstuff"
        layoutClassName="md:flex-row-reverse pb-20 pt-0 md:pb-40"
        descriptionClassName="w-9/12 text-elr-black text-opacity-55"
        descriptionText="Say goodbye to market stress and get fresh groceries delivered directly to your door whenever you need them."
        buttonText="Order Now"
        buttonLink={publicRoutes.registration}
      />
      <FeatureSection
        title="You Order, We Deliver"
        description="Shop from the varieties of items and products on the app and get it delivered to you in the quickest time possible."
        imageSrc={apiScreenMockup}
        imageAlt="Errandlr API Screen"
        imageClassName="md:max-w-sm"
        gradients={{
          mainBackground:
            'bg-gradient-to-b box-decoration-clone from-elr-black via-elr-black to-elr-black',
          leftBackground:
            'bg-gradient-to-b box-decoration-clone from-elr-black via-elr-black to-elr-black',
          rightBackground: 'bg-elr-green-50A',
        }}
        rightBorderRadius="rounded-tl-2xl rounded-bl-2xl"
        leftBorderRadius="rounded-tr-2xl rounded-br-2xl"
        buttons={[
          {
            text: 'Download on App Store',
            imageSrc: appleStoreBtn,
            action: () =>
              window.open(
                'https://apps.apple.com/app/errandlr/id6499235011',
                '_blank'
              ),
          },
          {
            text: 'Get it on Google Play',
            imageSrc: googlePlay,
            action: () =>
              window.open(
                'https://play.google.com/store/apps/details?id=com.errandlr.commerce&pcampaignid=web_share',
                '_blank'
              ),
          },
        ]}
        reverseLayout
      />
      <FeatureBlock
        imageSrc={EssentialItemsVector}
        imageAlt="essential-items"
        headingText="Get Daily Essential Items"
        layoutClassName="md:flex-row-reverse md:py-40 py-20"
        descriptionText="Shop your everyday essentials, from toiletries to household supplies on our app, and have them delivered fast and hassle-free."
        buttonText="Order Now"
        buttonLink={publicRoutes.registration}
      />
      <FeatureBlock
        imageSrc={QualityItemsVector}
        imageAlt="fast-delivery"
        headingText="Buy Quality Clothing and Accessories"
        layoutClassName="md:flex-row pb-20 pt-0 md:pb-40"
        descriptionText="Find everything you need to elevate your style, from premium clothing to unique accessories, all in one app."
        buttonText="Order Now"
        buttonLink={publicRoutes.registration}
      />
      <PopularQuestions questions={questions} headingColor="text-elr-green" />
      <ExploreMore hiddenCards={['Get']} />
      <Footer />
    </div>
  );
};

export default ProductPage;
