import {
  useCreateRestaurantProducts,
  useGetServiceProfile,
  useServiceProductCategory,
  useUpdateRestaurantProducts,
} from 'app/hooks/requests/commerce';
import { useGetProfile } from 'app/hooks/user';
import { useUploadFileImage } from 'app/hooks/utils';
import {
  Menu,
  ProductFormFieldsProps,
  ServiceOutletType,
} from 'app/utilities/types/shared';
import { useFormik } from 'formik';
import { useState } from 'react';
import {
  ElrButton,
  ElrInput,
  ElrSwitch,
  ElrTextAreaInput,
} from 'ui-components';
import { ElrButtonPreIcon } from 'ui-components/components/ElrButton';
import { ElrRadio } from 'ui-components/components/ElrRadio';
import {
  DropdownOption,
  ElrSearchableDropdown,
} from 'ui-components/components/ElrSearchableDropdown';
import { ElrUploadV3 } from 'ui-components/components/ElrUpload';
import * as Yup from 'yup';
import { DeleteIcon, PlusIcon } from '../../SvgAssets';

const ProductSchema = Yup.array().of(
  Yup.object().shape({
    cuisine: Yup.string(),
    shelf: Yup.string(),
    categoryName: Yup.string().required('Category name is required'),
    categoryDescription: Yup.string().required(
      'Category description is required'
    ),
    categoryImage: Yup.string(),
    categoryId: Yup.string().optional(),
    items: Yup.array().of(
      Yup.object().shape({
        itemName: Yup.string().required('Item name is required'),
        itemPrice: Yup.number()
          .required('Price is required')
          .transform((value) => (value ? Number(value) : 0))
          .test(
            'is-number',
            'Must be a valid number',
            (value) => !isNaN(Number(value))
          ),
        itemDescription: Yup.string().required('Description is required'),
        itemImage: Yup.mixed().required('Image is required'),
        itemPopular: Yup.boolean(),
        itemHandPicked: Yup.boolean(),
        itemInStock: Yup.boolean(),
        options: Yup.array().of(
          Yup.object().shape({
            subItemName: Yup.string().required('Item Name is required'),
            subItemImage: Yup.string(),
            subItemDescription: Yup.string(),
            subItemQuantity: Yup.number(),
            subItemPrice: Yup.number().required('Price is required'),
            subItemInStock: Yup.boolean().default(true),
            subItemRequired: Yup.boolean()
              .required('Item is required')
              .default(false),
            childOptions: Yup.array().of(
              Yup.object().shape({
                childItemName: Yup.string().required('Item is required'),
                childItemImage: Yup.string(),
                childItemDescription: Yup.string(),
                childItemPrice: Yup.number().required('Price is required'),
                childItemInStock: Yup.boolean().default(true),
              })
            ),
          })
        ),
      })
    ),
  })
);

interface SubMenu {
  isRequired: boolean;
}

const ProductFormFields = ({
  initialMenuCategory,
  isEditMode = false,
  outletType: outletModelType,
}: {
  initialMenuCategory: ProductFormFieldsProps;
  isEditMode?: boolean;
  outletType: string;
}) => {
  const { serviceProfile } = useGetServiceProfile(ServiceOutletType.restaurant);

  const [subMenusMap, setSubMenusMap] = useState<Record<number, SubMenu[]>>(
    () => {
      const initialSubMenus: Record<number, SubMenu[]> = {};
      initialMenuCategory.items.forEach((item, index) => {
        if (item.options && item.options.length > 0) {
          initialSubMenus[index] = item.options.map(() => ({
            isRequired: false,
          }));
        }
      });
      return initialSubMenus;
    }
  );
  const [currentCategory, setCurrentCategory] = useState(0);
  const [currentItem] = useState(0);
  const { handleUploadImage } = useUploadFileImage();
  const {
    id: userId,
    accountInformation: { id: roleId },
  } = useGetProfile();
  const { createRestaurantProducts, loading: isUploadingProducts } =
    useCreateRestaurantProducts();
  const { updateRestaurantProducts, loading: isUpdatingProducts } =
    useUpdateRestaurantProducts();
  const [useLogo, setUseLogo] = useState(false);
  const [initialItemImage, setInitialItemImage] = useState<
    File | string | null
  >(initialMenuCategory.items[currentItem].itemImage || null);
  const convertPricesToNumbers = (formValues: Menu) =>
    Object.values(formValues).map((category) => ({
      ...category,
      _id: undefined,
      items: category.items.map((item) => ({
        ...item,
        itemId: initialMenuCategory._id,
        itemPrice: Number(item.itemPrice),
        options: item.options?.map((option) => ({
          ...option,
          subItemPrice: Number(option.subItemPrice),
          childOptions: option.childOptions?.map((childItem) => ({
            ...childItem,
            childItemPrice: Number(childItem.childItemPrice),
          })),
        })),
      })),
    }));

  const uploadImages = async (formValues: Menu) => {
    const newValues = [...formValues];

    await Promise.all(
      newValues.map(async (category, categoryIndex) =>
        Promise.all(
          category.items.map(async (item, itemIndex) => {
            if (item.itemImage) {
              // Check if itemImage is already a URL (existing image)
              if (typeof item.itemImage === 'string') {
                // Use the existing URL
                newValues[categoryIndex].items[itemIndex].itemImage =
                  item.itemImage;
              } else {
                // Upload the new image
                const image = await handleUploadImage(item.itemImage as File);
                if (image?.url) {
                  newValues[categoryIndex].items[itemIndex].itemImage =
                    image.url;
                }
              }
            }
          })
        )
      )
    );

    return newValues;
  };

  const {
    handleSubmit,
    values,
    handleChange,
    errors,
    setValues,
    setFieldValue,
  } = useFormik<Menu>({
    initialValues: [initialMenuCategory],
    enableReinitialize: true,
    validationSchema: ProductSchema,
    onSubmit: async (formValues: Menu) => {
      const menuArray = convertPricesToNumbers(formValues);
      const menuWithImages = await uploadImages(menuArray);
      if (isEditMode) {
        updateRestaurantProducts({
          menu: menuWithImages[0],
          userId,
          roleId,
          productId: initialMenuCategory._id as string,
        });
      } else {
        createRestaurantProducts({ menu: menuWithImages, userId, roleId });
      }
    },
  });

  const handleSubMenuToggle = (categoryIndex: number, enabled: boolean) => {
    if (enabled) {
      setSubMenusMap((prev) => ({
        ...prev,
        [categoryIndex]: [{ isRequired: false }],
      }));
      addChildItem(categoryIndex, currentItem, 0);
    } else {
      // Clear subMenusMap
      setSubMenusMap((prev) => ({
        ...prev,
        [categoryIndex]: [],
      }));

      // Clear options data in values
      const newValues = [...values];
      if (newValues[categoryIndex]?.items?.[currentItem]) {
        newValues[categoryIndex].items[currentItem].options = [];
        setValues(newValues);
      }
    }
  };

  const addSubMenu = () => {
    setSubMenusMap((prev) => ({
      ...prev,
      [currentCategory]: [
        ...(prev[currentCategory] ?? []),
        { isRequired: false },
      ],
    }));
    const newIndex = subMenusMap[currentCategory]?.length ?? 0;
    addChildItem(currentCategory, currentItem, newIndex);
  };

  const deleteSubMenu = (categoryIndex: number, subMenuIndex: number) => {
    // Update subMenusMap
    const updatedSubMenus =
      subMenusMap[categoryIndex]?.filter(
        (_, index) => index !== subMenuIndex
      ) ?? [];
    setSubMenusMap((prev) => ({
      ...prev,
      [categoryIndex]: updatedSubMenus,
    }));

    // Clear options data in values
    const newValues = [...values];
    if (newValues[categoryIndex]?.items?.[currentItem]?.options) {
      newValues[categoryIndex].items[currentItem].options.splice(
        subMenuIndex,
        1
      );
      setValues(newValues);
    }
  };

  const addNewCategory = () => {
    setValues([...values, initialMenuCategory]);
  };

  const addChildItem = (
    categoryIndex: number,
    itemIndex: number,
    submenuIndex: number
  ) => {
    const newValues = [...values];

    // Ensure the full path exists
    if (!newValues[categoryIndex].items[itemIndex].options) {
      newValues[categoryIndex].items[itemIndex].options = [];
    }
    if (!newValues[categoryIndex].items[itemIndex].options[submenuIndex]) {
      newValues[categoryIndex].items[itemIndex].options[submenuIndex] = {
        subItemName: '',
        subItemImage: '',
        subItemDescription: '',
        subItemQuantity: 0,
        subItemPrice: 0,
        subItemInStock: true,
        childOptions: [],
      };
    }

    // Now safely add the new child item
    newValues[categoryIndex].items[itemIndex].options[
      submenuIndex
    ]?.childOptions?.push({
      childItemName: '',
      childItemPrice: 0,
      childItemDescription: '',
      childItemImage: '',
      childItemInStock: true,
    });

    setValues(newValues);
  };

  const deleteChildItem = (
    categoryIndex: number,
    itemIndex: number,
    submenuId: number,
    childIndex: number
  ) => {
    const newValues = [...values];

    // Check if all required properties exist before splicing
    if (
      newValues[categoryIndex]?.items?.[itemIndex]?.options?.[submenuId]
        ?.childOptions
    ) {
      newValues[categoryIndex].items[itemIndex].options[
        submenuId
      ].childOptions?.splice(childIndex, 1);
      setValues(newValues);
    }
  };

  const deleteMenu = (categoryIndex: number) => {
    const newValues = [...values];
    newValues.splice(categoryIndex, 1);
    setValues(newValues);

    // Reset currentCategory if we're deleting the current one
    if (currentCategory >= newValues.length) {
      setCurrentCategory(newValues.length - 1);
    }
  };

  const handleSubItemRequiredToggle = (
    categoryIndex: number,
    subMenuIndex: number,
    required: boolean
  ) => {
    const newValues = [...values];
    if (
      newValues[categoryIndex]?.items?.[currentItem]?.options?.[subMenuIndex]
    ) {
      newValues[categoryIndex].items[currentItem].options[
        subMenuIndex
      ].subItemRequired = required;
      setValues(newValues);
    }
  };

  // Helper function to get nested error
  const getFieldError = (errors_: any, path: string) => {
    // Remove the first empty string from array notation paths
    const parts = path
      .replace(/\[(\d+)\]/g, '.$1')
      .split('.')
      .filter(Boolean);
    let current = errors_;

    for (const part of parts) {
      if (!current?.[part]) return undefined;
      current = current[part];
    }

    return current;
  };

  const { serviceProductCategories } = useServiceProductCategory({
    userId,
    outletType: outletModelType,
    roleId,
    serviceTypeId: serviceProfile?.id || '',
  });

  // const vendorId = serviceCategories?.[0]?.vendor || '';

  const categoryOptions: DropdownOption[] =
    serviceProductCategories?.map(
      (category: { name: string; _id: string }) => ({
        value: category._id,
        label: category.name,
        items: [],
      })
    ) ?? [];

  const handleCategoryChange = (
    selectedLabel: string,
    categoryIndex: number
  ) => {
    // Update the form values with the selected category name
    setFieldValue(`[${categoryIndex}].categoryName`, selectedLabel.trim());

    // Check if this is an existing category
    const isExistingCategory = categoryOptions.some(
      (cat) => cat.label === selectedLabel.trim()
    );

    // Set a flag in the form values to indicate if this is a new category
    setFieldValue(`[${categoryIndex}].isNewCategory`, !isExistingCategory);

    // If it's an existing category, you might want to store its ID for the backend
    if (isExistingCategory) {
      const selectedCategory = categoryOptions.find(
        (cat) => cat.label === selectedLabel
      );
      setFieldValue(`[${categoryIndex}].categoryId`, selectedCategory?.value);
    } else {
      // For new categories, generate a temporary ID or clear the existing one
      // setFieldValue(`[${categoryIndex}].categoryId`, uuid);
    }
  };

  return (
    <div className="px-10 py-8 bg-white w-full">
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col gap-3">
          {values.map((menuCategory: any, categoryIndex: number) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={categoryIndex}
              className="flex flex-col gap-3"
            >
              <div className="flex items-center justify-between">
                <p className="text-base">Main Menu {categoryIndex + 1}</p>
                {values.length > 1 && (
                  <div
                    className="flex items-center justify-center w-8 h-8 rounded-full cursor-pointer bg-elr-orange-900/10"
                    onClick={() => deleteMenu(categoryIndex)}
                  >
                    <DeleteIcon />
                  </div>
                )}
              </div>
              <ElrSearchableDropdown
                options={categoryOptions}
                value={values[categoryIndex].categoryName}
                placeholder="Menu Category e.g Breakfast, Swallow..."
                onChange={(selectedValue: string) =>
                  handleCategoryChange(selectedValue, categoryIndex)
                }
                className="rounded-none bg-elr-gray !mb-0 h-12 flex items-center align-middle justify-center outline-none border-none"
              />
              <ElrInput
                onChange={handleChange}
                value={menuCategory.items[currentItem].itemName}
                name={`[${categoryIndex}].items[${currentItem}].itemName`}
                className={`rounded-none bg-elr-gray !h-12 outline-none border-none ${
                  getFieldError(
                    errors,
                    `[${categoryIndex}].items[${currentItem}].itemName`
                  )
                    ? 'border border-red-500'
                    : 'border-none'
                }`}
                placeholder="Food Name e.g white rice, jollof rice"
              />
              {getFieldError(
                errors,
                `[${categoryIndex}].items[${currentItem}].itemName`
              ) && (
                <p className="text-red-500 text-sm mt-1">
                  {getFieldError(
                    errors,
                    `[${categoryIndex}].items[${currentItem}].itemName`
                  )}
                </p>
              )}
              <ElrTextAreaInput
                onChange={handleChange}
                value={menuCategory.items[currentItem].itemDescription}
                name={`[${categoryIndex}].items[${currentItem}].itemDescription`}
                className={`border-none rounded-none !-mb-1.5 outline-none bg-elr-gray ${
                  getFieldError(
                    errors,
                    `[${categoryIndex}].items[${currentItem}].itemDescription`
                  )
                    ? 'border-red-500'
                    : ''
                }`}
                placeholder="Food Description"
              />
              {getFieldError(
                errors,
                `[${categoryIndex}].items[${currentItem}].itemDescription`
              ) && (
                <p className="text-red-500 text-sm mt-1">
                  {getFieldError(
                    errors,
                    `[${categoryIndex}].items[${currentItem}].itemDescription`
                  )}
                </p>
              )}
              <div className="flex items-center">
                <span className="inline-flex items-center h-12 px-3 text-sm border-none bg-elr-deep-gray">
                  NGN
                </span>
                <ElrInput
                  onChange={handleChange}
                  value={menuCategory.items[currentItem].itemPrice}
                  name={`[${categoryIndex}].items[${currentItem}].itemPrice`}
                  className={`border-none rounded-none bg-elr-gray outline-none !h-12 ${
                    getFieldError(
                      errors,
                      `[${categoryIndex}].items[${currentItem}].itemPrice`
                    )
                      ? 'border-red-500'
                      : ''
                  }`}
                  placeholder="Price"
                />
              </div>
              {getFieldError(
                errors,
                `[${categoryIndex}].items[${currentItem}].itemPrice`
              ) && (
                <p className="text-red-500 text-sm mt-1">
                  {getFieldError(
                    errors,
                    `[${categoryIndex}].items[${currentItem}].itemPrice`
                  )}
                </p>
              )}
              <div className="flex flex-col gap-2">
                <p className="text-base">Food Image</p>
                <div className="border border-dashed border-elr-gray-neutral-300 py-5">
                  <ElrUploadV3
                    autoUpload={false}
                    className=""
                    accept=".jpeg,.jpg,.png"
                    tag={''}
                    onUploaded={() => {}}
                    previewFile={menuCategory.items[currentItem].itemImage}
                    onTempUpload={(file: any) => {
                      setInitialItemImage(file);
                      setFieldValue(
                        `[${categoryIndex}].items[${currentItem}].itemImage`,
                        file
                      );
                    }}
                  />
                  <div className="text-center flex flex-col gap-2 pt-2">
                    <p>OR</p>
                    <div className="flex gap-4 align-middle items-center justify-center">
                      <p>Use Logo Image</p>
                      <ElrSwitch
                        isToggled={useLogo}
                        toggle={() => {
                          setUseLogo((prev) => !prev);
                          setFieldValue(
                            `[${categoryIndex}].items[${currentItem}].itemImage`,
                            !useLogo
                              ? serviceProfile?.bannerImage
                              : initialItemImage
                          );
                        }}
                        parentClassName="bg-elr-gray-200 border-none cursor-pointer"
                        circleClassName="bg-elr-white"
                        toggledCircleClassName="!bg-elr-white"
                        toggledParentClassName="!bg-elr-black cursor-pointer"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-0.5">
                <h3 className="pl-1">Does this food have sub menu?</h3>
                <p className="pl-1 text-sm text-elr-green">
                  (sub menu includes proteins, drinks, sides, soup etc.)
                </p>
                <div className="flex pt-1 gap gap-x-6 text-elr-black-300">
                  <ElrRadio
                    checked={(subMenusMap[categoryIndex]?.length ?? 0) > 0}
                    onChange={() => handleSubMenuToggle(categoryIndex, true)}
                    radioClassName="accent-elr-black"
                    label="Yes"
                    labelClassName="text-elr-black-300 before:align-text-top gap-2"
                  />
                  <ElrRadio
                    checked={(subMenusMap[categoryIndex]?.length ?? 0) === 0}
                    onChange={() => handleSubMenuToggle(categoryIndex, false)}
                    radioClassName="accent-elr-black"
                    label="No"
                    labelClassName="text-elr-black-300 before:align-text-top gap-2"
                  />
                </div>
              </div>

              {subMenusMap[categoryIndex]?.length > 0 && (
                <>
                  {subMenusMap[categoryIndex]?.map((_, subMenuIndex) => (
                    <div
                      // eslint-disable-next-line react/no-array-index-key
                      key={subMenuIndex}
                      className="pt-4"
                    >
                      <div className="flex items-center justify-between pb-2">
                        <p className="text-base">Sub Menu</p>
                        {(subMenusMap[categoryIndex]?.length ?? 0) > 1 && (
                          <div
                            className="flex items-center justify-center w-8 h-8 rounded-full cursor-pointer bg-elr-orange-900/10"
                            onClick={() =>
                              deleteSubMenu(categoryIndex, subMenuIndex)
                            }
                          >
                            <DeleteIcon />
                          </div>
                        )}
                      </div>
                      <div className="flex flex-col gap-3">
                        <ElrInput
                          name={`[${categoryIndex}].items[${currentItem}].options[${subMenuIndex}].subItemName`}
                          className={`border-none rounded-none bg-elr-gray !h-12 outline-none ${
                            getFieldError(
                              errors,
                              `[${categoryIndex}].items[${currentItem}].options[${subMenuIndex}].subItemName`
                            )
                              ? 'border-red-500'
                              : ''
                          }`}
                          placeholder="Sub Menu Category e.g Proteins, Drinks, Sides..."
                          onChange={handleChange}
                          value={
                            menuCategory?.items[currentItem]?.options?.[
                              subMenuIndex
                            ]?.subItemName
                          }
                        />
                        {getFieldError(
                          errors,
                          `[${categoryIndex}].items[${currentItem}].options[${subMenuIndex}].subItemName`
                        ) && (
                          <p className="text-red-500 text-sm mt-1">
                            {getFieldError(
                              errors,
                              `[${categoryIndex}].items[${currentItem}].options[${subMenuIndex}].subItemName`
                            )}
                          </p>
                        )}

                        {menuCategory?.items[currentItem]?.options?.[
                          subMenuIndex
                        ]?.childOptions?.map((__: any, childIndex: number) => (
                          <div
                            // eslint-disable-next-line react/no-array-index-key
                            key={childIndex}
                            className="flex flex-col gap-3 border-l-2 border-elr-gray pl-4"
                          >
                            <div className="flex justify-between items-center">
                              <ElrInput
                                className={`border-none rounded-none bg-elr-gray !h-12 outline-none ${
                                  getFieldError(
                                    errors,
                                    `[${categoryIndex}].items[${currentItem}].options[${subMenuIndex}].childOptions[${childIndex}].childItemName`
                                  )
                                    ? 'border-red-500'
                                    : ''
                                }`}
                                placeholder="Sub Menu Name e.g Beef, Coke, Plantain, Turkey..."
                                onChange={handleChange}
                                name={`[${categoryIndex}].items[${currentItem}].options[${subMenuIndex}].childOptions[${childIndex}].childItemName`}
                                value={
                                  menuCategory?.items[currentItem]?.options?.[
                                    subMenuIndex
                                  ]?.childOptions?.[childIndex]?.childItemName
                                }
                              />
                              {(menuCategory?.items[currentItem]?.options?.[
                                subMenuIndex
                              ]?.childOptions?.length ?? 0) > 1 && (
                                <div
                                  className="flex items-center justify-center w-8 h-8 rounded-full cursor-pointer bg-elr-orange-900/10 ml-2"
                                  onClick={() =>
                                    deleteChildItem(
                                      categoryIndex,
                                      currentItem,
                                      subMenuIndex,
                                      childIndex
                                    )
                                  }
                                >
                                  <DeleteIcon />
                                </div>
                              )}
                            </div>
                            <div className="flex items-center">
                              <span className="inline-flex items-center h-12 px-3 text-sm border-none bg-elr-deep-gray">
                                NGN
                              </span>
                              <ElrInput
                                className={`border-none rounded-none bg-elr-gray outline-none !h-12 ${
                                  getFieldError(
                                    errors,
                                    `[${categoryIndex}].items[${currentItem}].options[${subMenuIndex}].childOptions[${childIndex}].childItemPrice`
                                  )
                                    ? 'border-red-500'
                                    : ''
                                }`}
                                placeholder="Price"
                                onChange={handleChange}
                                name={`[${categoryIndex}].items[${currentItem}].options[${subMenuIndex}].childOptions[${childIndex}].childItemPrice`}
                                value={
                                  menuCategory?.items[currentItem]?.options?.[
                                    subMenuIndex
                                  ]?.childOptions?.[childIndex]?.childItemPrice
                                }
                              />
                            </div>
                          </div>
                        ))}

                        <div
                          className="flex items-center cursor-pointer gap-x-2 text-elr-purple mt-2 justify-end"
                          onClick={() =>
                            addChildItem(
                              categoryIndex,
                              currentItem,
                              subMenuIndex
                            )
                          }
                        >
                          <PlusIcon />
                          <p>Add AnotherItem</p>
                        </div>
                      </div>
                      <div className="border-elr-gray border-t-2 mt-4" />
                      <div className="flex flex-col gap-0.5">
                        <p>Is this sub menu a required option?</p>
                        <div className="flex gap-x-2">
                          <ElrRadio
                            checked={
                              menuCategory?.items[currentItem]?.options?.[
                                subMenuIndex
                              ]?.subItemRequired === true
                            }
                            onChange={() =>
                              handleSubItemRequiredToggle(
                                categoryIndex,
                                subMenuIndex,
                                true
                              )
                            }
                            radioClassName="accent-elr-black"
                            label="Yes"
                            labelClassName="text-elr-black-300 before:align-text-top gap-2"
                          />
                          <ElrRadio
                            checked={
                              menuCategory?.items[currentItem]?.options?.[
                                subMenuIndex
                              ]?.subItemRequired === false
                            }
                            onChange={() =>
                              handleSubItemRequiredToggle(
                                categoryIndex,
                                subMenuIndex,
                                false
                              )
                            }
                            radioClassName="accent-elr-black"
                            label="No"
                            labelClassName="text-elr-black-300 before:align-text-top gap-2"
                          />
                        </div>
                      </div>
                    </div>
                  ))}

                  <div
                    className="flex items-center justify-end cursor-pointer gap gap-x-2 text-elr-purple border-y-2 py-3 mt-4 border-elr-gray"
                    onClick={addSubMenu}
                  >
                    <PlusIcon />
                    <p>Add Another Sub Menu</p>
                  </div>
                </>
              )}
              <div className="border-elr-gray border-t-2 mt-4" />
            </div>
          ))}
        </div>
      </form>

      <div className="flex w-full gap-2 mt-14">
        <ElrButtonPreIcon
          text={'Add New Menu'}
          icon={<PlusIcon fill="#000" />}
          className="flex justify-center items-center rounded-md w-full text-center bg-elr-gray text-elr-black"
          onClick={addNewCategory}
        />
        <ElrButton
          text="Save Menu"
          className="w-full rounded-md bg-elr-black text-elr-white-400"
          onClick={() => handleSubmit()}
          loading={isUploadingProducts || isUpdatingProducts}
          spinnerColor="White"
          disabled={isUploadingProducts || isUpdatingProducts}
        />
      </div>
    </div>
  );
};

export default ProductFormFields;
