import Developer from 'app/commerce/modules/developer';
import CommerceSettings from 'app/commerce/modules/settings';
import OperatorEssentials from 'app/operator/modules/essential';
import OperatorIdentityVerification from 'app/operator/modules/identityVerification';
import OperatorMarketRuns from 'app/operator/modules/market-runs';
import OperatorLifestyle from 'app/operator/modules/product';
import OperatorRestaurant from 'app/operator/modules/restaurant';
import React from 'react';
import {
  Navigate,
  Routes as ReactRoutes,
  Route,
  BrowserRouter as Router,
  useLocation,
} from 'react-router-dom';
import Dashboard from './commerce/Dashboard';
import CustomerEstimate from './commerce/customer-estimate';
import Logistics from './commerce/modules/logistics';
import SellProduct from './commerce/modules/product/SellProduct';
import PaymentGateWayResponse from './components/PaymentGateWayResponse';
import Ecommerce from './components/ecommerce';
import UploadDashboard from './components/ecommerce/UploadDashboard';
import ExperienceDashboard from './components/ecommerce/experiences/ExperienceDashboard';
import ExperienceEdit from './components/ecommerce/experiences/ExperienceEdit';
import ExperienceList from './components/ecommerce/experiences/ExperienceList';
import ExperiencePreview from './components/ecommerce/experiences/ExperiencePreview';
import ExperienceQrScan from './components/ecommerce/experiences/ExperienceQrScan';
import Experiences from './components/ecommerce/experiences/Experiences';
import EcommerceInitial from './components/ecommerce/initial';
import MarketDashboard from './components/ecommerce/marketRuns/MarketDashboard';
import UploadMarketProducts from './components/ecommerce/marketRuns/UploadMarketProducts';
import MarketRunsOnboarding from './components/ecommerce/marketRuns/onboarding';
import RestaurantDashboard from './components/ecommerce/restaurant/RestaurantDashboard';
import UploadRestaurantProducts from './components/ecommerce/restaurant/UploadProducts';
import RestaurantOnboarding from './components/ecommerce/restaurant/onboarding';
import { ExperienceContactInformation } from './components/experiences/ContactInformation';
import { ExperienceHome } from './components/experiences/Home';
import ExperiencePurchaseSuccess from './components/experiences/PurchaseSuccess';
import BusinessInformation from './developer/modules/onboarding/BusinessInformation';
import OldDispatcherDashboard from './dispatcher/modules';
import { Roles } from './hooks/user';
import { Issues } from './modules/Issues';
import Requirements from './modules/Requirements';
import AboutUs from './modules/about';
import ApiPage from './modules/apiPage';
import Careers from './modules/careers';
import DeliveryPolicy from './modules/deliveryPolicy';
import { DispatchOrder } from './modules/dispatch/DispatchOrder';
import SuccessDispatchDetails from './modules/dispatch/SuccessDispatchDetails';
import FailedBilling from './modules/dispatch/billing/FailedBilling';
import VerifyBilling from './modules/dispatch/billing/VerifyBilling';
import ConfirmDetails from './modules/dispatch/components/estimate/ConfirmDetails';
import ExperiencePage from './modules/experiencePage';
import Landing from './modules/landing';
import Login from './modules/login/Login';
import LoginPortal from './modules/loginPortal';
import LogiscticsPage from './modules/logisticsPage';
import Onboarding from './modules/onboarding';
import TradingAddress from './modules/onboarding/TradingAddress';
import ForgotPassword from './modules/passwordSetting/ForgotPassword';
import ResetPassword from './modules/passwordSetting/ResetPassword';
import PaymentPage from './modules/paymentPage.tsx';
import Policy from './modules/policies';
import CookiePolicy from './modules/policies/cookiePolicy';
import Faq from './modules/policies/faq';
import PrivacyPolicy from './modules/policies/privacyPolicy';
import RiderTermsAndCondtion from './modules/policies/riderTermsAndCondition';
import CustomerSla from './modules/policies/sla/index';
import TermsAndCondition from './modules/policies/termsAndCondition';
import ProductPage from './modules/productPage';
import Registration from './modules/registration';
import BillingRecord from './modules/settings/billing-record';
import BulkEstimate from './modules/settings/bulkEstimate';
import CompanyDetail from './modules/settings/company';
import CompanySetting from './modules/settings/company/CompanySetting';
import PaymentDetail from './modules/settings/payment';
import PersonalProfile from './modules/settings/profile';
import TrackParcel from './modules/trackParcel';
import Rate from './modules/trackParcel/Rate';
import TrackParcelUpdate from './modules/trackParcel/TrackParcelUpdate';
import OperatorDashboard from './operator/Dashboard';
import OperatorLogistics from './operator/modules/logistics';
import OperatorSettings from './operator/modules/settings';
import Contact from './static/Contact';
import NoMatch from './static/NoMatch';
import { isSupport } from './utilities/helpers';
import { privateRoutes, publicRoutes } from './utilities/routes';
import BusinessPage from './modules/businessPage';
import RiderPage from './modules/riderPage';
import AddedRestaurantProducts from './components/ecommerce/restaurant/AddedRestaurantProducts';

interface AuthRouteProps {
  user: {
    id?: string;
    role: Roles;
  };
}

export const RequireAuth = ({
  children,
  auth,
}: {
  children: JSX.Element;
  auth: AuthRouteProps;
}) => {
  const location = useLocation();
  if (!auth?.user?.id) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.

    // @TODO clear storage/ with tokens
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
};

export const RequireSupportAuth = ({
  children,
  auth,
}: {
  children: JSX.Element;
  auth: AuthRouteProps;
}) => {
  const location = useLocation();
  if (!auth?.user?.id || !isSupport(auth.user.role)) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.

    // @TODO clear storage/ with tokens
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
};

export const TakeOverAuth = ({
  children,
  auth,
}: {
  children: JSX.Element;
  auth: AuthRouteProps;
}) => {
  const location = useLocation();
  if (auth?.user?.id || isSupport(auth.user.role)) {
    // this will check if the user is currently logged, then takes them to the dashboard

    return (
      <Navigate to={privateRoutes.selectPortal} state={{ from: location }} />
    );
  }
  return children;
};

export const Routes: React.FC<AuthRouteProps> = ({ user }) => (
  <Router>
    <ReactRoutes>
      <Route
        path={`${publicRoutes.dispatchDetails}/:requestId`}
        element={<SuccessDispatchDetails />}
      />
      <Route
        path={publicRoutes.paymentGatewayResponse}
        element={<PaymentGateWayResponse />}
      />
      <Route
        path={publicRoutes.registration}
        element={
          <TakeOverAuth auth={{ user }}>
            <Registration />
          </TakeOverAuth>
        }
      />
      <Route
        path={`${publicRoutes.rate}/:trackingId`}
        element={
          <RequireAuth auth={{ user }}>
            <Rate />
          </RequireAuth>
        }
      />
      <Route
        path={publicRoutes.login}
        element={
          <TakeOverAuth auth={{ user }}>
            <Login />
          </TakeOverAuth>
        }
      />
      <Route path={publicRoutes.estimate} element={<DispatchOrder />} />
      <Route path={publicRoutes.careers} element={<Careers />} />
      <Route path={publicRoutes.track} element={<TrackParcel />} />
      <Route path={publicRoutes.logisticsPage} element={<LogiscticsPage />} />
      <Route path={publicRoutes.experiencePage} element={<ExperiencePage />} />
      <Route path={publicRoutes.productPage} element={<ProductPage />} />
      <Route path={publicRoutes.riderPage} element={<RiderPage />} />
      <Route path={publicRoutes.businessPage} element={<BusinessPage />} />
      <Route path={publicRoutes.paymentPage} element={<PaymentPage />} />
      <Route
        path={publicRoutes.trackParcelUpdate}
        element={<TrackParcelUpdate />}
      />
      <Route path={publicRoutes.issues} element={<Issues />} />
      <Route path={publicRoutes.tradeAddress} element={<TradingAddress />} />
      <Route path={publicRoutes.policy} element={<Policy />} />
      <Route path={publicRoutes.forgotPassword} element={<ForgotPassword />} />
      <Route path={publicRoutes.resetPassword} element={<ResetPassword />} />
      <Route
        path={`${publicRoutes.confirmDetails}/:trackingId`}
        element={<ConfirmDetails />}
      />
      <Route path={publicRoutes.privacyPolicy} element={<PrivacyPolicy />} />
      <Route
        path={publicRoutes.riderTermsAndCondition}
        element={<RiderTermsAndCondtion />}
      />
      <Route path={publicRoutes.faq} element={<Faq />} />
      <Route path={publicRoutes.contact} element={<Contact />} />
      <Route
        path={publicRoutes.termsAndCondition}
        element={<TermsAndCondition />}
      />

      <Route path={publicRoutes.cookiePolicy} element={<CookiePolicy />} />

      <Route path={publicRoutes.sla} element={<CustomerSla />} />
      <Route
        path={publicRoutes.experienceQrScan}
        element={<ExperienceQrScan />}
      />

      <Route
        path={privateRoutes.selectPortal}
        element={
          <RequireAuth auth={{ user }}>
            <LoginPortal />
          </RequireAuth>
        }
      />

      <Route path={publicRoutes.landing} element={<Landing />} />

      {/* BEGIN BILLING ROUTES */}
      <Route path={publicRoutes.billing}>
        <Route path={publicRoutes.verifyBilling} element={<VerifyBilling />} />
        <Route path={publicRoutes.failedBilling} element={<FailedBilling />} />
        <Route path="*" element={<NoMatch />} />
      </Route>
      {/* END BILLING ROUTES */}

      {/* BEGIN DISPATCHER ROUTES */}
      <Route path={publicRoutes.dispatcher}>
        <Route
          path="dashboard"
          element={
            <RequireAuth auth={{ user }}>
              <OperatorDashboard />
            </RequireAuth>
          }
        />
        <Route
          path="old-dashboard"
          element={
            <RequireAuth auth={{ user }}>
              <OldDispatcherDashboard />
            </RequireAuth>
          }
        />
        <Route
          path="onboarding"
          element={
            <RequireAuth auth={{ user }}>
              <Onboarding />
            </RequireAuth>
          }
        />
        <Route
          path="logistics"
          element={
            <RequireAuth auth={{ user }}>
              <OperatorLogistics />
            </RequireAuth>
          }
        />
        <Route
          path="identity-verification"
          element={
            <RequireAuth auth={{ user }}>
              <OperatorIdentityVerification />
            </RequireAuth>
          }
        />
        <Route
          path="restaurant"
          element={
            <RequireAuth auth={{ user }}>
              <OperatorRestaurant />
            </RequireAuth>
          }
        />
        <Route
          path="product"
          element={
            <RequireAuth auth={{ user }}>
              <OperatorLifestyle />
            </RequireAuth>
          }
        />
        <Route
          path="essentials"
          element={
            <RequireAuth auth={{ user }}>
              <OperatorEssentials />
            </RequireAuth>
          }
        />
        <Route
          path="market"
          element={
            <RequireAuth auth={{ user }}>
              <OperatorMarketRuns />
            </RequireAuth>
          }
        />
        <Route
          path={privateRoutes.settings}
          element={
            <RequireAuth auth={{ user }}>
              <OperatorSettings />
            </RequireAuth>
          }
        />
        <Route
          path={privateRoutes.payment}
          element={
            <RequireAuth auth={{ user }}>
              <PaymentDetail />
            </RequireAuth>
          }
        />
        <Route
          path={privateRoutes.company}
          element={
            <RequireAuth auth={{ user }}>
              <CompanyDetail />
            </RequireAuth>
          }
        />
        <Route
          path={privateRoutes.profile}
          element={
            <RequireAuth auth={{ user }}>
              <PersonalProfile />
            </RequireAuth>
          }
        />

        <Route
          path={privateRoutes.requirements}
          element={
            <RequireAuth auth={{ user }}>
              <Requirements />
            </RequireAuth>
          }
        />

        <Route
          path={privateRoutes.bulkEstimate}
          element={
            <RequireAuth auth={{ user }}>
              <BulkEstimate />
            </RequireAuth>
          }
        />
      </Route>

      {/* END DISPATCHER ROUTES */}

      {/* ----- BEGING COMMERCE ROUTES ------ */}
      <Route path={publicRoutes.commerce}>
        <Route
          path="onboarding"
          element={
            <RequireAuth auth={{ user }}>
              <BusinessInformation />
            </RequireAuth>
          }
        />
        <Route
          path="dashboard"
          element={
            <RequireAuth auth={{ user }}>
              <Dashboard />
            </RequireAuth>
          }
        />

        <Route
          path="logistics"
          element={
            <RequireAuth auth={{ user }}>
              <Logistics />
            </RequireAuth>
          }
        />

        <Route
          path={privateRoutes.developer}
          element={
            <RequireAuth auth={{ user }}>
              <Developer />
            </RequireAuth>
          }
        />
        <Route
          path={privateRoutes.commerceSettings}
          element={
            <RequireAuth auth={{ user }}>
              <CommerceSettings />
            </RequireAuth>
          }
        />
        <Route
          path={privateRoutes.company}
          element={
            <RequireAuth auth={{ user }}>
              <CompanySetting />
            </RequireAuth>
          }
        />
        <Route
          path={privateRoutes.bulkEstimate}
          element={
            <RequireAuth auth={{ user }}>
              <BulkEstimate />
            </RequireAuth>
          }
        />
        <Route
          path={privateRoutes.billingRecord}
          element={
            <RequireAuth auth={{ user }}>
              <BillingRecord />
            </RequireAuth>
          }
        />

        <Route path={privateRoutes.ecommerce} element={<Ecommerce />}>
          <Route index element={<EcommerceInitial />} />
          <Route path={privateRoutes.experiences} element={<Experiences />} />
          <Route
            path={privateRoutes.experiencesList}
            element={<ExperienceList />}
          />
          <Route
            path={`:experienceUUId/${privateRoutes.experiencesEdit}`}
            element={<ExperienceEdit />}
          />
          <Route
            path={privateRoutes.experiencesPreview}
            element={<ExperiencePreview />}
          />
          <Route
            path={`:experienceUUId/${privateRoutes.experiencesDashboard}`}
            element={<ExperienceDashboard />}
          />

          {/* MARKET ROUTES */}
          <Route
            path={privateRoutes.marketOnboarding}
            element={<MarketRunsOnboarding />}
          />
          <Route
            path={`${privateRoutes.market}/:serviceProfileId/${privateRoutes.marketDashboard}`}
            element={<MarketDashboard />}
          />
          <Route
            path={`${privateRoutes.market}/:serviceProfileId/${privateRoutes.uploadMarketProducts}`}
            element={<UploadMarketProducts />}
          />
          <Route
            path={`${privateRoutes.market}/:serviceProfileId/${privateRoutes.uploadDashboard}`}
            element={<UploadDashboard />}
          />

          {/* RESTAURANT ROUTES */}

          <Route
            path={`${privateRoutes.restaurant}/:serviceProfileId/${privateRoutes.restaurantDashboard}`}
            element={<RestaurantDashboard />}
          />
          <Route
            path={privateRoutes.restaurantOnboarding}
            element={<RestaurantOnboarding />}
          />
          <Route
            path={`${privateRoutes.restaurant}/:serviceProfileId/${privateRoutes.uploadRestaurantProducts}`}
            element={<UploadRestaurantProducts />}
          />
          <Route
            path={`${privateRoutes.restaurant}/:serviceProfileId/${privateRoutes.addedRestaurantProducts}`}
            element={<AddedRestaurantProducts />}
          />
        </Route>

        <Route path={privateRoutes.product} element={<SellProduct />} />
      </Route>

      {/* END COMMERCE ROUTES */}

      {/* BEGIN EXPERIENCE ROUTES */}
      <Route path={publicRoutes.experience}>
        <Route path={':experienceUUId'} element={<ExperienceHome />} />
        <Route
          path={':experienceUUId/contact-information'}
          element={<ExperienceContactInformation />}
        />
        <Route
          path={':experienceUUId/purchase-success'}
          element={<ExperiencePurchaseSuccess />}
        />
      </Route>
      <Route path={publicRoutes.aboutUs} element={<AboutUs />} />
      <Route path={publicRoutes.deliveryPolicy} element={<DeliveryPolicy />} />
      <Route path={publicRoutes.apiPage} element={<ApiPage />} />
      <Route
        path={`${publicRoutes.customerEstimate}/:commerceSlug`}
        element={<CustomerEstimate />}
      />

      <Route path="*" element={<NoMatch />} />
    </ReactRoutes>
  </Router>
);
