import { OutletTypeEnum } from 'app/utilities/helpers';
import BusinessInfoForm from '../BusinessInfoForm';

const RestaurantOnboarding = () => (
  <div className="flex justify-center pt-16">
    <div className="flex flex-col justify-center w-full px-4 md:w-8/12 md:px-0">
      <BusinessInfoForm outletType={OutletTypeEnum.restaurant} />
    </div>
  </div>
);

export default RestaurantOnboarding;
