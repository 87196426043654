import MapPinIcon from 'assets/images/map-pin-range-line.svg';
import HonourLineIcon from 'assets/images/honour-line.svg';
import WalletLineIcon from 'assets/images/wallet-line-yellow.svg';
import { FeatureCard } from 'app/modules/components/FeatureCard';

const StartDelivering = () => {
  const deliveryBenefits = [
    {
      icon: HonourLineIcon,
      title: 'Customize and Set Your Rates',
      description:
        'Set your price and bid for delivery requests that fit your schedule and preferences.',
    },
    {
      icon: MapPinIcon,
      title: 'In-App Navigation for Easy Routes',
      description:
        'Get optimized delivery routes with in-app GPS, ensuring you reach your destination faster.',
    },
    {
      icon: WalletLineIcon,
      title: 'Smooth Withdrawal Process',
      description:
        'Your payment is deposited into your wallet for you to withdraw at any convenient time.',
    },
  ];
  return (
    <div className="mx-5 md:mx-0 md:py-40 py-28">
      <div className="max-w-6.5xl mx-auto flex flex-col md:flex-col">
        <p className="text-4xl mx-auto md:w-full w-11/12 md:text-5xl text-center">
          Start Delivering, Start Earning
        </p>
        <p className="w-9/12 md:text-2xl text-center text-lg  text-elr-black-300 mx-auto pt-6">
          Accept deliveries, optimize your routes, and boost your income
        </p>
        <div className="md:mt-16 mt-10 flex flex-wrap items-center justify-center md:gap-8 gap-6">
          {deliveryBenefits.map((benefits) => (
            <div key={benefits.title} className="w-full md:w-[30%]">
              <FeatureCard
                icon={benefits.icon}
                titleClassName="w-11/12 md:w-full"
                title={benefits.title}
                description={benefits.description}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StartDelivering;
