import { MobileHeader } from 'app/components/Header';
import TopNavigationMenu from 'app/components/menu/TopNavigationMenu';
import {
  BasketIconWonWithBG,
  CalendarIconWonWithBG,
  CutleryIconWonWithBG,
  LeafIconWonWithBG,
  LogisticsBoxWithBG,
  WearIconWonWithBG,
} from 'app/components/SvgAssets';
import { useGetWalletBalance } from 'app/hooks/requests/platform';
import { useGetProfile } from 'app/hooks/user';
import FundWallet from 'app/sender/components/FundWallet';
import copyToClipboard from 'app/utilities/copyToClipboard';
import { privateRoutes, publicRoutes } from 'app/utilities/routes';
import { OutletType } from 'app/utilities/types/shared';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ElrPageTitle } from 'ui-components';
import { ElrButton } from 'ui-components/components/ElrButton';
import BankLineImg from '../../assets/images/bank-line.svg';
import FundWalletImg from '../../assets/images/fund-wallet.svg';
import InvoiceImg from '../../assets/images/invoice.svg';

const Dashboard = () => {
  const {
    id: userId,
    accountInformation: {
      id: roleId,
      outletType = {
        market: false,
        logistics: false,
        essentials: false,
        product: false,
        experience: false,
        restaurant: false,
      },
    },
    role,
    accountInformation,
    currentAccessRole,
  } = useGetProfile();
  const navigate = useNavigate();
  const currentRole = currentAccessRole || role;
  const [getWalletBalance, wallet] = useGetWalletBalance(
    userId,
    roleId,
    currentRole
  );

  const getBalance = useCallback(async () => {
    const account: any = await getWalletBalance();
    if (account.data) {
      const { paystackAccountName, paystackAccountNumber } = account.data;
      paystackAccountName &&
        setTransferDetails({ paystackAccountName, paystackAccountNumber });
    }
  }, [wallet?.balance]);

  useEffect(() => {
    getBalance();
  }, [getBalance]);

  const [showFundModal, setShowFundModal] = useState(false);
  const [transferDetails, setTransferDetails] = useState({
    paystackAccountName: '',
    paystackAccountNumber: '',
  });

  const outletTypeToRouteMap: Record<OutletType, string> = {
    market: outletType.market
      ? `${publicRoutes.commerce}/${privateRoutes.ecommerce}/${privateRoutes.market}/${userId}/${privateRoutes.marketDashboard}`
      : privateRoutes.marketOnboarding,
    logistics: `${publicRoutes.commerce}/${privateRoutes.logistics}`,
    essentials: '',
    product: '',
    restaurant: outletType.restaurant
      ? `${publicRoutes.commerce}/${privateRoutes.ecommerce}/${privateRoutes.restaurant}/${userId}/${privateRoutes.restaurantDashboard}`
      : privateRoutes.restaurantOnboarding,
    experience: `${publicRoutes.commerce}/${privateRoutes.ecommerce}/${privateRoutes.experiencesList}`,
  };

  const handleCopyDetails = () => {
    if (accountInformation?.bankDetails?.accountNumber) {
      copyToClipboard(accountInformation.bankDetails.accountNumber);
      toast.success('Account number copied to clipboard');
    }
  };

  return (
    <div>
      <ElrPageTitle title="Commerce Dashboard" />
      <div className="bg-white">
        <div className="flex items-center justify-between w-full align-middle md:mx-auto">
          <div className="w-full md:w-11/12 md:mx-auto">
            <MobileHeader mdHide />
          </div>
          <TopNavigationMenu containerClass="md:mr-0" />
        </div>
      </div>
      {showFundModal && (
        <FundWallet
          amount={0}
          skipOrderMethod={!!transferDetails.paystackAccountName}
          close={() => setShowFundModal(false)}
        />
      )}
      <div className="flex flex-col items-center justify-center h-full max-w-4xl mx-4 my-20 align-middle md:mx-auto">
        <div className="flex flex-col items-center justify-center gap-2 pb-8 align-middle">
          <h2 className="text-2xl">What would you like to do today?</h2>
          <p className="text-lg text-elr-black-400">Select an option below</p>
        </div>
        {wallet?.accountDetails ? (
          <div className="flex flex-col w-full gap-5 md:flex-row">
            <div className="flex flex-col w-full p-5 bg-white rounded-lg">
              <div className="flex flex-col justify-between flex-grow gap-5">
                <div className="flex items-start justify-start gap-4">
                  <img src={BankLineImg} alt="bank-line" />
                  <div className="flex flex-col gap-2">
                    <p>{wallet.accountDetails.name}</p>
                    <span className="text-base">
                      <p className="text-elr-black">SafeHaven MFB</p>
                      <p className="text-elr-green">
                        {wallet.accountDetails.number}
                      </p>
                    </span>
                  </div>
                </div>
                <div className="flex items-center justify-center w-full gap-4 mt-auto">
                  <ElrButton
                    text="Top Up Wallet"
                    onClick={() => setShowFundModal(true)}
                    className="w-full px-5 py-2 rounded-md bg-elr-gray text-elr-black"
                  />
                  <ElrButton
                    text="Copy Details"
                    onClick={handleCopyDetails}
                    className="w-full px-5 py-2 text-white rounded-md bg-elr-black"
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col w-full p-5 bg-white rounded-lg">
              <div className="flex flex-col justify-between flex-grow gap-5">
                <div className="flex items-center justify-start gap-4">
                  <img src={InvoiceImg} alt="invoice" />
                  <div className="w-9/12">
                    <p className="text-lg">Get Invoice Instantly</p>
                    <p className="text-elr-black-300">
                      Simplify your billing process with a few clicks
                    </p>
                  </div>
                </div>
                <div className="flex items-center justify-center w-full gap-4">
                  <ElrButton
                    text="Generate Invoice"
                    onClick={() => true}
                    className="w-full px-5 py-2 rounded-md bg-elr-gray text-elr-black"
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col w-full gap-5 md:flex-row">
            <div className="flex flex-col w-full p-5 bg-white rounded-lg">
              <div className="flex flex-col justify-between flex-grow gap-5">
                <div className="flex items-center justify-start gap-4">
                  <img src={FundWalletImg} alt="wallet" />
                  <p className="w-9/12 text-lg">
                    Fund your wallet or{' '}
                    <span
                      className="cursor-pointer text-elr-purple"
                      onClick={() => window.location.reload()}
                    >
                      get an account number
                    </span>{' '}
                    linked to your business.
                  </p>
                </div>
                <div className="flex items-center justify-center w-full gap-4 mt-auto">
                  <ElrButton
                    text="Top Up Wallet"
                    onClick={() => setShowFundModal(true)}
                    className="w-full px-5 py-2 rounded-md bg-elr-gray text-elr-black"
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col w-full p-5 bg-white rounded-lg">
              <div className="flex flex-col justify-between flex-grow gap-5">
                <div className="flex items-center justify-start gap-4">
                  <img src={InvoiceImg} alt="invoice" />
                  <div className="w-9/12">
                    <p className="text-lg">Get Invoice Instantly</p>
                    <p className="text-elr-black-300">
                      Simplify your billing process with a few clicks
                    </p>
                  </div>
                </div>
                <div className="flex items-center justify-center w-full gap-4">
                  <ElrButton
                    text="Generate Invoice"
                    onClick={() => true}
                    className="w-full px-5 py-2 rounded-md bg-elr-gray text-elr-black"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="grid w-full gap-6 my-4 md:grid-cols-column-three grid-cols-column-two">
          <OutletCard
            icon={<LogisticsBoxWithBG />}
            label="Logistics"
            onClick={() => navigate(outletTypeToRouteMap.logistics)}
          />
          <OutletCard
            icon={<LeafIconWonWithBG />}
            label="Market Runs"
            onClick={() => navigate(outletTypeToRouteMap.market)}
            disabled={!outletType?.market}
          />
          <OutletCard
            icon={<CutleryIconWonWithBG />}
            label="Restaurant"
            onClick={() => navigate(outletTypeToRouteMap.restaurant)}
            disabled={!outletType?.restaurant}
          />
          <OutletCard
            icon={<BasketIconWonWithBG />}
            label="Essentials"
            disabled={!outletType?.essentials}
          />
          <OutletCard
            icon={<WearIconWonWithBG />}
            label="Lifestyle"
            disabled={!outletType?.product}
          />
          <OutletCard
            icon={<CalendarIconWonWithBG />}
            label="Experiences"
            onClick={() => navigate(outletTypeToRouteMap.experience)}
            disabled={!outletType?.experience}
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

interface OutletCardProps {
  icon: React.ReactNode;
  label: string;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
}

export const OutletCard: React.FC<OutletCardProps> = ({
  icon,
  label,
  onClick,
  className,
  disabled = false,
}) => (
  <div
    className={`bg-elr-white-400 md:py-12 py-10 px-8 w-full md:h-52 h-fit rounded-lg cursor-pointer ${
      disabled ? 'opacity-50' : ''
    } ${className}`}
    onClick={disabled ? undefined : onClick}
  >
    <div className="flex flex-col items-center justify-center align-middle">
      {icon}
      <p className="mt-6 text-lg leading-5 text-elr-black">{label}</p>
    </div>
  </div>
);
