import React, { useState } from 'react';
import { ElrDropdownIconToggle } from '../ElrDropdownIconToggle';

interface Props {
  active: boolean;
  setActive: (index: number) => void;
  iconClass?: string;
  transformPositionClass?: string;
  header?: string;
  index: number;
  children: React.ReactNode;
}

export const ElrAccordion: React.FC<Props> = ({
  transformPositionClass = '-translate-x-25',
  iconClass = 'opacity-40 text-elr-black',
  active,
  setActive,
  index,
  children,
  header,
}) => {
  const [dropdown, setDropdown] = useState(false);

  return (
    <div
      className={`rounded-lg border-05 border-elr-black border-opacity-30 mt-7 mb-7 ${
        !active ? 'h-20' : ''
      }`}
    >
      <div className="flex w-full justify-between pt-6 p-6">
        <p className="text-xl">{header}</p>
        <div className={`${iconClass}`} onClick={() => setActive(index)}>
          <ElrDropdownIconToggle
            isToggled={dropdown && active}
            toggle={setDropdown}
          />
        </div>
      </div>
      {dropdown && active && (
        <div
          className={`${transformPositionClass} p-6 pt-0 opacity-60 text-lg`}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export const ElrAccordion2: React.FC<Props> = ({
  transformPositionClass = '-translate-x-25',
  iconClass = 'opacity-40 text-elr-black',
  active,
  setActive,
  index,
  children,
  header,
}) => {
  const [, setDropdown] = useState(false);

  const handleAccordionClick = () => {
    if (active) {
      setActive(-1);
      setDropdown(false);
    } else {
      setActive(index);
      setDropdown(true);
    }
  };

  return (
    <div>
      <div
        className="flex w-full justify-between items-center cursor-pointer"
        onClick={handleAccordionClick}
      >
        <p className="text-xl text-elr-black">{header}</p>
        <div className={`${iconClass}`}>
          <ElrDropdownIconToggle isToggled={active} toggle={() => {}} />
        </div>
      </div>
      <div
        className={`transition-all duration-300 ease-in-out overflow-hidden text-lg ${
          active ? 'max-h-screen pt-6 opacity-100' : 'max-h-0 opacity-0'
        } ${transformPositionClass}`}
      >
        {children}
      </div>
    </div>
  );
};
