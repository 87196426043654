import { axiosDefault as axios } from 'app/utilities/axios';
import SupportHeader from 'app/utilities/HttpSupportHeader';
import { ExperienceCategory } from 'app/utilities/types/shared';
import { toast } from 'react-toastify';
import {
  ExperienceDataInterface,
  ListSoldTicketsParams,
  PurchaseTicketPayload,
  SoldTicketsResponse,
  TicketSalesDataInterface,
} from './types';

interface Params {
  experienceUUId: string;
}

export const httpGetExperience = async ({
  experienceUUId,
}: Params): Promise<ExperienceDataInterface> => {
  try {
    const response = await axios.get(`/experience/${experienceUUId}`, {
      ...SupportHeader(),
    });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpCloseExperience = async ({
  experienceId,
  roleId,
}: {
  experienceId: string;
  roleId: string;
}): Promise<ExperienceDataInterface> => {
  try {
    const response = await axios.delete(`/experience/${experienceId}`, {
      ...SupportHeader(),
      data: { roleId },
    });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpPurchaseTicket = async (
  payload: PurchaseTicketPayload
): Promise<any> => {
  try {
    const response = await axios.post(
      `/experience/purchase-ticket`,
      payload,
      SupportHeader()
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpGetTicketSales = async ({
  experienceId,
}: {
  experienceId: string;
}): Promise<TicketSalesDataInterface[]> => {
  try {
    const response = await axios.get<TicketSalesDataInterface[]>(
      `/experience/${experienceId}/ticket-sales`,
      {
        ...SupportHeader(),
      }
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpToggleTicket = async (
  experienceId: string,
  ticketType: string,
  ticketStatus: boolean
): Promise<{ ok: boolean }> => {
  try {
    const response = await axios.put(
      `/experience/${experienceId}/toggle-ticket`,
      {
        ticketType,
        ticketStatus,
      },
      {
        ...SupportHeader(),
      }
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpGetListSoldTickets = async ({
  experienceId,
  page,
  limit,
}: ListSoldTicketsParams): Promise<SoldTicketsResponse[]> => {
  try {
    const response = await axios.get(
      `/experience/${experienceId}/list-sold-tickets`,
      {
        params: { page, limit },
        ...SupportHeader(),
      }
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpGetExperienceTicketLeft = async ({
  experienceId,
  tickets,
}: {
  experienceId: string;
  tickets: Array<{ id: string; quantity: number }>;
}): Promise<Array<{ type: string; remaining: number }>> => {
  try {
    const response = await axios.post(
      `/experience/${experienceId}/tickets-left`,
      tickets,
      {
        ...SupportHeader(),
      }
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpFetchExperienceCategories = async (): Promise<
  ExperienceCategory[]
> => {
  try {
    const response = await axios.get<ExperienceCategory[]>(
      `/experience/categories`,
      {
        ...SupportHeader(),
      }
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};
