import { API_URL } from 'app/constants';
import axios, { AxiosRequestConfig } from 'axios';
import { jwtDecode } from 'jwt-decode';
import { Session, accessToken, refreshToken } from './Session';
import { setRedirectUrl } from './helpers';
import { privateRoutes, publicRoutes } from './routes';

const instance = axios.create({
  baseURL: `${API_URL}/v1`,
  withCredentials: true,
});

interface JwtPayloadExp {
  exp: number;
  iat: number;
  sub: string;
}

/* @todo
 * what should happen here is
 * when the main token expires, we should use the refreshtoken to get a new one
 */
async function checkAndRefreshToken(config: AxiosRequestConfig) {
  const getToken = Session.currentUser();
  if (getToken && config?.headers?.Authorization) {
    const decoded = jwtDecode<JwtPayloadExp>(getToken);
    const currentTime = Date.now() / 1000; // to get in milliseconds
    if (decoded.exp < currentTime) {
      try {
        const response = await instance.post<
          { refreshToken: string | null },
          {
            data: {
              access: { token: string };
              refresh: { token: string };
            };
          }
        >('/auth/refresh-tokens', {
          refreshToken: Session.refreshToken(),
        });
        return response.data;
      } catch (error: any) {
        // @todo if error, push to log out
      }
    }
  }
  return null;
}

instance.interceptors.request.use(
  async (config) => {
    const requestConfig = config;
    const newAccessToken = await checkAndRefreshToken(config);
    if (newAccessToken) {
      Session.setValue(accessToken, newAccessToken.access.token);
      Session.setValue(refreshToken, newAccessToken.refresh.token);
      // set refresh token as well
      // @ts-ignore
      requestConfig.headers.Authorization = `Bearer ${newAccessToken.accessToken}`;
    }
    return requestConfig;
  },
  (error) => Promise.reject(error)
);

const AUTH_ERRORS = {
  SESSION_CONFLICT: 'SESSION_CONFLICT_ERROR',
  AUTHENTICATION_REQUIRED: 'Please authenticate',
} as const;

instance.interceptors.response.use(
  (r) => r,
  (error) => {
    const { status, data } = error.response;
    if (
      (status === 409 && data.code === AUTH_ERRORS.SESSION_CONFLICT) ||
      (status === 401 &&
        data.message === AUTH_ERRORS.AUTHENTICATION_REQUIRED &&
        Object.values(privateRoutes).includes(window.location.pathname))
    ) {
      Session.logout();
      const redirectUrl = `${publicRoutes.login}${setRedirectUrl(window.location.pathname)}`;
      window.location.replace(redirectUrl);
    }
    return Promise.reject(error);
  }
);

export { instance as axiosDefault, axios as axiosStatic };
