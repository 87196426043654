import { IdentityVerificationRequest } from 'app/api/d';
import { useVerifyCustomer } from 'app/hooks/operator';
import { useState } from 'react';
import { ElrButton, ElrModal } from 'ui-components';
import ErrorIcon from '../../../../assets/images/error-icon.svg';
import IdCardIcon from '../../../../assets/images/id-cardIcon.svg';

interface VerificationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onActionTaken: () => void;
  data: IdentityVerificationRequest | null;
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onClick: () => void;
  loading: boolean;
}

export const VerificationModal: React.FC<VerificationModalProps> = ({
  isOpen,
  onClose,
  data,
  onActionTaken,
}) => {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isDeclineOpen, setIsDeclineOpen] = useState(false);
  const { mutate: verifyCustomer, isPending } = useVerifyCustomer();

  const handleVerifyCustomer = () => {
    if (data) {
      verifyCustomer({ status: 'approved', senderId: data?.id });
      setIsConfirmOpen(false);
      onClose();
      onActionTaken();
    }
  };

  const handleDeclineCustomer = () => {
    if (data) {
      verifyCustomer({ status: 'declined', senderId: data?.id });
      setIsDeclineOpen(false);
      onClose();
      onActionTaken();
    }
  };

  return (
    <>
      <ElrModal isOpen={isOpen} onClose={onClose} width="100%">
        <div className="pb-16 pt-12 px-8 w-full">
          <h2 className="text-2xl font-normal text-elr-black mb-6">
            Identity Verification
          </h2>

          <div className="grid w-full gap-4 my-4 px-8 py-6 md:grid-cols-column-two bg-elr-gray">
            <div className="bg-white py-4 px-6 rounded">
              <h3 className="text-lg text-elr-green font-normal">
                Errandlr User Details
              </h3>
              <div className="grid grid-cols-column-two mt-4 gap-7 md:gap-8">
                <div className="flex flex-col">
                  <p className="text-base pb-1 text-elr-black-400">Name</p>
                  <p className="text-elr-black text-base">{data?.name}</p>
                </div>
                <div className="flex flex-col">
                  <p className="text-base pb-1 text-elr-black-400">
                    Nationality
                  </p>
                  <p className="text-elr-black text-base">
                    {data?.nationality}
                  </p>
                </div>
              </div>
            </div>
            <div className="bg-white py-4 px-6 rounded">
              <h3 className="text-lg text-elr-green font-normal">
                Account Upgrade Information
              </h3>
              <div className="grid grid-cols-column-two mt-4 gap-7 md:gap-8">
                <div className="flex flex-col">
                  <p className="text-base pb-1 text-elr-black-400">
                    Document Type
                  </p>
                  <p className="text-elr-black text-base">
                    {data?.documentType}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="text-base pb-1 text-elr-black-400">
                    Date Submitted
                  </p>
                  <p className="text-elr-black text-base">
                    {data?.dateSubmitted}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="text-base pb-1 text-elr-black-400">
                    Nationality
                  </p>
                  <p className="text-elr-black text-base">
                    {data?.nationality}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex gap-4 w-full">
            <div className="bg-elr-gray px-8 py-6 w-full">
              <h3 className="text-lg text-elr-green font-normal">ID Image</h3>
              <img
                src={data?.documentUrl}
                alt={`ID document of ${data?.name}`}
                className="mt-4 w-full rounded"
              />
            </div>
            <div className="bg-elr-gray px-8 py-6 w-full">
              <h3 className="text-lg text-elr-green font-normal">Selfie</h3>
              <img
                src={data?.selfieUrl}
                alt={`Selfie of ${data?.name}`}
                className="mt-4 w-full"
              />
            </div>
          </div>

          <div className="flex justify-end gap-4 mt-11">
            <ElrButton
              text="Decline Request"
              className="py-2 px-8 text-elr-error bg-elr-gray rounded-md"
              onClick={() => setIsDeclineOpen(true)}
            />
            <ElrButton
              text="Approve Verification"
              className="py-2 px-8 bg-elr-black text-white rounded-md"
              onClick={() => setIsConfirmOpen(true)}
            />
          </div>
        </div>
      </ElrModal>

      <ConfirmVerificationModal
        isOpen={isConfirmOpen}
        onClose={() => setIsConfirmOpen(false)}
        onClick={handleVerifyCustomer}
        loading={isPending}
      />

      <DeclineVerificationModal
        isOpen={isDeclineOpen}
        onClose={() => setIsDeclineOpen(false)}
        onClick={handleDeclineCustomer}
        loading={isPending}
      />
    </>
  );
};

export const DeclineVerificationModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onClick,
  loading,
}) => (
  <ElrModal isOpen={isOpen} onClose={onClose}>
    <div className="flex flex-col items-center px-6 py-10 text-center">
      <div className="flex items-center justify-center mb-8">
        <img src={ErrorIcon} alt="error-icon" className="w-20 h-20" />
      </div>
      <p className="w-10/12 pb-4 text-2xl text-elr-black">
        You are about to decline this verification request
      </p>
      <p className="pt-4 text-base text-center text-elr-black-400">
        Do you still want to proceed?
      </p>
      <div className="flex gap-2.5 mt-10 w-full">
        <ElrButton
          text="No, Go Back"
          className="w-full h-10 px-4 py-2 rounded-md bg-elr-gray text-elr-black"
          onClick={onClose}
        />
        <ElrButton
          text="Yes, Decline Request"
          className="w-full h-10 px-4 py-2 rounded-md text-white bg-elr-black"
          spinnerColor="Black"
          onClick={onClick}
          loading={loading}
        />
      </div>
    </div>
  </ElrModal>
);

export const ConfirmVerificationModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onClick,
  loading,
}) => (
  <ElrModal isOpen={isOpen} onClose={onClose}>
    <div className="flex flex-col items-center px-6 py-10 text-center">
      <div className="flex items-center justify-center mb-8">
        <img
          src={IdCardIcon}
          alt="ID card verification"
          className="w-20 h-20"
        />
      </div>
      <p className="w-10/12 pb-4 text-2xl text-elr-black">
        Confirm Verification
      </p>
      <p className="w-11/12 text-base text-center text-elr-black-400">
        You are about to approve this verification request.
      </p>
      <p className="pt-4 text-base text-center text-elr-black-400">
        Do you still want to proceed?
      </p>
      <div className="flex gap-2.5 mt-10 w-full">
        <ElrButton
          text="No, Go Back"
          className="w-full h-10 px-4 py-2 rounded-md bg-elr-gray text-elr-black"
          onClick={onClose}
        />
        <ElrButton
          text="Yes, Verify"
          className="w-full h-10 px-4 py-2 rounded-md text-white bg-elr-black"
          spinnerColor="Black"
          onClick={onClick}
          loading={loading}
        />
      </div>
    </div>
  </ElrModal>
);
