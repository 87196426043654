import { useEffect } from 'react';
import { ElrPageTitle } from 'ui-components';
import makeMoneyImage from 'assets/images/make-money.svg';
import appStore from 'assets/images/apple-fill-logo.svg';
import googlePlay from 'assets/images/google-play-line.svg';
import PopularQuestions from '../components/PopularQuestions';
import { Footer } from '../landing/components';
import PoliciesAppBanner from '../landing/components/PoliciesAppBanner';
import { FeatureBlock } from '../components/FeatureBlock';
import StartDelivering from './components/StartDelivering';
import { Header } from './components/Header';

const RiderPage = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  });

  const questions = [
    {
      id: 1,
      header: 'How do i become a rider?',
      content:
        'To become a rider, you need to download the Errandlr Rider app, sign up, and complete the verification process. Once you have been verified, you can start accepting deliveries and making money.',
    },
    {
      id: 2,
      header: 'How can i contact support?',
      content:
        'You can contact support by clicking the "Help" button in the app, by clicking the "Having issues?" button on any request or by emailing us at support@errandlr.com.',
    },
    {
      id: 3,
      header: 'Where are our services available?',
      content:
        'Our services are available in Lagos. We are expanding to other cities soon.',
    },
  ];
  const iosLink = 'https://apps.apple.com/app/errandlr-rider/id6456889640';
  const androidLink =
    'https://play.google.com/store/apps/details?id=com.errandlr.rider';

  return (
    <div className="bg-white">
      <ElrPageTitle title="Errandlr - Rider" />
      <Header />
      <StartDelivering />
      <FeatureBlock
        imageSrc={makeMoneyImage}
        imageAlt="make-money-on-the-move"
        headingText="Make Money on the Move, Earn Without Limits"
        layoutClassName="md:flex-row md:pb-40 pb-28 flex-col"
        descriptionText="Pick up orders, drop them off, and cash out quickly with every successful delivery you make on our platform."
        buttonText="Get on iPhone"
        buttonLink={iosLink}
        buttonClassName="px-8 bg-elr-black text-white md:w-fit w-full"
        buttonImage={appStore}
        buttonImageAltName="app-store"
        buttonImageClassName="filter invert brightness-0"
        secondaryButtonText="Get on Android"
        secondaryButtonLink={androidLink}
        secondaryButtonClassName="px-8 bg-elr-black text-white md:w-fit w-full"
        secondaryButtonImage={googlePlay}
        secondaryButtonImageAltName="google-play"
        secondaryButtonImageClassName="filter invert brightness-0"
      />
      <PopularQuestions questions={questions} headingColor="text-elr-black" />
      <div className="max-w-6.5xl md:mx-auto mx-5">
        <PoliciesAppBanner />
      </div>
      <Footer />
    </div>
  );
};

export default RiderPage;
