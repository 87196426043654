import { useQueryClient } from '@tanstack/react-query';
import { httpUploadFile } from 'app/api/utils';
import { EllipseSvg } from 'app/components/SvgAssets';
import { useGetProfile } from 'app/hooks/user';
import { fileSize } from 'app/utilities/helpers';
import { FileStructureResponse } from 'app/utilities/types/shared';
import CheckCircle from 'assets/images/CheckCircle.svg';
import CloudUpload from 'assets/images/CloudUpload.svg';
import ErrorOutline from 'assets/images/ErrorOutline.svg';
import spinner from 'assets/images/spinner-black.svg';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

interface Props {
  backgroundColor?: string;
  className?: string;
  containerClassName?: string;
  size?: 'sm' | 'lg' | 'xm';
  placeholder?: string;
  onChange?: boolean;
  accept?: string;
  value?: any;
  name?: string;
  isValid?: boolean;
  disabled?: boolean;
  category?: 'document' | 'image';
  tag: string; // acts as a unique identifier to the inputs
  onUploaded: (payload: FileStructureResponse, tag: string) => void;
  autoUpload?: boolean;
  onTempUpload?: (file: { name: string; type: string }) => void;
  defaultFile?: { name: string; type: string; size: number };
  allowedImageDimensions?: { width: number; height: number };
  previewFile?: string | File;
  preview?: boolean;
}

export const ElrUpload: React.FC<Props> = ({
  className,
  size = 'lg',
  placeholder,
  onChange,
  accept = '.jpg',
  onUploaded,
  disabled,
  tag,
  isValid = false,
  category,
  name,
  preview,
  ...props
}) => {
  const type = 'file';
  const [upload, setUpload] = useState('');
  const [fileName, setInputFileName] = useState<any>(
    props.value?.originalFilename
  );
  const [isUploading, setUploading] = useState<boolean>(false);
  const [isSuccess, setSuccessUploading] = useState<boolean>(
    props.value ? true : isValid
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const queryClient = useQueryClient();
  const { id: userId } = useGetProfile();

  const allowedFileExt = (file: string) => {
    if (
      !file.match(
        /\.(jpg|JPG|JPEG|PNG|jpeg|png|pdf|docx|doc|txt|XLS|PDF|DOCX|DOC|TXT)$/
      )
    ) {
      return false;
    }
    return true;
  };

  const makeUploadFileRequest = async (file: {
    name: string;
    type: string;
  }) => {
    const isImage = file.type.includes('image') ? 'image' : null;
    const isDocument = file.type.includes('application') ? 'document' : null;
    const fileType = category || isImage || isDocument || 'image';
    if (!allowedFileExt(file.name)) return;

    try {
      setUploading(true);
      const data = await queryClient.fetchQuery({
        queryKey: [file.name],
        queryFn: () => httpUploadFile(userId, file, fileType),
        gcTime: 30000,
      });

      const response = {
        key: data.key,
        originalFilename: file.name,
        type: file.type,
        url: data.url,
      };
      setUploading(false);
      setSuccessUploading(true);

      onUploaded(response, tag);
    } catch (error: any) {
      setUploading(false);
    }
  };

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files || [];
    const fileValue = event.target.value;
    const files = Array.from(selectedFile);
    setUpload(fileValue);
    if (!files.length) return;
    const selectedFileName = files[0]?.name;
    setInputFileName(selectedFileName);
    makeUploadFileRequest(files[0]);
  };

  const handleClick = () => {
    if (inputRef.current !== null) {
      inputRef.current.click();
    }
  };

  return (
    <div
      onClick={handleClick}
      className="bg-elr-gray flex-col justify-center items-center flex overflow-hidden p-2 gap-x-3.5 w-72 mb-3 h-48 border border-elr-purple border-dashed cursor-pointer"
    >
      <div className="hidden h-8 pt-2 pl-1">
        {isSuccess ? (
          <span>
            <img src={CheckCircle} alt="check-circle" />
          </span>
        ) : (
          <span>
            <img src={ErrorOutline} alt="error-outline" />
          </span>
        )}
      </div>
      <div className="hidden w-full border-none bg-elr-gray h-11">
        <input
          {...props}
          onChange={onChangeInput}
          type={type}
          disabled={disabled}
          accept={accept}
          value={upload}
          className={`bg-elr-gray border-none h-11 w-full focus:outline-none ${
            type === 'file' && 'hidden'
          } `}
          ref={inputRef}
        />
      </div>
      {!disabled && (
        <div className="w-10 h-10 pt-3 cursor-pointer">
          {isUploading ? (
            <span className="mt-2 -mr-2">
              <img src={spinner} className="animate-spin h-3.5" alt="spinner" />
            </span>
          ) : (
            <div>
              <img src={CloudUpload} alt="cloud-upload" />
            </div>
          )}
        </div>
      )}

      <h3 className="text-lg">{fileName || name}</h3>
      <p className="pt-1 text-elr-gray-400">{placeholder}</p>
    </div>
  );
};

export const ElrUploadV2: React.FC<Props> = ({
  className,
  size = 'lg',
  placeholder,
  onChange,
  accept = '.jpg',
  onUploaded,
  disabled,
  tag,
  isValid = false,
  category,
  name,
  autoUpload = true,
  onTempUpload,
  defaultFile,
  ...props
}) => {
  const type = 'file';
  const [upload, setUpload] = useState('');
  const [previewFile, setPreviewFile] = useState<{
    name: string;
    type: string;
    size: number;
  } | null>(null);
  const [isUploading, setUploading] = useState<boolean>(false);
  const [isSuccess, setSuccessUploading] = useState<boolean>(
    props.value ? true : isValid
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const queryClient = useQueryClient();
  const { id: userId } = useGetProfile();

  const allowedFileExt = (file: string) => {
    if (
      !file.match(
        /\.(jpg|JPG|JPEG|PNG|jpeg|png|pdf|docx|doc|txt|XLS|PDF|DOCX|DOC|TXT)$/
      )
    ) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (defaultFile) {
      setPreviewFile(defaultFile);
      makeUploadFileRequest(defaultFile);
    }
  }, []);

  const makeUploadFileRequest = async (file: {
    name: string;
    type: string;
  }) => {
    const isImage = file.type.includes('image') ? 'image' : null;
    const isDocument = file.type.includes('application') ? 'document' : null;
    const fileType = category || isImage || isDocument || 'image';
    if (!allowedFileExt(file.name)) return;

    if (autoUpload) {
      try {
        setUploading(true);
        const data = await queryClient.fetchQuery({
          queryKey: [file.name],
          queryFn: () => httpUploadFile(userId, file, fileType),
          gcTime: 30000,
        });

        const response = {
          key: data.key,
          originalFilename: file.name,
          type: file.type,
          url: `${new URL(data.url).origin}${new URL(data.url).pathname}`,
        };
        setUploading(false);
        setSuccessUploading(true);

        onUploaded(response, tag);
      } catch (error: any) {
        setUploading(false);
      }
    } else {
      onTempUpload?.(file);
    }
  };

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files || [];
    const fileValue = event.target.value;
    const files = Array.from(selectedFile);
    setUpload(fileValue);
    if (!files.length) return;
    // check if the file size is greater than 5MB
    const fileSizeInMB = files[0].size / (1024 * 1024);
    if (fileSizeInMB > 1) {
      toast.error('File size exceeds 1MB');
      return;
    }

    // Check if the image height is exactly 540px and width is exactly 960px
    const file = files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        if (img.width !== 960 || img.height !== 540) {
          toast.error(
            'Image dimensions must be exactly 960px of width and 540px of height'
          );
          return;
        }

        setPreviewFile(file);
        makeUploadFileRequest(file);
      };
      img.src = e?.target?.result as string;
    };
    reader.readAsDataURL(file);
  };

  const handleClick = () => {
    if (inputRef.current !== null) {
      inputRef.current.click();
    }
  };

  return (
    <div
      onClick={handleClick}
      className="flex flex-col items-center justify-center overflow-hidden border border-dashed cursor-pointer border-elr-gray-neutral-300"
    >
      <div className="hidden h-8 pt-2 pl-1">
        {isSuccess ? (
          <span>
            <img src={CheckCircle} alt="check-circle" />
          </span>
        ) : (
          <span>
            <img src={ErrorOutline} alt="error-outline" />
          </span>
        )}
      </div>
      <div className="flex w-full h-24">
        <input
          {...props}
          onChange={onChangeInput}
          type={type}
          disabled={disabled}
          accept={accept}
          value={upload}
          className={`bg-elr-gray border-none h-11 w-full focus:outline-none ${
            type === 'file' && 'hidden'
          } `}
          ref={inputRef}
        />
        {!disabled && (
          <div className="relative w-full cursor-pointer">
            {isUploading ? (
              <span className="mt-2 -mr-2">
                <img
                  src={spinner}
                  className="animate-spin h-3.5"
                  alt="spinner"
                />
              </span>
            ) : (
              <ImageUploadContainer previewFile={previewFile} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const ImageUploadContainer: React.FC<{
  previewFile: { name: string; type: string; size: number } | File | null;
}> = ({ previewFile }) =>
  previewFile instanceof File ? (
    <div className="flex px-3 py-3 pb-3">
      <div className="flex items-center justify-between w-7/12 gap-x-3">
        <img
          src={URL.createObjectURL(previewFile)}
          alt="preview"
          className="object-cover w-full h-20"
        />
        <div className="flex flex-col justify-center w-full">
          <h3 className="text-lg">{previewFile.name}</h3>
          <p className="flex items-center text-opacity-50 uppercase text-elr-black gap-x-2">
            <span>{fileSize(previewFile.size)}</span>
            <EllipseSvg height="5" width="5" />
            <span>{previewFile.type.split('/')[1]}</span>
          </p>
        </div>
      </div>
      <div className="flex items-center justify-end w-5/12">
        <button type="button" className="px-8 py-2.5 rounded bg-elr-gray-100">
          Replace Image
        </button>
      </div>
    </div>
  ) : (
    <div className="mt-4">
      <div className="flex justify-center gap gap-x-2 text-elr-purple">
        <img src={CloudUpload} alt="cloud-upload" />
        <p>Upload Image</p>
      </div>
      <div className="text-xs text-center text-elr-disabled-gray">
        <p>Files Supported: JPEG, PNG</p>
        <p>Recommended Dimension: Width - 960px, Height - 540px</p>
        <p>Maximum Size: 1MB</p>
      </div>
    </div>
  );

export const ElrUploadV3: React.FC<Props> = ({
  className,
  containerClassName,
  size = 'lg',
  placeholder,
  onChange,
  accept = '.jpg',
  onUploaded,
  disabled,
  tag,
  isValid = false,
  category,
  name,
  autoUpload = true,
  onTempUpload,
  defaultFile,
  preview,
  allowedImageDimensions = { width: 2560, height: 1706 },
  ...props
}) => {
  const type = 'file';
  const [upload, setUpload] = useState('');
  const [previewFile, setPreviewFile] = useState<string | File>('');
  const [isUploading, setUploading] = useState<boolean>(false);
  const [isSuccess, setSuccessUploading] = useState<boolean>(
    props.value ? true : isValid
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const queryClient = useQueryClient();
  const { id: userId } = useGetProfile();

  const allowedFileExt = (file: string) => {
    if (!file.match(/\.(jpg|JPG|JPEG|PNG|jpeg|png)$/)) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (defaultFile) {
      setPreviewFile(defaultFile as string | File);
      makeUploadFileRequest(defaultFile);
    } else if (props.previewFile) {
      setPreviewFile(props.previewFile);
    }
  }, [defaultFile, props.previewFile]);

  const makeUploadFileRequest = async (file: {
    name: string;
    type: string;
  }) => {
    const isImage = file.type.includes('image') ? 'image' : null;
    const isDocument = file.type.includes('application') ? 'document' : null;
    const fileType = category || isImage || isDocument || 'image';
    if (!allowedFileExt(file.name)) return;

    if (autoUpload) {
      try {
        setUploading(true);
        const data = await queryClient.fetchQuery({
          queryKey: [file.name],
          queryFn: () => httpUploadFile(userId, file, fileType),
          gcTime: 30000,
        });

        const response = {
          key: data.key,
          originalFilename: file.name,
          type: file.type,
          url: `${new URL(data.url).origin}${new URL(data.url).pathname}`,
        };
        setUploading(false);
        setSuccessUploading(true);

        onUploaded(response, tag);
      } catch (error: any) {
        setUploading(false);
      }
    } else {
      onTempUpload?.(file);
    }
  };

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files || [];
    const fileValue = event.target.value;
    const files = Array.from(selectedFile);
    setUpload(fileValue);
    if (!files.length) return;
    // check if the file size is greater than 5MB
    const fileSizeInMB = files[0].size / (1024 * 1024);
    if (fileSizeInMB > 1) {
      toast.error('File size exceeds 1MB');
      return;
    }

    // Check if the image height is exactly 540px and width is exactly 960px
    const file = files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        if (
          img.width > allowedImageDimensions.width ||
          img.height > allowedImageDimensions.height
        ) {
          toast.error(
            `Image dimensions cannot be greater than ${allowedImageDimensions.width}px of width and ${allowedImageDimensions.height}px of height`
          );
          return;
        }

        setPreviewFile(file);
        makeUploadFileRequest(file);
      };
      img.src = e?.target?.result as string;
    };
    reader.readAsDataURL(file);
  };

  const handleClick = () => {
    if (inputRef.current !== null) {
      inputRef.current.click();
    }
  };

  return (
    <div
      onClick={handleClick}
      className={`${containerClassName}flex flex-col items-center justify-center overflow-hidden  cursor-pointer `}
    >
      <div className="hidden h-8 pt-2 pl-1">
        {isSuccess ? (
          <span>
            <img src={CheckCircle} alt="check-circle" />
          </span>
        ) : (
          <span>
            <img src={ErrorOutline} alt="error-outline" />
          </span>
        )}
      </div>
      <div className="flex w-full">
        <input
          {...props}
          onChange={onChangeInput}
          type={type}
          disabled={disabled}
          accept={accept}
          value={upload}
          className={`bg-elr-gray border-none h-11 w-full focus:outline-none ${
            type === 'file' && 'hidden'
          } `}
          ref={inputRef}
        />
        {!disabled && (
          <div className="relative w-full cursor-pointer">
            {isUploading ? (
              <span className="mt-2 -mr-2">
                <img
                  src={spinner}
                  className="animate-spin h-3.5"
                  alt="spinner"
                />
              </span>
            ) : (
              <ProductImageUploadUploadedContainer previewFile={previewFile} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export const ProductImageUploadContainer: React.FC<{
  previewFile: { name: string; type: string; size: number } | File | null;
}> = ({ previewFile }) =>
  previewFile instanceof File ? (
    <div className="flex px-5 pb-2">
      <div className="flex items-center justify-between w-7/12 gap-x-3">
        <img
          src={URL.createObjectURL(previewFile)}
          alt="preview"
          className="object-cover w-50 h-50"
        />
        <div className="flex flex-col justify-center w-full">
          <h3 className="text-lg">{previewFile.name}</h3>
          <p className="flex items-center text-opacity-50 uppercase text-elr-black gap-x-2">
            <span>{fileSize(previewFile.size)}</span>
            <EllipseSvg height="5" width="5" />
            <span>{previewFile.type.split('/')[1]}</span>
          </p>
        </div>
      </div>
      <div className="flex items-center justify-end w-5/12">
        <button type="button" className="px-6 py-2.5 rounded bg-elr-gray-100">
          Replace Image
        </button>
      </div>
    </div>
  ) : (
    <div className="">
      <div className="flex justify-center gap gap-x-2 text-elr-purple pb-1">
        <img src={CloudUpload} alt="cloud-upload" />
        <p>Upload a Clear Image</p>
      </div>
      <div className="text-xs text-center text-elr-disabled-gray">
        <p>Files supported: JPEG, PNG ( Width - 80px, Height - 80px)</p>
        <p className="pt-0.5">Maximum Size: 1MB</p>
      </div>
    </div>
  );

export const ProductImageUploadUploadedContainer: React.FC<{
  previewFile?: string | File;
}> = ({ previewFile }) => {
  const isFile = previewFile instanceof File;
  const imageUrl = isFile ? URL.createObjectURL(previewFile) : previewFile;
  const fileName = isFile ? previewFile.name : 'Image Preview';
  const fileSizeFormatted = isFile ? fileSize(previewFile.size) : 'MB';
  const fileType = isFile
    ? previewFile.type.split('/')[1].toUpperCase()
    : 'JPEG';

  return previewFile ? (
    <div className="flex px-5">
      <div className="flex items-center justify-between w-7/12 gap-x-3">
        <img src={imageUrl} alt="preview" className="object-cover w-50 h-50" />
        <div className="flex flex-col justify-center w-full">
          <h3 className="text-lg truncate w-9/12">{fileName}</h3>
          <p className="flex items-center text-opacity-50 uppercase text-elr-black gap-x-2">
            <span>{fileSizeFormatted}</span>
            <EllipseSvg height="5" width="5" />
            <span>{fileType}</span>
          </p>
        </div>
      </div>
      <div className="flex items-center justify-end w-5/12">
        <button type="button" className="px-6 py-2.5 rounded bg-elr-gray-100">
          Replace Image
        </button>
      </div>
    </div>
  ) : (
    <div className="">
      <div className="flex justify-center gap-x-2 text-elr-purple pb-1">
        <img src={CloudUpload} alt="cloud-upload" />
        <p>Upload a Clear Image</p>
      </div>
      <div className="text-xs text-center text-elr-disabled-gray">
        <p>Files supported: JPEG, PNG (Width - 80px, Height - 80px)</p>
        <p className="pt-0.5">Maximum Size: 1MB</p>
      </div>
    </div>
  );
};
