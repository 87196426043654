import SlideIn from 'app/components/SlideIn/SlideIn';
import { DeleteIcon, LogisticsBike } from 'app/components/SvgAssets';
import { useGetDeliveryBids } from 'app/hooks/requests/commerce';
import {
  useFetchCommerceUpcomingRequests,
  useFetchDeliveriesMade,
  useFetchNewRequests,
} from 'app/hooks/requests/dashboard';
import {
  useAcceptRequest,
  useCancelRequest,
  useReassignRequest,
} from 'app/hooks/requests/platform';
import {
  getDropdownIconClassNames,
  getRequestStatusClassNames,
} from 'app/operator/components/ClassUtils';
import {
  currencyFormatter,
  getDistanceinKm,
  getFirstItem,
} from 'app/utilities/helpers';
import { Request, RequestTypes } from 'app/utilities/types/shared';
import Dropdown from 'assets/images/dropdown.svg';
import { truncate } from 'lodash';
import { DateTime } from 'luxon';
import React, { Fragment, useState } from 'react';
import { ElrButton, ElrModal } from 'ui-components';

/* eslint-disable-next-line no-shadow */
export enum SingleDeliveryTabNames {
  NEW_REQUESTS = 'New Requests',
  IN_TRANSIT = 'In-Transit',
  DELIVERED = 'Delivered',
}

interface SingleDeliveryProps {
  deliveries: Request[];
  activeTab: SingleDeliveryTabNames;
  toggleRow: (index: number) => void;
  isExpanded: number | null;
  renderLoadState: () => React.ReactNode;
}

export const SingleDelivery: React.FC<SingleDeliveryProps> = ({
  deliveries,
  activeTab,
  toggleRow,
  isExpanded,
  renderLoadState,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCancelRequestModalOpen, setIsCancelRequestModalOpen] =
    useState(false);
  const { cancelRequest } = useCancelRequest();
  const { refetch: refetchUpcomingRequests } =
    useFetchCommerceUpcomingRequests();
  const { refetch: refetchDeliveriesMade } = useFetchDeliveriesMade();

  const formatDateTime = (createdAt: string) => {
    const dateTime = DateTime.fromISO(createdAt);
    return dateTime.toFormat('yyyy-LL-dd');
  };

  const renderSingleDeliveryTableHeader = () => (
    <thead>
      <tr className="border-b-2 border-elr-gray text-elr-black">
        <th className="font-normal py-7 text-left md:pl-32 md:w-1/3 w-1/4 pl-20 flex-grow truncate">
          Delivery Destination
        </th>
        <th className="font-normal py-7 text-center flex-grow">Sender</th>
        <th className="font-normal py-7 text-center flex-grow">Tracking ID</th>
        <th className="font-normal py-7 text-center flex-grow">Amount</th>
        {activeTab === SingleDeliveryTabNames.IN_TRANSIT && (
          <th className="font-normal py-7 text-center flex-grow">Status</th>
        )}
        <th className="font-normal py-7 text-center md:w-24 w-12" />
      </tr>
    </thead>
  );

  const renderSingleDeliveryTableRow = (request: Request, index: number) => {
    const {
      trackingId,
      priceEstimate,
      senderInformation,
      status,
      deliverToInformation,
    } = request;
    const deliverToInfo = deliverToInformation[0];

    return (
      <Fragment key={request.trackingId}>
        <tr
          className="text-center border border-x-0 border-elr-gray-500 cursor-pointer"
          onClick={() => toggleRow(index)}
        >
          <td className="text-left py-5 pl-7 text-elr-black truncate max-w-[150px] md:max-w-xs overflow-hidden">
            <div className="truncate">
              {deliverToInfo?.address.fullAddress || 'N/A'}
            </div>
            <div className="text-elr-black-300 text-sm pt-1">
              {formatDateTime(request.createdAt)}
            </div>
          </td>
          <td className="py-5 px-5 text-center text-elr-black text-opacity-60 truncate">
            {truncate(senderInformation?.data?.name || 'N/A', { length: 20 })}
          </td>
          <td className="py-5 text-center text-elr-black text-opacity-60">
            {trackingId || 'N/A'}
          </td>
          <td className="py-5 text-center text-elr-black text-opacity-60">
            {currencyFormatter(priceEstimate)}
          </td>
          {activeTab === SingleDeliveryTabNames.IN_TRANSIT && (
            <td className="py-5 text-center text-elr-black text-opacity-60">
              <p className={getRequestStatusClassNames(status)}>
                {status === RequestTypes.accepted ? 'Assigned' : 'Picked Up'}
              </p>
            </td>
          )}
          <td className="py-5 relative">
            <button
              className="focus:outline-none"
              type="button"
              onClick={() => toggleRow(index)}
            >
              <img
                src={Dropdown}
                alt="Dropdown"
                className={getDropdownIconClassNames(isExpanded === index)}
              />
            </button>
          </td>
        </tr>
        {isExpanded === index && renderSingleDeliveryExpandedRow(request)}
      </Fragment>
    );
  };

  const handleCancelRequest = async (trackingId: string) => {
    await cancelRequest({
      trackingId,
      cancelReason: 'Customer requested cancellation',
    });
    setIsCancelRequestModalOpen(false);
    refetchUpcomingRequests();
    refetchDeliveriesMade();
  };

  const renderSingleDeliveryExpandedRow = (request: Request) => {
    const {
      deliverToInformation,
      pickupAddress,
      designatedAgent,
      estimatedTime,
      status,
      verificationCodes,
      trackingId,
    } = request;
    const recipientInfo = getFirstItem(deliverToInformation);

    const riderName =
      designatedAgent?.firstname || designatedAgent?.lastname
        ? `${designatedAgent.firstname || ''} ${designatedAgent.lastname || ''}`
        : 'N/A';
    const riderPhone = designatedAgent?.phone || 'N/A';

    const deliveryActions = () => {
      if (status === RequestTypes.open) {
        return (
          <ElrButton
            text="Assign Rider"
            size="sm"
            className="h-8 text-white bg-elr-black px-4 rounded-sm"
            onClick={() => setIsModalOpen(true)}
          />
        );
      }
      if (status === RequestTypes.accepted) {
        return (
          <div className="flex gap-4">
            <ElrButton
              text="Re-Assign"
              size="sm"
              className="h-8 text-white bg-elr-black px-4 rounded-sm"
              onClick={() => setIsModalOpen(true)}
            />

            <ElrButton
              text="Cancel Request"
              size="sm"
              className="h-8 text-elr-error bg-elr-gray px-4 rounded-sm"
              onClick={() => setIsCancelRequestModalOpen(true)}
            />
          </div>
        );
      }
      if (status === RequestTypes.closed) {
        return (
          <ElrButton
            text="Request Was Closed"
            size="sm"
            className="h-8 text-white bg-elr-error px-4 rounded-sm"
            disabled
          />
        );
      }
      return null;
    };
    return (
      <tr className="text-left">
        <td colSpan={5} className="p-5 text-elr-black md:pl-56 pl-4">
          <div className="flex flex-col gap-4">
            <div className="flex justify-between">
              <div className="flex justify-between align-middle items-center">
                <div className="space-y-5 relative">
                  <div className="flex items-center">
                    <div className="inline-block w-[8px] h-[8px] rounded-full bg-elr-purple" />
                    <p className="ml-3">
                      {pickupAddress?.fullAddress || 'N/A'}
                    </p>
                  </div>
                  <div className="absolute left-1 top-0 bottom-0 border-l-2 border-dashed h-6 border-elr-gray-200" />
                  <div className="flex items-start">
                    <span className="w-[8px] h-[8px] bg-elr-yellow rounded-sm mt-1.5" />
                    <p className="ml-3">
                      {recipientInfo?.address.fullAddress || 'N/A'}
                    </p>
                  </div>
                </div>
              </div>
              {deliveryActions()}
            </div>
            <div className="flex w-full p-8 bg-elr-gray md:gap-40 gap-16">
              <div className="flex flex-col">
                <p>Recipient Details</p>
                <p className="text-elr-black text-opacity-60 py-1">
                  {recipientInfo?.name || 'N/A'}
                </p>
                <p className="text-elr-black text-opacity-60">
                  {recipientInfo?.phone || 'N/A'}
                </p>
              </div>
              <div className="flex flex-col">
                <p>Package Info</p>
                <p className="text-elr-black text-opacity-60 py-1">
                  Package Type: {recipientInfo?.packageType}
                </p>
                <p className="text-elr-black text-opacity-60">
                  Package Value: {recipientInfo?.packageValue}
                </p>
                <p className="text-elr-black text-opacity-60">
                  <strong>Pickup Verification Code:</strong>{' '}
                  {verificationCodes?.pickups[trackingId]}
                </p>
              </div>
              {activeTab === SingleDeliveryTabNames.NEW_REQUESTS ? (
                <div className="flex flex-col">
                  <p>Estimated Time</p>
                  <p className="text-elr-black text-opacity-60 py-1">
                    {estimatedTime || '1hr 30mins'}
                  </p>
                </div>
              ) : (
                <div className="flex flex-col">
                  <p>Rider Details</p>
                  <p className="text-elr-black text-opacity-60 py-1">
                    {riderName}
                  </p>
                  <p className="text-elr-black text-opacity-60">{riderPhone}</p>
                </div>
              )}
            </div>
            <CancelRequestModal
              isModalOpen={isCancelRequestModalOpen}
              setIsModalOpen={setIsCancelRequestModalOpen}
              handleCancelRequest={handleCancelRequest}
              trackingId={request.trackingId}
            />
          </div>
        </td>
      </tr>
    );
  };

  return (
    <>
      <table className="table-auto w-full">
        {renderSingleDeliveryTableHeader()}
        <tbody>
          {deliveries.length
            ? deliveries.map((request, index) =>
                renderSingleDeliveryTableRow(request, index)
              )
            : renderLoadState()}
        </tbody>
      </table>
      <AssignRiderModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        deliveries={deliveries}
      />
    </>
  );
};

const AssignRiderModal = ({
  isModalOpen,
  setIsModalOpen,
  deliveries,
}: {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  deliveries: Request[];
}) => {
  const { bids } = useGetDeliveryBids();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const acceptRequest = useAcceptRequest();
  const reassignRequest = useReassignRequest();
  const { refetch: refetchNewRequests } = useFetchNewRequests();
  const { refetch: refetchUpcomingRequests } =
    useFetchCommerceUpcomingRequests();

  const deliveryBids = deliveries
    .map((delivery) => bids[delivery.id])
    .flat()
    .filter(Boolean);
  const getDelivery: Record<string, Request> = deliveries
    .filter((delivery) => delivery.id in bids)
    .reduce(
      (acc, delivery) => ({
        ...acc,
        [delivery.id]: delivery,
      }),
      {}
    );

  const getDistance = (
    pickup: { latitude: string; longitude: string },
    delivery: { latitude: string; longitude: string }
  ) =>
    getDistanceinKm(
      { longitude: pickup.longitude, latitude: pickup.latitude },
      {
        latitude: delivery.latitude,
        longitude: delivery.longitude,
      }
    );

  const handleAssignRider = async (requestId: string, agentId: string) => {
    const delivery = getDelivery[requestId];
    const { status } = delivery;
    if (RequestTypes.accepted === status) {
      await reassignRequest(requestId, agentId);
    } else {
      await acceptRequest(requestId, agentId);
    }
    setIsModalOpen(false);
    setIsConfirmationModalOpen(false);
    refetchNewRequests();
    refetchUpcomingRequests();
  };

  const isCurrentRiderAssigned = (deliveryBid: any) => {
    const delivery = getDelivery[deliveryBid.requestId];
    return delivery?.designatedAgent?.id === deliveryBid.agentId;
  };

  return (
    <SlideIn
      close={() => setIsModalOpen(false)}
      shown={isModalOpen}
      leftMarginOffset="md:ml-[60%]"
    >
      <div className="p-4 w-full text-center bg-elr-gray-100">
        <p>List of riders</p>
      </div>

      <div className="flex flex-col gap-1 mt-10">
        {deliveryBids.map((deliveryBid, index) => (
          <span key={deliveryBid.agentId}>
            <div className="flex justify-between items-center py-4">
              <div className="flex flex-col gap-1">
                <p>{deliveryBid.agentName}</p>
                <p className="text-elr-black text-opacity-60 text-sm">
                  {deliveryBid.phoneNumber}
                </p>
              </div>
              <div>
                <p className="text-elr-black text-opacity-60 text-sm">
                  {getDistance(
                    {
                      latitude: deliveryBid.location.latitude.toString(),
                      longitude: deliveryBid.location.longitude.toString(),
                    },
                    {
                      latitude:
                        getDelivery[
                          deliveryBid.requestId
                        ].pickupAddress.latitude.toString(),
                      longitude:
                        getDelivery[
                          deliveryBid.requestId
                        ].pickupAddress.longitude.toString(),
                    }
                  )}{' '}
                  Away
                </p>
              </div>
              <div>
                {isCurrentRiderAssigned(deliveryBid) ? (
                  <ElrButton
                    text="Assigned"
                    size="sm"
                    className="h-8 text-white bg-elr-black px-4 rounded-sm min-w-24"
                    disabled
                  />
                ) : (
                  <>
                    <ElrButton
                      text="Assign"
                      size="sm"
                      className="h-8 text-black bg-elr-gray-100 px-4 rounded-sm min-w-24"
                      onClick={() => setIsConfirmationModalOpen(true)}
                    />
                    <AssignRiderConfirmationModal
                      isModalOpen={isConfirmationModalOpen}
                      setIsModalOpen={setIsConfirmationModalOpen}
                      handleAssignRider={handleAssignRider}
                      requestId={deliveryBid.requestId}
                      agentId={deliveryBid.agentId}
                    />
                  </>
                )}
              </div>
            </div>
            {index !== deliveryBids.length - 1 && (
              <div className="border-b border-elr-gray-200" />
            )}
          </span>
        ))}
      </div>
    </SlideIn>
  );
};

const AssignRiderConfirmationModal = ({
  isModalOpen,
  setIsModalOpen,
  handleAssignRider,
  requestId,
  agentId,
}: {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  handleAssignRider: (id: string, agent: string) => Promise<void>;
  requestId: string;
  agentId: string;
}) => {
  const [loading, setLoading] = useState(false);

  const onAssignRider = async (
    currentRequestId: string,
    currentAgentId: string
  ) => {
    try {
      setLoading(true);
      await handleAssignRider(currentRequestId, currentAgentId);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <ElrModal
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      height="h-38"
    >
      <div>
        <div className="text-center mx-4">
          <div className="w-16 h-16 rounded-full bg-elr-green flex items-center justify-center mx-auto text-white">
            <LogisticsBike fill="#FCCD33" />
          </div>
          <h5 className="text-elr-black text-xl mt-4">
            Confirm Courier Assignment
          </h5>
          <p className="text-elr-black text-opacity-60 text-sm mt-2">
            You are about to assign this courier to this delivery.
          </p>
          <p className="text-elr-black text-opacity-60 text-sm mt-2">
            Do you want to proceed?
          </p>
          <div className="flex justify-between items-center my-6">
            <ElrButton
              text="No, go back"
              size="sm"
              className="h-8 text-black bg-elr-gray-100 px-4 rounded-sm min-w-24"
              onClick={() => setIsModalOpen(false)}
            />
            <ElrButton
              text="Yes, assign"
              size="sm"
              disabled={loading}
              spinnerColor="Black"
              className="h-8 text-white bg-elr-black px-4 rounded-sm min-w-24"
              onClick={() => onAssignRider(requestId, agentId)}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </ElrModal>
  );
};

export const CancelRequestModal = ({
  isModalOpen,
  setIsModalOpen,
  handleCancelRequest,
  trackingId,
}: {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  handleCancelRequest: (id: string) => Promise<void>;
  trackingId: string;
}) => {
  const [loading, setLoading] = useState(false);

  const onCancelRequest = async (currentTrackingId: string) => {
    try {
      setLoading(true);
      await handleCancelRequest(currentTrackingId);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <ElrModal
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      height="h-38"
    >
      <div>
        <div className="text-center mx-4">
          <div className="w-16 h-16 rounded-full bg-elr-error flex items-center justify-center mx-auto text-white">
            <DeleteIcon fill="#FCCD33" />
          </div>
          <h5 className="text-elr-black text-xl mt-4">
            Confirm Request Cancellation
          </h5>
          <p className="text-elr-black text-opacity-60 text-sm mt-2">
            Cancelling this request will remove it from list of ongoing
            deliveries.
          </p>
          <p className="text-elr-black text-opacity-60 text-sm mt-2">
            Do you want to proceed?
          </p>
          <div className="flex justify-between items-center my-6">
            <ElrButton
              text="No, go back"
              size="sm"
              className="h-8 text-black bg-elr-gray-100 px-4 rounded-sm min-w-24"
              onClick={() => setIsModalOpen(false)}
            />
            <ElrButton
              text="Yes, cancel"
              size="sm"
              disabled={loading}
              className="h-8 text-white bg-elr-black px-4 rounded-sm min-w-24"
              onClick={() => onCancelRequest(trackingId)}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </ElrModal>
  );
};
