import SlideIn from 'app/components/SlideIn/SlideIn';
import {
  useGetServiceProduct,
  useGetServiceProducts,
  useGetServiceProfile,
} from 'app/hooks/requests/commerce';
import { useGetProfile } from 'app/hooks/user';
import { COMMERCE } from 'app/utilities/roles';
import { privateRoutes } from 'app/utilities/routes';
import {
  GetServiceProducts,
  ServiceOutletType,
} from 'app/utilities/types/shared';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { ElrButtonPreIcon } from 'ui-components/components/ElrButton';
import {
  BackIconWonWithBG,
  DeleteIcon,
  EditPencilIcon,
  PlusIcon,
  SearchIcon,
} from '../../SvgAssets';
import { MoreDropdown } from '../MoreDropdown';
import ProductFormFields from './ProductFormFields';

const AddedRestaurantProducts = () => {
  const navigate = useNavigate();
  const { id: userId } = useGetProfile();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { serviceProfile } = useGetServiceProfile(ServiceOutletType.restaurant);

  const { fetchServiceProducts, serviceProducts = [] } =
    useGetServiceProducts();
  const { fetchServiceProduct, serviceProduct } = useGetServiceProduct();

  const fetchProducts = useCallback(() => {
    fetchServiceProducts({
      outletType: ServiceOutletType.restaurant,
      outletTypeId: serviceProfile?.id || '',
    });
  }, [fetchServiceProducts, serviceProfile?.id]);

  useEffect(() => {
    if (serviceProfile?.id) {
      fetchProducts();
    }
  }, [fetchProducts, serviceProfile?.id]);

  const fetchSingleProduct = useCallback(
    (product: GetServiceProducts) => {
      fetchServiceProduct({
        productId: product?._id || '',
        outletType: ServiceOutletType.restaurant,
        outletTypeId: serviceProfile?.id || '',
      });
    },
    [fetchServiceProduct, serviceProfile?.id]
  );

  const onClickEditMenu = (product: GetServiceProducts) => {
    fetchSingleProduct(product);
  };

  return (
    <div className="bg-elr-gray flex flex-col pt-8 px-7 md:p-10 min-h-screen">
      <div className="flex flex-col gap-8">
        <div className="flex justify-between w-full align-middle">
          <section
            className="flex cursor-pointer items-center justify-center gap gap-x-2"
            onClick={() => navigate(-1)}
          >
            <BackIconWonWithBG />
            <p className="self-center">Back</p>
          </section>
          <Link
            to={`/${COMMERCE}/${privateRoutes.ecommerce}/${privateRoutes.restaurant}/${userId}/${privateRoutes.uploadRestaurantProducts}`}
          >
            <ElrButtonPreIcon
              text={'Add More Products'}
              icon={<PlusIcon fill="#fff" />}
              className="bg-elr-black text-elr-white rounded-md py-3 px-5"
            />
          </Link>
        </div>
        {isModalOpen && serviceProduct && (
          <SlideIn
            close={() => setIsModalOpen(false)}
            shown={isModalOpen}
            overflow
            leftMarginOffset="md:ml-[60%]"
          >
            <ProductFormFields
              initialMenuCategory={serviceProduct}
              isEditMode
              outletType="restaurant"
            />
          </SlideIn>
        )}
        <section className="bg-elr-white-400 mb-8 w-3/5">
          <div className="py-4 md:px-8 px-2 md:flex justify-between border-b-2 border-elr-gray">
            <div className="flex self-center text-lg md:gap-16 md:justify-between justify-around border-elr-black-200 relative">
              <p>Added Products</p>
            </div>
            <div className="flex gap-4 md:flex-row flex-col">
              <div className="md:flex justify-between gap-4 bg-elr-gray px-4 py-2 rounded-sm w-72 hidden">
                <input
                  type="text"
                  placeholder="search order ID or item"
                  className="outline-none w-full bg-elr-gray border-none focus:outline-0"
                />
                <SearchIcon />
              </div>
            </div>
          </div>

          <div>
            <table className="table-auto w-full">
              <thead>
                <tr className="border-b-2 border-elr-gray text-elr-black">
                  <th className="font-normal py-7 text-left pl-6 flex-grow ">
                    Main Menu
                  </th>
                  <th className="font-normal py-7 flex-grow">Category</th>
                  <th className="font-normal py-7 flex-grow">
                    Food Description
                  </th>
                  <th className="font-normal py-7 flex-grow">Sub Menu</th>
                  <th className="font-normal py-7 flex-grow">Price</th>
                  <th className="font-normal py-7 flex-grow" />
                </tr>
              </thead>
              <tbody>
                {serviceProducts?.map((product) => (
                  <TableRow
                    key={product._id}
                    product={product}
                    setIsModalOpen={setIsModalOpen}
                    onClickEditMenu={onClickEditMenu}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AddedRestaurantProducts;

interface TableRowProps {
  product: GetServiceProducts;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClickEditMenu: (product: GetServiceProducts) => void;
}

const TableRow: React.FC<TableRowProps> = ({
  product,
  setIsModalOpen,
  onClickEditMenu,
}) => {
  const moreOption = [
    {
      value: 'Edit Menu',
      label: 'Edit Menu',
      icon: <EditPencilIcon />,
      labelClassName: 'text-elr-green',
    },
    {
      value: 'Delete Menu',
      label: 'Delete Menu',
      icon: <DeleteIcon />,
      labelClassName: 'text-elr-error',
    },
  ];

  const handleSelectMoreOption = (
    selectedValue: string,
    productSelected: GetServiceProducts
  ) => {
    if (selectedValue === 'Delete Menu') {
      // To handle Delete Menu
    } else if (selectedValue === 'Edit Menu') {
      setIsModalOpen(true);
      onClickEditMenu(productSelected);
    }
  };
  return (
    <tr className="border-b border-elr-gray hover:bg-gray-100">
      <td className="py-5 px-5 text-center text-elr-black text-opacity-60 w-52">
        {product.item}
      </td>
      <td className="text-left py-5 pl-14 text-wrap w-52">
        {product.category}
      </td>
      <td className="py-5 px-5 text-center text-wrap text-elr-black text-opacity-60 w-52">
        {product.description}
      </td>
      <td className="py-5 px-5 text-center text-elr-black text-opacity-60 w-52">
        {product.subMenu}
      </td>
      <td className="py-5 px-5 text-center text-elr-black text-opacity-60 w-52">
        {product.price}
      </td>
      <td className="py-5 px-5 text-center text-elr-black text-opacity-60 w-52">
        <MoreDropdown
          options={moreOption}
          onSelect={(selectedValue) =>
            handleSelectMoreOption(selectedValue, product)
          }
          dropdownPosition="right"
          dropdownOptionClassName="hover:bg-elr-gray-100"
          dropdownClassName="mr-10"
          className="px-3 py-3 rounded-md bg-elr-white-400 text-elr-black"
        />
      </td>
    </tr>
  );
};
