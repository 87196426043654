import { useEffect, useRef, useState } from 'react';
import { ElrAccordion2 } from 'ui-components/components/ElrAccordion';

interface Question {
  id: number;
  header: string;
  content: string;
}

interface PopularQuestionsProps {
  questions: Question[];
  headingColor?: string;
  headingText?: string;
}

const PopularQuestions: React.FC<PopularQuestionsProps> = ({
  questions,
  headingColor = 'text-elr-purple',
  headingText = 'Popular Questions',
}) => {
  const [active, setActive] = useState<number>(0);
  const fieldRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (fieldRef.current) {
      fieldRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, []);

  return (
    <div className="mx-5 md:mx-0">
      <div className="max-w-6.5xl mx-auto flex flex-col bg-elr-gray rounded-lg py-12 md:py-16 px-4 md:px-28">
        <p
          className={`text-28 ${headingColor} pb-12 text-center md:text-40 w-full leading-tight font-bold`}
        >
          {headingText}
        </p>
        <div className="flex flex-col">
          {questions.map((question, index) => (
            <div key={question.id}>
              <ElrAccordion2
                setActive={setActive}
                index={question.id}
                active={question.id === active}
                transformPositionClass=""
                header={question.header}
              >
                <div className="">{question.content}</div>
              </ElrAccordion2>
              {index < questions.length - 1 && (
                <div className="border-t border-elr-gray-200 my-6" />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PopularQuestions;
