import { publicRoutes } from 'app/utilities/routes';
import DeliverFast from 'assets/images/deliver-fast.svg';
import ExpandReachIcon from 'assets/images/expand-reach.svg';
import React, { useEffect } from 'react';
import { ElrPageTitle } from 'ui-components';
import { FeatureBlock } from '../components/FeatureBlock';
import { Footer, Header } from './components';
import AboutUs from './components/AboutUs';
import HowItWorks from './components/HowItWorks';
import InfoCards from './components/InfoCards';
import Partners from './components/Partners';
import Testimonials from './components/Testimonials';

const Landing: React.FC = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  });
  return (
    <>
      <ElrPageTitle title="Errandlr - Home" />
      <div className="landing bg-white">
        <Header />
        <InfoCards />
        <Partners />
        <FeatureBlock
          imageSrc={DeliverFast}
          imageAlt="fast-delivery"
          headingText="Deliver with Ease, Get Paid Fast"
          descriptionText="Join Errandlr's trusted network of delivery partners and earn on your terms. Whether you're delivering food, parcels, or groceries, 
                    our seamless system ensures fair pricing and quick payouts. 
                    Sign up today and start earning effortlessly!"
          descriptionClassName="w-full text-elr-black text-opacity-55"
          layoutClassName="md:flex-row md:py-40 py-20"
          buttonText="Learn More"
          buttonLink={publicRoutes.riderPage}
        />
        <AboutUs />
        <FeatureBlock
          imageSrc={ExpandReachIcon}
          imageAlt="expand-reach"
          headingText="Expand Your Reach: Sell Your Products Online"
          descriptionText="Boost your business growth by tapping into a wider marketplace. Our intuitive platform empowers you to build an engaging online service,
           manage orders effortlessly, and gain real-time insights. 
           With secure payment processing and customizable business options,
          you can transform local opportunities into lasting global success."
          descriptionClassName="w-full text-elr-black text-opacity-55"
          layoutClassName="md:flex-row md:py-40 py-20"
          buttonText="Learn More"
          buttonLink={publicRoutes.businessPage}
        />
        <HowItWorks />
        <Testimonials />
        <Footer />
      </div>
    </>
  );
};
export default Landing;
