import { IDetectedBarcode, Scanner } from '@yudiel/react-qr-scanner';
import { BackIconWonWithBG } from 'app/components/SvgAssets';
import { useScanTicket } from 'app/hooks/experience';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { ElrButton } from 'ui-components';
import ErrorIcon from '../../../../assets/images/error-icon.svg';
import QrSuccessIcon from '../../../../assets/images/payment-success-logo.svg';
import QrCodeIcon from '../../../../assets/images/qr-code-search.svg';

const ExperienceQrScan = () => {
  const { scanTicket, loading, ticketValid } = useScanTicket();
  const [showScanner, setShowScanner] = useState(false);
  const [scanResult, setScanResult] = useState<string | null>(null);
  const [experienceData, setExperienceData] = useState<{
    experience: string;
    date: string;
    name: string;
    ticketType: string;
    numberOfTickets: string;
  } | null>(null);
  const [isExperience, setIsExperience] = useState(false);
  const navigate = useNavigate();

  useEffect(
    () =>
      showScanner
        ? () => {
            const timeout = setTimeout(() => {
              if (!scanResult) {
                setShowScanner(false);
              }
            }, 100000);

            return () => clearTimeout(timeout);
          }
        : () => {},
    [showScanner, scanResult]
  );

  const handleScan = async (detectedCodes: IDetectedBarcode[]) => {
    if (detectedCodes.length > 0) {
      const result = detectedCodes[0].rawValue;
      setScanResult(result);
      setShowScanner(false);

      try {
        const parsedResult = JSON.parse(result);
        const ticketId = parsedResult.id;

        if (
          parsedResult.experienceName &&
          parsedResult.experienceDate &&
          parsedResult.experienceUUId
        ) {
          setIsExperience(true);
          setExperienceData({
            experience: parsedResult.experienceName,
            date: parsedResult.experienceDate,
            name: parsedResult.experienceUUId,
            ticketType: parsedResult.type || 'Standard',
            numberOfTickets: parsedResult.numberOfTickets
              ? String(parsedResult.numberOfTickets)
              : '1',
          });
        } else if (
          parsedResult.experience &&
          parsedResult.date &&
          parsedResult.name &&
          parsedResult.type
        ) {
          setIsExperience(false);
          setExperienceData({
            experience: parsedResult.experience,
            date: parsedResult.date,
            name: parsedResult.name,
            ticketType: parsedResult.type,
            numberOfTickets: parsedResult.numberOfTickets
              ? String(parsedResult.numberOfTickets)
              : '1',
          });
        }
        if (ticketId) {
          await scanTicket(ticketId);
        }
      } catch (error) {
        // Handle error
      }
    }
  };

  const handleScanNow = () => {
    setShowScanner(true);
    setScanResult(null);
    setExperienceData(null);
  };

  const handleGoBack = () => {
    if (showScanner) {
      setShowScanner(false);
    } else {
      navigate(-1);
    }
  };

  const capitalize = (s: any) => s && s[0].toUpperCase() + s.slice(1);

  const renderTicketValidity = () => {
    if (loading) return <p>Loading...</p>;
    if (ticketValid === null)
      return <p className="text-elr-error">Ticket could not be validated</p>;
    return ticketValid.ok ? (
      <p>Valid</p>
    ) : (
      <p className="text-elr-error">Ticket used already</p>
    );
  };

  return (
    <div className="flex items-center justify-center w-full min-h-screen align-middle bg-elr-gray">
      <div className="flex flex-col items-center w-full p-10 bg-white md:w-4/12 rounded-2xl">
        {!scanResult && !showScanner && (
          <>
            <div className="flex items-center justify-center w-40 h-40 mb-8 rounded-full bg-elr-gray">
              <img src={QrCodeIcon} alt="qr code" />
            </div>
            <p className="pb-4 text-2xl text-elr-black">Scan QR Code</p>
            <p className="w-10/12 text-lg text-center text-elr-black-400">
              Streamline your experience check-in process by verifying attendees
              with a quick QR code scan
            </p>
            <div className="flex items-center justify-center w-full pt-12 align-middle">
              <ElrButton
                text="Scan Now"
                className="w-full text-white rounded-md bg-elr-black"
                onClick={handleScanNow}
              />
            </div>
          </>
        )}

        {showScanner && (
          <div className="w-full ">
            <section
              className="flex pb-6 cursor-pointer gap gap-x-2"
              onClick={handleGoBack}
            >
              <BackIconWonWithBG />
              <p className="self-center">Back</p>
            </section>
            <Scanner onScan={handleScan} />
            <ElrButton
              text="Scanning ..."
              className="w-full mt-6 text-white rounded-md bg-elr-black"
              disabled
              spinnerColor="White"
            />
          </div>
        )}

        {!showScanner && scanResult && experienceData && (
          <div className="w-full">
            <div className="flex flex-col justify-center">
              <div className="flex items-center justify-center w-full h-20 mb-8 align-middle">
                <img src={QrSuccessIcon} alt="qr-success" />
              </div>
              <p className="mb-6 text-2xl text-center text-elr-black">
                {isExperience
                  ? 'Experience Information'
                  : 'Attendee Information'}
              </p>
              <div className="flex flex-col gap-6">
                <section className="flex-col md:flex md:flex-row">
                  <p className="w-1/2 pb-1 text-elr-black-300">Name</p>
                  <p className="md:w-1/2 w-9/10">{experienceData.name}</p>
                </section>
                <section className="flex-col md:flex md:flex-row">
                  <p className="w-1/2 pb-1 text-elr-black-300">
                    Experience Name
                  </p>
                  <p className="md:w-1/2 w-9/10">{experienceData.experience}</p>
                </section>
                <section className="flex-col md:flex md:flex-row">
                  <p className="w-1/2 pb-1 text-elr-black-300">
                    Date Purchased
                  </p>
                  <p className="md:w-1/2 w-9/10">{experienceData.date}</p>
                </section>
                <section className="flex-col md:flex md:flex-row">
                  <p className="w-1/2 pb-1 text-elr-black-300">
                    Number of Tickets
                  </p>
                  <p className="md:w-1/2 w-9/10">
                    {experienceData.numberOfTickets}
                  </p>
                </section>
                <section className="flex-col md:flex md:flex-row">
                  <p className="w-1/2 pb-1 text-elr-black-300">Ticket Type</p>
                  <p className="md:w-1/2 w-9/10">
                    {capitalize(experienceData.ticketType)}
                  </p>
                </section>
                <section className="flex-col md:flex md:flex-row">
                  <p className="w-1/2 pb-1 text-elr-black-300">
                    Ticket Validity
                  </p>
                  {renderTicketValidity()}
                </section>
              </div>
              <div className="flex items-center justify-center w-full pt-12 align-middle">
                <ElrButton
                  text="Scan Another Code"
                  className="w-full text-white rounded-md bg-elr-black"
                  onClick={handleScanNow}
                />
              </div>
            </div>
          </div>
        )}

        {!showScanner && scanResult && !experienceData && (
          <>
            <div className="flex items-center justify-center mb-8">
              <img src={ErrorIcon} alt="error-icon" />
            </div>
            <p className="pb-4 text-2xl text-elr-black">Invalid QR Code</p>
            <p className="w-8/12 text-lg text-center text-elr-black-400">
              The QR code could not be verified. Please try again
            </p>
            <div className="flex items-center justify-center w-full pt-12 align-middle">
              <ElrButton
                text="Scan Again"
                className="w-full text-white rounded-md bg-elr-black"
                onClick={handleScanNow}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ExperienceQrScan;
