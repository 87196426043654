import SlideIn from 'app/components/SlideIn/SlideIn';
import {
  MoneyIcon,
  PencilIcon,
  PlusIcon,
  SearchIcon,
} from 'app/components/SvgAssets';
import {
  getDropdownIconClassNames,
  getRequestStatusClassNames,
  getTabClassNames,
} from 'app/dispatcher/modules/components/ClassUtils';
import {
  ArchiveDrawerWithBG,
  ProfileIconWithBG,
} from 'app/operator/components/SvgAssets';
import { currencyFormatter, getFirstItem } from 'app/utilities/helpers';
import { privateRoutes } from 'app/utilities/routes';
import {
  Request,
  RequestTypes,
  ServiceOutletType,
} from 'app/utilities/types/shared';
import { truncate } from 'lodash';
import { DateTime } from 'luxon';
import { Fragment, useEffect, useRef, useState } from 'react';
import { ElrInput, ElrLocationSelect, ElrPhoneInputFlat } from 'ui-components';
import {
  ElrButton,
  ElrButtonPreIcon,
} from 'ui-components/components/ElrButton';
import TimePicker from 'ui-components/components/ErlTimePicker';
import { useNavigate } from 'react-router';
import { COMMERCE } from 'app/utilities/roles';
import { useGetProfile } from 'app/hooks/user';
import { useGetServiceProfile } from 'app/hooks/requests/commerce';
import Dropdown from '../../../../assets/images/dropdown.svg';
import { DashboardCards } from '../components/DashboardCards';
import {
  SalesTurnOffModal,
  UpdateProductModal,
  ViewProductsModal,
} from './MarketModals';
import { addedProducts, marketTransaction } from './mock';
import UploadDashboard from '../UploadDashboard';

/* eslint-disable-next-line no-shadow */
enum TabNames {
  NEW_ORDERS = 'New Orders',
  ONGOING = 'Ongoing',
  COMPLETED = 'Completed',
}

const MarketDashboard = () => {
  const navigate = useNavigate();
  const { id: userId } = useGetProfile();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isSalesModalOpen, setIsSalesModalOpen] = useState<boolean>(false);
  const [isViewProductsModalOpen, setIsViewProductsModalOpen] =
    useState<boolean>(false);
  const [isUpdateProductModalOpen, setIsUpdateProductModalOpen] =
    useState<boolean>(false);
  const [activeTab, setActiveTab] = useState(TabNames.NEW_ORDERS);
  const [underlinePosition, setUnderlinePosition] = useState({
    left: 0,
    width: 0,
  });
  const newOrdersTabRef = useRef<HTMLButtonElement | null>(null);
  const ongoingTabRef = useRef<HTMLButtonElement | null>(null);
  const { isLoading } = useGetServiceProfile(ServiceOutletType.market);
  const completedTabRef = useRef<HTMLButtonElement | null>(null);
  const [isExpanded, setIsExpanded] = useState<number | null>(null);
  const tabs = [
    { name: TabNames.NEW_ORDERS, ref: newOrdersTabRef },
    { name: TabNames.ONGOING, ref: ongoingTabRef },
    { name: TabNames.COMPLETED, ref: completedTabRef },
  ];

  const toggleRow = (index: number) => {
    setIsExpanded(isExpanded === index ? null : index);
  };

  useEffect(() => {
    const activeTabRef = tabs.find((tab) => tab.name === activeTab)?.ref
      .current;
    if (activeTabRef) {
      const activeTabWidth = activeTabRef.offsetWidth;
      setUnderlinePosition({
        left: activeTabRef.offsetLeft + (activeTabWidth - 110) / 2,
        width: 110,
      });
    }
  }, [activeTab]);

  const deactivateSales = false;

  const filteredDocuments: any[] = []; // change this to the actual data type
  const cardData = [
    {
      title: 'Total Sales',
      count: currencyFormatter(0),
      Icon: <MoneyIcon />,
    },
    {
      title: 'Total Added Products',
      count: 0,
      Icon: <ArchiveDrawerWithBG />,
    },
    {
      title: 'Total Orders',
      count: 0,
      Icon: <ProfileIconWithBG />,
    },
  ];

  const [orderStatuses, setOrderStatuses] = useState<Record<string, boolean>>(
    {}
  );
  const handleOrderReady = (trackingId: string) => {
    setOrderStatuses((prev) => ({
      ...prev,
      [trackingId]: true,
    }));
  };

  const renderEditInfo = (
    <SlideIn
      close={() => setIsModalOpen(false)}
      shown={isModalOpen}
      leftMarginOffset="md:ml-[60%]"
    >
      <p className="text-center pb-6 text-xl">Edit Business Information</p>
      <form>
        <div className="w-full py-8 mx-auto mb-10 bg-elr-white-400">
          <div className="flex flex-col gap-6">
            <div className="w-full">
              <p className="text-base pb-2 text-elr-black-400">
                Business Address
              </p>
              <div className="h-12">
                <ElrLocationSelect
                  className="pr-2"
                  controlHeight={44}
                  currentValue=""
                  placeholder="Address"
                  onChange={() => {}}
                />
              </div>
            </div>
            <div className="w-full">
              <p className="text-base pb-2 text-elr-black-400">Phone Number</p>
              <ElrPhoneInputFlat
                containerClass="w-full flex !mb-0"
                className="border-none bg-elr-gray h-12"
                onChange={() => {}}
                placeholder="Phone Number"
              />
            </div>
            <div className="w-full">
              <p className="text-base pb-2 text-elr-black-400">Email Address</p>
              <ElrInput
                onChange={() => {}}
                name="businessEmail"
                className="border-none rounded-none bg-elr-gray !h-12 outline-none"
                placeholder="Email Address"
              />
            </div>
            <div className="w-full">
              <p className="text-base pb-2 text-elr-black-400">Opening time</p>
              <div className="flex items-center justify-between w-full h-12 bg-elr-gray">
                <TimePicker
                  className="w-full bg-elr-gray pr-4"
                  placeholder="Opening Time"
                  clearIcon={null}
                  onChange={() => {}}
                />
              </div>
            </div>
            <div className="w-full">
              <p className="text-base pb-2 text-elr-black-400">Closing Time</p>
              <div className="flex items-center justify-between w-full h-12 bg-elr-gray">
                <TimePicker
                  className="w-full bg-elr-gray pr-4"
                  placeholder="Closing Time"
                  clearIcon={null}
                  onChange={() => {}}
                />
              </div>
            </div>
          </div>
        </div>
        <ElrButton
          text="Save Changes"
          className="w-full bg-elr-black text-white rounded-md text-base mt-24"
          submit
          disabled
        />
      </form>
    </SlideIn>
  );

  const _renderLoadState = () => null;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!filteredDocuments.length) {
    return <UploadDashboard outletType={ServiceOutletType.market} />;
  }

  const handleMoreProducts = () => {
    navigate(
      `/${COMMERCE}/${privateRoutes.ecommerce}/${privateRoutes.market}/${userId}/${privateRoutes.uploadMarketProducts}`
    );
  };

  return (
    <div className="bg-elr-gray flex flex-col pt-8 px-7 md:pt-3 md:px-20 min-h-screen">
      <section className="md:flex md:flex-row flex-col items-center pt-6 justify-between pb-8">
        <div className=" text-elr-black font-normal font-serif leading-tight">
          <h3 className="text-xl mb-2">E-commerce</h3>
          <p className="text-elr-black-300 text-base">
            View and follow up your products and sales
          </p>
        </div>
        {isSalesModalOpen && (
          <SalesTurnOffModal
            isOpen={isSalesModalOpen}
            onClose={() => setIsSalesModalOpen(false)}
            onClick={() => {}}
            loading={false}
          />
        )}
        {isUpdateProductModalOpen && (
          <UpdateProductModal
            isOpen={isUpdateProductModalOpen}
            onClose={() => setIsUpdateProductModalOpen(false)}
            onClick={() => {}}
            loading={false}
          />
        )}
        {isViewProductsModalOpen && (
          <ViewProductsModal
            isOpen={isViewProductsModalOpen}
            onClose={() => setIsViewProductsModalOpen(false)}
            onClick={() => {}}
            loading={false}
            products={addedProducts}
          />
        )}
        <div className="flex flex-wrap gap-4 items-center md:pt-0 pt-8">
          {!deactivateSales ? (
            <ElrButton
              text={'Deactivate Sales'}
              disabled={false}
              loading={false}
              onClick={() => setIsSalesModalOpen(true)}
              className="bg-elr-white-400 text-elr-error rounded-sm py-3 px-5"
            />
          ) : (
            <ElrButton
              text={'Sales Deactivated'}
              disabled
              onClick={() => {}}
              className="bg-elr-gray text-success rounded-md py-3 px-5"
            />
          )}

          {isModalOpen && renderEditInfo}

          <ElrButtonPreIcon
            text={'Edit Market Profile'}
            icon={<PencilIcon fill="#081120" />}
            onClick={() => setIsModalOpen(true)}
            className="bg-elr-white-400 text-elr-black rounded-md py-3 px-5"
          />
          <ElrButtonPreIcon
            text={'Add More Products'}
            icon={<PlusIcon fill="#fff" />}
            onClick={handleMoreProducts}
            className="bg-elr-black text-elr-white rounded-md py-3 px-5"
          />
        </div>
      </section>
      <DashboardCards data={cardData} />
      <section className="bg-elr-white-400 mb-8">
        <div className="py-4 md:px-8 px-2 md:flex justify-between border-b-2 border-elr-gray">
          <div className="flex self-center text-lg md:gap-16 md:justify-between justify-around border-elr-black-200 relative">
            {tabs.map((tab) => (
              <button
                key={tab.name}
                type="button"
                ref={tab.ref}
                className={getTabClassNames(activeTab === tab.name)}
                onClick={() => setActiveTab(tab.name)}
              >
                {tab.name}
              </button>
            ))}
            <div
              className={`rounded-t-lg h-1 bg-elr-black absolute transition-all duration-300 ease-in-out`}
              style={{
                width: `${underlinePosition.width}px`,
                left: `${underlinePosition.left}px`,
                bottom: '-24px',
              }}
            />
          </div>
          <div className="flex gap-4 md:flex-row flex-col">
            <div className="md:flex justify-between gap-4 bg-elr-gray px-4 py-2 rounded-sm w-72 hidden">
              <input
                type="text"
                placeholder="search order ID or item"
                className="outline-none w-full bg-elr-gray border-none focus:outline-0"
              />
              <SearchIcon />
            </div>
            <ElrButton
              text={'View Products'}
              onClick={() => setIsViewProductsModalOpen(true)}
              className="bg-elr-black text-white rounded-md py-3 px-5"
            />
          </div>
        </div>

        <div className="overflow-x-auto w-full relative">
          <table className="table-auto w-full">
            <thead>
              <tr className="border-b-2 border-elr-gray text-elr-black">
                <th className="font-normal py-7 text-left md:pl-20 pl-6 flex-grow ">
                  Order ID
                </th>
                <th className="font-normal py-7 text-center md:w-1/3 flex-grow truncate">
                  Order Details
                </th>
                <th className="font-normal py-7 text-center flex-grow">
                  Amount
                </th>
                {activeTab === TabNames.COMPLETED && (
                  <th className="font-normal py-7 text-center flex-grow">
                    Date & Time
                  </th>
                )}
                <th className="font-normal py-7 text-right flex-grow " />
              </tr>
            </thead>
            <tbody>
              {filteredDocuments.length
                ? filteredDocuments.map((request: Request, index: number) => (
                    <Fragment key={request.trackingId}>
                      <TableRow
                        request={request}
                        activeTab={activeTab}
                        index={index}
                        toggleRow={toggleRow}
                        isExpanded={isExpanded}
                        isOrderReady={
                          orderStatuses[request.trackingId] || false
                        }
                      />
                      {isExpanded === index && (
                        <ExpandedRow
                          request={request}
                          activeTab={activeTab}
                          isOrderReady={
                            orderStatuses[request.trackingId] || false
                          }
                          onOrderReady={() =>
                            handleOrderReady(request.trackingId)
                          }
                        />
                      )}
                    </Fragment>
                  ))
                : _renderLoadState()}
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
};

export default MarketDashboard;

interface TableRowProps {
  request: Request;
  index: number;
  activeTab: TabNames;
  toggleRow: (index: number) => void;
  isExpanded: number | null;
  isOrderReady: boolean;
}

export const TableRow: React.FC<TableRowProps> = ({
  request,
  index,
  toggleRow,
  isExpanded,
  isOrderReady,
  activeTab,
}) => {
  const { trackingId, priceEstimate, senderInformation, status } = request;

  const formatDateTime = (createdAt: string) => {
    const dateTime = DateTime.fromISO(createdAt);
    return dateTime.toFormat('yyyy-LL-dd HH:mm a');
  };

  const getButtonText = () => {
    if (isExpanded === index) {
      return 'Minimize';
    }
    return 'View Order';
  };

  return (
    <tr
      className="text-center border border-x-0 border-elr-gray-500 cursor-pointer"
      onClick={() => toggleRow(index)}
    >
      <td className="text-left py-5 pl-8 text-elr-black truncate ">
        <div className="truncate">{trackingId}</div>
        {activeTab !== TabNames.COMPLETED && (
          <div className="text-elr-black-300 text-sm pt-1">
            {formatDateTime(request.createdAt)}
          </div>
        )}
      </td>
      <td className="py-5 px-5 text-center text-elr-black text-opacity-60 truncate max-w-[150px] md:max-w-xs overflow-hidden">
        {truncate(senderInformation?.data?.name || 'N/A', { length: 20 })}
      </td>
      <td className="py-5 text-center text-elr-black text-opacity-60">
        {currencyFormatter(priceEstimate)}
      </td>
      {activeTab === TabNames.COMPLETED && (
        <td className="py-5 px-5 text-center text-elr-black text-opacity-60">
          {formatDateTime(request.createdAt)}
        </td>
      )}

      {activeTab === TabNames.ONGOING && (
        <td className="py-5 px-5 text-center text-elr-black w-60">
          {isOrderReady ? (
            <td className="text-center text-elr-black text-opacity-60">
              <p className={getRequestStatusClassNames(status)}>
                {status === RequestTypes.accepted
                  ? 'Awaiting Pickup'
                  : 'Awaiting Delivery'}
              </p>
            </td>
          ) : (
            <ElrButton
              text="Order is Ready"
              className="bg-elr-gray rounded-sm"
            />
          )}
        </td>
      )}

      {activeTab === TabNames.NEW_ORDERS && (
        <td className="py-5 px-5 text-center text-elr-black w-52">
          <button
            className="focus:outline-none flex justify-center gap-4 align-middle items-center px-6 py-3 w-full bg-elr-gray rounded-sm"
            type="button"
          >
            {getButtonText()}
            <img
              src={Dropdown}
              alt="Dropdown"
              className={getDropdownIconClassNames(isExpanded === index)}
            />
          </button>
        </td>
      )}
      {activeTab !== TabNames.NEW_ORDERS && (
        <td className="py-5 relative w-10">
          <button className="focus:outline-none" type="button">
            <img
              src={Dropdown}
              alt="Dropdown"
              className={getDropdownIconClassNames(isExpanded === index)}
            />
          </button>
        </td>
      )}
    </tr>
  );
};

interface ExpandedRowProps {
  request: Request;
  activeTab: TabNames;
  isOrderReady: boolean;
  onOrderReady: () => void;
}
export const ExpandedRow: React.FC<ExpandedRowProps> = ({
  request,
  activeTab,
  isOrderReady,
  onOrderReady,
}) => {
  const { deliverToInformation, designatedAgent } = request;
  const recipientInfo = getFirstItem(deliverToInformation);

  const riderName =
    designatedAgent?.firstname || designatedAgent?.lastname
      ? `${designatedAgent.firstname || ''} ${designatedAgent.lastname || ''}`
      : 'N/A';
  const riderPhone = designatedAgent?.phone || 'N/A';

  return (
    <tr className="text-left">
      <td colSpan={5} className="p-5 text-elr-black">
        <div className="flex flex-col gap-6">
          <div className="flex w-full p-8 bg-elr-gray md:gap-24 gap-12">
            <div className="flex flex-col w-1/4">
              <p>Recipient Details</p>
              <p className="text-elr-black text-opacity-60 py-1">
                {recipientInfo?.address.fullAddress || 'N/A'}
              </p>
              <p className="text-elr-black text-opacity-60">
                {recipientInfo?.phone || 'N/A'}
              </p>
            </div>

            <div className="flex flex-col w-1/3">
              <p>Order Details</p>
              <div className="flex flex-col gap-2 pt-2">
                {marketTransaction.market.items.map((item) => (
                  <div
                    key={item.id}
                    className="text-elr-black text-opacity-60 flex justify-between"
                  >
                    <p className="w-1/3">{item.name}</p>
                    <p className="w-1/4 text-center">{item.quantity}</p>
                    <p className="w-1/4 text-right">
                      {currencyFormatter(item.price)}
                    </p>
                  </div>
                ))}
                <div className="text-elr-black flex justify-between pt-2">
                  <p className="w-1/2">Total</p>
                  <p className="w-1/4 text-center" />
                  <p className="w-1/4 text-right">
                    {currencyFormatter(marketTransaction.market.totalAmount)}
                  </p>
                </div>
              </div>
            </div>
            {activeTab === TabNames.NEW_ORDERS && (
              <div className="flex flex-col gap-4">
                <ElrButton
                  text="Decline Order"
                  onClick={() => {}}
                  size="sm"
                  className="bg-white text-elr-error rounded-md px-4 py-2"
                />
                <ElrButton
                  text="Accept Order"
                  onClick={() => {}}
                  size="sm"
                  className="bg-elr-black text-white rounded-md px-4 py-2"
                />
              </div>
            )}

            {activeTab === TabNames.ONGOING && (
              <div>
                {!isOrderReady ? (
                  <div className="flex flex-col flex-1">
                    <p className="text-elr-black text-opacity-60 py-1 w-9/12">
                      Rider details will appear once you confirm the order is
                      ready.
                    </p>
                    <div className="pt-10">
                      <ElrButton
                        text="Order is Ready"
                        size="sm"
                        onClick={onOrderReady}
                        className="bg-elr-black text-white rounded-md px-4 py-2"
                      />
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col flex-1">
                    <p>Rider Details</p>
                    <p className="text-elr-black text-opacity-60 py-1">
                      {riderName}
                    </p>
                    <p className="text-elr-black text-opacity-60 py-1">
                      {riderPhone}
                    </p>
                  </div>
                )}
              </div>
            )}

            {activeTab === TabNames.COMPLETED && (
              <div className="flex flex-col">
                <p>Rider Details</p>
                <p className="text-elr-black text-opacity-60 py-1">
                  {riderName}
                </p>
                <p className="text-elr-black text-opacity-60 py-1">
                  {riderPhone}
                </p>
              </div>
            )}
          </div>
        </div>
      </td>
    </tr>
  );
};
