import React from 'react';
import { ElrButton } from 'ui-components';

interface FeatureSectionProps {
  title: string;
  description: string;
  buttonText?: string;
  buttonAction?: () => void;
  imageSrc: string;
  imageClassName?: string;
  imageAlt: string;
  gradients: {
    mainBackground: string;
    leftBackground: string;
    rightBackground: string;
  };
  rightBorderRadius?: string;
  leftBorderRadius?: string;
  buttons?: {
    text: string;
    imageSrc?: string;
    action: () => void;
  }[];
  reverseLayout?: boolean;
  gradientReverseLayout?: boolean;
}

const FeatureSection: React.FC<FeatureSectionProps> = ({
  title,
  description,
  buttonText,
  buttonAction,
  imageSrc,
  imageAlt,
  gradients,
  rightBorderRadius = 'rounded-tr-2xl rounded-br-2xl',
  leftBorderRadius = 'rounded-tl-2xl rounded-bl-2xl',
  buttons,
  imageClassName = 'md:max-w-sm',
  reverseLayout = false,
  gradientReverseLayout = false,
}) => (
  <div className="relative flex justify-center items-center">
    <div className="md:h-600 h-700 py-10 box-decoration-clone">
      <div
        className={`absolute z-10 left-0 md:h-447 h-full w-full top-1/2 transform -translate-y-1/2 ${gradients.mainBackground}`}
      />
      <div
        className={`hidden md:block absolute z-20 ${
          gradientReverseLayout ? 'right-0' : 'left-0'
        } w-1/2 h-540 top-1/2 transform -translate-y-1/2 ${
          gradients.leftBackground
        } ${leftBorderRadius}`}
      />
      <div
        className={`hidden md:block absolute z-0 ${
          gradientReverseLayout ? 'left-0' : 'right-0'
        } w-7/12 h-600 top-1/2 transform -translate-y-1/2 ${
          gradients.rightBackground
        } ${rightBorderRadius}`}
      />
    </div>
    <div className="relative z-30 mx-auto max-w-6.5xl py-24 md:py-2">
      <div
        className={`flex-col-reverse md:flex flex ${
          reverseLayout ? 'md:flex-row-reverse' : 'md:flex-row'
        } md:gap-20 gap-10 justify-center items-center mx-5 md:mx-5`}
      >
        <div className="flex flex-col">
          <h2 className="text-28 md:text-40 w-full leading-tight font-bold text-elr-white">
            {title}
          </h2>
          <p className="pt-6 pb-14 text-elr-white text-opacity-55 md:text-2xl text-lg w-11/12">
            {description}
          </p>
          {buttonText && buttonAction && (
            <ElrButton
              text={buttonText}
              className="bg-elr-white text-elr-black w-fit px-8 py-3"
              onClick={buttonAction}
            />
          )}
          {buttons && (
            <div className="relative flex items-center gap-6">
              {buttons.map((btn) => (
                <button
                  key={btn.text}
                  onClick={btn.action}
                  type="button"
                  className="cursor-pointer h-12"
                >
                  <div className="flex items-center h-full">
                    {btn.imageSrc ? (
                      <img
                        src={btn.imageSrc}
                        alt={btn.text}
                        className="h-full object-contain"
                      />
                    ) : (
                      btn.text
                    )}
                  </div>
                </button>
              ))}
            </div>
          )}
        </div>

        <div className="flex w-full justify-center items-center align-middle">
          <img
            src={imageSrc}
            alt={imageAlt}
            className={`w-full md:w-auto mx-auto ${imageClassName}`}
          />
        </div>
      </div>
    </div>
  </div>
);

export default FeatureSection;
