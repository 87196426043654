import { useEffect } from 'react';
import { ElrPageTitle } from 'ui-components';
import linkAccountMockup from 'assets/images/link-account-mockup.svg';
import { useNavigate } from 'react-router';
import generateInvoiceMockup from 'assets/images/generate-invoice-mockup.png';
import { publicRoutes } from 'app/utilities/routes';
import PopularQuestions from '../components/PopularQuestions';
import { Footer } from '../landing/components';
import PoliciesAppBanner from '../landing/components/PoliciesAppBanner';
import { FeatureBlock } from '../components/FeatureBlock';
import MoreVisibility from './components/MoreVisibility';
import FeatureSection from '../components/FeatureSection';
import Header from './components/Header';

const BusinessPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  });

  const questions = [
    {
      id: 1,
      header: 'What do i need to start using Errandlr Business?',
      content:
        'To start using Errandlr Business, you need to download the Errandlr app, sign up, and complete the verification process. You would be required to provide your BVN and Company CAC RC number.',
    },
    {
      id: 2,
      header:
        'Is there a limit to how much i can send or receive on my Errandlr Business account?',
      content:
        'There is no limit to how much you can send or receive on your Errandlr Business account.',
    },
    {
      id: 3,
      header: 'Can i start selling my services on Errandlr?',
      content:
        'Yes and No. You can start selling your services on Errandlr to your customers by sending an invoice. To sell on the Errandlr Marketplace, you need to be verified by Errandlr.',
    },
  ];

  return (
    <div className="bg-white">
      <ElrPageTitle title="Errandlr - Business" />
      <Header />
      <MoreVisibility />
      <FeatureSection
        title="Generate Invoice For Your Customers with Ease"
        description="Create and share invoices to your customers and keep track of payments seamlessly with our invoice creation tool."
        imageSrc={generateInvoiceMockup}
        imageAlt="Errandlr Generate Invoice Screen"
        imageClassName="md:max-w-lg"
        gradients={{
          mainBackground:
            'bg-gradient-to-b box-decoration-clone from-elr-black via-elr-black to-elr-black',
          leftBackground:
            'bg-gradient-to-b box-decoration-clone from-elr-black via-elr-black to-elr-black',
          rightBackground: 'bg-elr-gray',
        }}
        rightBorderRadius="rounded-tl-2xl rounded-bl-2xl"
        leftBorderRadius="rounded-tr-2xl rounded-br-2xl"
        buttonText="Get Started"
        buttonAction={() => navigate(publicRoutes.registration)}
        reverseLayout
      />
      <FeatureBlock
        imageSrc={linkAccountMockup}
        imageAlt="link-and-manage-your-accounts"
        headingText="Link and Manage your Accounts on-the-go"
        layoutClassName="md:flex-row md:py-40 py-28 flex-col"
        descriptionText="Easily connect your business wallet to the mobile app and seamlessly manage both personal and business transactions anytime, anywhere."
        buttonText="Get Started"
        buttonLink={publicRoutes.registration}
      />
      <PopularQuestions questions={questions} headingColor="text-elr-black" />
      <div className="max-w-6.5xl md:mx-auto mx-5">
        <PoliciesAppBanner />
      </div>
      <Footer />
    </div>
  );
};

export default BusinessPage;
