import { useQueryClient } from '@tanstack/react-query';
import { httpTrackParcel } from 'app/api/trackParcel';
import { TopNav } from 'app/components/menu/Navbar';
import { publicRoutes } from 'app/utilities/routes';
import React, { FormEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ElrButton, ElrInput, ElrPageTitle } from 'ui-components';
import PoliciesAppBanner from '../landing/components/PoliciesAppBanner';
import { Footer } from '../landing/components';

const TrackParcel: React.FC = () => {
  const queryClient = useQueryClient();
  const history = useNavigate();
  const [trackId, setTrackId] = useState('');
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  });

  const onSubmit = async (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    try {
      setLoading(true);
      await queryClient.fetchQuery({
        queryKey: ['trackparcel'],
        queryFn: () => httpTrackParcel(trackId),
      });
      setLoading(false);
      return history(`${publicRoutes.trackParcelUpdate}?trackingId=${trackId}`);
    } catch (error) {
      setLoading(false);
      return error;
    }
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setTrackId(e.target.value);

  return (
    <div className="bg-white">
      <ElrPageTitle title="Errandlr - Track your Parcel" />
      <TopNav isDefault />
      <div className="flex flex-col md:max-w-6.5xl md:mx-auto md:flex-col mx-5">
        <div className="w-full bg-elr-gray rounded-md md:py-20 md:px-80 px-6 py-12 md:mt-20 mt-12 flex justify-center flex-col items-center">
          <p className="text-2xl font-semibold mb-6">Track Parcel</p>
          <div className="flex flex-col items-center md:w-480 w-full border rounded-md p-8 bg-elr-white-400 border-elr-gray-200 border-opacity-30">
            <div className="w-full">
              <div className="text-lg">
                <p className="text-elr-black text-opacity-60 md:pb-0 pb-8 w-11/12">
                  Enter your parcel ID to check its delivery status or{' '}
                  <span>
                    <a href={publicRoutes.issues} className="text-elr-purple">
                      submit a query
                    </a>
                  </span>{' '}
                  for any issue.
                </p>
              </div>
              <form onSubmit={onSubmit}>
                <div className="md:mt-5">
                  <ElrInput
                    className="h-10 border-none rounded-none outline-none bg-elr-gray mb-10 md:mb-7"
                    placeholder="Enter parcel ID"
                    value={trackId}
                    required
                    onChange={onInputChange}
                  />
                  <div className="flex items-end justify-end">
                    <ElrButton
                      text="Track parcel"
                      className="w-44 text-lg text-white bg-elr-black"
                      disabled={!trackId.trim()}
                      loading={loading}
                      spinnerColor="White"
                      submit
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <PoliciesAppBanner />
      </div>
      <Footer hideTopFooter />
    </div>
  );
};

export default TrackParcel;
