import { httpScanTicket } from 'app/api/commerce';
import {
  httpCloseExperience,
  httpFetchExperienceCategories,
  httpGetExperience,
  httpGetExperienceTicketLeft,
  httpGetListSoldTickets,
  httpGetTicketSales,
  httpPurchaseTicket,
  httpToggleTicket,
} from 'app/api/experience';
import {
  ExperienceDataInterface,
  ListSoldTicketsParams,
  PurchaseTicketPayload,
  ScanTicketResponse,
  SoldTicketsResponse,
  TicketSalesDataInterface,
} from 'app/api/types';
import { ExperienceSession } from 'app/components/experiences/utils';
import { ExperienceCategory } from 'app/utilities/types/shared';
import { useCallback, useEffect, useState } from 'react';

export const useManageTicket = (
  experienceUUId: string,
  experienceId?: string,
  ticket?: ExperienceDataInterface['tickets'][number]
) => {
  const [updatedTicket, setUpdatedTicket] = useState<
    | (ExperienceDataInterface['tickets'][number] & {
        quantityRequested: number;
      })
    | null
  >(null);

  const getUpdatedTicket = () => {
    if (!ticket) return;
    if (!ExperienceSession(experienceUUId).getSession()?.tickets?.[ticket.id]) {
      ExperienceSession(experienceUUId).setSession({
        experienceId,
        tickets: {
          ...(ExperienceSession(experienceUUId).getSession()?.tickets || {}),
          [ticket.id]: { ...ticket, quantityRequested: 0 },
        },
      });
    }
    setUpdatedTicket(
      ExperienceSession(experienceUUId).getSession()?.tickets?.[ticket.id]
    );
  };

  const setQuantity = (quantity: number) => {
    if (!ticket) return;
    ExperienceSession(experienceUUId).setSession({
      experienceId,
      tickets: {
        ...(ExperienceSession(experienceUUId).getSession()?.tickets || {}),
        [ticket.id]: { ...ticket, quantityRequested: quantity },
      },
    });
    getUpdatedTicket();
  };

  const getTotalQuantities = () => {
    const tickets: {
      [key: string]: ExperienceDataInterface['tickets'][number] & {
        quantityRequested: number;
      };
    } = ExperienceSession(experienceUUId).getSession()?.tickets || {};
    const totalQuantityRequested = Object.values(tickets).reduce(
      (acc, t) => acc + (t?.quantityRequested || 0),
      0
    );
    return totalQuantityRequested;
  };

  const getRequestedTickets = () => {
    const tickets: (ExperienceDataInterface['tickets'][number] & {
      quantityRequested: number;
    })[] = ExperienceSession(experienceUUId).getSession()?.tickets
      ? Object.values(ExperienceSession(experienceUUId).getSession()?.tickets)
      : [];
    return {
      tickets,
      experienceId:
        ExperienceSession(experienceUUId).getSession()?.experienceId,
    };
  };

  useEffect(() => {
    getUpdatedTicket();
  }, []);

  return {
    quantity: updatedTicket?.quantityRequested || 0,
    setQuantity,
    getTotalQuantities,
    getRequestedTickets,
  };
};

export const usePurchaseTicket = () => {
  const purchaseTicket = useCallback(
    async (ticketData: PurchaseTicketPayload) => {
      try {
        const response = await httpPurchaseTicket(ticketData);
        return response.data; // Adjust based on the response structure
      } catch (error) {
        console.error('Error purchasing ticket:', error);
        throw error; // Rethrow the error for handling in the component
      }
    },
    []
  );

  return { purchaseTicket };
};

export const useFetchExperience = (
  experienceUUId: string,
  setExperience: (experience: ExperienceDataInterface | null) => void
) => {
  const getExperience = useCallback(async () => {
    const ev = await httpGetExperience({ experienceUUId });
    if (ev) setExperience(ev);
  }, [experienceUUId, setExperience]);

  return { getExperience };
};

export const useCloseExperience = () => {
  const closeExperience = useCallback(
    async (experienceId: string, roleId: string) => {
      const ev = await httpCloseExperience({ experienceId, roleId });
      return ev;
    },
    []
  );

  return { closeExperience };
};

export const useFetchTicketSales = () => {
  const fetchTicketSales = useCallback(
    async (
      experienceId: string,
      setTicketSales: (sales: TicketSalesDataInterface[] | null) => void
    ) => {
      try {
        const sales = await httpGetTicketSales({ experienceId });
        setTicketSales(sales);
      } catch (error) {
        setTicketSales(null);
      }
    },
    []
  );

  return { fetchTicketSales };
};

export const useToggleTicket = () => {
  const toggleTicket = useCallback(
    async (experienceId: string, ticketType: string, ticketStatus: boolean) => {
      const response = await httpToggleTicket(
        experienceId,
        ticketType,
        ticketStatus
      );
      return response.ok;
    },
    []
  );

  return { toggleTicket };
};

export const useListSoldTickets = () => {
  const listSoldTickets = useCallback(
    async (
      params: ListSoldTicketsParams,
      setSoldTickets: (soldTickets: SoldTicketsResponse[]) => void
    ) => {
      const { experienceId, page, limit } = params;

      if (!experienceId) {
        setSoldTickets([]);
        return;
      }

      try {
        const soldTickets = await httpGetListSoldTickets({
          experienceId,
          page,
          limit,
        });
        setSoldTickets(soldTickets);
      } catch (error) {
        console.error('Error listing sold tickets:', error);
        setSoldTickets([]);
      }
    },
    []
  );

  return { listSoldTickets };
};

interface GetExperienceTicketLeftParams {
  experienceId: string;
  tickets: Array<{ id: string; quantity: number }>;
}

export const useGetExperienceTicketLeft = () => {
  const getExperienceTicketLeft = useCallback(
    async (params: GetExperienceTicketLeftParams) => {
      const response = await httpGetExperienceTicketLeft(params);
      return response;
    },
    []
  );

  return { getExperienceTicketLeft };
};

export const useScanTicket = () => {
  const [loading, setLoading] = useState(false);
  const [ticketValid, setTicketValid] = useState<ScanTicketResponse | null>(
    null
  );

  const scanTicket = async (ticketId: string) => {
    setLoading(true);
    setTicketValid(null);

    try {
      const result = await httpScanTicket(ticketId);
      setTicketValid(result);
    } catch (error: any) {
      // handle error
    } finally {
      setLoading(false);
    }
  };

  return { scanTicket, loading, ticketValid };
};

export const useFetchExperienceCategories = () => {
  const fetchExperienceCategories = useCallback(
    async (setCategories: (categories: ExperienceCategory[]) => void) => {
      try {
        const categories = await httpFetchExperienceCategories();
        setCategories(categories);
      } catch (error) {
        setCategories([]);
      }
    },
    []
  );

  return { fetchExperienceCategories };
};
