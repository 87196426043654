import { privateRoutes } from 'app/utilities/routes';
import { Outlet, useLocation } from 'react-router-dom';
import { ElrPageTitle } from 'ui-components';
import ProfileNavigationMenu from '../menu/ProfileNavigationMenu';
import TopNavigationMenu from '../menu/TopNavigationMenu';

const Ecommerce = () => {
  const location = useLocation();

  // Define paths that should be headless
  const headlessPaths = [
    privateRoutes.uploadMarketProducts,
    privateRoutes.uploadRestaurantProducts,
  ];

  const isHeadless = headlessPaths.some((path) =>
    location.pathname.includes(path)
  );

  return (
    <>
      <ElrPageTitle title="Errandlr - Ecommerce" />
      {!isHeadless ? (
        <ProfileNavigationMenu>
          <TopNavigationMenu />
          <div className="bg-elr-gray">
            <Outlet />
          </div>
        </ProfileNavigationMenu>
      ) : (
        <div className="bg-elr-gray">
          <Outlet />
        </div>
      )}
    </>
  );
};

export default Ecommerce;
