import { IdentityVerificationRequest } from 'app/api/d';
import {
  AccountPinIcon,
  SearchIcon,
  UserFollowIcon,
  UserUnFollowIcon,
} from 'app/components/SvgAssets';
import { useFetchIdentityVerificationRequests } from 'app/hooks/operator';
import ProfileNavigationMenu from 'app/operator/components/menu/ProfileNavigationMenu';
import TopNavigationMenu from 'app/operator/components/menu/TopNavigationMenu';
import { useState } from 'react';
import { ElrButton, ElrPageTitle } from 'ui-components';
import { DashboardCards } from '../logistics';
import { VerificationModal } from './VerificationModals';

/* eslint-disable-next-line no-shadow */
enum TabNames {
  TOTAL_VERIFICATION_REQUESTS = 'Total Verification Requests',
  TOTAL_ACCEPTED = 'Total Accepted',
  TOTAL_DECLINED = 'Total Declined',
}

const IdentityVerificationPage = () => {
  const [selectedRequest, setSelectedRequest] =
    useState<IdentityVerificationRequest | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { latestData: verificationRequests = [], refetch } =
    useFetchIdentityVerificationRequests();

  const openModal = (request: IdentityVerificationRequest) => {
    setSelectedRequest(request);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedRequest(null);
    setIsModalOpen(false);
  };

  const onActionTaken = () => {
    refetch();
  };

  const cardData = [
    {
      title: TabNames.TOTAL_VERIFICATION_REQUESTS,
      count: 300,
      Icon: <AccountPinIcon />,
    },
    {
      title: TabNames.TOTAL_ACCEPTED,
      count: 0,
      Icon: <UserFollowIcon />,
    },
    {
      title: TabNames.TOTAL_DECLINED,
      count: 0,
      Icon: <UserUnFollowIcon />,
    },
  ];

  return (
    <div>
      <ElrPageTitle title="Errandlr - Identity Verification" />
      <ProfileNavigationMenu>
        <div className="flex justify-between w-full md:mx-auto items-center">
          <TopNavigationMenu containerClass="md:mr-0" />
        </div>
        <div className="bg-elr-gray flex flex-col py-8 px-7 md:pt-3 md:px-20">
          <div className="mb-6 text-elr-black font-normal font-serif leading-tight">
            <h3 className="pt-6 text-xl mb-2">Identity Verification</h3>
            <p className="text-elr-black-300 text-base">
              Verify users’ identity for account upgrade
            </p>
          </div>
          <DashboardCards data={cardData} />
          <section className="bg-elr-white-400 mb-8">
            <div className="py-4 md:px-8 px-2 md:flex justify-between border-b-2 border-elr-gray">
              <p className="text-lg text-elr-black my-auto">
                Verification Requests
              </p>
              <div className="flex gap-4 md:flex-row flex-col">
                <div className="md:flex justify-between gap-4 bg-elr-gray px-4 py-2 rounded-sm w-72 hidden">
                  <input
                    type="text"
                    placeholder="search name"
                    className="outline-none w-full bg-elr-gray border-none focus:outline-0"
                  />
                  <SearchIcon />
                </div>
              </div>
            </div>

            <div className="overflow-x-auto w-full relative">
              <table className="table-auto w-full">
                <thead>
                  <tr className="border-b-2 border-elr-gray text-elr-black">
                    <th className="font-normal py-7 text-left md:pl-32 md:w-1/3  pl-6 flex-grow truncate">
                      Name
                    </th>
                    <th className="font-normal py-7 text-center flex-grow">
                      Nationality
                    </th>
                    <th className="font-normal py-7 text-center flex-grow">
                      Means of Identification
                    </th>
                    <th className="font-normal py-7 text-center flex-grow">
                      Date Submitted
                    </th>
                    <th className="font-normal py-7 text-center flex-grow" />
                  </tr>
                </thead>
                <tbody>
                  {verificationRequests.docs.map(
                    (
                      identityVerificationRequest: IdentityVerificationRequest
                    ) => (
                      <tr
                        key={identityVerificationRequest.id}
                        className="border-b border-elr-gray text-elr-black text-center"
                      >
                        <td className="py-4 text-left md:pl-20 pl-6">
                          {identityVerificationRequest.name}
                        </td>
                        <td className="py-4 text-elr-black-400">
                          {identityVerificationRequest.nationality}
                        </td>
                        <td className="py-4 text-elr-black-400">
                          {identityVerificationRequest.documentType}
                        </td>
                        <td className="py-4 text-elr-black-400">
                          {identityVerificationRequest.dateSubmitted}
                        </td>
                        <td className="py-4">
                          <ElrButton
                            text="View Details"
                            className="px-4 py-2 bg-elr-gray text-elr-black rounded-sm"
                            onClick={() =>
                              openModal(identityVerificationRequest)
                            }
                          />
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
              <VerificationModal
                isOpen={isModalOpen}
                onClose={closeModal}
                data={selectedRequest}
                onActionTaken={onActionTaken}
              />
            </div>
          </section>
        </div>
      </ProfileNavigationMenu>
    </div>
  );
};

export default IdentityVerificationPage;
