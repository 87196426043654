import React, { useEffect, useRef, useState } from 'react';
import { ElrDropdownIconToggle } from '../ElrDropdownIconToggle';

export interface DropdownOption {
  value: string;
  label: string;
  items: ItemOption[];
}

export interface ItemOption {
  value: string;
  label: string;
}

interface ElrSearchableDropdownProps {
  options: DropdownOption[];
  placeholder?: string;
  className?: string;
  value?: any;
  inputClassName?: string;
  onChange?: any;
}

export const ElrSearchableDropdown: React.FC<ElrSearchableDropdownProps> = ({
  options,
  placeholder = 'Select or create an option',
  className = '',
  inputClassName = '',
  value,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState(value || '');
  const [filteredOptions, setFilteredOptions] =
    useState<DropdownOption[]>(options);
  const dropdownRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setFilteredOptions(
      options.filter((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      )
    );
  }, [inputValue, options]);

  useEffect(() => {
    setInputValue(value || '');
  }, [value]);

  const handleSelect = (selectedValue: string) => {
    setInputValue(selectedValue);
    setIsOpen(false);
    if (onChange) {
      onChange(selectedValue);
    }
  };

  const handleBlur = () => {
    setTimeout(() => setIsOpen(false), 200);
    if (onChange) {
      onChange(inputValue);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (inputValue.trim() !== '') {
        handleSelect(inputValue);
      }
    }
  };

  return (
    <div ref={dropdownRef} className={`relative ${className}`}>
      <div className="relative w-full">
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onFocus={() => setIsOpen(true)}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          className={`bg-elr-gray w-full px-4 py-2 text-base outline-none text-elr-black pr-10 ${inputClassName}`}
        />
        <div className="absolute right-2 top-1/2 transform -translate-y-1/2">
          <ElrDropdownIconToggle
            isToggled={isOpen}
            toggle={setIsOpen}
            fontSize="small"
          />
        </div>
      </div>

      {isOpen && (
        <div className="absolute rounded-md top-10 mt-3 bg-white w-full shadow-md p-2 z-10">
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option) => (
              <button
                key={option.value}
                type="button"
                className="px-3 py-2 w-full text-left hover:bg-elr-gray-500 hover:text-elr-green"
                onMouseDown={(e) => {
                  e.preventDefault();
                  handleSelect(option.label);
                }}
              >
                {option.label}
              </button>
            ))
          ) : (
            <div className="px-3 py-2 text-gray-500">
              Press Enter to create &quot;{inputValue}&quot;
            </div>
          )}
        </div>
      )}
    </div>
  );
};
