interface FeatureCardProps {
  icon: string;
  title: string;
  description: string;
  titleClassName?: string;
}

export const FeatureCard: React.FC<FeatureCardProps> = ({
  icon,
  title,
  description,
  titleClassName,
}) => (
  <div className="flex flex-col items-center bg-elr-gray justify-center py-10 px-6 rounded-md">
    <div className="md:p-4 rounded-full p-6 flex justify-center bg-white">
      <img
        src={icon}
        alt={title}
        className="w-[38px] h-[38px] md:w-[48px] md:h-[48px]"
      />
    </div>
    <p className={`md:text-24 text-2xl text-center mt-10 ${titleClassName}`}>
      {title}
    </p>
    <p className="text-elr-black-300 md:text-xl text-lg mt-4 text-center ">
      {description}
    </p>
  </div>
);
