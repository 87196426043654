import { useGetProfile } from 'app/hooks/user';
import { MobileHeader } from 'app/operator/components/Header';
import { DISPATCHER } from 'app/utilities/roles';
import { privateRoutes } from 'app/utilities/routes';
import Logo from 'assets/images/logo.svg';
import React, { useEffect, useRef, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import {
  CutleryIconLine,
  Leafline,
  LogisticsBike,
  Profileline,
  SettingsOutline,
  ShoppingBasketLine,
  WearIconLine,
} from '../SvgAssets';
import * as styles from './ClassUtils';

const ProfileNavigationMenu: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { currentAccessRole, role } = useGetProfile();
  const currentRole = currentAccessRole || role;
  const [isMenuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const toggleMenu = () => setMenuOpen((prev) => !prev);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const navigation: { [key: string]: JSX.Element } = {
    dispatcher: (
      <>
        <NavLink to={`/${DISPATCHER}/${privateRoutes.logistics}`} replace>
          {({ isActive }) => (
            <div className={styles.navlinkStyle(isActive)}>
              <div className="flex gap-x-4 gap">
                <LogisticsBike fill={isActive ? '#000000' : '#8E8E93'} />
                <p
                  className={`content-center text-lg leading-5 ${isActive ? 'text-black' : 'text-elr-black-400'}`}
                >
                  Logistics
                </p>
              </div>
            </div>
          )}
        </NavLink>

        <NavLink
          to={`/${DISPATCHER}/${privateRoutes.identityVerification}`}
          replace
        >
          {({ isActive }) => (
            <div className={styles.navlinkStyle(isActive)}>
              <div className="flex gap-x-3 gap">
                <Profileline fill={isActive ? '#000000' : '#8E8E93'} />
                <p
                  className={`content-center text-lg leading-5 ${isActive ? 'text-black' : 'text-elr-black-400'}`}
                >
                  Identity Verification
                </p>
              </div>
            </div>
          )}
        </NavLink>

        <NavLink to={`/${DISPATCHER}/${privateRoutes.market}`} replace>
          {({ isActive }) => (
            <div className={styles.navlinkStyle(isActive)}>
              <div className="flex gap-x-4 gap">
                <Leafline fill={isActive ? '#000000' : '#8E8E93'} />
                <p
                  className={`content-center text-lg leading-5 ${isActive ? 'text-black' : 'text-elr-black-400'}`}
                >
                  Market Runs
                </p>
              </div>
            </div>
          )}
        </NavLink>

        <NavLink to={`/${DISPATCHER}/${privateRoutes.restaurant}`} replace>
          {({ isActive }) => (
            <div className={styles.navlinkStyle(isActive)}>
              <div className="flex gap-x-4 gap">
                <CutleryIconLine fill={isActive ? '#000000' : '#8E8E93'} />
                <p
                  className={`content-center text-lg leading-5 ${isActive ? 'text-black' : 'text-elr-black-400'}`}
                >
                  Restaurants
                </p>
              </div>
            </div>
          )}
        </NavLink>

        <NavLink to={`/${DISPATCHER}/${privateRoutes.product}`} replace>
          {({ isActive }) => (
            <div className={styles.navlinkStyle(isActive)}>
              <div className="flex gap-x-4 gap">
                <WearIconLine fill={isActive ? '#000000' : '#8E8E93'} />
                <p
                  className={`content-center text-lg leading-5 ${isActive ? 'text-black' : 'text-elr-black-400'}`}
                >
                  Lifestyle
                </p>
              </div>
            </div>
          )}
        </NavLink>

        <NavLink to={`/${DISPATCHER}/${privateRoutes.essentials}`} replace>
          {({ isActive }) => (
            <div className={styles.navlinkStyle(isActive)}>
              <div className="flex gap-x-4 gap">
                <ShoppingBasketLine fill={isActive ? '#000000' : '#8E8E93'} />
                <p
                  className={`content-center text-lg leading-5 ${isActive ? 'text-black' : 'text-elr-black-400'}`}
                >
                  Essentials
                </p>
              </div>
            </div>
          )}
        </NavLink>

        <NavLink to={`/${DISPATCHER}/${privateRoutes.settings}`} replace>
          {({ isActive }) => (
            <div className={styles.navlinkStyle(isActive)}>
              <div className="flex gap-x-4 gap">
                <SettingsOutline fill={isActive ? '#000000' : '#8E8E93'} />
                <p
                  className={`content-center text-lg leading-5 ${isActive ? 'text-black' : 'text-elr-black-400'}`}
                >
                  Settings
                </p>
              </div>
            </div>
          )}
        </NavLink>
      </>
    ),
  };

  return (
    <div className="flex flex-col md:h-full">
      <div className="w-full overflow-x-hidden md:w-72 md:h-full md:fixed bg-elr-white-400">
        <div className="md:mx-auto">
          <MobileHeader
            linkTo={privateRoutes.dashboard(currentRole)}
            className="!justify-center"
            toggleMenu={toggleMenu}
          />
          <div>
            <div
              ref={menuRef}
              className={`fixed top-0 pt-14 md:pt-0 md:px-7 md:top-0 left-0 z-50 h-full w-64 bg-elr-white-400 md:shadow-none shadow-xl transition-transform duration-300 ${
                isMenuOpen ? 'translate-x-0' : '-translate-x-full'
              } md:translate-x-0 md:w-72 md:relative`}
            >
              <div className="md:hidden block mx-8 mb-12">
                <Link to="/">
                  <img
                    className="h-8"
                    src={Logo}
                    alt="Errandlr-Logo"
                    title="Errandlr-Logo"
                  />
                </Link>
              </div>
              {navigation[currentRole]}
            </div>
          </div>
        </div>
      </div>
      <div className="h-full overflow-y-auto bg-white md:ml-72">{children}</div>
    </div>
  );
};

export default ProfileNavigationMenu;
