/* eslint-disable react/no-array-index-key */
import check from 'assets/images/checkbox-multiple-line.svg';
import APIMockupImg from 'assets/images/API mockkup errand 1.svg';
import React from 'react';
import { channelsOneText } from './d';

const PaymentExperienceSection: React.FC = () => (
  <div className="pb-24 md:pb-48">
    <div className="bg-elr-green bg-opacity-5 align-middle items-center overflow-hidden flex flex-col md:flex-row px-5 justify-between">
      <div className="flex flex-col md:flex-row md:items-center md:justify-between md:max-w-6.5xl md:mx-auto mx-5">
        <div className="flex flex-col md:py-8 py-14 gap-8 w-11/12">
          <p className="text-3xl md:w-8/12 w-10/12 md:text-5xl text-left">
            Enhance your Logistics and Customer Experience
          </p>
          <div className="flex">
            <div className="mr-10 md:mr-30">
              {channelsOneText.map((txt, idx) => (
                <div className="flex items-center pb-2" key={idx}>
                  <div className="md:mr-3 flex">
                    <img className="md:w-10/12 w-9/12" src={check} alt="" />
                  </div>
                  <p className="text-elr-black opacity-60 md:text-xl text-lg">
                    {txt}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex justify-center md:w-[1150px] md:mt-20 md:-mr-64">
          <img className="w-full" src={APIMockupImg} alt="" />
        </div>
      </div>
    </div>
  </div>
);

export default PaymentExperienceSection;
