import React from 'react';
import styled from 'styled-components';

const StyledInput = styled.input`
  width: 100%;
  height: ${(props) => props.height || '50px'};
  box-sizing: border-box;
  padding: 12px 10px;
  color: rgba(8, 17, 32, 0.6);

  :focus {
    outline: 0;
    color: ${(props) => props.color || ''};
  }
`;

interface Props {
  onChange: (S: any) => void;
  ref?: any;
  placeholder?: string;
  className?: string;
  name?: string;
  type?: string;
  value?: string | number;
  height?: string;
  disabled?: boolean;
  max?: number;
  required?: boolean;
  onFocus?: any;
  onBlur?: any;
  maxLength?: number;
  color?: string;
}

export const ElrInput: React.FC<Props> = ({
  onChange,
  placeholder,
  className,
  ...props
}) => (
  <StyledInput
    className={className}
    onChange={onChange}
    placeholder={placeholder}
    {...props}
  />
);

interface InputIconProps {
  icon: React.ReactNode;
  placeHolder?: string;
  className?: string;
  parentClassName?: string;
}
export const ElrInputWithIcon: React.FC<InputIconProps> = ({
  icon,
  placeHolder,
  className,
  parentClassName,
}) => (
  <div className={`relative inline-block ${parentClassName}`}>
    <input
      type="text"
      placeholder={placeHolder}
      className={`pr-10 pl-3 py-2 bg-elr-gray focus:outline-none ${className}`}
    />
    <span className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400">
      {icon}
    </span>
  </div>
);

interface Props {
  onChange: (S: any) => void;
  placeholder?: string;
  className?: string;
  name?: string;
  type?: string;
  value?: string | number;
}

export const ElrTextAreaInput: React.FC<Props> = ({
  onChange,
  placeholder,
  className,
  ...props
}) => (
  <div className="w-full">
    <textarea
      placeholder={placeholder}
      rows={10}
      cols={20}
      className={`${className} focus:outline-none bg-elr-gray w-full h-90 px-3 py-2.5`}
      onChange={onChange}
      {...props}
    />
  </div>
);

export const ElrTextAreaInputVar2: React.FC<Props> = ({
  onChange,
  placeholder,
  className,
  ...props
}) => (
  <div className="w-full">
    <textarea
      placeholder={placeholder}
      rows={10}
      cols={20}
      className={`${className} focus:outline-black border border-elr-border rounded w-full h-90 text-elr-black p-2 text-base`}
      onChange={onChange}
      {...props}
    />
  </div>
);
