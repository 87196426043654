import { ArrowRightIcon } from 'app/components/SvgAssets';
import { publicRoutes } from 'app/utilities/routes';
import { Link } from 'react-router-dom';
/* eslint-disable import/no-unresolved */
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';

interface InfoCardProps {
  className?: string;
  hiddenCards?: string[];
}

interface CardProps {
  title: string;
  subtitle: string;
  points: string[];
  bgColor: string;
  textColor: string;
  arrowColor: string;
  link: string;
}

const InfoCard: React.FC<CardProps> = ({
  title,
  subtitle,
  points,
  bgColor,
  textColor,
  arrowColor,
  link,
}) => (
  <div
    className={`flex-grow basis-[240px] min-h-[400px] pt-9 pb-6 px-8 rounded-lg ${bgColor} relative`}
  >
    <div className="w-60 flex-1 md:w-full">
      <div>
        <h3 className={`font-bold text-4xl ${textColor}`}>{title}</h3>
        <p className="text-black font-bold text-2xl">{subtitle}</p>
        <ul className="mt-4 text-elr-black text-opacity-60 list-disc list-inside">
          {points.map((point) => (
            <li key={point} className="py-1">
              {point}
            </li>
          ))}
        </ul>
      </div>
      <div className="absolute bottom-6 right-6">
        <Link
          to={link}
          className="w-12 h-12 flex items-center justify-center bg-white rounded-full p-2 cursor-pointer"
        >
          <ArrowRightIcon fill={arrowColor} />
        </Link>
      </div>
    </div>
  </div>
);

const cardData = [
  {
    title: 'Deliver',
    subtitle: 'anytime',
    points: ['Local Delivery', 'International Delivery', 'Chauffeur Service'],
    bgColor: 'bg-[#F3F4FE]',
    textColor: 'text-[#5A5CE6]',
    arrowColor: '#5A4AF2',
    link: publicRoutes.logisticsPage,
  },
  {
    title: 'Get',
    subtitle: 'anything',
    points: ['Groceries', 'Supermarkets', 'Pharmacies', 'Restaurants'],
    bgColor: 'bg-[#E9F5F5]',
    textColor: 'text-[#087C70]',
    arrowColor: '#054853',
    link: publicRoutes.productPage,
  },
  {
    title: 'Explore',
    subtitle: 'experiences',
    points: ['Spa', 'Live Music', 'Events', 'Art Galleries', 'And More.'],
    bgColor: 'bg-[#FCF8E9]',
    textColor: 'text-[#E9B322]',
    arrowColor: '#FCCD33',
    link: publicRoutes.experiencePage,
  },
  {
    title: 'Pay',
    subtitle: 'anyone',
    points: ['Pay', 'Recieve', 'Fx Conversion', 'Instant ATM Card'],
    bgColor: 'bg-[#EFF3F5]',
    textColor: 'text-[#343A40]',
    arrowColor: '#081120',
    link: publicRoutes.paymentPage,
  },
];

const InfoCards: React.FC<InfoCardProps> = ({
  className,
  hiddenCards = [],
}) => {
  const visibleCards = cardData.filter(
    (card) => !hiddenCards.includes(card.title)
  );

  return (
    <div className={`md:my-14 my-20 mx-5 md:mx-0 ${className}`}>
      <Swiper slidesPerView={'auto'} spaceBetween={30} className="md:hidden">
        {visibleCards.map((card) => (
          <SwiperSlide key={card.title} style={{ width: 'auto' }}>
            <InfoCard {...card} />
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="hidden md:flex flex-wrap gap-4 justify-start items-start max-w-6.5xl mx-auto">
        {visibleCards.map((card) => (
          <InfoCard key={card.title} {...card} />
        ))}
      </div>
    </div>
  );
};

export default InfoCards;
