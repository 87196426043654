import { useQueryClient } from '@tanstack/react-query';
import { SendRequest, SendRequestState, itemType } from 'app/api/d';
import { httpFetchPackageTypes } from 'app/api/requests';
import { httpUploadFile } from 'app/api/utils';
import {
  BATCH_ESTIMATE_KEY,
  ESTIMATE_KEY,
  MAX_ORDER_BULK_ESTIMATE,
  PackageTypeCode,
} from 'app/constants';
import { deliverToInformationStructure } from 'app/modules/dispatch/components/utils';
import {
  ESTIMATE_CHECKOUT_PAYLOAD,
  STATE_DISPATCH_PAYLOAD,
} from 'app/state/constants';
import {
  getCurrentCountry,
  getCurrentCountryData,
  getDefaultState,
} from 'app/utilities/country';
import { packageTypeIcons, renderTrackingId } from 'app/utilities/helpers';
import {
  GeoLocationData,
  HttpDispatchResponse,
  UserDetailsValues,
} from 'app/utilities/types/shared';
import libPhoneNumber from 'google-libphonenumber';
import qs from 'qs';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useGetProfile } from './user';

/**
 *
 * @param url If rdr url is undefined, redirect to url params
 * @returns string
 */
export const useRedirectUrl = () => {
  const location = useLocation();
  return (url: string) => {
    const search = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    return (search?.rdr as string) || url;
  };
};
const dispatchDefaults = {
  batchEstimate: 0,
  estimate: 0,
  batch: false,
  reasons: [],
  geoId: '',
  status: 'success',
  batchLabel: '',
  estimateLabel: '',
  parcelCode: '',
  routes: {
    empty: true,
    dropoffLocations: [
      {
        destination: {
          id: '',
          label: '',
          distance: 0,
          duration: 0,
        },
        distance: 0,
        duration: 0,
        originDestination: '',
      },
    ],
    originAddresses: [],
    pickupLocation: {
      id: '',
      label: '',
      distance: 0,
      duration: 0,
    },
  },
};

export const useGetDispatchPayload = () => {
  const queryClient = useQueryClient();

  const dispatchPayload: HttpDispatchResponse | typeof dispatchDefaults =
    queryClient.getQueryData([STATE_DISPATCH_PAYLOAD]) || dispatchDefaults;
  return dispatchPayload;
};

export const useGetEstimate = ({
  estimateDetails,
}: {
  estimateDetails: HttpDispatchResponse | typeof dispatchDefaults;
}) => {
  const dispatchPayload = useGetDispatchPayload();
  const queryClient = useQueryClient();
  const premiumOptionDisplay = useCheckPremiumOption();
  const premiumOnlyOption = [
    PackageTypeCode.FOOD,
    PackageTypeCode.LARGE_PARCEL,
  ].includes(estimateDetails.parcelCode);

  const getSelection =
    (dispatchPayload.batch || !premiumOptionDisplay) && !premiumOnlyOption
      ? BATCH_ESTIMATE_KEY
      : ESTIMATE_KEY;
  const [estimateSelection, setEstimateSelection] =
    useState<string>(getSelection);
  const setEstimation = useCallback((selection: 'economy' | 'premium') => {
    setEstimateSelection(selection);
  }, []);

  const defaultEstimateSelection = premiumOnlyOption
    ? ESTIMATE_KEY
    : estimateSelection;

  const autoEconomySingleOption: boolean =
    !premiumOptionDisplay &&
    getSelection === BATCH_ESTIMATE_KEY &&
    getSelection !== estimateSelection &&
    !premiumOnlyOption;

  const touchEstimateSelection = autoEconomySingleOption
    ? BATCH_ESTIMATE_KEY
    : defaultEstimateSelection;

  if (autoEconomySingleOption) {
    queryClient.setQueryData(
      [STATE_DISPATCH_PAYLOAD],
      (dispatchPayloadState: any) => ({
        ...dispatchPayloadState,
        batch: true,
      })
    );
  }

  const estimate =
    /* @ts-ignore */
    (estimateDetails && estimateDetails[touchEstimateSelection]) || 0;

  return [setEstimation, estimate, getSelection];
};

/**
 * Hook to check if we're only displaying the economy option only
 */
export const useCheckPremiumOption = () => {
  const estimateDetails = useGetDispatchPayload();

  const estimateRoutes = estimateDetails?.routes;
  const estimateDestinations = estimateRoutes?.dropoffLocations;
  const premiumOptionDisplay =
    estimateDestinations?.length <= MAX_ORDER_BULK_ESTIMATE;
  return premiumOptionDisplay;
};

export const defaultEstimateData = {
  geoId: '',
  name: '',
  email: '',
  phone: '',
  deliverToInformation: [],
  dispatcherId: undefined,
  latitude: '',
  longitude: '',
  state: getDefaultState(),
  country: getCurrentCountry(),
  city: undefined,
  localGovt: undefined,
  customPrice: undefined,
  batch: false,
  toState: getDefaultState(),
  fromState: getDefaultState(),
};

export const useGetEstimateCheckout = () => {
  const queryClient = useQueryClient();

  const result: SendRequest =
    queryClient.getQueryData([ESTIMATE_CHECKOUT_PAYLOAD]) ||
    defaultEstimateData;
  return result;
};

export const useSetEstimateCheckout = () => {
  const queryClient = useQueryClient();

  const setEstimateData = useCallback(
    (record: any) => {
      queryClient.setQueryData(
        [ESTIMATE_CHECKOUT_PAYLOAD],
        (estimateCheckoutData?: SendRequestState) => ({
          ...estimateCheckoutData,
          ...record,
        })
      );
    },
    [queryClient]
  );

  return [setEstimateData];
};

const phoneUtil = libPhoneNumber.PhoneNumberUtil.getInstance();

export const useRequestDataPayload = () => {
  const queryClient = useQueryClient();
  const estimateCheckoutData: SendRequestState =
    queryClient.getQueryData([ESTIMATE_CHECKOUT_PAYLOAD]) ||
    defaultEstimateData;

  const req = useCallback(
    ({
      geoId,
      data,
      senderInformation,
      geolocation,
      dispatcherId,
      country,
      batch = false,
    }: {
      geoId: string;
      data: any;
      senderInformation: UserDetailsValues | undefined;
      geolocation: GeoLocationData | undefined;
      dispatcherId?: string;
      batch?: boolean;
      country?: string;
    }) => {
      const resolvedCountry =
        country || geolocation?.place.country || getCurrentCountry();
      const obj = { ...data, ...senderInformation, geoId };
      const email =
        obj.email ||
        `0${phoneUtil
          .parse(obj.phone, getCurrentCountryData().countryCode.toUpperCase())
          .getNationalNumber()}@errandlr.com`;

      return {
        geoId: obj.geoId,
        name: obj.name,
        email,
        phone: obj.phone,
        deliverToInformation: deliverToInformationStructure(
          obj.deliverToInformation,
          resolvedCountry.toLowerCase(),
          estimateCheckoutData.toState
        ),
        dispatcherId,
        lockerSize: obj?.lockerSize,
        paymentOption: obj.paymentOption,
        latitude: geolocation?.geolocation?.lat,
        longitude: geolocation?.geolocation?.lng,
        state: estimateCheckoutData.fromState,
        country: resolvedCountry.toLowerCase(),
        city: geolocation?.place?.city?.trim() || undefined,
        localGovt: geolocation?.place?.localGovt?.trim() || undefined,
        customPrice: undefined,
        batch,
      };
    },
    [estimateCheckoutData.toState, estimateCheckoutData.fromState]
  );
  return [req];
};

export const useGetTrackingId = (originTrackingId: string) => {
  const query = useLocation();
  const { track: urlTrackingId, trackingId: urlTrackingIdTrackPage }: any =
    qs.parse(query.search, {
      ignoreQueryPrefix: true,
    });
  const tID = urlTrackingId || urlTrackingIdTrackPage;
  if (!tID) return originTrackingId;
  const trackingUID = renderTrackingId(tID, originTrackingId);
  return trackingUID;
};

type FetchPackageTypeHook = () => [itemType[]];

export const useFetchPackageType: FetchPackageTypeHook = () => {
  const [itemTypes, setItemsTypes] = useState<itemType[]>([]);

  const fetchPackageType = async () => {
    try {
      const data = await httpFetchPackageTypes();
      const packageTypeWithIcons = Object.keys(data)
        .filter((key) => data[key].code !== 'default')
        .map((key) => ({
          code: key,
          name: data[key].label,
          imageSrc:
            packageTypeIcons[data[key].code as keyof typeof packageTypeIcons],
        }));

      setItemsTypes(packageTypeWithIcons);
    } catch (error: any) {
      // no op
    }
  };

  useEffect(() => {
    fetchPackageType();
  }, []);

  return [itemTypes];
};

export const useUploadFileImage = () => {
  const queryClient = useQueryClient();
  const { id: userId } = useGetProfile();
  const handleUploadImage = async (file: File) => {
    try {
      const data = await queryClient.fetchQuery({
        queryKey: [file.name],
        queryFn: () => httpUploadFile(userId, file, 'image'),
        gcTime: 30000,
      });

      const response = {
        key: data.key,
        originalFilename: file.name,
        type: file.type,
        url: `${new URL(data.url).origin}${new URL(data.url).pathname}`,
      };
      return response;
    } catch (error: any) {
      // Handle error
      return null;
    }
  };

  return { handleUploadImage };
};
