import { useGetServiceProfile } from 'app/hooks/requests/commerce';
import {
  ArchiveDrawerWithBG,
  ProfileIconWithBG,
} from 'app/operator/components/SvgAssets';
import { currencyFormatter } from 'app/utilities/helpers';
import { COMMERCE } from 'app/utilities/roles';
import { privateRoutes } from 'app/utilities/routes';
import {
  OutletType,
  ServiceOutletType,
  ServiceOutletTypeKey,
} from 'app/utilities/types/shared';
import UploadProducts from 'assets/images/cuate.svg';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { ElrButton } from 'ui-components';
import { MoneyIcon } from '../SvgAssets';
import { DashboardCards } from './components/DashboardCards';

interface UploadDashboardProps {
  outletType?: OutletType;
}

const UploadDashboard: React.FC<UploadDashboardProps> = ({ outletType }) => {
  const navigate = useNavigate();
  const serviceOutletType: ServiceOutletTypeKey = Object.values(
    ServiceOutletType
  ).includes(outletType as ServiceOutletTypeKey)
    ? (outletType as ServiceOutletTypeKey)
    : ServiceOutletType.market;

  const { serviceProfile, isLoading } = useGetServiceProfile(serviceOutletType);
  const cardData = [
    {
      title: 'Total Sales',
      count: currencyFormatter(0),
      Icon: <MoneyIcon />,
    },
    {
      title: 'Total Added Products',
      count: 0,
      Icon: <ArchiveDrawerWithBG />,
    },
    {
      title: 'Total Orders',
      count: 0,
      Icon: <ProfileIconWithBG />,
    },
  ];

  const outletTypeToRouteMap: Record<
    OutletType,
    { upload: string; view: string }
  > = {
    market: {
      upload: privateRoutes.uploadMarketProducts,
      view: '',
    },
    restaurant: {
      upload: privateRoutes.uploadRestaurantProducts,
      view: privateRoutes.addedRestaurantProducts,
    },
    experience: {
      upload: '',
      view: '',
    },
    essentials: {
      upload: '',
      view: '',
    },
    product: {
      upload: '',
      view: '',
    },
    logistics: {
      upload: '',
      view: '',
    },
  };

  const handleUploadProducts = () => {
    const route = outletTypeToRouteMap[serviceOutletType as OutletType];
    navigate(
      `/${COMMERCE}/${privateRoutes.ecommerce}/${serviceOutletType}/${serviceProfile?.id}/${route.upload}`
    );
  };

  const outletMessages: Partial<Record<OutletType, string>> = {
    market:
      'No new orders has been uploaded yet. The sales of your products will appear here.',
    restaurant:
      'No new orders has been uploaded yet. The sales of your products will appear here.',
    essentials: '',
    product: '',
    logistics: '',
  };

  return (
    <div className="bg-elr-gray flex flex-col pt-8 px-7 md:pt-3 md:px-20 min-h-screen">
      <section className="md:flex md:flex-row flex-col items-center pt-6 justify-between pb-8">
        <div className="text-elr-black font-normal font-serif leading-tight">
          <h3 className="text-xl mb-2">E-commerce</h3>
          <p className="text-elr-black-300 text-base">
            View and follow up your products and sales
          </p>
        </div>
        <div className="flex gap-4">
          <Link
            to={`/${COMMERCE}/${privateRoutes.ecommerce}/${serviceOutletType}/${serviceProfile?.id}/${outletTypeToRouteMap[serviceOutletType as OutletType].view}`}
          >
            <ElrButton
              text={'View Products'}
              onClick={() => {}}
              className="bg-elr-black text-white rounded-md py-3 px-5"
            />
          </Link>
          <ElrButton
            text={'Upload Products'}
            onClick={handleUploadProducts}
            className="bg-elr-black text-elr-white rounded-md py-3 px-5"
          />
        </div>
      </section>

      <DashboardCards data={cardData} />

      <section className="bg-elr-white-400 px-12 py-8 mb-8 border-b-2 border-elr-gray w-full flex flex-col items-center">
        <div className="w-full bg-elr-gray p-8 mb-8">
          {isLoading ? (
            <p className="text-center text-lg">Loading...</p>
          ) : (
            <div className="flex w-full justify-between">
              <div className="flex flex-col gap-1 w-4/12">
                <p className="text-lg">{serviceProfile?.name || 'N/A'}</p>
                <p className="text-elr-black text-opacity-60 ">
                  {serviceProfile?.address || 'N/A'}
                </p>
                <p className="text-elr-black text-opacity-60">
                  {serviceProfile?.phone || 'N/A'}
                </p>
                <p className="text-elr-black text-opacity-60">
                  {serviceProfile?.email || 'N/A'}
                </p>
              </div>

              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-1">
                  <p className="text-elr-black text-opacity-60">Opening Time</p>
                  <p>{serviceProfile?.openingTime || 'N/A'}</p>
                </div>
                <div className="flex flex-col gap-1">
                  <p className="text-elr-black text-opacity-60">Closing Time</p>
                  <p>{serviceProfile?.closingTime || 'N/A'}</p>
                </div>
              </div>

              <div className="flex items-end">
                <ElrButton
                  text="Edit Information"
                  className="bg-white px-4 h-fit py-2 rounded-md"
                />
              </div>
            </div>
          )}
        </div>

        <div className="flex flex-col gap-6 mx-auto">
          <img
            src={UploadProducts}
            alt="upload-products"
            className="max-w-sm mx-auto"
          />
          <p className="text-center text-base w-7/12 mx-auto text-elr-black text-opacity-60">
            {outletType
              ? outletMessages[outletType]
              : 'No new orders have been uploaded yet. The sales of your products will appear here.'}
          </p>
        </div>
      </section>
    </div>
  );
};

export default UploadDashboard;
