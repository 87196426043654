import React, { useRef, useState } from 'react';
/* eslint-disable import/no-unresolved */
import { publicRoutes } from 'app/utilities/routes';
import { useNavigate } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import { ElrButton } from 'ui-components';
import arrowForward from '../../../assets/images/arrowForward.svg';
import LocationPin from '../../../assets/images/location-line.svg';
import { ExperienceData, exploreExperiences } from './mock';

const ExperienceCard: React.FC<{ experience: ExperienceData }> = ({
  experience,
}) => {
  const navigate = useNavigate();

  const handleViewExperience = () => {
    navigate(`${publicRoutes.experience}/${experience.uuid}`);
  };

  return (
    <div className="bg-white rounded-md">
      <img
        src={experience.bannerUrl}
        alt="banner"
        className="w-full mb-4 rounded-md"
      />
      <p className="text-xl">{experience.experienceName}</p>
      <div className="flex items-center gap-2 pt-1 pb-2">
        <p className="text-base text-elr-black-400">
          {experience.experienceDate}
        </p>
        <span className="h-[4px] w-[4px] rounded-full bg-elr-black-400 opacity-60 inline-block" />
        <p className="text-base text-elr-black-400">
          {experience.experienceTime}
        </p>
      </div>
      <div className="flex items-center gap-2 align-middle">
        <img src={LocationPin} alt="" className="w-[16px]" />
        <p className="text-base text-elr-green">
          {experience.experienceLocation}
        </p>
      </div>
      <ElrButton
        text="View Experience"
        className="w-full mt-6 text-elr-black bg-elr-gray"
        onClick={handleViewExperience}
      />
    </div>
  );
};

const ExperienceCarousel = () => {
  const swiperRef = useRef<SwiperRef>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [totalSlides, setTotalSlides] = useState(0);
  const visibleSlides = 3;

  const breakpoints = {
    320: {
      slidesPerView: 1.2,
      spaceBetween: 10,
    },
    1024: {
      slidesPerView: visibleSlides,
      spaceBetween: 20,
    },
  };

  const handlePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideNext();
    }
  };

  return (
    <div className="max-w-6.5xl pb-20 mx-auto md:pb-40">
      <div className="mx-5 md:mx-0">
        <p className="w-10/12 mx-auto text-4xl text-center md:w-full md:text-5xl">
          Dive into a Range of Exciting Experiences
        </p>
        <div className="flex flex-col items-center justify-center pt-12 md:pt-12">
          <Swiper
            ref={swiperRef}
            modules={[Navigation, Pagination]}
            spaceBetween={20}
            pagination={{
              clickable: true,
              renderBullet: (_, className) =>
                `<span class="${className} !bg-elr-black md:!hidden"></span>`,
            }}
            style={{ paddingBottom: '50px' }}
            breakpoints={breakpoints}
            className="w-full"
            onSlideChange={(swiper) => setCurrentIndex(swiper.activeIndex)}
            onSwiper={(swiper) => setTotalSlides(swiper.slides.length)}
          >
            {exploreExperiences.map((experience) => (
              <SwiperSlide key={experience.uuid}>
                <ExperienceCard experience={experience} />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="hidden md:flex gap-7">
            <button
              type="button"
              onClick={handlePrev}
              className={`bg-elr-black w-10 h-10 md:w-12 md:h-12 flex items-center justify-center rounded-full ${
                currentIndex === 0 ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              disabled={currentIndex === 0}
            >
              <img
                src={arrowForward}
                alt="Back"
                className="rotate-180 filter invert brightness-0"
              />
            </button>
            <button
              type="button"
              onClick={handleNext}
              className={`bg-elr-black w-10 h-10 sm:w-12 sm:h-12 flex items-center justify-center rounded-full ${
                currentIndex >= totalSlides - visibleSlides
                  ? 'opacity-50 cursor-not-allowed'
                  : ''
              }`}
              disabled={currentIndex >= totalSlides - visibleSlides}
            >
              <img
                src={arrowForward}
                alt="Next"
                className="filter invert brightness-0"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExperienceCarousel;
