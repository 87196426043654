import { TopNav } from 'app/components/menu/Navbar';
import appStore from 'assets/images/app-store-btn.svg';
import appMockup from 'assets/images/errandlr-app.svg';
import googlePlay from 'assets/images/google-play-btn.svg';
import React from 'react';

export const Header: React.FC = () => (
  <div className="relative w-full md:pb-6 bg-gradient-to-b from-white to-elr-gray">
    <div className="relative z-10">
      <TopNav />
    </div>
    <div className="max-w-6.5xl mx-auto flex flex-col md:flex-row items-center pt-12 mb-10 md:pt-28">
      <div className="relative flex flex-col justify-center gap-8 md:w-1/2 mx-5 md:mx-0">
        <h1 className="font-bold text-left text-4xl md:text-6xl leading-tight md:leading-tight">
          One App, Boundless Possibilities
        </h1>
        <p className="text-left text-xl md:text-2xl text-elr-black-300 md:w-9/12 w-full">
          Errandlr is ‘the everything app’, making it easier than ever to
          discover activities, order food and groceries, manage payments, and
          more.
        </p>
        <div className="relative flex justify-center md:justify-start gap-6 md:h-12 w-full mb-16 md:mb-0">
          <button
            onClick={() =>
              window.open(
                'https://apps.apple.com/app/errandlr/id6499235011',
                '_blank'
              )
            }
            type="button"
            className="cursor-pointer"
          >
            <img
              src={appStore}
              alt="Download on App Store"
              className="h-full"
            />
          </button>
          <button
            onClick={() =>
              window.open(
                'https://play.google.com/store/apps/details?id=com.errandlr.commerce&pcampaignid=web_share',
                '_blank'
              )
            }
            type="button"
            className="cursor-pointer"
          >
            <img
              src={googlePlay}
              alt="Get it on Google Play"
              className="h-full"
            />
          </button>
        </div>
      </div>
      <div className="relative flex items-center justify-center md:w-1/2 pb-10 md:pb-0">
        <img
          src={appMockup}
          alt="app-mockup"
          className="w-3/4 md:w-full max-w-lg md:max-w-2xl"
        />
      </div>
    </div>
  </div>
);
