import { useLocation } from 'react-router-dom';
import ExperienceForm from './ExperienceForm';

const Experiences = () => {
  const { state } = useLocation();
  const experience = state?.experience || null;

  return (
    <div className="flex justify-center pt-16">
      <div className="flex flex-col justify-center w-full px-4 md:w-8/12 md:px-0">
        <ExperienceForm experience={experience} />
      </div>
    </div>
  );
};

export default Experiences;
