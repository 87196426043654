import SupportHeader from 'app/utilities/HttpSupportHeader';
import { axiosDefault as axios } from 'app/utilities/axios';
import { cleanNullInObject } from 'app/utilities/helpers';
import { AccountType, Dispatcher } from 'app/utilities/types/dispatcherTypes';
import {
  CommerceSlugResponse,
  CreateExperienceProps,
  CreateServiceProfileProps,
  GetServiceProducts,
  Menu,
  MenuCategory,
  OutletType,
  ProductFormFieldsProps,
  ProductsListData,
  ServiceProfile,
  SingleProductFetchResponse,
  UpdateBank,
} from 'app/utilities/types/shared';
import {
  CommerceUpdateBusinessBranch,
  ProductsProps,
  SettingsConfiguration,
} from 'app/utilities/types/userTypes';
import { toast } from 'react-toastify';
import { RequestsDataInterface } from './d';
import {
  AccountPayloadResponse,
  ScanTicketResponse,
  TicketSale,
} from './types';

interface BusinessProfilePayload {
  onboardingStage: boolean;
  businessName: string;
  country: string;
  state: string;
  pickupLocation: {
    streetNumber: string;
    streetName: string;
    country: string;
    state: string;
  };
  email: string;
  websiteUrl: string;
}

interface Params {
  userId: string;
  nextPage: number;
}

export const httpOnboardCommerce = async (
  payload: BusinessProfilePayload,
  userId: string
): Promise<any> => {
  const cleanPayload = cleanNullInObject(payload);
  try {
    const response = await axios.post(
      `/commerce/${userId}/onboarding`,
      cleanPayload,
      SupportHeader()
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpGenerateApiKey = async (
  userId: string,
  commerceId: string
): Promise<any> => {
  try {
    const response = await axios.post(
      `commerce/${userId}/generate-api-keys?commerceId=${commerceId}`,
      { userId, commerceId },
      SupportHeader()
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error.response?.data?.message);
  }
};

export const httpGetApiKeyApiUser = async (
  userId: string,
  commerceId: string
): Promise<any> => {
  try {
    const response = await axios.get(
      `commerce/${userId}/get-api-keys?commerceId=${commerceId}`,
      SupportHeader()
    );
    return response?.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    return null;
  }
};

export const httpPostWebhookUrl = async (
  userId: string,
  commerceId: string,
  webhookUrl: string
): Promise<any> => {
  try {
    const response = await axios.post(
      `commerce/${userId}/register-webhook?commerceId=${commerceId}`,
      { webhookUrl },
      SupportHeader()
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error.response?.data?.message);
  }
};

export const httpProcessPendingPayment = async (
  userId: string,
  commerceId: string
): Promise<any> => {
  try {
    const response = await axios.post(
      `commerce/${userId}/process-pending-requests?commerceId=${commerceId}`,
      {},
      SupportHeader()
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error.response?.data?.message);
  }
};

export const httpGetPendingRequestsInfo = async (
  userId: string,
  commerceId: string
): Promise<any> => {
  try {
    const response = await axios.get(
      `commerce/${userId}/pending-requests-info?commerceId=${commerceId}`,
      SupportHeader()
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error.response?.data?.message);
  }
};

export const httpToggleBatchDeliveries = async (
  toggle: boolean,
  userId: string,
  commerceId: string
): Promise<any> => {
  try {
    const response = await axios.post(
      `commerce/${userId}/batch-toggle`,
      { toggle, commerceId },
      SupportHeader()
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error.response?.data?.message);
  }
};

export const httpGetUpcomingDeliveries = async ({
  userId,
  nextPage,
}: Params): Promise<RequestsDataInterface> => {
  try {
    const response = await axios.get(
      `/commerce/${userId}/get-upcoming-deliveries`,
      {
        params: { page: nextPage },
        ...SupportHeader(),
      }
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpGetDeliveriesMade = async ({
  userId,
  nextPage,
}: Params): Promise<RequestsDataInterface> => {
  try {
    const response = await axios.get(
      `/commerce/${userId}/get-total-deliveries`,
      {
        params: { page: nextPage },
        ...SupportHeader(),
      }
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpGetCommerceBySlug = async (
  commerceSlug: string
): Promise<CommerceSlugResponse> => {
  try {
    const response = await axios.get(
      `/commerce/slug/${commerceSlug}`,
      SupportHeader()
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error.response?.data?.message);
  }
};

export const httpUpdateCommerceProfile = async (
  payload: Dispatcher,
  userId: string
): Promise<AccountType> => {
  try {
    const response = await axios.put<Dispatcher, AccountPayloadResponse>(
      `commerce/${userId}`,
      payload,
      SupportHeader()
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpUpdateOutletType = async (
  userId: string,
  outletType: Record<OutletType, boolean>,
  commerceId: string
): Promise<AccountType> => {
  try {
    const response = await axios.put<Dispatcher, AccountPayloadResponse>(
      `commerce/${userId}`,
      { outletType, commerceId },
      SupportHeader()
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpUpdateCommerceSettings = async (
  payload: SettingsConfiguration,
  userId: string
) => {
  try {
    const response = await axios.put(
      `/commerce/${userId}/settings`,
      { ...payload },
      SupportHeader()
    );
    toast.success('Setting Updated', { position: 'top-right' });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message, { position: 'top-right' });
    throw new Error(error.response?.data?.message);
  }
};

export const httpUpdateBusinessBranches = async (
  payload: CommerceUpdateBusinessBranch,
  userId: string
) => {
  try {
    const response = await axios.post(
      `/commerce/${userId}/branch-settings`,
      { ...payload },
      SupportHeader()
    );
    toast.success('Branch Updated', { position: 'top-right' });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message, { position: 'top-right' });
    throw new Error(error.response?.data?.message);
  }
};

export const httpGetBusinessBranches = async (userId: string) => {
  try {
    const response = await axios.get(
      `/commerce/${userId}/branch-settings`,
      SupportHeader()
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message, { position: 'top-right' });
    throw new Error(error.response?.data?.message);
  }
};

export const httpRemoveBusinessBranch = async (
  slug: string,
  userId: string
) => {
  try {
    const response = await axios.delete(`/commerce/${userId}/branch-settings`, {
      data: { slug },
      ...SupportHeader(),
    });
    toast.success('Branch Deleted', { position: 'top-right' });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message, { position: 'top-right' });
    throw new Error(error.response?.data?.message);
  }
};

interface productsParams {
  userId: string;
  roleId: string;
  nextPage: number;
}

export const httpGetProductsList = async ({
  userId,
  roleId,
  nextPage,
}: productsParams): Promise<ProductsListData> => {
  const limit = 10;
  try {
    const response = await axios.get(`/product/${userId}?roleId=${roleId}`, {
      params: {
        page: nextPage,
        limit,
      },
      ...SupportHeader(),
    });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpCreateProduct = async ({
  products,
  userId,
  roleId,
}: ProductsProps): Promise<any> => {
  try {
    const response = await axios.post(
      `/product/${userId}?roleId=${roleId}`,
      { ...products },
      SupportHeader()
    );
    toast.success('Product Created', { position: 'top-right' });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpCreateMarketProducts = async ({
  payload,
  userId,
  roleId,
}: {
  payload: any;
  userId: string;
  roleId: string;
}): Promise<any> => {
  try {
    const response = await axios.post(
      `/product/${userId}/market/${roleId}`,
      payload,
      SupportHeader()
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error.response?.data?.message);
  }
};

export const httpCreateExperience = async ({
  name,
  description,
  location,
  roleId,
  experienceDate,
  timeZone,
  media,
  tickets,
  category,
}: CreateExperienceProps): Promise<any> => {
  try {
    const response = await axios.post(
      `/experience`,
      {
        name,
        description,
        location,
        roleId,
        experienceDate,
        timeZone,
        media,
        tickets,
        category,
      },
      SupportHeader()
    );
    toast.success('Experience Created', { position: 'top-right' });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message || 'Experience creation failed');
    throw new Error(error);
  }
};

export const httpGetExperienceList = async ({
  limit = 10,
  page = 1,
  userId,
  roleId,
}: {
  limit?: number;
  page?: number;
  userId: string;
  roleId: string;
}): Promise<any> => {
  try {
    const response = await axios.get(
      `/experience/${userId}/commerce/${roleId}`,
      {
        params: {
          limit,
          page,
        },
        ...SupportHeader(),
      }
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message || 'Failed to fetch experiences');
    throw new Error(error);
  }
};

export const httpScanTicket = async (
  ticketId: string
): Promise<ScanTicketResponse> => {
  try {
    const response = await axios.put<ScanTicketResponse>(
      `/experience/${ticketId}/scan-ticket`,
      { scanned: true },
      SupportHeader()
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message || 'Failed to scan the ticket');
    throw new Error(error);
  }
};

export const httpGetTicketSalesAggregate = async ({
  experienceId,
  ticketId,
}: {
  experienceId: string;
  ticketId: string;
}): Promise<TicketSale[]> => {
  try {
    const response = await axios.get(
      `/experience/${experienceId}/tickets/sales-aggregate`,
      {
        params: {
          ticketId,
        },
        ...SupportHeader(),
      }
    );
    return response.data;
  } catch (error: any) {
    toast.error(
      error.response?.data?.message || 'Failed to fetch ticket sales'
    );
    throw new Error(error);
  }
};

export const httpUpdateBankDetails = async (
  payload: UpdateBank,
  userId: string
) => {
  try {
    const response = await axios.put(
      `/commerce/${userId}`,
      { ...payload },
      SupportHeader()
    );
    toast.success('Bank details updated successfully', {
      position: 'top-right',
    });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message, { position: 'top-right' });
    throw new Error(error.response?.data?.message);
  }
};

export const httpCreateServiceProfile = async (
  payload: CreateServiceProfileProps,
  userId: string
) => {
  try {
    const response = await axios.post(
      `/product/${userId}/service-profile`,
      payload,
      SupportHeader()
    );
    toast.success('Service Profile Created', { position: 'top-right' });
    return response.data;
  } catch (error: any) {
    toast.error(
      error.response?.data?.message || 'Service Profile creation failed'
    );
    throw new Error(error);
  }
};

export const httpCreateRestautantProducts = async (
  payload: Menu,
  userId: string,
  roleId: string
) => {
  try {
    const response = await axios.post(
      `/product/${userId}/restaurant/${roleId}`,
      payload,
      SupportHeader()
    );
    toast.success('Products Created', { position: 'top-right' });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpUpdateRestaurantProducts = async (
  payload: MenuCategory,
  userId: string,
  roleId: string,
  productId: string
) => {
  try {
    const response = await axios.put(
      `/product/${userId}/restaurant/${roleId}/${productId}`,
      payload,
      SupportHeader()
    );
    toast.success('Products Updated', { position: 'top-right' });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpGetServiceCategory = async ({
  userId,
  outletType,
  vendorId,
  internal,
}: {
  userId: string;
  outletType: string;
  internal: boolean;
  vendorId: string;
}) => {
  try {
    const response = await axios.get(`/product/${userId}/service-category`, {
      params: { outletType, vendorId, internal },
      ...SupportHeader(),
    });
    return response.data;
  } catch (error: any) {
    toast.error(
      error.response?.data?.message || 'Failed to fetch service categories'
    );
    throw new Error(error);
  }
};

export const httpGetServiceProductCategory = async ({
  userId,
  roleId,
  outletType,
  serviceTypeId,
}: {
  userId: string;
  outletType: string;
  roleId: string;
  serviceTypeId: string;
}) => {
  try {
    const response = await axios.get(
      `/product/${userId}/service-product-category/${roleId}`,
      {
        params: { outletType, serviceTypeId },
        ...SupportHeader(),
      }
    );
    return response.data;
  } catch (error: any) {
    toast.error(
      error.response?.data?.message ||
        'Failed to fetch service product category'
    );
    throw new Error(error);
  }
};

export const httpGetServiceProducts = async ({
  userId,
  outletType,
  outletTypeId,
  roleId,
  page = 1,
  limit = 50,
}: {
  userId: string;
  outletType: string;
  outletTypeId: string;
  roleId: string;
  page: number;
  limit: number;
}): Promise<GetServiceProducts[]> => {
  try {
    const response = await axios.get(`/product/${userId}/service-products`, {
      params: { outletType, outletTypeId, roleId, page, limit },
      ...SupportHeader(),
    });
    return response.data;
  } catch (error: any) {
    toast.error(
      error.response?.data?.message || 'Failed to fetch service products'
    );
    throw new Error(error);
  }
};

export const httpGetServiceProfile = async ({
  outletType,
  roleId,
  userId,
}: {
  outletType: string;
  roleId: string;
  userId: string;
}): Promise<ServiceProfile> => {
  try {
    const response = await axios.get(`/product/${userId}/service-profile`, {
      params: { outletType, roleId },
      ...SupportHeader(),
    });
    return response.data;
  } catch (error: any) {
    toast.error(
      error.response?.data?.message || 'Failed to fetch service profile'
    );
    throw new Error(error);
  }
};

const transformServiceProduct = (product: SingleProductFetchResponse) => ({
  _id: product._id,
  cuisine: product.category.cuisine || 'Default',
  shelf: product.category.shelf || 'Default',
  categoryName: product.category.name || '',
  categoryDescription:
    product.category.description || 'Default Nothing for Restaurant',
  categoryImage: product.category.images[0],
  categoryId: product.category._id,
  items: [
    {
      itemName: product.name,
      itemPrice: Number(product.price),
      itemDescription: product.description || '',
      itemImage: product.image || '',
      itemPopular: product.popular || false,
      itemHandPicked: product.handPicked || false,
      itemInStock: product.inStock || true,
      options: product.options?.map((option: any) => ({
        subItemName: option.name,
        subItemPrice: Number(option.price),
        subItemDescription: option.description || '',
        subItemImage: option.image || '',
        subItemQuantity: option.quantity || 0,
        subItemInStock: option.inStock || true,
        subItemRequired: option.required || false,
        childOptions: option.childOptions?.map((child: any) => ({
          childItemName: child.name,
          childItemPrice: Number(child.price),
          childItemDescription: child.description || '',
          childItemImage: child.image || '',
          childItemQuantity: child.quantity || 1,
          childItemInStock: child.inStock || true,
          childItemRequired: child.required || false,
        })),
      })),
    },
  ],
});

export const httpGetServiceProduct = async ({
  userId,
  productId,
  outletType,
  outletTypeId,
  roleId,
}: {
  userId: string;
  productId: string;
  outletType: string;
  outletTypeId: string;
  roleId: string;
}): Promise<ProductFormFieldsProps> => {
  try {
    const response: {
      data: SingleProductFetchResponse;
    } = await axios.get(`/product/${userId}/service-product/${productId}`, {
      params: { outletType, outletTypeId, roleId },
      ...SupportHeader(),
    });
    // if response.data is not null, modify the response to be of type Menu
    if (response.data) {
      const transformedProduct = transformServiceProduct(response.data);
      return transformedProduct;
    }
    return response.data;
  } catch (error: any) {
    toast.error(
      error.response?.data?.message || 'Failed to fetch service product'
    );
    throw new Error(error);
  }
};
