import sayge from 'assets/images/sayge.png';
import sendBox from 'assets/images/sendBoxLogo.png';
import shipbubble from 'assets/images/shipbubble.png';
import smartParcel from 'assets/images/smartParcelLogo.png';
import tamedLux from 'assets/images/sudoImage.png';
import React from 'react';

const Partners = () => {
  const logos = [
    {
      img: tamedLux,
      alt: 'Tamed lux logo',
    },
    {
      img: sendBox,
      alt: 'SendBox logo',
    },
    {
      img: smartParcel,
      alt: 'Smart Parcel logo',
    },
    {
      img: sayge,
      alt: 'Sayge logo',
    },
    {
      img: shipbubble,
      alt: 'Shipbubble logo',
    },
  ];

  return (
    <div className="max-w-6.5xl mx-auto">
      <div className="flex flex-col md:gap-4 gap-0 bg-elr-gray rounded-md py-8 px-8 mx-5 md:mx-0">
        <h2 className="text-22 md:text-3xl text-elr-purple text-opacity-80 w-full text-center md:mb-2 mb-6">
          Trusted By Innovative Brands
        </h2>
        <div className="flex flex-wrap justify-center items-center max-h-full gap-x-6 md:gap-x-5">
          {logos.map((logo, index) => (
            <React.Fragment key={logo.alt}>
              <img
                src={logo.img}
                alt={logo.alt}
                className=" md:h-10 h-6 object-contain filter grayscale"
              />
              {index !== logos.length - 1 && (
                <div className="w-[1px] md:h-16 h-8 my-2 bg-elr-gray-200" />
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Partners;
