import { TopNav } from 'app/components/menu/Navbar';
import { publicRoutes } from 'app/utilities/routes';
import ecommerceMockup from 'assets/images/ecommerce-errandlr-mockup.svg';
import sellRightVector from 'assets/images/sell-vector-right.svg';
import sellLeftVector from 'assets/images/sell-vector-left.svg';
import React from 'react';
import { useNavigate } from 'react-router';
import { ElrButton } from 'ui-components';

const Header: React.FC = () => {
  const navigate = useNavigate();
  const handleGetStarted = () => {
    navigate(publicRoutes.registration);
  };

  return (
    <div className="relative w-full bg-gradient-to-b from-white to-elr-green-50A">
      <div className="relative z-10">
        <TopNav />
      </div>
      <div className="max-w-6.5xl mx-auto flex flex-col items-center gap-14 pt-12 md:pt-28 relative">
        <div className="relative flex flex-col justify-center gap-8 items-center mb-0 md:mb-14 mx-5 md:mx-0">
          <h1 className="font-bold w-11/12 text-center text-4xl md:text-6xl leading-tight md:leading-tight">
            Sell Smarter and Expand Your Reach
          </h1>
          <p className="text-center text-xl md:text-2xl text-elr-black-300 md:w-8/12 w-full">
            Expand your business beyond physical borders with our e-commerce
            platform that makes selling online fast, simple, and profitable.
          </p>
          <div className="relative flex justify-center gap-6 md:h-12 w-full mb-16 md:mb-0">
            <ElrButton
              text="Get Started"
              className="bg-elr-black text-white px-14 md:mb-8 mb-12 md:w-auto"
              onClick={handleGetStarted}
            />
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 left-0 w-full flex justify-between items-end">
        <img
          src={sellLeftVector}
          alt="vector-left"
          className="w-1/5 md:w-1/6"
        />
        <img
          src={sellRightVector}
          alt="vector-right"
          className="w-1/5 md:w-1/6"
        />
      </div>

      <div className="relative flex items-center justify-center mx-auto md:w-1/2  md:pb-0 z-10">
        <img
          src={ecommerceMockup}
          alt="app-mockup"
          className="w-9/12 md:w-full md:max-w-full"
        />
      </div>
    </div>
  );
};

export default Header;
