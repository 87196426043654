import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  httpCreateExperience,
  httpCreateRestautantProducts,
  httpCreateServiceProfile,
  httpGetBusinessBranches,
  httpGetExperienceList,
  httpGetServiceCategory,
  httpGetServiceProduct,
  httpGetServiceProductCategory,
  httpGetServiceProducts,
  httpGetServiceProfile,
  httpGetTicketSalesAggregate,
  httpRemoveBusinessBranch,
  httpUpdateBusinessBranches,
  httpUpdateOutletType,
  httpUpdateRestaurantProducts,
} from 'app/api/commerce';
import { httpGetDeliveryBids } from 'app/api/requests';
import { TicketSale } from 'app/api/types';
import { ExperienceType } from 'app/components/ecommerce/experiences/ExperienceList';
import {
  BUSINESS_BRANCHES,
  OUTLET_SERVICE_PRODUCT,
  OUTLET_SERVICE_PROFILE,
} from 'app/state/constants';
import { COMMERCE } from 'app/utilities/roles';
import { privateRoutes } from 'app/utilities/routes';
import {
  CreateExperienceProps,
  CreateServiceProfileProps,
  GetServiceProducts,
  Menu,
  MenuCategory,
  OutletType,
  ProductFormFieldsProps,
  ServiceOutletType,
  ServiceProfile,
} from 'app/utilities/types/shared';
import {
  BusinessBranchInterface,
  CommerceUpdateBusinessBranch,
} from 'app/utilities/types/userTypes';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useGetProfile } from '../user';

export const useGetBusinessBranches = () => {
  const queryClient = useQueryClient();
  const profile = useGetProfile();

  const query = useMutation({
    mutationFn: () => httpGetBusinessBranches(profile.id),
    onSuccess: (data) => {
      queryClient.fetchQuery({
        queryKey: [BUSINESS_BRANCHES],
        queryFn: (_: any) => data,
        staleTime: Infinity,
      });
    },
  });
  return query;
};

export const useGetBranches = () => {
  const queryClient = useQueryClient();
  return (
    queryClient.getQueryData<BusinessBranchInterface[] | []>([
      BUSINESS_BRANCHES,
    ]) || []
  );
};

export const useAddBusinessBranch = () => {
  const queryClient = useQueryClient();
  const profile = useGetProfile();
  const query = useMutation({
    mutationFn: (payload: CommerceUpdateBusinessBranch) =>
      httpUpdateBusinessBranches(payload, profile.id),
    onSuccess: (data) => {
      queryClient.setQueryData([BUSINESS_BRANCHES], (oldData: any) => {
        const newArray = [...oldData, data];
        return newArray;
      });
    },
  });
  return query;
};

export const useRemoveBusinessBranch = () => {
  const queryClient = useQueryClient();
  const profile = useGetProfile();
  const query = useMutation({
    mutationFn: (payload: { slug: string }) =>
      httpRemoveBusinessBranch(payload.slug, profile.id),
    onSuccess: (data) => {
      queryClient.setQueryData([BUSINESS_BRANCHES], (oldData: any) => {
        const filteredArray = oldData.filter(
          (item: { slug: string }) => item.slug !== data.slug
        );
        return filteredArray;
      });
    },
  });
  return query;
};

export const useUpdateOutletType = () => {
  const queryClient = useQueryClient();
  const profile = useGetProfile();
  return useMutation({
    mutationFn: ({
      outletType,
      commerceId,
    }: {
      outletType: Record<OutletType, boolean>;
      commerceId: string;
    }) => httpUpdateOutletType(profile.id, outletType, commerceId),
    onSuccess: (data) => {
      queryClient.setQueryData(
        [`commerceProfile_${profile.id}`],
        (oldData: any) => ({
          ...oldData,
          outletType: data.outletType,
        })
      );
    },
  });
};

export const useCreateExperience = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [experienceData, setExperienceData] = useState<any>(null);

  const createExperience = async (
    experienceDetails: CreateExperienceProps
  ): Promise<any> => {
    setLoading(true);
    setError(null);
    try {
      const data = await httpCreateExperience(experienceDetails);
      setExperienceData(data);
      return data;
    } catch (err: any) {
      setError(err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return {
    createExperience,
    loading,
    error,
    experienceData,
  };
};

export const useFetchExperienceList = () => {
  const [experienceListData, setExperienceListData] = useState<
    ExperienceType[] | undefined
  >();
  const [loading, setLoading] = useState(false);
  const profile = useGetProfile();

  const fetchExperienceList = useCallback(async (page = 1) => {
    setLoading(true);
    try {
      const result = await httpGetExperienceList({
        page,
        userId: profile.id,
        roleId: profile.accountInformation.id,
      });
      const experiences = result.docs.map((experience: any) => ({
        title: experience.name,
        createdDate: experience.createdAt,
        experienceUUId: experience.uuid,
      }));
      setExperienceListData(experiences);
    } catch (error: any) {
      // Handle error
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchExperienceList(1);
  }, [fetchExperienceList]);

  return {
    experienceListData,
    fetchExperienceList,
    loading,
  };
};

interface UseTicketSalesAggregateParams {
  experienceId: string;
  ticketId: string;
}

export const useTicketSalesAggregate = ({
  experienceId,
  ticketId,
}: UseTicketSalesAggregateParams) => {
  const [totalTicketSales, setTotalTicketSales] = useState<TicketSale[] | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchTicketSalesAggregate = async () => {
      setLoading(true);
      try {
        const data = await httpGetTicketSalesAggregate({
          experienceId,
          ticketId,
        });
        setTotalTicketSales(data);
      } catch (error: any) {
        // Handle error
      } finally {
        setLoading(false);
      }
    };

    if (experienceId && ticketId) {
      fetchTicketSalesAggregate();
    }
  }, [experienceId, ticketId]);

  return { totalTicketSales, loading };
};

export const useCreateRestaurantProducts = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const createRestaurantProducts = async (restaurantProductsDetails: {
    menu: Menu;
    userId: string;
    roleId: string;
  }): Promise<any> => {
    setLoading(true);
    setError(null);
    try {
      const response = await httpCreateRestautantProducts(
        restaurantProductsDetails.menu,
        restaurantProductsDetails.userId,
        restaurantProductsDetails.roleId
      );

      navigate(
        `/${COMMERCE}/${privateRoutes.ecommerce}/${privateRoutes.restaurant}/${response.restaurant}/${privateRoutes.restaurantDashboard}`
      );
      return response;
    } catch (err: any) {
      setError(err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return {
    createRestaurantProducts,
    loading,
    error,
  };
};

export const useCreateServiceProfile = () => {
  const [isLoading, setIsLoading] = useState(false);
  const updateOutletTypeMutation = useUpdateOutletType();
  const {
    id: userId,
    accountInformation: { id: roleId, outletType },
  } = useGetProfile();

  const handleCreateServiceProfile = async (
    payload: CreateServiceProfileProps
  ): Promise<any> => {
    setIsLoading(true);
    try {
      const response = await httpCreateServiceProfile(payload, userId);
      await updateOutletTypeMutation.mutateAsync({
        outletType: {
          ...outletType,
          [payload.outletType]: true,
        } as any,
        commerceId: roleId,
      });
      return response;
    } finally {
      setIsLoading(false);
    }
  };

  return { handleCreateServiceProfile, isLoading };
};

export interface Bid {
  location: {
    latitude: number;
    longitude: number;
  };
  requestId: string;
  bidAmount: number;
  status: string;
  createdAt: string;
  updatedAt: string;
  agentName: string;
  priceEstimate: number;
  agentId: string;
  phoneNumber: string;
  trackingId: string;
}

export type Bids = {
  [key: string]: Bid[];
};

export const useGetDeliveryBids = () => {
  const { id: userId } = useGetProfile();
  const [bids, setBids] = useState<Bids>({});
  const { isLoading } = useQuery({
    queryKey: ['deliveryBids', userId],
    queryFn: async () => {
      const result = await httpGetDeliveryBids(userId);
      if (result) {
        const bidSnapshots: Bids = {};
        result.forEach((dtv: Bid) => {
          const bid = dtv;
          if (!bidSnapshots[bid.requestId]) {
            bidSnapshots[bid.requestId] = [];
          }
          bidSnapshots[bid.requestId].push(bid);
        });
        setBids(bidSnapshots);
      }
      return {};
    },
    enabled: !!userId,
    refetchInterval: 1200000, // refetch every 20 minutes
  });

  return { bids, isLoading };
};

interface ServiceCategoryParams {
  userId: string;
  outletType: string;
  vendorId: string;
  internal: boolean;
}

type ServiceCategoryType = any;

export const useServiceCategory = ({
  userId,
  outletType,
  vendorId,
  internal,
}: ServiceCategoryParams) => {
  const [serviceCategories, setServiceCategories] = useState<
    ServiceCategoryType[] | undefined
  >();
  const [loading, setLoading] = useState(false);

  const fetchServiceCategories = useCallback(async () => {
    setLoading(true);
    try {
      const result = await httpGetServiceCategory({
        userId,
        outletType,
        vendorId,
        internal,
      });
      setServiceCategories(result);
    } catch (error: any) {
      // Handle error
    } finally {
      setLoading(false);
    }
  }, [userId, outletType, vendorId, internal]);

  useEffect(() => {
    if (userId && outletType) {
      fetchServiceCategories();
    }
  }, [fetchServiceCategories]);

  return {
    serviceCategories,
    fetchServiceCategories,
    loading,
  };
};

interface ServiceProductCategoryParams {
  userId: string;
  outletType: string;
  roleId: string;
  serviceTypeId: string;
}

export const useServiceProductCategory = ({
  userId,
  outletType,
  roleId,
  serviceTypeId,
}: ServiceProductCategoryParams) => {
  const [serviceProductCategories, setServiceProductCategories] = useState<
    { name: string; _id: string }[] | undefined
  >();
  const [loading, setLoading] = useState(false);

  const fetchServiceProductCategories = useCallback(async () => {
    setLoading(true);
    try {
      if (!serviceTypeId) {
        return;
      }
      const result = await httpGetServiceProductCategory({
        userId,
        outletType,
        roleId,
        serviceTypeId,
      });
      setServiceProductCategories(result);
    } catch (error: any) {
      // Handle error
    } finally {
      setLoading(false);
    }
  }, [userId, outletType, roleId, serviceTypeId]);

  useEffect(() => {
    if (userId && outletType) {
      fetchServiceProductCategories();
    }
  }, [fetchServiceProductCategories]);

  return {
    serviceProductCategories,
    fetchServiceProductCategories,
    loading,
  };
};

export const useGetServiceProducts = (): {
  serviceProducts: GetServiceProducts[];
  fetchServiceProducts: (params: {
    outletType: string;
    outletTypeId: string;
  }) => Promise<void>;
} => {
  const {
    id: userId,
    accountInformation: { id: roleId },
  } = useGetProfile();
  const [serviceProducts, setServiceProducts] = useState<GetServiceProducts[]>(
    []
  );

  const fetchServiceProducts = useCallback(
    async ({
      outletType,
      outletTypeId,
    }: {
      outletType: string;
      outletTypeId: string;
    }) => {
      const result = await httpGetServiceProducts({
        userId,
        outletType,
        outletTypeId,
        roleId,
        page: 1,
        limit: 50,
      });
      setServiceProducts(result);
    },
    [userId]
  );

  return {
    serviceProducts,
    fetchServiceProducts,
  };
};

export const useGetServiceProfile = (
  outletType: keyof typeof ServiceOutletType
) => {
  const {
    id: userId,
    accountInformation: { id: roleId },
  } = useGetProfile();
  const queryClient = useQueryClient();

  const { isLoading } = useQuery({
    queryKey: [OUTLET_SERVICE_PROFILE, outletType],
    queryFn: () =>
      httpGetServiceProfile({
        roleId,
        userId,
        outletType,
      }),
    notifyOnChangeProps: ['data'],
    gcTime: Infinity,
  });

  const serviceProfile = queryClient.getQueryData<ServiceProfile>([
    OUTLET_SERVICE_PROFILE,
    ServiceOutletType.restaurant,
  ]);

  return { serviceProfile, isLoading };
};

export const useGetServiceProduct = () => {
  const {
    id: userId,
    accountInformation: { id: roleId },
  } = useGetProfile();
  const queryClient = useQueryClient();
  const [serviceProduct, setServiceProduct] =
    useState<ProductFormFieldsProps>();

  const fetchServiceProduct = useCallback(
    ({
      productId,
      outletType,
      outletTypeId,
    }: {
      productId: string;
      outletType: string;
      outletTypeId: string;
    }) => {
      if (productId && outletType && outletTypeId) {
        queryClient.fetchQuery({
          queryKey: [OUTLET_SERVICE_PRODUCT, outletType],
          queryFn: async () => {
            const result = await httpGetServiceProduct({
              productId,
              roleId,
              userId,
              outletType,
              outletTypeId,
            });
            setServiceProduct(result);
            return result;
          },
        });
      }
    },
    [queryClient, roleId, userId]
  );

  return { serviceProduct, fetchServiceProduct };
};

export const useUpdateRestaurantProducts = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const updateRestaurantProducts = async (restaurantProductsDetails: {
    menu: MenuCategory;
    userId: string;
    roleId: string;
    productId: string;
  }): Promise<any> => {
    setLoading(true);
    setError(null);
    try {
      const response = await httpUpdateRestaurantProducts(
        restaurantProductsDetails.menu,
        restaurantProductsDetails.userId,
        restaurantProductsDetails.roleId,
        restaurantProductsDetails.productId
      );

      navigate(
        `/${COMMERCE}/${privateRoutes.ecommerce}/${privateRoutes.restaurant}/${response.restaurant}/${privateRoutes.restaurantDashboard}`
      );
      return response;
    } catch (err: any) {
      setError(err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return {
    updateRestaurantProducts,
    loading,
    error,
  };
};
