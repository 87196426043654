import { useQuery, useQueryClient } from '@tanstack/react-query';
import { httpReAssignAgents } from 'app/api/agent';
import { WalletBalance, prePopulatedWalletData } from 'app/api/d';
import {
  cancelRequestResponse,
  httpAcceptRequest,
  httpCancelRequest,
  httpGenerateTrackingID,
} from 'app/api/requests';
import { httpTrackParcel } from 'app/api/trackParcel';
import { TBillingHistory } from 'app/api/types';
import {
  httpGetBillingHistory,
  httpGetBillingRecord,
  httpGetWalletBalance,
} from 'app/api/wallet';
import {
  useFetchInfiniteQuery,
  useFetchQueryOnce,
} from 'app/components/dashboard/hooks';
import { WALLET_BALANCE } from 'app/state/constants';
import { COMMERCE, SENDER } from 'app/utilities/roles';
import { useCallback, useEffect, useState } from 'react';
import { Roles, useGetProfile } from '../user';

type UseCancelRequestHook = () => {
  loading: boolean;
  setShowCancelModal: React.Dispatch<React.SetStateAction<boolean>>;
  showCancelModal: boolean;
  cancelRequest: (payload: {
    trackingId: string;
    cancelReason: string;
  }) => Promise<cancelRequestResponse | void>;
  error: string | null;
};

export const useCancelRequest: UseCancelRequestHook = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const {
    id: userId,
    accountInformation: { id: accountId },
  } = useGetProfile();

  const cancelRequest = useCallback(
    async ({
      trackingId,
      cancelReason,
    }: {
      trackingId: string;
      cancelReason: string;
    }) => {
      setError(null);
      try {
        setLoading(true);
        const response = await httpCancelRequest(
          trackingId,
          accountId,
          userId,
          cancelReason
        );
        setShowCancelModal(false);
        return response;
      } catch (err: any) {
        return setError(err);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return { loading, setShowCancelModal, showCancelModal, cancelRequest, error };
};

type UseGetBalanceHook = (
  userId: string,
  roleId: string,
  role?: Roles
) => [() => Promise<{ data: WalletBalance }>, WalletBalance, boolean, boolean];

export const useGetWalletBalance: UseGetBalanceHook = (
  userId: string,
  roleId,
  role = SENDER
): any => {
  const queryClient = useQueryClient();

  const { isLoading, isError, refetch } = useQuery({
    queryKey: [WALLET_BALANCE],
    queryFn: () =>
      httpGetWalletBalance({
        roleId,
        userId,
        role,
      }),
    notifyOnChangeProps: ['data'],
  });

  const walletData: WalletBalance =
    queryClient.getQueryData([WALLET_BALANCE]) || prePopulatedWalletData;

  return [refetch, walletData, isError, isLoading];
};

type UseGetRecordHook = (
  userId: string,
  roleId: string,
  role?: Roles
) => {
  refetch: () => Promise<{ data: WalletBalance }>;
  docsList: [] | null;
  totalDebit: number;
  totalCredit: number;
  error: string | null;
  hasNextPage: boolean;
  fetchNextPage: () => Promise<void>;
};

export const useGetBillingRecord: UseGetRecordHook = (
  userId: string,
  roleId,
  role?: Roles
): any => {
  const response = useFetchInfiniteQuery({
    key: 'walletRecord',
    fn: httpGetBillingRecord,
    payload: { roleId, userId, role },
  });

  const walletData = response.docsList || [prePopulatedWalletData];
  return {
    ...response,
    totalDebit: response.latestData.totalDebit || 0,
    totalCredit: response.latestData.totalCredit || 0,
    docsList: walletData,
  };
};

type UseGenerateTrackingId = (
  userId: string,
  batch: boolean
) => [() => Promise<string>, string, string | null];
export const useGetTemporaryTrackingId: UseGenerateTrackingId = (
  userId: string,
  batch: boolean
): any => {
  const [error, setError] = useState<string | null>(null);
  const [trackingId, setTrackingId] = useState<string>('');

  const generateTrackingID = useCallback(async (): Promise<
    WalletBalance | any
  > => {
    setError(null);
    try {
      const response = await httpGenerateTrackingID({
        userId,
        batch,
      });
      setTrackingId(response);
      return response;
    } catch (err: any) {
      return setError(err);
    }
  }, [userId]);

  return [generateTrackingID, trackingId, error];
};

export const useGetParcelDetails = (parcelId: string) =>
  useFetchQueryOnce({
    key: 'parcelDetails',
    fn: httpTrackParcel,
    payload: parcelId,
  });

export const useFetchBillingHistory = (
  limit: number = 10,
  role = COMMERCE
): [TBillingHistory[], boolean] => {
  const queryClient = useQueryClient();
  const profile = useGetProfile();
  const {
    id: userId,
    accountInformation: { id: roleId },
  } = profile;

  const [loading, setLoading] = useState(true);
  const [billRecords, setBillRecords] = useState<TBillingHistory[]>([]);

  useEffect(() => {
    const loadBillingHistory = async () => {
      try {
        setLoading(true);
        const fetchedData = await queryClient.fetchQuery({
          queryKey: ['billingHistory', userId, roleId],
          queryFn: () => httpGetBillingHistory({ userId, roleId, limit, role }),
        });

        setBillRecords(fetchedData);
      } catch (error) {
        // no opp
      } finally {
        setLoading(false);
      }
    };

    if (userId && roleId) {
      loadBillingHistory();
    }
  }, [queryClient, userId, roleId, limit]);
  return [billRecords, loading];
};

export const useAcceptRequest = () => {
  const { id: userId } = useGetProfile();
  const acceptRequest = useCallback(
    async (requestId: string, agentId: string) => {
      await httpAcceptRequest({ userId, requestId, agentId });
    },
    [userId]
  );
  return acceptRequest;
};

export const useReassignRequest = () => {
  const { id: userId } = useGetProfile();
  const reassignRequest = useCallback(
    async (requestId: string, agentId: string) => {
      await httpReAssignAgents(userId, { requestId, agentId });
    },
    [userId]
  );
  return reassignRequest;
};
