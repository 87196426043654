import { useServiceCategory } from 'app/hooks/requests/commerce';
import { useGetProfile } from 'app/hooks/user';
import { Products } from 'app/utilities/types/shared';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { ElrButton, ElrInput } from 'ui-components';
import { ElrSelect } from 'ui-components/components/ElrSelect/ElrSelect';
import * as Yup from 'yup';
import { BackIconWonWithBG, DeleteIcon, PlusIcon } from '../../SvgAssets';
import HeadlessHeader from '../components/HeadlessHeader';

interface SelectedOption {
  value: string;
  label: string;
}

const ProductSchema = Yup.array().of(
  Yup.object().shape({
    categoryName: Yup.string().required('Category name is required'),
    categoryDescription: Yup.string().required(
      'Category description is required'
    ),
    categoryImage: Yup.string(),
    items: Yup.array().of(
      Yup.object().shape({
        itemName: Yup.string().required('Item name is required'),
        itemPrice: Yup.number()
          .required('Price is required')
          .transform((value) => (value ? Number(value) : 0))
          .test(
            'is-number',
            'Must be a valid number',
            (value) => !isNaN(Number(value))
          ),
        itemPopular: Yup.boolean(),
        itemHandPicked: Yup.boolean(),
        itemInStock: Yup.boolean(),
      })
    ),
  })
);

const UploadMarketProducts = () => {
  const navigate = useNavigate();
  const [currentCategory, setCurrentCategory] = useState(0);
  const [currentItem] = useState(0);
  const {
    id: userId,
    accountInformation: { id: roleId, outletType = {} },
  } = useGetProfile();
  const [selectedItems, setSelectedItems] = useState([]);
  const initialMenuCategory = {
    categoryName: '',
    categoryDescription: 'Default Nothing for Market Products',
    categoryImage: '',
    items: [
      {
        itemName: '',
        itemPrice: 0,
        itemPopular: false,
        itemHandPicked: false,
        itemInStock: true,
      },
    ],
  };

  const { handleSubmit, values, handleChange, errors, isValid, setValues } =
    useFormik<Products>({
      initialValues: [initialMenuCategory],
      enableReinitialize: true,
      validationSchema: ProductSchema,
      onSubmit: async () => {},
    });

  const addNewCategory = () => {
    setValues([...values, initialMenuCategory]);
  };

  const deleteMenu = (categoryIndex: number) => {
    const newValues = [...values];
    newValues.splice(categoryIndex, 1);
    setValues(newValues);

    // Reset currentCategory if we're deleting the current one
    if (currentCategory >= newValues.length) {
      setCurrentCategory(newValues.length - 1);
    }
  };

  // Helper function to get nested error
  const getFieldError = (errors_: any, path: string) => {
    // Remove the first empty string from array notation paths
    const parts = path
      .replace(/\[(\d+)\]/g, '.$1')
      .split('.')
      .filter(Boolean);
    let current = errors_;

    for (const part of parts) {
      if (!current?.[part]) return undefined;
      current = current[part];
    }

    return current;
  };

  const isDisabled = !isValid;

  const selectedOutletType =
    Object.keys(outletType).find(
      (key) => outletType[key as keyof typeof outletType]
    ) || '';

  const { serviceCategories } = useServiceCategory({
    userId,
    outletType: selectedOutletType,
    vendorId: roleId,
    internal: false,
  });

  // const vendorId = serviceCategories?.[0]?.vendor || '';

  const categoryOptions = serviceCategories?.map((category) => ({
    value: category.id,
    label: category.categoryName,
    items: category.items || [],
  }));

  const handleCategoryChange = (
    selectedOption: SelectedOption | null,
    categoryIndex: number
  ) => {
    setValues((prevValues) => {
      const newValues = [...prevValues];
      newValues[categoryIndex] = {
        ...newValues[categoryIndex],
        categoryName: selectedOption?.label || '',
      };
      return newValues;
    });

    const selectedCategory = categoryOptions?.find(
      (cat) => cat.value === selectedOption?.value
    );
    setSelectedItems(selectedCategory?.items || []);
  };

  const handleItemChange = (
    selectedOption: SelectedOption | null,
    categoryIndex: number
  ) => {
    setValues((prevValues) => {
      const newValues = [...prevValues];
      newValues[categoryIndex].items[currentItem] = {
        ...newValues[categoryIndex].items[currentItem],
        itemName: selectedOption?.label || '',
      };
      return newValues;
    });
  };

  return (
    <>
      <HeadlessHeader title="Upload Products" />
      <div className="max-w-6xl md:mx-auto mx-5">
        <div className="py-16 w-full">
          <div className="flex flex-col">
            <section
              className="flex cursor-pointer gap gap-x-2"
              onClick={() => navigate(-1)}
            >
              <BackIconWonWithBG />
              <p className="self-center">Back</p>
            </section>
            <header className="my-6 text-left">
              <h1 className="mb-2 text-2xl font-normal leading-7 text-elr-black">
                Add Products
              </h1>
              <p className="text-lg leading-5 text-elr-black-400">
                Upload the items you sell.
              </p>
            </header>
          </div>
          <div className="flex md:flex-row gap-3 w-full flex-col">
            <div className="px-10 py-8 bg-white w-full">
              <form onSubmit={handleSubmit}>
                <div className="flex flex-col gap-3">
                  {values.map((menuCategory, categoryIndex) => (
                    <div
                      // eslint-disable-next-line react/no-array-index-key
                      key={categoryIndex}
                      className="flex flex-col gap-3"
                    >
                      <div className="flex items-center justify-between">
                        <p className="text-base">Items {categoryIndex + 1}</p>
                        {values.length > 1 && (
                          <div
                            className="flex items-center justify-center w-8 h-8 rounded-full cursor-pointer bg-elr-orange-900/10"
                            onClick={() => deleteMenu(categoryIndex)}
                          >
                            <DeleteIcon />
                          </div>
                        )}
                      </div>
                      <ElrSelect
                        options={categoryOptions}
                        isClearable={false}
                        showIndicator
                        value={categoryOptions?.find(
                          (option) =>
                            option.label === values[categoryIndex].categoryName
                        )}
                        isSearchable={false}
                        placeHolder="Select Item Category"
                        controlHeight={10}
                        bgcolor="#fff"
                        onChange={(selectedOption: SelectedOption) =>
                          handleCategoryChange(selectedOption, categoryIndex)
                        }
                        className="rounded-none bg-elr-gray !mb-0 pr-2 !h-12 outline-none border-none"
                      />

                      <ElrSelect
                        options={selectedItems.map((item: any) => ({
                          value: item.id,
                          label: item.name,
                        }))}
                        isClearable={false}
                        showIndicator
                        value={selectedItems.find(
                          (item: any) =>
                            item.label ===
                            values[categoryIndex].items[currentItem]?.itemName
                        )}
                        isSearchable={false}
                        placeHolder="Select Item"
                        controlHeight={10}
                        bgcolor="#fff"
                        onChange={(selectedOption: SelectedOption) =>
                          handleItemChange(selectedOption, categoryIndex)
                        }
                        className="rounded-none bg-elr-gray !mb-0 pr-2 !h-12 outline-none border-none"
                      />
                      {getFieldError(
                        errors,
                        `[${categoryIndex}].items[${currentItem}].itemName`
                      ) && (
                        <p className="text-red-500 text-sm mt-1">
                          {getFieldError(
                            errors,
                            `[${categoryIndex}].items[${currentItem}].itemName`
                          )}
                        </p>
                      )}

                      <div className="flex items-center">
                        <span className="inline-flex items-center h-12 px-3 text-sm border-none bg-elr-deep-gray">
                          NGN
                        </span>
                        <ElrInput
                          onChange={handleChange}
                          value={menuCategory.items[currentItem].itemPrice}
                          name={`[${categoryIndex}].items[${currentItem}].itemPrice`}
                          className={`border-none rounded-none bg-elr-gray outline-none !h-12 ${
                            getFieldError(
                              errors,
                              `[${categoryIndex}].items[${currentItem}].itemPrice`
                            )
                              ? 'border-red-500'
                              : ''
                          }`}
                          placeholder="Price"
                        />
                      </div>
                      {getFieldError(
                        errors,
                        `[${categoryIndex}].items[${currentItem}].itemPrice`
                      ) && (
                        <p className="text-red-500 text-sm mt-1">
                          {getFieldError(
                            errors,
                            `[${categoryIndex}].items[${currentItem}].itemPrice`
                          )}
                        </p>
                      )}
                    </div>
                  ))}
                  <div
                    className="flex items-center cursor-pointer border-elr-gray border-y-2 py-4 mb-3 gap-x-2 text-elr-purple mt-2 justify-end"
                    onClick={addNewCategory}
                  >
                    <PlusIcon />
                    <p>Add Another Item</p>
                  </div>
                </div>
              </form>
              <div className="flex w-full gap-2 mt-14">
                <ElrButton
                  text="Save Menu"
                  disabled={isDisabled}
                  className="w-full rounded-md bg-elr-black text-elr-white-400"
                  onClick={() => handleSubmit()}
                />
              </div>
            </div>
            <div className="px-10 py-8 bg-white w-full">
              <p className="text-lg text-center">App Preview</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadMarketProducts;
