import { TopNav } from 'app/components/menu/Navbar';
import { Footer } from 'app/modules/landing/components';
import React, { useEffect } from 'react';
import { ElrPageTitle } from 'ui-components';
import PurpleVector from 'assets/images/policies-purple-vector.svg';
import YellowVector from 'assets/images/policies-yellow-vector.svg';
import PoliciesAppBanner from 'app/modules/landing/components/PoliciesAppBanner';
import SubSection from '../components/SubSection';

const Landing: React.FC = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  });

  return (
    <>
      <ElrPageTitle title="Errandlr - Cookie Policy" />
      <div className="bg-white pb-24 md:pb-40">
        <TopSection />
        <div className="mx-5 max-w-6.5xl md:mx-auto">
          <p className="text-elr-black text-lg text-left w-11/12 text-opacity-60">
            This Errandlr Cookie Policy (&quot;Policy&quot;) explains how
            Errandlr (&quot;we,&quot; &quot;us,&quot; or &quot;our&quot;) uses
            cookies and similar technologies (&quot;Cookies&quot;) when you
            visit our website or mobile application (collectively, the
            &quot;Platform&quot;).
          </p>
          <SubSection
            title="What are cookies?"
            content={`Cookies are small data files that are placed on your device (computer, smartphone, tablet, etc.) when you visit a website or use a mobile application. Cookies store information about your visit and usage, such as your browsing preferences, login credentials (if applicable), and other data to improve your experience and our services.`}
          />
          <SubSection
            title="What types of Cookies do we use?"
            content={
              <div className="flex flex-col gap-5">
                <p>
                  We use several types of Cookies on the Platform, each serving
                  a specific purpose:
                </p>
                <ul className="ml-8 list-disc flex flex-col gap-5">
                  <li>
                    <span className="text-elr-black">Essential Cookies:</span>{' '}
                    These cookies are strictly necessary for the Platform to
                    function properly. They enable features like user login,
                    shopping cart functionality, and secure payment processing.
                    Disabling these cookies may hinder your experience on the
                    Platform.
                  </li>
                  <li>
                    <span className="text-elr-black">Performance Cookies:</span>{' '}
                    These cookies collect information on how you use the
                    Platform, such as the pages you visit, the time spent on
                    each page, and any errors encountered. This data helps us
                    analyze usage patterns, improve website performance, and
                    personalize your experience.
                  </li>
                  <li>
                    <span className="text-elr-black">
                      Functionality Cookies:
                    </span>{' '}
                    These cookies remember your preferences (e.g., language,
                    location) and settings to provide a more personalized and
                    convenient experience.
                  </li>
                  <li>
                    <span className="text-elr-black">
                      Targeting or Advertising Cookies:
                    </span>{' '}
                    These cookies track your browsing activity across different
                    websites and platforms. They are used to deliver targeted
                    advertisements or marketing messages relevant to your
                    interests.
                  </li>
                </ul>
              </div>
            }
          />

          <SubSection
            title="Third-Party Cookies"
            content={`We may also allow certain third-party service providers to set and access Cookies on your device through the Platform. These third-party service providers use Cookies to provide specific services, such as analytics, advertising, and social media integration. We do not control the use of Cookies by these third parties, and you should refer to their respective privacy policies for more information about their practices.`}
          />

          <SubSection
            title="How to Manage Cookies"
            content={
              <div className="flex flex-col gap-5">
                <p>
                  Most web browsers allow you to control or disable Cookies
                  through their settings. You can choose to:
                </p>
                <ul className="ml-8 list-disc flex flex-col gap-2">
                  <li>Accept all Cookies</li>
                  <li>Block all Cookies</li>
                  <li>Be notified when a Cookie is placed</li>
                </ul>
                <p>
                  Please note that disabling certain Cookies may affect your
                  experience on the Platform. You may not be able to access
                  certain features or functionalities that rely on Cookies.
                </p>
              </div>
            }
          />
          <SubSection
            title="Your Consent"
            content={`By using the Platform, you consent to the use of Cookies as described in this Policy. You can withdraw your consent at any time by adjusting your browser settings. However, please remember that this may affect your experience on the Platform.`}
          />
          <SubSection
            title="Changes to the Cookie Policy"
            content={`We may update this Cookie Policy from time to time to reflect changes in our practices or applicable laws. We will post the revised version on the Platform and update the &quot;Effective Date&quot; at the top of this Policy. We encourage you to review this Cookie Policy periodically to stay informed of any updates.`}
          />
          <SubSection
            title="Contact Us"
            content={
              <p>
                If you have any questions regarding this Cookie Policy, please
                contact us through the Errandlr app or by email at{' '}
                <a
                  href="mailto:support@errandlr.com"
                  className="text-elr-purple"
                >
                  support@errandlr.com
                </a>
                .
              </p>
            }
          />
          <PoliciesAppBanner />
        </div>
      </div>
      <Footer hideTopFooter />
    </>
  );
};

export default Landing;

const TopSection: React.FC = () => (
  <div>
    <TopNav isDefault />
    <div className="text-center mx-5 max-w-6.5xl md:mx-auto">
      <div className="text-xl my-12 py-16 bg-elr-gray relative rounded-xl overflow-hidden">
        <img
          src={PurpleVector}
          alt=""
          className="absolute md:bottom-0 w-3/12 md:w-32 -bottom-2 -left-2 md:left-0"
        />
        <img
          src={YellowVector}
          alt=""
          className="absolute md:top-0 -right-2 md:right-0 w-3/12 md:w-32 -top-2"
        />
        <p className="md:text-5xl text-center text-3xl">Cookie Policy</p>
        <p className="text-elr-black text-opacity-60 text-base pt-4">
          Effective Date: 07 June, 2024
        </p>
      </div>
    </div>
  </div>
);
