import ProfileNavigationMenu from 'app/components/menu/ProfileNavigationMenu';
import TopNavigationMenu from 'app/components/menu/TopNavigationMenu';
import {
  BoxIcon,
  CheckboxIcon,
  CopyDoubleIcon,
  EBikeIcon,
  SearchIcon,
} from 'app/components/SvgAssets';
import { ERRANDLR_REDIRECT_URL } from 'app/constants';
import { getTabClassNames } from 'app/dispatcher/modules/components/ClassUtils';
import {
  useFetchCommerceNewRequests,
  useFetchCommerceUpcomingRequests,
  useFetchDeliveriesMade,
  useFetchNewBatchRequests,
} from 'app/hooks/requests/dashboard';
import { useGetProfile } from 'app/hooks/user';
import copyToClipboard from 'app/utilities/copyToClipboard';
import { publicRoutes } from 'app/utilities/routes';
import { Request, RequestTypes } from 'app/utilities/types/shared';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { ElrButton, ElrPageTitle } from 'ui-components';
import {
  ElrButtonPreIcon,
  ElrDropdown,
} from 'ui-components/components/ElrButton';
import { BatchDelivery, BatchDeliveryTabNames } from './BatchDeliveryOutlet';
import { DashboardCards } from './DashboardCards';
import { SingleDelivery, SingleDeliveryTabNames } from './SingleDeliveryOutlet';

interface Props {}

/* eslint-disable-next-line no-shadow */
export enum DashboardCardsState {
  NEW_REQUESTS = 'New Requests',
  IN_TRANSIT = 'In-Transit',
  DELIVERED = 'Delivered',
}

const DashboardContainer: React.FC<Props> = () => {
  const {
    accountInformation: { slug = '' },
  } = useGetProfile();

  const businessDeliveryLink = `${ERRANDLR_REDIRECT_URL}/${slug}`;

  const [singleDeliveryActiveTab, setSingleDeliveryActiveTab] = useState(
    SingleDeliveryTabNames.NEW_REQUESTS
  );
  const [batchDeliveryActiveTab, setBatchDeliveryActiveTab] = useState(
    BatchDeliveryTabNames.ONGOING
  );
  const [underlinePosition, setUnderlinePosition] = useState({
    left: 0,
    width: 0,
  });
  const newRequestsTabRef = useRef<HTMLButtonElement | null>(null);
  const inTransitTabRef = useRef<HTMLButtonElement | null>(null);
  const deliveredTabRef = useRef<HTMLButtonElement | null>(null);
  const [isExpanded, setIsExpanded] = useState<number | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [, setRequests] = useState<Request[]>([]);

  const {
    latestData: newBatchRequestsData,
    isLoading: isLoadingNewBatchRequests,
  } = useFetchNewBatchRequests('fulfilment');

  const {
    latestData: newRequestsData,
    fetchNextPage: fetchNextNewRequestsPage,
    hasNextPage: hasNextNewRequestsPage,
    isFetchingNextPage,
    isLoading: isLoadingNewRequests,
  } = useFetchCommerceNewRequests();

  const {
    latestData: upcomingRequestsData,
    fetchNextPage: fetchNextUpcomingRequestsPage,
    hasNextPage: hasNextUpcomingRequestsPage,
    isLoading: isLoadingUpcomingRequests,
  } = useFetchCommerceUpcomingRequests();

  const {
    latestData: deliveriesMadeRequestData,
    fetchNextPage: fetchNextDeliveriesMadePage,
    hasNextPage: hasNextDeliveriesMadePage,
    isLoading: isLoadingDeliveriesRequests,
  } = useFetchDeliveriesMade();

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return undefined;

    const handleScroll = () => {
      if (isFetchingNextPage) return;

      const { scrollTop, scrollHeight, clientHeight } = container;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        if (hasNextNewRequestsPage) fetchNextNewRequestsPage?.();
        if (hasNextUpcomingRequestsPage) fetchNextUpcomingRequestsPage?.();
        if (hasNextDeliveriesMadePage) fetchNextDeliveriesMadePage?.();
      }
    };

    container.addEventListener('scroll', handleScroll);

    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, [
    isFetchingNextPage,
    hasNextNewRequestsPage,
    hasNextUpcomingRequestsPage,
    hasNextDeliveriesMadePage,
  ]);

  useEffect(() => {
    if (newRequestsData?.docs) {
      setRequests((prev) => [...prev, ...newRequestsData.docs]);
    }
  }, [newRequestsData]);

  const deliveryType = [
    { value: 'singleDelivery', label: 'Single Delivery' },
    { value: 'batchDelivery', label: 'Batch Delivery' },
  ];

  const [selectedOption, setSelectedOption] = useState(deliveryType[0]);

  const isSingleDelivery = selectedOption.value === 'singleDelivery';

  const onDeliveryChange = (value: string) => {
    const selected = deliveryType.find((option) => option.value === value);
    setSelectedOption(selected || deliveryType[0]);
  };

  useEffect(() => {
    if (!selectedOption) {
      setSelectedOption(deliveryType[0]);
    }
  }, [selectedOption]);

  const toggleRow = (index: number) => {
    setIsExpanded(isExpanded === index ? null : index);
  };

  const getSingleDeliveryTabNames = () => {
    if (isSingleDelivery) {
      return [
        { name: SingleDeliveryTabNames.NEW_REQUESTS, ref: newRequestsTabRef },
        { name: SingleDeliveryTabNames.IN_TRANSIT, ref: inTransitTabRef },
        { name: SingleDeliveryTabNames.DELIVERED, ref: deliveredTabRef },
      ];
    }
    return [
      { name: BatchDeliveryTabNames.ONGOING, ref: newRequestsTabRef },
      { name: BatchDeliveryTabNames.COMPLETED, ref: inTransitTabRef },
    ];
  };

  const tabs = getSingleDeliveryTabNames();

  useEffect(() => {
    const activeTab = isSingleDelivery
      ? singleDeliveryActiveTab
      : batchDeliveryActiveTab;

    const activeTabRef = tabs.find((tab) => tab.name === activeTab)?.ref
      .current;

    if (activeTabRef) {
      const activeTabWidth = activeTabRef.offsetWidth;
      const underlineWidth = isSingleDelivery ? 110 : 90;
      setUnderlinePosition({
        left: activeTabRef.offsetLeft + (activeTabWidth - underlineWidth) / 2,
        width: underlineWidth,
      });
    }
  }, [singleDeliveryActiveTab, batchDeliveryActiveTab]);

  const isActiveTab = (tabName: string) =>
    isSingleDelivery
      ? singleDeliveryActiveTab === tabName
      : batchDeliveryActiveTab === tabName;

  const handleTabClick = (tabName: string) => {
    if (isSingleDelivery) {
      setSingleDeliveryActiveTab(tabName as SingleDeliveryTabNames);
    } else {
      setBatchDeliveryActiveTab(tabName as BatchDeliveryTabNames);
    }
  };

  const newBatchRequests =
    newBatchRequestsData?.docs.map((request: Request) => ({
      ...request,
      status:
        request.status === RequestTypes.open
          ? RequestTypes.open
          : request.status,
    })) || [];

  const newRequests =
    newRequestsData?.docs.map((request: Request) => ({
      ...request,
      status:
        request.status === RequestTypes.open
          ? RequestTypes.open
          : request.status,
    })) || [];

  const inTransitRequests =
    upcomingRequestsData?.docs.map((request: Request) => ({
      ...request,
      status:
        request.status === RequestTypes.accepted
          ? RequestTypes.accepted
          : request.status,
    })) || [];

  const deliveredRequests =
    deliveriesMadeRequestData?.docs.map((request: Request) => ({
      ...request,
      status:
        request.status === RequestTypes.completed
          ? RequestTypes.completed
          : request.status,
    })) || [];

  const singleDeliveryFilteredDocuments = (() => {
    switch (singleDeliveryActiveTab) {
      case SingleDeliveryTabNames.NEW_REQUESTS:
        return newRequests;
      case SingleDeliveryTabNames.IN_TRANSIT:
        return inTransitRequests;
      case SingleDeliveryTabNames.DELIVERED:
        return deliveredRequests;
      default:
        return [];
    }
  })();

  const batchDeliveryFilteredDocuments = (() => {
    switch (batchDeliveryActiveTab) {
      case BatchDeliveryTabNames.ONGOING:
        return newBatchRequests;
      case BatchDeliveryTabNames.COMPLETED:
        return deliveredRequests;
      default:
        return [];
    }
  })();

  const cardData = [
    {
      title: DashboardCardsState.NEW_REQUESTS,
      count: newRequestsData?.totalDocs || 0,
      Icon: <CheckboxIcon fill="#FFF9E4" bgFill="#FCCD33" />,
    },
    {
      title: DashboardCardsState.IN_TRANSIT,
      count: upcomingRequestsData?.totalDocs || 0,
      Icon: <EBikeIcon fill="#EFEDFE" bgFill="#5A4AF2" />,
    },
    {
      title: DashboardCardsState.DELIVERED,
      count: deliveriesMadeRequestData?.totalDocs || 0,
      Icon: <BoxIcon />,
    },
  ];

  const _renderLoadState = () => {
    const isLoading =
      isLoadingNewRequests ||
      isLoadingUpcomingRequests ||
      isLoadingNewBatchRequests ||
      isLoadingDeliveriesRequests;

    if (isLoading) {
      return (
        <tr>
          <td
            colSpan={4}
            className="text-center italic py-8 text-elr-black-300"
          >
            Loading...
          </td>
        </tr>
      );
    }

    if (
      (isSingleDelivery && !singleDeliveryFilteredDocuments.length) ||
      (!isSingleDelivery && !batchDeliveryFilteredDocuments.length)
    ) {
      const emptyMessages: Record<string, React.ReactNode> = {
        [SingleDeliveryTabNames.NEW_REQUESTS]: (
          <>
            There are no new requests.&nbsp;
            <span>
              Create a&nbsp;
              <a
                href={publicRoutes.estimate}
                className="cursor-pointer underline"
                target="_blank"
                rel="noreferrer"
              >
                new order here
              </a>
            </span>
          </>
        ),
        [SingleDeliveryTabNames.IN_TRANSIT]: (
          <>There are no requests in transit at the moment.</>
        ),
        [SingleDeliveryTabNames.DELIVERED]: (
          <>No deliveries have been made yet.</>
        ),
      };

      return (
        <tr>
          <td
            colSpan={4}
            className="text-center italic py-8 text-elr-black-300"
          >
            {isSingleDelivery
              ? emptyMessages[singleDeliveryActiveTab] || 'No data available.'
              : 'No batch requests available.'}
          </td>
        </tr>
      );
    }

    return null;
  };

  const handleCopy = () => {
    copyToClipboard(businessDeliveryLink);
    toast.success('Business Delivery link copied');
  };

  return (
    <div className="">
      <ElrPageTitle title="Errandlr - Logistics" />
      <ProfileNavigationMenu>
        <div className="flex justify-between w-full md:mx-auto items-center">
          <TopNavigationMenu containerClass="md:mr-0" />
        </div>
        <div className="bg-elr-gray flex flex-col py-8 px-7 md:pt-3 md:px-20">
          <div className="mb-6 text-elr-black font-normal font-serif leading-tight">
            <h3 className="pt-6 text-xl mb-2">Logistics</h3>
            <p className="text-elr-black-300 text-base">
              View and manage deliveries
            </p>
          </div>

          <DashboardCards data={cardData} />

          <section className="px-8 py-4 flex justify-between bg-elr-white-400 mb-5">
            <div className="md:flex md:flex-row items-center flex-col">
              <p>Business Delivery Link &nbsp; - &nbsp;</p>
              <div className="flex items-center gap-2 align-middle pt-2 md:pt-0">
                <p className="text-elr-purple bg-elr-gray py-2 px-3">
                  {businessDeliveryLink}
                </p>
                <div className="md:hidden flex p-2 bg-elr-gray">
                  <CopyDoubleIcon fill="#5a4af2" />
                </div>
              </div>
            </div>
            <div className="md:flex gap-x-4 hidden">
              <ElrButton
                text={'Learn More'}
                onClick={() => true}
                className="bg-elr-gray text-elr-black rounded-md py-3 px-5"
              />
              <ElrButtonPreIcon
                text={'Copy Link'}
                icon={<CopyDoubleIcon />}
                onClick={handleCopy}
                className="bg-elr-black flex items-center justify-center text-elr-white-400 rounded-md py-3 px-5"
              />
            </div>
          </section>

          <section className="bg-elr-white-400 mb-8">
            <div className="py-4 md:px-8 px-2 md:flex justify-between border-b-2 border-elr-gray">
              <div className="flex self-center px-4 pt-2 md:px-0 md:pt-0 text-lg md:gap-16 gap-12 border-elr-black-200 relative">
                {tabs.map((tab) => (
                  <button
                    key={tab.name}
                    type="button"
                    ref={tab.ref}
                    className={getTabClassNames(isActiveTab(tab.name))}
                    onClick={() => handleTabClick(tab.name)}
                  >
                    {tab.name}
                  </button>
                ))}
                <div
                  className={`rounded-t-lg h-1 bg-elr-black absolute transition-all duration-300 ease-in-out`}
                  style={{
                    width: `${underlinePosition.width}px`,
                    left: `${underlinePosition.left}px`,
                    bottom: '-24px',
                  }}
                />
              </div>
              <div className="flex gap-4 md:flex-row flex-col">
                <div className="mt-12 md:mt-0">
                  <ElrDropdown
                    options={deliveryType}
                    borderRadius="0"
                    inputClassName=" h-12 flex align-center justify-center"
                    currentValue={selectedOption?.label || ''}
                    onChange={onDeliveryChange}
                    className="h-full"
                  />
                </div>
                <div className="md:flex justify-between gap-4 bg-elr-gray px-4 py-2 rounded-sm w-72 hidden">
                  <input
                    type="text"
                    placeholder="search address or tracking ID"
                    className="outline-none w-full bg-elr-gray border-none focus:outline-0"
                  />
                  <SearchIcon />
                </div>
              </div>
            </div>
            <div
              ref={containerRef}
              className="overflow-auto h-fit w-full relative"
            >
              {isSingleDelivery ? (
                <SingleDelivery
                  deliveries={singleDeliveryFilteredDocuments}
                  activeTab={singleDeliveryActiveTab}
                  toggleRow={toggleRow}
                  isExpanded={isExpanded}
                  renderLoadState={_renderLoadState}
                />
              ) : (
                <BatchDelivery
                  deliveries={batchDeliveryFilteredDocuments}
                  activeTab={batchDeliveryActiveTab}
                  toggleRow={toggleRow}
                  isExpanded={isExpanded}
                  renderLoadState={_renderLoadState}
                />
              )}
            </div>
          </section>
        </div>
      </ProfileNavigationMenu>
    </div>
  );
};

export default DashboardContainer;
