import SupportHeader from 'app/utilities/HttpSupportHeader';
import { axiosDefault as axios } from 'app/utilities/axios';
import { toast } from 'react-toastify';
import { IdentityVerificationRequest } from './d';

interface Params {
  userId: string;
  nextPage: number;
  limit: number;
}

export const httpGetIdentityVerificationRequests = async ({
  userId,
  nextPage,
  limit,
}: Params): Promise<IdentityVerificationRequest[]> => {
  try {
    const response = await axios.get(
      `/operator/${userId}/manual-verification`,
      {
        params: { page: nextPage, limit },
        ...SupportHeader(),
      }
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpVerifyCustomer = async ({
  userId,
  status,
  senderId,
}: {
  userId: string;
  status: string;
  senderId: string;
}): Promise<void> => {
  try {
    const response = await axios.post(
      `/operator/${userId}/manual-verification`,
      {
        status,
        senderId,
      },
      SupportHeader()
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};
