import { PrintInvoiceIcon } from 'app/components/SvgAssets';
import {
  getDropdownIconClassNames,
  getRequestStatusClassNames,
} from 'app/operator/components/ClassUtils';
import { currencyFormatter, getFirstItem } from 'app/utilities/helpers';
import { Request, RequestTypes } from 'app/utilities/types/shared';
import { capitalize } from 'lodash';
import { DateTime } from 'luxon';
import { Fragment, useState } from 'react';
import { ElrButtonPreIcon } from 'ui-components/components/ElrButton';
import Dropdown from 'assets/images/dropdown.svg';

/* eslint-disable-next-line no-shadow */
export enum BatchDeliveryTabNames {
  ONGOING = 'Ongoing',
  COMPLETED = 'Completed',
}

interface BatchDeliveryProps {
  deliveries: Request[];
  activeTab: BatchDeliveryTabNames;
  toggleRow: (index: number) => void;
  isExpanded: number | null;
  renderLoadState: () => React.ReactNode;
}

export const BatchDelivery: React.FC<BatchDeliveryProps> = ({
  deliveries,
  activeTab,
  toggleRow,
  isExpanded,
  renderLoadState,
}) => {
  const formatDateTime = (createdAt: string) => {
    const dateTime = DateTime.fromISO(createdAt);
    return dateTime.toFormat('yyyy-LL-dd');
  };
  const [detailsIsExpanded, setDetailsIsExpanded] = useState<number | null>(
    null
  );

  const toggleShowDetails = (currentIndex: number) => {
    setDetailsIsExpanded((prev) =>
      prev === currentIndex ? null : currentIndex
    );
  };

  const renderBatchDeliveryTableHeader = () => (
    <thead>
      <tr className="border-b-2 border-elr-gray text-elr-black">
        <th className="font-normal py-7 text-left md:pl-32 pl-20 flex-grow truncate">
          Location
        </th>
        {activeTab === BatchDeliveryTabNames.ONGOING && (
          <th className="font-normal py-7 text-center flex-grow">
            No. of Deliveries
          </th>
        )}
        {activeTab === BatchDeliveryTabNames.COMPLETED && (
          <th className="font-normal py-7 text-center flex-grow">Recipients</th>
        )}
        <th className="font-normal py-7 text-center flex-grow">Tracking Id</th>
        <th className="font-normal py-7 text-center flex-grow">
          Cumulative Cost.
        </th>
        <th className="font-normal py-7 text-center w-24" />
      </tr>
    </thead>
  );

  const renderBatchDeliveryTableRow = (batch: Request, index: number) => (
    <Fragment key={batch.trackingId}>
      <tr
        className="text-center border border-x-0 border-elr-gray-500 cursor-pointer"
        onClick={() => toggleRow(index)}
      >
        <td className="text-left py-5 pl-7 text-elr-black truncate max-w-[150px] md:max-w-xs overflow-hidden">
          <div className="truncate">
            {`${batch.pickupArea?.area || 'N/A'} to ${batch.destinationArea?.area || 'N/A'}`}
          </div>
          <div className="text-elr-black-300 text-sm pt-1">
            {formatDateTime(batch.createdAt)}
          </div>
        </td>
        <td className="py-5 text-center text-elr-black text-opacity-60">
          {batch.mergedInformation?.length || 0}
        </td>
        <td className="py-5 text-center text-elr-black text-opacity-60">
          {batch.trackingId || 'N/A'}
        </td>
        <td className="py-5 text-center text-elr-black text-opacity-60">
          {currencyFormatter(batch.priceEstimate)}
        </td>
        <td className="py-5 relative">
          <button
            type="button"
            className="focus:outline-none"
            onClick={() => toggleRow(index)}
          >
            <img
              src={Dropdown}
              alt="Dropdown"
              className={getDropdownIconClassNames(isExpanded === index)}
            />
          </button>
        </td>
      </tr>
      {isExpanded === index && renderBatchDeliveryExpandedRow(batch, index)}
    </Fragment>
  );

  const renderBatchDeliveryExpandedRow = (batch: Request, index: number) => {
    const {
      deliverToInformation,
      pickupAddress,
      designatedAgent,
      destinationArea,
      mergedInformation,
    } = batch;
    const recipientInfo = getFirstItem(deliverToInformation);
    const riderName =
      designatedAgent?.firstname || designatedAgent?.lastname
        ? `${designatedAgent.firstname || ''} ${designatedAgent.lastname || ''}`
        : 'N/A';
    const riderPhone = designatedAgent?.phone || 'N/A';

    return (
      <tr className="text-left">
        <td colSpan={5} className="p-5 text-elr-black md:pl-32 pl-4 w-full">
          {activeTab === BatchDeliveryTabNames.ONGOING && (
            <>
              {mergedInformation.map((merged) => (
                <div
                  className="flex flex-col gap-4 bg-elr-gray p-8"
                  key={merged.createdAt}
                >
                  <div
                    className="flex justify-between align-middle items-center cursor-pointer"
                    onClick={() => toggleShowDetails(index)}
                  >
                    <div className="flex gap-4 align-middle items-center">
                      <p className="w-8 h-8 rounded-full font-bold text-sm bg-elr-white-400 flex justify-center items-center">
                        {index + 1}
                      </p>
                      <div className="space-y-5 relative">
                        <div className="flex items-center">
                          <div className="inline-block w-[8px] h-[8px] rounded-full bg-elr-purple" />
                          <p className="ml-3">
                            {merged.pickupAddress?.fullAddress || 'N/A'}
                          </p>
                        </div>
                        <div className="absolute left-1 top-0 bottom-0 border-l-2 border-dashed h-6 border-elr-gray-200" />
                        <div className="flex items-start">
                          <span className="w-[8px] h-[8px] bg-elr-yellow rounded-sm mt-1.5" />
                          <p className="ml-3">
                            {merged.deliverToInformation.address.fullAddress ||
                              'N/A'}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-center align-middle items-center gap-10">
                      {activeTab === BatchDeliveryTabNames.ONGOING && (
                        <div className="py-5 text-center text-elr-black text-opacity-60">
                          <p
                            className={getRequestStatusClassNames(
                              merged.deliverToInformation.status
                            )}
                          >
                            {merged.deliverToInformation.status ===
                            RequestTypes.accepted
                              ? 'Assigned'
                              : capitalize(merged.deliverToInformation.status)}
                          </p>
                        </div>
                      )}
                      <div className="py-5 relative">
                        <button
                          type="button"
                          className="focus:outline-none  opacity-60"
                        >
                          <img
                            src={Dropdown}
                            alt="Dropdown"
                            className={getDropdownIconClassNames(
                              detailsIsExpanded === index
                            )}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                  {detailsIsExpanded === index && (
                    <div className="flex w-full p-8 bg-elr-white-400 md:gap-40 gap-16">
                      <div className="flex flex-col">
                        <p>Recipient Details</p>
                        <p className="text-elr-black text-opacity-60 py-1">
                          {merged.deliverToInformation.name || 'N/A'}
                        </p>
                        <p className="text-elr-black text-opacity-60">
                          {merged.deliverToInformation.phone || 'N/A'}
                        </p>
                      </div>
                      <div className="flex flex-col">
                        <p>Product Info</p>
                        <p className="text-elr-black text-opacity-60 py-1">
                          {merged.deliverToInformation.packageType}
                        </p>
                        <p className="text-elr-black text-opacity-60">
                          {merged.deliverToInformation.packageValue}
                        </p>
                      </div>
                      <div className="flex flex-col">
                        <p>Rider Details</p>
                        <p className="text-elr-black text-opacity-60 py-1">
                          {riderName}
                        </p>
                        <p className="text-elr-black text-opacity-60">
                          {riderPhone}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </>
          )}
          {activeTab === BatchDeliveryTabNames.COMPLETED && (
            <div className="flex flex-col gap-4">
              <div className="flex justify-between align-middle items-center">
                <div className="space-y-5 relative">
                  <div className="flex items-center">
                    <div className="inline-block w-[8px] h-[8px] rounded-full bg-elr-purple" />
                    <p className="ml-3">
                      {pickupAddress?.fullAddress || 'N/A'}
                    </p>
                  </div>
                  <div className="absolute left-1 top-0 bottom-0 border-l-2 border-dashed h-6 border-elr-gray-200" />
                  <div className="flex items-start">
                    <span className="w-[8px] h-[8px] bg-elr-yellow rounded-sm mt-1.5" />
                    <p className="ml-3">
                      {destinationArea?.area ||
                        recipientInfo?.address.fullAddress ||
                        'N/A'}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex w-full p-8 bg-elr-gray md:gap-40 gap-16">
                <div className="flex flex-col">
                  <p>Recipient Details</p>
                  <p className="text-elr-black text-opacity-60 py-1">
                    {recipientInfo?.name || 'N/A'}
                  </p>
                  <p className="text-elr-black text-opacity-60">
                    {recipientInfo?.phone || 'N/A'}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p>Product Info</p>
                  <p className="text-elr-black text-opacity-60 py-1">
                    {recipientInfo?.packageType}
                  </p>
                  <p className="text-elr-black text-opacity-60">
                    {recipientInfo?.packageValue}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p>Rider Details</p>
                  <p className="text-elr-black text-opacity-60 py-1">
                    {riderName}
                  </p>
                  <p className="text-elr-black text-opacity-60">{riderPhone}</p>
                </div>
              </div>
              <div className="flex justify-end">
                <ElrButtonPreIcon
                  text={'Print Invoice'}
                  icon={<PrintInvoiceIcon />}
                  className="bg-elr-black flex items-center justify-center text-elr-white-400 rounded-md py-3 px-5"
                />
              </div>
            </div>
          )}
        </td>
      </tr>
    );
  };

  return (
    <table className="table-auto w-full">
      {renderBatchDeliveryTableHeader()}
      <tbody>
        {deliveries.length
          ? deliveries.map((batch, index) =>
              renderBatchDeliveryTableRow(batch, index)
            )
          : renderLoadState()}
      </tbody>
    </table>
  );
};
