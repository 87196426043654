import { useLocation } from 'react-router';
import ExperienceForm from './ExperienceForm';

const ExperienceEdit = () => {
  const { state } = useLocation();
  const experience = state?.experience;
  return (
    <div className="flex justify-center pt-16">
      <div className="flex flex-col justify-center w-full px-4 md:w-8/12 md:px-0">
        <ExperienceForm experience={experience} />
      </div>
    </div>
  );
};

export default ExperienceEdit;
