import { ElrModal } from 'ui-components';
import qrCodeSVG from 'assets/images/qr-code.svg';

interface QrCodeModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const QrCodeModal: React.FC<QrCodeModalProps> = ({
  isOpen,
  onClose,
}) => (
  <ElrModal isOpen={isOpen} onClose={onClose}>
    <div className="py-5 text-center">
      <h3 className="text-3xl font-semibold pt-4">
        Proceed to the Errandlr App
      </h3>
      <p className="pt-2 w-10/12 mx-auto text-elr-black-300">
        Scan the QR code below to continue through the Errandlr Mobile App.
      </p>
      <div className="flex justify-center py-5">
        <img src={qrCodeSVG} alt="QR Code" className="w-9/12" />
      </div>
    </div>
  </ElrModal>
);
