import React from 'react';

import ProductForm from './components/ProductForm';

const SellProduct: React.FC = () => {
  const submitForm = () => {};

  return <ProductForm onSubmit={submitForm} />;
};

export default SellProduct;
