import { ITenantData } from 'app/api/d';

export type TPackageTypeCode = {
  DEFAULT: '000';
  SMALL_PARCEL: '001';
  LARGE_PARCEL: '002';
  DOCUMENT: '003';
  FOOD: '004';
};

export type TPackageTypeLabel = {
  DEFAULT: 'Default';
  SMALL_PARCEL: 'Small Parcel';
  LARGE_PARCEL: 'Large Parcel';
  DOCUMENT: 'Document';
  FOOD: 'Food';
};

export type dropoffLocationsResponse = Array<{
  destination: LocationTagValues;
  distance: number;
  duration: number;
  originDestination: string;
}>;

export interface EstimateProps {
  dropoffLocations: dropoffLocationsResponse;
  originAddresses: Array<string>;
  pickupLocation: LocationTagValues;
  parcelCode: string;
}

export type LocationTagValues = {
  id: string;
  label: string;
  distance: number;
  duration: number;
};

export type dropoffLocations = Array<LocationTagValues>;

export interface HttpLocationValues {
  pickupLocation: LocationTagValues;
  dropoffLocations: dropoffLocations;
  isSegmentedDelivery?: boolean;
  parcelCode: string;
  lockerSize?: string;
}

export interface HttpDispatchResponse {
  estimate: number;
  batchEstimate: number;
  batchLabel: string;
  estimateLabel: string;
  batch: boolean;
  reasons: Array<string>;
  geoId: string;
  status: 'success' | 'failed';
  routes: EstimateProps;
  parcelCode: string;
}

export type FileStructureResponse = {
  key: string;
  url?: string;
  originalFilename: string;
  type: string;
  _id?: string;
};

export interface LocationWrapperValues {
  pickupLocation: LocationTagValues;
  dropoffLocations: dropoffLocations;
  parcelCode: string;
}
export type ValueOf<T> = T[keyof T];
export interface AgentInterface {
  email: string;
  agentUUID: string;
  firstname: string;
  id: string;
  lastname: string;
  phone: string;
  trips: string[];
  tripsCount: number;
  vehicleType: string;
  status: string;
  country: string;
  state: string;
  vehiclePlateNumber: string;
  driversLicense: string | object | undefined;
  additionalId: string | object | undefined;
  vehicleRegistrationDocument: string | object | undefined;
  bankDetails?: BankDetails;
}

export interface ValueAgentInterface {
  email: string;
  agentUUID: string;
  firstname: string;
  id: string;
  lastname: string;
  phone: string;
  trips: string[];
  tripsCount: number;
  vehicleType: string;
  status: string;
  country: string;
  state: string;
  vehiclePlateNumber: string;
  driversLicense: string | object | undefined;
  additionalId: string | object | undefined;
  vehicleRegistrationDocument: string | object | undefined;
  bankDetails?: BankDetails;
}

export interface SharedAccount {
  account: {
    id: string;
    firstname: string;
    lastname: string;
    trips: number;
    phone: string;
  };
  companyName: string;
  id: string;
}

interface MarketItem {
  id: string;
  name: string;
  price: number;
  quantity: number;
  _id: string;
}

interface PurchasedAtMarket {
  id: string;
  name: string;
  _id: string;
}

interface MarketDetails {
  items: MarketItem[];
  destinationAddress: string;
  totalAmount: number;
  purchasedAtMarket: PurchasedAtMarket;
}

export interface MarketTransaction {
  id: string;
  market: MarketDetails;
  _id: string;
}

export interface Request {
  deliverToInformation: DestinationInterface[];
  id: string;
  batch: boolean;
  tag: string;
  mergedInformation: MergedInformation[];
  pickupAddress: PickupAddressesInterface;
  pickupAddresses: PickupAddressesInterface[];
  pickupArea: {
    latitude: string;
    longitude: string;
    area: string;
  };
  pickupNotes: string;
  priceEstimate: number;
  outsourcedAt: string;
  afterChargeEstimate: number;
  destinationArea: { area: string; latitude: string; longitude: string };
  riderMarkedAsCompleted: boolean;
  requestAcceptedAt: null;
  requestCompletedAt: null;
  senderInformation: { data: { email?: string; name: string; phone: string } };
  country: keyof ITenantData;
  state: string;
  trackingId: string;
  type: string;
  status: string;
  vehicleType: string;
  parcelCode: string;
  totalDistanceEstimate: string;
  distance: string;
  createdAt: string;
  designatedAgent: AgentInterface;
  acceptedBy: SharedAccount;
  mapUrl: string;
  requestOpenedBy: string;
  paymentOption?: string;
  promoOriginalPrice?: string;
  payWithCash: boolean;
  assignedTo?: {
    companyName: string;
  };
  multiplePickup?: boolean;
  tracking_log: TrackInterface[];
  agentsWithInterest: AgentsWithInterestType[];
  businessName?: string;
  deliveryTimelineLabel: string;
  deliveryNotes: string;
  businessPays?: boolean;
  deliveryType: string;
  estimatedTime?: string;
  verificationCodes?: {
    pickups: {
      [key: string]: string;
    };
    destinations: {
      [key: string]: string;
    };
    activationCode: {
      [key: string]: string;
    };
  };
  marketTransaction?: MarketTransaction;
}

export type AgentsWithInterestType = {
  _id: string;
  firstname: string;
  lastname: string;
  phone: string;
  location: {
    type: string;
    coordinates: [longitude: number, latitude: number];
  };
  tripsCount: number;
  id: string;
};

export type TrackInterfaceRider = {
  _id: string;
  firstname: string;
  phone: string;
  tripsCount: number;
  id: string;
};

export interface TrackInterface {
  event: string;
  timestamp: string;
  parent_message: string;
  _id: string;
  rider?: TrackInterfaceRider;
  sorting_station?: {
    _id: string;
    companyName: string;
    mainAddress: {
      addressNumber: string;
      streetName: string;
      localGovtArea: string;
      registeredCity: string;
      _id: string;
    };
    id: string;
  };
}

export interface DestinationInterface {
  state: string;
  country: string;
  address: {
    fullAddress: string;
    latitude: string;
    longitude: string;
    duration: string;
    distance: string;
  };
  name: string;
  phone: string;
  deliveryNotes?: string;
  packageValue: number;
  packageType?: string;
  id: number;
  order: number;
  status?: string;
  trackingId?: string;
  requestOpenedBy?: string;
}

export interface PickupAddressesInterface {
  fullAddress: string;
  latitude: string;
  longitude: string;
  duration?: string;
  distance?: string;
  trackingId: string;
  _id: string;
  id: string;
  senderInformation: {
    data: { email?: string; name: string; phone: string };
  };
  status?: string;
  lockerRequest?: boolean;
  locker?: {
    lockerMerchant: string;
    boxId: string;
    sizeId: string;
    boxName: string;
    boxAddress: string;
    dropCode: string;
    collectCode: string;
    boxLockerNumber: string;
    createdAt: string;
    updatedAt: string;
  };
}

export interface UserDetailsValues {
  state?: string;
  email?: string;
  name: string;
  phone: string;
}

type BankDetails = {
  bankCode: string;
  bankName: string;
  accountName: string;
  accountNumber: string;
};
export interface AgentFormInterface {
  firstname: string;
  lastname: string;
  phone: string;
  alternativePhone: string;
  country: string;
  state: string;
  vehiclePlateNumber: string;
  driversLicense?: string | object;
  additionalId?: string | object;
  vehicleRegistrationDocument?: string | object;
  bankDetails?: BankDetails;
}

export interface AgentPayloadInterface {
  id: string;
  agentUUID?: string;
  email: string;
  firstname: string;
  lastname: string;
  vehicleType: string;
  status: string;
  phone: string;
  country: keyof ITenantData;
  state: string;
  vehiclePlateNumber: string;
  driversLicense: string | object | undefined;
  additionalId: string | object | undefined;
  vehicleRegistrationDocument: string | object | undefined;
  mainAddress: {
    addressLine1: string;
    city: string;
    country: string;
  };
  location: {
    coordinates: number[];
  };
}

export interface NearbyAgentsInterface {
  id: string;
  name: string;
  phone: string;
  distance: {
    text: string;
    value: string;
  };
}

export type OptionType = { label: string; value: string };
export interface IOptions {
  value: string;
  label: string;
  id: number;
}

export interface IPaginateOptions {
  limit?: number;
  offset?: number;
  sort?: 'desc' | 'asc' | '-1' | string;
}

export interface PaginateResponseInterface {
  totalDocs: number;
  offset: number;
  limit: number;
  totalPages: number;
  page: number;
  pagingCounter?: number;
  hasPrevPage?: boolean;
  hasNextPage?: boolean;
  prevPage?: number;
  nextPage?: number;
}

export interface GeoLocationData {
  geolocation: {
    lat: number;
    lng: number;
  };
  place: {
    city: string;
    country: string;
    localGovt: string;
    state: string;
    postalCode: string;
  };
}

export interface AgentsResult {
  docs: AgentInterface[];
  hasNextPage: boolean;
  hasPrevPage: boolean;
  limit: number;
  offset: number;
  page: number;
  nextPage: number;
  pagingCounter: number;
  prevPage: number | null;
  totalDocs: number;
  totalPages: number;
}

export type RequestType = 'marketplace' | 'quote' | undefined;

export interface Days {
  from: string;
  to: string;
  isAvailable: boolean;
}

export const dropdownItems: { [key: string]: Days } = {
  Mondays: { from: '8am', to: '4pm', isAvailable: true },
  Tuesdays: { from: '8am', to: '4pm', isAvailable: true },
  Wednesdays: { from: '8am', to: '4pm', isAvailable: true },
  Thursdays: { from: '8am', to: '4pm', isAvailable: true },
  Fridays: { from: '8am', to: '4pm', isAvailable: true },
  Saturdays: { from: '8am', to: '4pm', isAvailable: true },
  Sundays: { from: '', to: 'Unavailable', isAvailable: false },
};
export type SenderType = 'Me' | 'Friend';

export interface AvailabilityOptions {
  from: string;
  to: string;
  isAvailable: boolean;
}
export interface Available {
  monday: AvailabilityOptions;
  tuesday: AvailabilityOptions;
  wednesday: AvailabilityOptions;
  thursday: AvailabilityOptions;
  friday: AvailabilityOptions;
  saturday: AvailabilityOptions;
  sunday: AvailabilityOptions;
}
export const companyAvailability = {
  monday: {
    from: '',
    to: '',
    isAvailable: false,
  },
  tuesday: {
    from: '',
    to: '',
    isAvailable: false,
  },
  wednesday: {
    from: '',
    to: '',
    isAvailable: false,
  },
  thursday: {
    from: '',
    to: '',
    isAvailable: false,
  },
  friday: {
    from: '',
    to: '',
    isAvailable: false,
  },
  saturday: {
    from: '',
    to: '',
    isAvailable: false,
  },
  sunday: {
    from: '',
    to: '',
    isAvailable: false,
  },
};

export interface DeliverToInformation {
  name: string;
  phone: string;
  address: {
    fullAddress: string;
    latitude: string;
    longitude: string;
    placeId: string;
    area: string;
    originAddresses: [];
    _id: string;
  };
  estimate: string;
  packageType: string;
  deliveryNotes: string;
  designatedAgent?: string;
  packageDetail: string;
  packageValue: string;
  type: string;
  status: string;
  requestOpenedBy: string;
  trackingId: string;
  _id: string;
  id: string;
}

export interface MergedInformation {
  createdAt: string;
  destinationArea: string;
  pickupAddress: {
    senderInformation: {
      data: {
        name: string;
        email: string;
        state: string;
        phone: string;
        _id: string;
      };
      type: string;
    };
    fullAddress: string;
    latitude: string;
    longitude: string;
    placeId: string;
    location: {
      type: string;
      coordinates: number[];
      _id: string;
    };
    originAddresses: [];
    status: string;
    requestOpenedBy: string;
    trackingId: string;
    _id: string;
  };
  deliverToInformation: {
    name: string;
    phone: string;
    address: {
      fullAddress: string;
      latitude: string;
      longitude: string;
      placeId: string;
      area: string;
      originAddresses: [];
      _id: string;
    };
    estimate: string;
    packageType: string;
    deliveryNotes: string;
    packageDetail: string;
    packageValue: string;
    type: string;
    status: string;
    requestOpenedBy: string;
    trackingId: string;
    _id: string;
    id: string;
  };
}

export interface Batch {
  deliverToInformation: DestinationInterface;
  id: string;
  _id: string;
  batch: boolean;
  tag: string;
  area: string;
  pickupAddress: PickupAddressesInterface;
  pickupAddresses: PickupAddressesInterface[];
  pickupArea: {
    latitude: string;
    longitude: string;
    area: string;
  };
  pickupNotes: string;
  priceEstimate: string;
  outsourcedAt: string;
  afterChargeEstimate: number;
  destinationArea: { area: string; latitude: string; longitude: string };
  riderMarkedAsCompleted: boolean;
  requestAcceptedAt: null;
  requestCompletedAt: null;
  senderInformation: { data: { email?: string; name: string; phone: string } };
  country: keyof ITenantData;
  state: string;
  trackingId: string;
  type: string;
  status: string;
  parcelCode: string;
  totalDistanceEstimate: string;
  distance: string;
  createdAt: string;
  designatedAgent: AgentInterface;
  acceptedBy: SharedAccount;
  mapUrl: string;
  requestOpenedBy: string;
  mergedInformation: MergedInformation[];
  multiplePickup?: boolean;
  tracking_log: TrackInterface[];
  agentsWithInterest: AgentsWithInterestType[];
  businessName?: string;
  deliveryTimelineLabel: string;
  deliveryNotes: string;
}

export type AssignedToAgent = {
  companyName: string;
  mainAddress: {
    fullAddress: string;
    streetName: string;
    addressNumber: string;
    placeId: string;
    registeredCity: string;
    country: string;
    latitude: string;
    localGovtArea: string;
    longitude: string;
    state: string;
  };
  account: {
    _id: string;
    phone: string;
    id: string;
  };
  id: string;
};

export type DesignatedMiddleAgent = {
  id: string;
  firstname: string;
  lastname: string;
  phone: string;
  tripsCount: number;
};

export const RequestTypes = {
  pre_payment: 'pre_payment', // when user clicks to make payment,but not verified
  open: 'open', // open request, when payment has been verified
  booking: 'booking',
  closed: 'closed', // closed request
  pending: 'pending', // used to temporarily close a request
  accepted: 'accepted', // accepted and in progress - aka upcoming deliveries
  collected: 'collected', // package has been collected by the rider
  completed: 'completed', // finished the delivery
  open_dispute: 'open_dispute',
  quote: 'quote',
  sorted: 'sorted',
  assigned: 'assigned',
};

export const CancelRequestTypes = {
  full_refund: 'Your Funds will be refunded fully into your account',
  partial_refund:
    'A penalty of N500 will be imposed for cancelling after your request has been accepted.',
};

export type BatchType = 'pre-sorted' | 'sorted' | 'fulfilment';

export interface PartnerInterface {
  id: string;
  _id: string;
  companyName: string;
  mainAddress: {
    fullAddress: string;
    addressNumber: string;
    placeId: string;
    city: string;
    country: string;
    latitude: string;
    localGovt: string;
    longitude: string;
    state: string;
  };
  account: {
    id: string;
    phone: string;
  };
  areasOfOperations: [
    {
      country: string;
      states: [string];
    },
  ];
  distance: {
    value: number;
    valueInKm: number;
  };
}

export type BatchTabsInterface = {
  id: string;
  label: string;
  disabled: boolean;
};

export interface PartnerDropdownOptions {
  id: string;
  name: string;
  disabled: boolean;
  location: string;
}

export interface LocalityDropDownOptions {
  id: string;
  name: string;
  disabled: boolean;
}

export type SelectedPartnerType = {
  dispatcherId: string;
  stage: number;
  fulfilment: boolean;
  id: string;
  logistics: string;
  number: string;
  place: string;
  requests: number;
};

export type AgentRatingInterface = {
  rating: number;
  comment: string;
  agentId: string;
  request: string;
};

export interface PickupAddress {
  senderInformation: SenderInformation;
  fullAddress: string;
  latitude: string;
  longitude: string;
  placeId?: string;
  location?: Location;
  originAddresses?: any[];
  status?: string;
  requestOpenedBy?: string;
  trackingId?: string;
  _id?: string;
  id: string;
}

export interface SenderInformation {
  data: SenderData;
  type?: string;
}

export interface SenderData {
  name: string;
  email?: string;
  state?: string;
  phone: string;
  _id?: string;
}

export interface Location {
  type: string;
  coordinates: number[];
  _id: string;
}

export interface CommerceSlugResponse {
  lockerClient: boolean;
  businessName: string;
  email: string;
  account: string;
  id: string;
  roleId: string;
  state: string;
  country: string;
  businessPays: boolean;
  mainAddress: {
    fullAddress: string;
    placeId: string;
    city: string;
    country: string;
    latitude: string;
    localGovt: string;
    longitude: string;
    state: string;
    originAddresses?: string[];
    postalCode: string;
  };
  phone: string;
  activateDirectory: boolean;
}

export interface ProductType {
  id: string;
  images: string[];
  price: string;
  name: string;
  quantity: number;
  _id: string;
}

export interface ProductsListData {
  docs: ProductType[];
  hasNextPage: boolean;
  hasPrevPage: boolean;
  limit: number;
  nextPage: number;
  page: number;
  pagingCounter: number;
  prevPage: number;
  totalDocs: number;
  totalPages: number;
}

export const ExperienceChargesType = {
  Customer: 'customer',
  Business: 'business',
};

export type OutletType =
  | 'experience'
  | 'market'
  | 'essentials'
  | 'product'
  | 'logistics'
  | 'restaurant';

export type DispatcherOutletType =
  | 'market'
  | 'essential'
  | 'product'
  | 'logistics'
  | 'restaurant';

export type TicketTypes =
  | 'vip'
  | 'regular'
  | 'cabana'
  | 'tableFor4'
  | 'earlyRelease';

export type Charges = 'customer' | 'business';
export type TicketCategory = 'single' | 'group';
export interface Ticket {
  id: number;
  type: TicketTypes;
  quantity: number;
  price: number;
  active: boolean;
  expiryDate: string;
  charge: Charges;
  whoPays?: Charges;
  ticketCategory: TicketCategory;
  groupQuantity?: number;
}

export type Media = {
  type: 'image' | 'video';
  url: string;
};

export interface CreateExperienceProps {
  name: string;
  description: string;
  location: string;
  roleId: string;
  experienceDate: string;
  timeZone: string;
  media?: Media[];
  tickets: Ticket[];
  category: string;
}

export interface CreateExperienceResponse {
  status: boolean;
  message: string;
  uuid: string;
}

export interface ExperienceCategory {
  id: string;
  name: string;
  description: string;
  mode: string;
}

export interface UpdateBank {
  bankDetails: {
    accountName: string;
    accountNumber: string;
    bankCode: string;
    bankName: string;
  };
  commerceId: string;
}

export interface CreateBuisinessProps {
  name: string;
  phone: string;
  location: string;
  roleId: string;
  businessClosingTime: string;
  businessOpeningTime: string;
  bannerUrl?: string;
}

export interface CreateServiceProfileProps {
  outletType: string;
  roleId: string;
  address: {
    fullAddress: string;
    latitude: string;
    longitude: string;
  };
  name: string;
  bannerImage: string;
  phone: string;
  email: string;
  opens: string;
  closes: string;
}

export interface ChildOption {
  childItemType?: string;
  childItemImage?: string;
  childItemName?: string;
  childItemDescription?: string;
  childItemPrice?: number;
  childItemQuantity?: number;
  childItemInStock?: boolean;
}

export interface SubItem {
  subItemName: string;
  subItemImage: string;
  subItemDescription: string;
  subItemQuantity: number;
  subItemPrice: number;
  subItemInStock: boolean;
  subItemRequired?: boolean;
  childOptions?: ChildOption[];
}

export interface MenuItem {
  itemName: string;
  itemPrice: number;
  itemDescription: string;
  itemImage: string;
  itemPopular: boolean;
  itemHandPicked: boolean;
  itemInStock: boolean;
  options?: SubItem[];
}

export interface MenuCategory {
  _id?: string;
  cuisine?: string;
  shelf?: string;
  categoryName: string;
  categoryDescription: string;
  categoryId?: string;
  categoryImage: string;
  items: MenuItem[];
}

export type Menu = MenuCategory[];
export interface ProductsItem {
  itemName: string;
  itemPrice: number;
  itemPopular: boolean;
  itemHandPicked: boolean;
  itemInStock: boolean;
}
export interface ProductsCategory {
  categoryName: string;
  categoryDescription: string;
  categoryImage: string;
  items: ProductsItem[];
}

export type Products = ProductsCategory[];

export const ServiceOutletType = {
  restaurant: 'restaurant',
  market: 'market',
  essentials: 'essentials',
  product: 'product',
  logistics: 'logistics',
} as const;

export type ServiceOutletTypeKey =
  (typeof ServiceOutletType)[keyof typeof ServiceOutletType];

export interface ServiceProfile {
  id: string;
  name: string;
  address: string;
  bannerImage?: string;
  email: string;
  openingTime: string;
  closingTime: string;
  phone: string;
}
export interface GetServiceProducts {
  _id: string;
  item: string;
  category: string;
  price: string;
  subMenu: string;
  description: string;
}

export interface ProductFormFieldsProps {
  _id?: string;
  cuisine: string;
  shelf: string;
  categoryName: string;
  categoryDescription: string;
  categoryImage: string;
  items: {
    itemName: string;
    itemPrice: number;
    itemDescription: string;
    itemImage: string;
    itemPopular: boolean;
    itemHandPicked: boolean;
    itemInStock: boolean;
    options: {
      subItemName: string;
      subItemImage: string;
      subItemPrice: number;
      subItemDescription: string;
      subItemQuantity: number;
      subItemInStock: boolean;
      subItemRequired: boolean;
      childOptions?: {
        childItemName: string;
        childItemPrice: number;
        childItemDescription: string;
        childItemImage: string;
        childItemInStock: boolean;
      }[];
    }[];
  }[];
}

export interface SingleProductFetchResponse {
  _id: string;
  vendor: string;
  category: {
    _id: string;
    vendor: string;
    name: string;
    __v: number;
    createdAt: string;
    cuisine: string;
    shelf: string;
    description: string;
    images: string[];
    options: string[];
    restaurant: string;
    updatedAt: string;
  };
  name: string;
  createdAt: string;
  description: string;
  handPicked: boolean;
  image: string;
  inStock: boolean;
  options: any[];
  popular: boolean;
  price: number;
  restaurant: string;
  sku: string;
  updatedAt: string;
}
