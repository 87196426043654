import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import {
  ElrButton,
  ElrInput,
  ElrTextAreaInput,
  ElrUploadThumbnail,
} from 'ui-components';
import * as Yup from 'yup';

import infoIcon from 'assets/images/infoIcon.svg';

export interface ProductFormValue {
  productName: string;
  productPrice: string;
  productQuantity: string;
  productDescription: string;
  thumbnails: string[];
}
interface ProductProps {
  edit?: boolean;
  onSubmit: Function;
}

const ProductSchema = Yup.object().shape({
  productName: Yup.string().required('Required'),
  productPrice: Yup.string().required('Required'),
  productQuantity: Yup.string().required('Required'),
  productDescription: Yup.string().required('Required'),
  thumbnails: Yup.array().of(Yup.string().required('Required')),
});

const ProductForm: React.FC<ProductProps> = ({ edit = false, onSubmit }) => {
  const [thumbnails, setThumbnails] = useState<string[]>([]);
  const [initialValues, setInitialValues] = useState<ProductFormValue>({
    productName: '',
    productPrice: '',
    productQuantity: '',
    productDescription: '',
    thumbnails: [],
  });

  useEffect(() => {
    if (!edit) return;
    // fetch the details of the product to edit here

    setInitialValues({
      productName: 'Nike Shoe',
      productPrice: '200',
      productQuantity: '',
      productDescription: '',
      thumbnails: [],
    });
    setThumbnails([]);
  }, [edit]);

  const { handleSubmit, values, handleChange, errors } =
    useFormik<ProductFormValue>({
      initialValues,
      enableReinitialize: true,
      validationSchema: ProductSchema,
      onSubmit: (value: ProductFormValue) => onSubmit(value),
    });

  return (
    <div className="bg-elr-gray-500">
      <div className="bg-elr-white-400 flex flex-col items-center rounded-xl shadow-xl mt-5 py-10">
        <h2 className="text-center text-lg">
          <img className="inline mr-2 pb-1" src={infoIcon} alt="info" />
          <span>{edit ? 'Edit' : 'Sell a'} Product</span>
        </h2>
        <form
          onSubmit={handleSubmit}
          className="w-full flex justify-center flex-col mt-10 px-6"
        >
          {errors?.productName && (
            <span className="text-xs text-elr-error">
              {errors?.productName}
            </span>
          )}
          <ElrInput
            className="bg-elr-gray rounded-none border-none mb-3"
            onChange={handleChange}
            placeholder="Product name"
            name="productName"
            value={values.productName}
          />
          {errors?.productPrice && (
            <span className="text-xs text-elr-error">
              {errors?.productPrice}
            </span>
          )}
          <ElrInput
            className="bg-elr-gray rounded-none border-none mb-3"
            type="number"
            onChange={handleChange}
            placeholder="Product price"
            value={values.productPrice}
            name="productPrice"
          />
          {errors?.productQuantity && (
            <span className="text-xs text-elr-error">
              {errors?.productQuantity}
            </span>
          )}
          <ElrInput
            className="bg-elr-gray rounded-none border-none mb-3"
            type="string"
            onChange={handleChange}
            placeholder="Product quantity"
            value={values.productQuantity}
            name="productQuantity"
          />

          <ElrUploadThumbnail
            thumbnails={thumbnails}
            setThumbnails={setThumbnails}
          />

          {errors?.productDescription && (
            <span className="text-xs text-elr-error">
              {errors?.productDescription}
            </span>
          )}
          <ElrTextAreaInput
            className="bg-elr-gray rounded-none border-none mb-3 p-2"
            value={values.productDescription}
            name="productDescription"
            onChange={handleChange}
            placeholder="Add a brief description of the product such as size and color where it applies."
          />

          <div className="text-center mt-3">
            <ElrButton
              type="submit"
              size="sm"
              className="bg-elr-black text-elr-white px-5"
              text={edit ? 'Save Changes' : 'Generate link'}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProductForm;
