import React from 'react';
import { Link } from 'react-router-dom';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import { ElrButton } from 'ui-components';
import { privateRoutes, publicRoutes } from 'app/utilities/routes';
import { currentTimeLogo } from 'app/modules/landing/components/ClassUtils';
import { useLogout } from 'app/hooks/auth';
import { Session } from 'app/utilities/Session';

interface IProps {
  onClose: () => void;
  navLinks: { path: string; label: string }[];
  isDefault?: boolean;
}

export const HamburgerMenu: React.FC<IProps> = ({
  onClose,
  navLinks,
  isDefault = true,
}) => {
  const isAuthenticated = Boolean(Session.currentUser());
  const [sendLogout] = useLogout();
  return (
    <div className="fixed inset-0 mx-5 my-7 bg-elr-gray z-50 flex flex-col items-center justify-start p-4  rounded-xl">
      <div className="flex justify-between items-center w-full p-3 align-middle">
        <Link to="/">
          <img
            src={currentTimeLogo(isDefault)}
            alt="site logo"
            className="w-9/12"
          />
        </Link>
        <div className="absolute top-5 right-5 cursor-pointer">
          <ClearRoundedIcon fontSize="medium" onClick={onClose} />
        </div>
      </div>

      <div className="flex flex-col items-center gap-7 mt-10">
        {navLinks.map((link) => (
          <Link
            key={link.path}
            to={link.path}
            onClick={onClose}
            className="text-lg text-gray-800 hover:underline"
          >
            {link.label}
          </Link>
        ))}
      </div>

      <div className="flex gap-4 mt-16">
        {isAuthenticated ? (
          <>
            <Link to={privateRoutes.selectPortal}>
              <ElrButton
                text="Select Portal"
                className="bg-white text-elr-black w-fit"
              />
            </Link>

            <Link to={publicRoutes.registration}>
              <ElrButton
                onClick={() => sendLogout()}
                text="Logout"
                className="px-8 py-2 bg-elr-black text-white w-fit"
              />
            </Link>
          </>
        ) : (
          <>
            <Link to={publicRoutes.login}>
              <ElrButton
                onClick={onClose}
                text="Log In"
                className="bg-white text-elr-black w-fit"
              />
            </Link>
            <Link to={publicRoutes.registration}>
              <ElrButton
                text="Create Account"
                onClick={onClose}
                className="px-8 py-2 bg-elr-black text-white w-fit"
              />
            </Link>
          </>
        )}
      </div>
    </div>
  );
};
